import { supabase } from '../../lib/supabase';

export async function verifyMembershipDetails(membershipPatientId: string): Promise<any> {
  try {
    // Get the latest membership details
    const { data: memberships, error } = await supabase
      .from('memberships')
      .select(`
        membership_name,
        charge_amount,
        status,
        date_created,
        start_date,
        internal_interval,
        last_run,
        last_run_status,
        next_run_date
      `)
      .eq('membership_patient_id', membershipPatientId)
      .order('date_created', { ascending: false })
      .limit(1);

    if (error) throw error;
    return memberships?.[0] || null;
  } catch (error) {
    console.error('Error verifying membership details:', error);
    return null;
  }
}