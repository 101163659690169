import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Search, Users, Leaf, LogOut, Calendar, ChevronDown, ShoppingCart, ArrowLeft } from 'lucide-react';
import { supabase } from '../lib/supabase';
import { useAuth } from '../contexts/AuthContext';
import { PatientDetailsModal } from '../components/PatientDetailsModal';
import { formatPacificDate } from '../utils/dateTime';

interface Patient {
  id: string;
  patient_id: string;
  first_name: string;
  last_name: string;
  last_visit: string | null;
  next_visit: string | null;
}

type SortField = 'patient_id' | 'first_name' | 'last_name' | 'last_visit' | 'next_visit';
type SortDirection = 'asc' | 'desc';

export function Patients() {
  const navigate = useNavigate();
  const { user, userRoles, signOut } = useAuth();
  const [patients, setPatients] = useState<Patient[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedPatient, setSelectedPatient] = useState<string | null>(null);
  const [sortField, setSortField] = useState<SortField>('last_name');
  const [sortDirection, setSortDirection] = useState<SortDirection>('asc');

  useEffect(() => {
    fetchPatients();
  }, []);

  const fetchPatients = async () => {
    try {
      const { data, error } = await supabase.rpc('get_patients_with_last_visit');
      if (error) throw error;
      setPatients(data || []);
    } catch (error) {
      console.error('Error fetching patients:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSort = (field: SortField) => {
    if (field === sortField) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortDirection('asc');
    }
  };

  const filteredAndSortedPatients = patients
    .filter(patient => {
      const searchLower = searchTerm.toLowerCase();
      return (
        patient.first_name.toLowerCase().includes(searchLower) ||
        patient.last_name.toLowerCase().includes(searchLower) ||
        patient.patient_id.toLowerCase().includes(searchLower)
      );
    })
    .sort((a, b) => {
      const direction = sortDirection === 'asc' ? 1 : -1;
      
      switch (sortField) {
        case 'patient_id':
          return (a.patient_id > b.patient_id ? 1 : -1) * direction;
        case 'first_name':
          return (a.first_name > b.first_name ? 1 : -1) * direction;
        case 'last_name':
          return (a.last_name > b.last_name ? 1 : -1) * direction;
        case 'last_visit':
          if (!a.last_visit && !b.last_visit) return 0;
          if (!a.last_visit) return 1 * direction;
          if (!b.last_visit) return -1 * direction;
          return (new Date(a.last_visit) > new Date(b.last_visit) ? 1 : -1) * direction;
        case 'next_visit':
          if (!a.next_visit && !b.next_visit) return 0;
          if (!a.next_visit) return 1 * direction;
          if (!b.next_visit) return -1 * direction;
          return (new Date(a.next_visit) > new Date(b.next_visit) ? 1 : -1) * direction;
        default:
          return 0;
      }
    });

  return (
    <div className="min-h-screen flex flex-col bg-gradient-to-br from-green-50 to-blue-50">
      {/* Fixed Header */}
      <nav className="bg-white shadow-sm">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16">
            <div className="flex items-center space-x-4">
              <button
                onClick={() => navigate('/dashboard')}
                className="text-gray-600 hover:text-gray-900"
              >
                <ArrowLeft className="h-6 w-6" />
              </button>
              <Leaf className="h-6 w-6 text-green-600" />
              <div className="ml-2">
                <span className="text-xl font-semibold text-gray-900">THRIVE</span>
                <span className="ml-2 text-sm text-gray-500">Patients</span>
              </div>
            </div>
            <div className="flex items-center space-x-4">
              <span className="text-sm text-gray-700">{user?.email}</span>
              <div className="flex flex-wrap gap-2">
                {userRoles.map((role) => (
                  <span
                    key={role}
                    className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800"
                  >
                    {role}
                  </span>
                ))}
              </div>
              <button
                onClick={() => signOut()}
                className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-green-600 hover:bg-green-700"
              >
                <LogOut className="h-4 w-4 mr-2" />
                Sign Out
              </button>
            </div>
          </div>
        </div>
      </nav>

      {/* Fixed Search Bar */}
      <div className="bg-white shadow-sm">
        <div className="max-w-7xl mx-auto px-4 py-4 sm:px-6 lg:px-8">
          <div className="relative">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
            <input
              type="text"
              placeholder="Search by name or ID..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="pl-10 pr-4 py-2 w-full rounded-lg border border-gray-300 focus:ring-2 focus:ring-green-500 focus:border-transparent"
            />
          </div>
        </div>
      </div>

      {/* Scrollable Content Area */}
      <div className="flex-1 overflow-hidden">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 h-full py-6">
          <div className="bg-white shadow-lg rounded-lg h-[calc(100vh-12rem)] flex flex-col">
            <div className="px-6 py-5 border-b border-gray-200">
              <div className="flex items-center">
                <Users className="h-5 w-5 text-green-600" />
                <h3 className="ml-2 text-lg font-medium text-gray-900">
                  Patient List
                </h3>
              </div>
            </div>

            <div className="flex-1 overflow-hidden">
              <div className="h-full overflow-auto">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50 sticky top-0 z-10">
                    <tr>
                      <th 
                        scope="col" 
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100"
                        onClick={() => handleSort('patient_id')}
                      >
                        <div className="flex items-center">
                          ID
                          <ChevronDown className={`ml-1 h-4 w-4 transform transition-transform ${
                            sortField === 'patient_id' && sortDirection === 'desc' ? 'rotate-180' : ''
                          } ${sortField === 'patient_id' ? 'opacity-100' : 'opacity-0'}`} />
                        </div>
                      </th>
                      <th 
                        scope="col" 
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100"
                        onClick={() => handleSort('last_name')}
                      >
                        <div className="flex items-center">
                          Last Name
                          <ChevronDown className={`ml-1 h-4 w-4 transform transition-transform ${
                            sortField === 'last_name' && sortDirection === 'desc' ? 'rotate-180' : ''
                          } ${sortField === 'last_name' ? 'opacity-100' : 'opacity-0'}`} />
                        </div>
                      </th>
                      <th 
                        scope="col" 
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100"
                        onClick={() => handleSort('first_name')}
                      >
                        <div className="flex items-center">
                          First Name
                          <ChevronDown className={`ml-1 h-4 w-4 transform transition-transform ${
                            sortField === 'first_name' && sortDirection === 'desc' ? 'rotate-180' : ''
                          } ${sortField === 'first_name' ? 'opacity-100' : 'opacity-0'}`} />
                        </div>
                      </th>
                      <th 
                        scope="col" 
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100"
                        onClick={() => handleSort('last_visit')}
                      >
                        <div className="flex items-center">
                          Last Visit
                          <ChevronDown className={`ml-1 h-4 w-4 transform transition-transform ${
                            sortField === 'last_visit' && sortDirection === 'desc' ? 'rotate-180' : ''
                          } ${sortField === 'last_visit' ? 'opacity-100' : 'opacity-0'}`} />
                        </div>
                      </th>
                      <th 
                        scope="col" 
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100"
                        onClick={() => handleSort('next_visit')}
                      >
                        <div className="flex items-center">
                          Next Visit
                          <ChevronDown className={`ml-1 h-4 w-4 transform transition-transform ${
                            sortField === 'next_visit' && sortDirection === 'desc' ? 'rotate-180' : ''
                          } ${sortField === 'next_visit' ? 'opacity-100' : 'opacity-0'}`} />
                        </div>
                      </th>
                      <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {loading ? (
                      <tr>
                        <td colSpan={6} className="px-6 py-4 text-center text-gray-500">
                          Loading patients...
                        </td>
                      </tr>
                    ) : filteredAndSortedPatients.length === 0 ? (
                      <tr>
                        <td colSpan={6} className="px-6 py-4 text-center text-gray-500">
                          No patients found
                        </td>
                      </tr>
                    ) : (
                      filteredAndSortedPatients.map((patient) => (
                        <tr
                          key={patient.id}
                          className="hover:bg-gray-50 cursor-pointer transition-colors duration-150"
                        >
                          <td 
                            className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                            onClick={() => setSelectedPatient(patient.patient_id)}
                          >
                            {patient.patient_id}
                          </td>
                          <td 
                            className="px-6 py-4 whitespace-nowrap text-sm text-gray-900"
                            onClick={() => setSelectedPatient(patient.patient_id)}
                          >
                            {patient.last_name}
                          </td>
                          <td 
                            className="px-6 py-4 whitespace-nowrap text-sm text-gray-900"
                            onClick={() => setSelectedPatient(patient.patient_id)}
                          >
                            {patient.first_name}
                          </td>
                          <td 
                            className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                            onClick={() => setSelectedPatient(patient.patient_id)}
                          >
                            <div className="flex items-center">
                              <Calendar className="h-4 w-4 mr-2 text-gray-400" />
                              {patient.last_visit ? (
                                formatPacificDate(patient.last_visit)
                              ) : (
                                'No visits'
                              )}
                            </div>
                          </td>
                          <td 
                            className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                            onClick={() => setSelectedPatient(patient.patient_id)}
                          >
                            <div className="flex items-center">
                              <Calendar className="h-4 w-4 mr-2 text-gray-400" />
                              {patient.next_visit ? (
                                formatPacificDate(patient.next_visit)
                              ) : (
                                'None scheduled'
                              )}
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                            <button
                              onClick={() => navigate(`/patients/${patient.patient_id}/services`)}
                              className="text-green-600 hover:text-green-900"
                              title="Record Services"
                            >
                              <ShoppingCart className="h-5 w-5" />
                            </button>
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Patient Details Modal */}
      <PatientDetailsModal
        patientId={selectedPatient || ''}
        isOpen={!!selectedPatient}
        onClose={() => setSelectedPatient(null)}
      />
    </div>
  );
}