import React, { useState, useEffect, useRef } from 'react';
import { X, AlertCircle } from 'lucide-react';
import { supabase } from '../lib/supabase';

interface ServiceLevelModalProps {
  serviceLevel?: {
    id: string;
    name: string;
    level: string;
    description: string;
  } | null;
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

export function ServiceLevelModal({ serviceLevel, isOpen, onClose, onSuccess }: ServiceLevelModalProps) {
  const [name, setName] = useState('');
  const [level, setLevel] = useState('provider_visit');
  const [description, setDescription] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const nameInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isOpen && nameInputRef.current) {
      nameInputRef.current.focus();
    }
  }, [isOpen]);

  useEffect(() => {
    if (serviceLevel) {
      setName(serviceLevel.name);
      setLevel(serviceLevel.level);
      setDescription(serviceLevel.description || '');
    } else {
      setName('');
      setLevel('provider_visit');
      setDescription('');
    }
  }, [serviceLevel]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    setLoading(true);

    try {
      if (!name.trim()) {
        throw new Error('Service level name is required');
      }

      if (serviceLevel?.id) {
        // Update existing service level
        const { error: updateError } = await supabase
          .from('service_levels')
          .update({
            name: name.trim(),
            level,
            description: description.trim() || null
          })
          .eq('id', serviceLevel.id);

        if (updateError) throw updateError;
      } else {
        // Create new service level
        const { error: insertError } = await supabase
          .from('service_levels')
          .insert({
            name: name.trim(),
            level,
            description: description.trim() || null
          });

        if (insertError) throw insertError;
      }

      onSuccess();
      onClose();
    } catch (error: any) {
      console.error('Error saving service level:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg shadow-xl max-w-md w-full">
        <div className="px-6 py-4 border-b border-gray-200 flex items-center justify-between">
          <h3 className="text-lg font-medium text-gray-900">
            {serviceLevel?.id ? 'Edit Service Level' : 'Add New Service Level'}
          </h3>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-500"
          >
            <X className="h-5 w-5" />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="p-6">
          {error && (
            <div className="mb-4 p-4 bg-red-50 rounded-md">
              <div className="flex">
                <AlertCircle className="h-5 w-5 text-red-400" />
                <p className="ml-3 text-sm text-red-700">{error}</p>
              </div>
            </div>
          )}

          <div className="space-y-4">
            <div>
              <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                Name
              </label>
              <input
                ref={nameInputRef}
                type="text"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                placeholder="Enter service level name"
                required
              />
            </div>

            <div>
              <label htmlFor="level" className="block text-sm font-medium text-gray-700">
                Level Type
              </label>
              <select
                id="level"
                value={level}
                onChange={(e) => setLevel(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
              >
                <option value="provider_visit">Provider Visit</option>
                <option value="nurse_visit">Nurse Visit</option>
                <option value="membership_visit">Membership Visit</option>
                <option value="initial_visit">Initial Visit</option>
                <option value="follow_up_visit">Follow-up Visit</option>
              </select>
            </div>

            <div>
              <label htmlFor="description" className="block text-sm font-medium text-gray-700">
                Description
              </label>
              <textarea
                id="description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                rows={3}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                placeholder="Enter service level description (optional)"
              />
            </div>
          </div>

          <div className="mt-6 flex justify-end space-x-3">
            <button
              type="button"
              onClick={onClose}
              className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 disabled:opacity-50 disabled:cursor-not-allowed"
              disabled={loading}
            >
              {loading ? (
                <>
                  <span className="animate-spin rounded-full h-4 w-4 border-b-2 border-white mr-2" />
                  {serviceLevel?.id ? 'Updating...' : 'Creating...'}
                </>
              ) : (
                serviceLevel?.id ? 'Update Service Level' : 'Create Service Level'
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}