import React, { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft, Leaf } from 'lucide-react';
import * as XLSX from 'xlsx';
import { supabase } from '../lib/supabase';
import { FileUploader } from '../components/membership/FileUploader';
import { DataPreview } from '../components/membership/DataPreview';
import { ProcessingLog } from '../components/membership/ProcessingLog';
import { ProcessingStats } from '../components/membership/ProcessingStats';
import { ImportInstructions } from '../components/membership/ImportInstructions';

interface PreviewData {
  headers: string[];
  rows: any[][];
}

interface ProcessingStats {
  total: number;
  processed: number;
  skipped: number;
  errors: number;
}

type LogType = 'info' | 'error' | 'success' | 'data' | 'verification';

interface LogEntry {
  type: LogType;
  message: string;
  timestamp: Date;
  data?: any;
}

interface PaymentRecord {
  clientId: string;
  membershipName: string;
  lastRan: string;
  lastRanStatus: string;
  chargeAmount: number | null;
}

export function MembershipPaymentsImport() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [preview, setPreview] = useState<PreviewData | null>(null);
  const [logs, setLogs] = useState<LogEntry[]>([]);
  const [stats, setStats] = useState<ProcessingStats | null>(null);

  const addLog = (type: LogType, message: string, data?: any) => {
    setLogs(prev => [...prev, { type, message, timestamp: new Date(), data }]);
  };

  const cleanupDuplicateRecords = async () => {
    try {
      addLog('info', 'Starting duplicate records cleanup...');
      
      const { error: cleanupError } = await supabase.rpc('cleanup_duplicate_membership_activity');
      
      if (cleanupError) throw cleanupError;
      
      addLog('success', 'Duplicate records cleanup completed successfully');
    } catch (error: any) {
      console.error('Error during cleanup:', error);
      addLog('error', `Error during cleanup: ${error.message}`);
    }
  };

  const isValidDate = (dateStr: string): boolean => {
    const date = new Date(dateStr);
    return date instanceof Date && !isNaN(date.getTime());
  };

  const parseChargeAmount = (value: any): number | null => {
    if (!value) return null;
    
    // If it's already a number, return it
    if (typeof value === 'number') return value;
    
    // If it's a string, clean it and parse it
    if (typeof value === 'string') {
      // Remove currency symbols, commas, and whitespace
      const cleanValue = value.replace(/[$,\s]/g, '');
      
      // Try to parse as float
      const amount = parseFloat(cleanValue);
      
      // Return the amount if valid, otherwise null
      return isNaN(amount) ? null : Math.abs(amount);
    }
    
    return null;
  };

  const processPaymentBatch = async (records: PaymentRecord[]) => {
    try {
      // Get all membership patients for this batch in one query
      const clientIds = records.map(r => r.clientId);
      const { data: patients, error: patientsError } = await supabase
        .from('membership_patients')
        .select('id, external_id')
        .in('external_id', clientIds);

      if (patientsError) throw patientsError;

      // Create a map for quick lookup
      const patientMap = new Map(patients?.map(p => [p.external_id, p.id]) || []);

      // Get all memberships for these patients in one query
      if (patients && patients.length > 0) {
        const { data: memberships, error: membershipsError } = await supabase
          .from('memberships')
          .select('id, membership_name, membership_patient_id')
          .in('membership_patient_id', patients.map(p => p.id));

        if (membershipsError) throw membershipsError;

        // Create a map for quick membership lookup
        const membershipMap = new Map(
          memberships?.map(m => [`${m.membership_patient_id}-${m.membership_name}`, m.id]) || []
        );

        // Prepare all payment records
        const paymentRecords = records
          .map(record => {
            const patientId = patientMap.get(record.clientId);
            if (!patientId) return null;

            const membershipId = membershipMap.get(`${patientId}-${record.membershipName}`);
            if (!membershipId) return null;

            const formattedDate = new Date(record.lastRan).toISOString().split('T')[0];

            return {
              membership_id: membershipId,
              Last_ran: formattedDate,
              charge_status: record.lastRanStatus || 'not run',
              charge_amount: record.chargeAmount
            };
          })
          .filter((r): r is NonNullable<typeof r> => r !== null);

        if (paymentRecords.length > 0) {
          // Insert all records in one query
          const { error: insertError } = await supabase
            .from('membership_activity')
            .insert(paymentRecords);

          if (insertError) throw insertError;

          return paymentRecords.length;
        }
      }

      return 0;
    } catch (error) {
      throw error;
    }
  };

  const processFile = async (file: File) => {
    setLoading(true);
    setStats(null);
    addLog('info', `Processing file: ${file.name}`);
    addLog('info', `File size: ${(file.size / 1024).toFixed(2)} KB`);

    const reader = new FileReader();
    
    reader.onload = async (e) => {
      try {
        const data = e.target?.result;
        addLog('info', 'File loaded successfully, parsing contents...');
        
        const workbook = XLSX.read(data, { type: 'binary', cellDates: true });
        addLog('info', `Workbook loaded with ${workbook.SheetNames.length} sheet(s)`);
        
        const firstSheetName = workbook.SheetNames[0];
        addLog('info', `Processing first sheet: ${firstSheetName}`);
        
        const worksheet = workbook.Sheets[firstSheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { 
          header: 1,
          raw: false,
          dateNF: 'yyyy-mm-dd'
        });
        
        if (jsonData.length < 2) {
          throw new Error('File has insufficient data');
        }

        // Set up preview data
        const previewHeaders = [
          'Client',
          'ClientId',
          'Location',
          'Membership',
          'Status',
          'Charge Amount',
          'Last Ran',
          'Last Ran Status'
        ];

        const previewRows = jsonData.slice(1, 6).map(row => [
          (row as any[])[1] || '',  // Client
          (row as any[])[2] || '',  // ClientId (Column C)
          (row as any[])[3] || '',  // Location
          (row as any[])[4] || '',  // Membership
          (row as any[])[5] || '',  // Status
          (row as any[])[6] || '',  // Charge Amount
          (row as any[])[10] || '', // Last Ran
          (row as any[])[11] || ''  // Last Ran Status
        ]);

        setPreview({ headers: previewHeaders, rows: previewRows });

        // Process the data rows
        const rows = jsonData.slice(1) as any[][]; // Skip header row
        addLog('info', `Found ${rows.length} records to process`);

        const stats: ProcessingStats = {
          total: rows.length,
          processed: 0,
          skipped: 0,
          errors: 0
        };

        // Prepare batches of records
        const BATCH_SIZE = 50;
        const paymentRecords: PaymentRecord[] = [];

        // First, collect all valid records
        for (const row of rows) {
          const clientId = row[2]?.trim(); // Column C: ClientId
          const membershipName = row[4]?.trim(); // Column E: Membership
          const chargeAmount = parseChargeAmount(row[6]); // Column G: Charge Amount
          const lastRan = row[10]; // Column K: Last Ran
          const lastRanStatus = row[11]?.toLowerCase(); // Column L: Last Ran Status

          if (!clientId || !lastRan || !isValidDate(lastRan) || !membershipName) {
            stats.skipped++;
            continue;
          }

          paymentRecords.push({
            clientId,
            membershipName,
            lastRan,
            lastRanStatus: lastRanStatus || 'not run',
            chargeAmount
          });
        }

        // Process records in batches
        for (let i = 0; i < paymentRecords.length; i += BATCH_SIZE) {
          const batch = paymentRecords.slice(i, i + BATCH_SIZE);
          try {
            const processedCount = await processPaymentBatch(batch);
            stats.processed += processedCount;
            stats.skipped += (batch.length - processedCount);

            // Update progress
            setStats({ ...stats });
            addLog('success', `Processed batch ${Math.floor(i / BATCH_SIZE) + 1}`);
          } catch (error: any) {
            console.error('Error processing batch:', error);
            stats.errors++;
            addLog('error', `Error processing batch: ${error.message}`);
          }
        }

        setStats(stats);
        addLog('success', 'File processing complete');
        addLog('info', `Processed: ${stats.processed}, Skipped: ${stats.skipped}, Errors: ${stats.errors}`);

        // Run cleanup after processing
        await cleanupDuplicateRecords();

      } catch (error: any) {
        addLog('error', `Error processing file: ${error.message}`);
        console.error('Error:', error);
      } finally {
        setLoading(false);
      }
    };

    reader.onerror = () => {
      addLog('error', 'Error reading file');
      setLoading(false);
    };

    reader.readAsBinaryString(file);
  };

  const onDrop = useCallback((acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) {
      processFile(acceptedFiles[0]);
    }
  }, []);

  return (
    <div className="min-h-screen bg-gradient-to-br from-green-50 to-blue-50">
      <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        <div className="px-4 py-4 sm:px-0">
          <div className="flex items-center mb-6">
            <button
              onClick={() => navigate('/settings')}
              className="mr-4 text-gray-600 hover:text-gray-900"
            >
              <ArrowLeft className="h-6 w-6" />
            </button>
            <h1 className="text-2xl font-bold text-gray-900">Import Membership Payments</h1>
          </div>

          <div className="bg-white shadow-lg rounded-lg overflow-hidden">
            <div className="relative">
              <div className="absolute inset-0 bg-gradient-to-r from-green-600 to-blue-600 opacity-90"></div>
              <div className="relative px-8 py-12">
                <div className="flex items-center justify-between">
                  <Leaf className="h-12 w-12 text-white opacity-20" />
                </div>
                <div className="mt-4 text-center">
                  <h1 className="text-4xl font-bold text-white mb-2">THRIVE</h1>
                  <p className="text-green-100 text-lg mb-4">
                    Tracking Health and Reducing Illness Via Efficiency
                  </p>
                  <div className="w-16 h-1 bg-white/30 mx-auto rounded-full"></div>
                  <h2 className="text-2xl font-semibold text-white mt-4">Membership Payments Import</h2>
                </div>
              </div>
            </div>

            <div className="p-6">
              <ImportInstructions />
              <FileUploader onDrop={onDrop} />
              {preview && <DataPreview headers={preview.headers} rows={preview.rows} />}
              <ProcessingLog logs={logs} />
              <ProcessingStats stats={stats} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}