import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  ArrowLeft, 
  Leaf, 
  Tags, 
  FileText,
  Check,
  X,
  AlertCircle,
  Info,
  ThumbsUp,
  ThumbsDown,
  Filter,
  Clock,
  CheckCircle,
  XCircle,
  Settings,
  FileCode2,
  Search
} from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import { supabase } from '../lib/supabase';
import { EnhancementNotesModal } from '../components/EnhancementNotesModal';

interface Suggestion {
  id: string;
  title: string;
  description: string;
  created_at: string;
  created_by_email: string;
  upvotes: number;
  downvotes: number;
  status: 'pending' | 'approved' | 'rejected' | 'implemented';
}

export function EnhancementManagement() {
  const navigate = useNavigate();
  const { userRoles } = useAuth();
  const isAdmin = userRoles.includes('admin');
  const [suggestions, setSuggestions] = useState<Suggestion[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState<string | null>(null);
  const [sortBy, setSortBy] = useState<'votes' | 'date'>('date');
  const [showNotesModal, setShowNotesModal] = useState(false);
  const [selectedSuggestion, setSelectedSuggestion] = useState<Suggestion | null>(null);
  const [pendingStatusChanges, setPendingStatusChanges] = useState<Record<string, string>>({});

  useEffect(() => {
    if (!isAdmin) {
      navigate('/settings');
      return;
    }
    fetchSuggestions();
  }, [isAdmin, navigate, sortBy]);

  const fetchSuggestions = async () => {
    try {
      setLoading(true);
      setError(null);

      const { data: suggestionsData, error: suggestionsError } = await supabase
        .from('enhancement_suggestions')
        .select('*')
        .order('created_at', { ascending: false });

      if (suggestionsError) throw suggestionsError;
      setSuggestions(suggestionsData || []);
    } catch (error: any) {
      console.error('Error fetching suggestions:', error);
      setError('Failed to load suggestions');
    } finally {
      setLoading(false);
    }
  };

  const handleStatusChange = async (suggestionId: string) => {
    const newStatus = pendingStatusChanges[suggestionId];
    if (!newStatus) return;

    try {
      setError(null);
      setSuccess(null);

      console.log('Updating status for suggestion ID:', suggestionId);
      console.log('suggestionId type:', typeof suggestionId);
      console.log('New status:', newStatus);
      console.log('New status:', typeof newStatus);

      const { data, error } = await supabase
        .rpc('update_suggestion_status_new', {
          p_suggestion_id: suggestionId,
          p_status: newStatus
        });

      if (error) throw error;
      if (!data.success) throw new Error(data.error);

      // Update local state
      setSuggestions(prev =>
        prev.map(s =>
          s.id === suggestionId 
            ? { ...s, status: data.data.new_status } 
            : s
        )
      );

      setSuccess(`Suggestion status updated to ${newStatus}`);
      
      // If status is set to implemented, show the notes modal
      if (newStatus === 'implemented') {
        const suggestion = suggestions.find(s => s.id === suggestionId);
        if (suggestion) {
          setSelectedSuggestion(suggestion);
          setShowNotesModal(true);
        }
      }

      // Clear the pending status change
      setPendingStatusChanges(prev => {
        const newChanges = { ...prev };
        delete newChanges[suggestionId];
        return newChanges;
      });

      // Refresh suggestions to ensure we have the latest data
      await fetchSuggestions();

      setTimeout(() => setSuccess(null), 3000);
    } catch (error: any) {
      console.error('Error updating suggestion status:', error);
      setError(`Failed to update suggestion status: ${error.message}`);
      setTimeout(() => setError(null), 3000);
    }
  };

  const handleEditNotes = (suggestion: Suggestion) => {
    setSelectedSuggestion(suggestion);
    setShowNotesModal(true);
  };

  const handleStatusSelect = (suggestionId: string, status: string) => {
    setPendingStatusChanges(prev => ({
      ...prev,
      [suggestionId]: status
    }));
  };

  const getStatusPriority = (status: string): number => {
    switch (status) {
      case 'pending': return 0;
      case 'implemented': return 1;
      case 'approved': return 2;
      case 'rejected': return 3;
      default: return 4;
    }
  };

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'implemented':
        return 'text-green-600 bg-green-100';
      case 'approved':
        return 'text-blue-600 bg-blue-100';
      case 'rejected':
        return 'text-red-600 bg-red-100';
      default:
        return 'text-yellow-600 bg-yellow-100';
    }
  };

  const filteredSuggestions = suggestions
    .filter(suggestion => {
      const matchesSearch = 
        suggestion.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        suggestion.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
        suggestion.created_by_email.toLowerCase().includes(searchTerm.toLowerCase());

      const matchesStatus = !statusFilter || suggestion.status === statusFilter;

      return matchesSearch && matchesStatus;
    })
    .sort((a, b) => {
      // First sort by status priority
      const statusDiff = getStatusPriority(a.status) - getStatusPriority(b.status);
      if (statusDiff !== 0) return statusDiff;

      // Then sort by the selected sort criteria (votes or date)
      if (sortBy === 'votes') {
        return b.upvotes - a.upvotes;
      } else {
        return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
      }
    });

  const statusOptions = [
    { value: 'pending', label: 'Pending' },
    { value: 'approved', label: 'Approved' },
    { value: 'rejected', label: 'Rejected' },
    { value: 'implemented', label: 'Implemented' }
  ];

  if (!isAdmin) return null;

  return (
    <div className="min-h-screen bg-gradient-to-br from-green-50 to-blue-50">
      <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        <div className="px-4 py-4 sm:px-0">
          {/* Header Section */}
          <div className="bg-white shadow-lg rounded-lg mb-6 overflow-hidden">
            <div className="relative">
              <div className="absolute inset-0 bg-gradient-to-r from-green-600 to-blue-600 opacity-90"></div>
              <div className="relative px-8 py-12">
                <div className="flex items-center justify-between">
                  <button
                    onClick={() => navigate('/settings')}
                    className="text-white hover:text-green-100 transition-colors duration-200"
                  >
                    <ArrowLeft className="h-6 w-6" />
                  </button>
                  <Leaf className="h-12 w-12 text-white opacity-20" />
                </div>
                
                <div className="mt-4 text-center">
                  <h1 className="text-4xl font-bold text-white mb-2">THRIVE</h1>
                  <p className="text-green-100 text-lg mb-4">
                    Tracking Health and Reducing Illness Via Efficiency
                  </p>
                  <div className="w-16 h-1 bg-white/30 mx-auto rounded-full"></div>
                  <h2 className="text-2xl font-semibold text-white mt-4">Enhancement Management</h2>
                </div>
              </div>
            </div>
          </div>

          {/* Main Content */}
          <div className="bg-white shadow-lg rounded-lg overflow-hidden">
            <div className="px-6 py-5 border-b border-gray-200">
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <Tags className="h-5 w-5 text-green-600" />
                  <h3 className="ml-2 text-lg font-medium text-gray-900">
                    Enhancement Suggestions
                  </h3>
                </div>
                <div className="flex items-center space-x-4">
                  <button
                    onClick={() => setSortBy(prev => prev === 'date' ? 'votes' : 'date')}
                    className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                  >
                    {sortBy === 'date' ? (
                      <>
                        <Clock className="h-4 w-4 mr-2" />
                        Sort by Date
                      </>
                    ) : (
                      <>
                        <ThumbsUp className="h-4 w-4 mr-2" />
                        Sort by Votes
                      </>
                    )}
                  </button>
                </div>
              </div>
            </div>

            {/* Search and Filters */}
            <div className="px-6 py-4 border-b border-gray-200">
              <div className="flex flex-col sm:flex-row gap-4">
                <div className="flex-1">
                  <div className="relative">
                    <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
                    <input
                      type="text"
                      placeholder="Search suggestions..."
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      className="pl-10 pr-4 py-2 w-full rounded-lg border border-gray-300 focus:ring-2 focus:ring-green-500 focus:border-transparent"
                    />
                  </div>
                </div>
                <div className="flex gap-2">
                  <button
                    onClick={() => setStatusFilter(statusFilter === 'pending' ? null : 'pending')}
                    className={`px-4 py-2 rounded-lg border ${
                      statusFilter === 'pending'
                        ? 'bg-yellow-50 border-yellow-500 text-yellow-700'
                        : 'border-gray-300 text-gray-700 hover:bg-gray-50'
                    }`}
                  >
                    <Clock className="h-4 w-4 inline mr-2" />
                    Pending
                  </button>
                  <button
                    onClick={() => setStatusFilter(statusFilter === 'approved' ? null : 'approved')}
                    className={`px-4 py-2 rounded-lg border ${
                      statusFilter === 'approved'
                        ? 'bg-blue-50 border-blue-500 text-blue-700'
                        : 'border-gray-300 text-gray-700 hover:bg-gray-50'
                    }`}
                  >
                    <Check className="h-4 w-4 inline mr-2" />
                    Approved
                  </button>
                  <button
                    onClick={() => setStatusFilter(statusFilter === 'implemented' ? null : 'implemented')}
                    className={`px-4 py-2 rounded-lg border ${
                      statusFilter === 'implemented'
                        ? 'bg-green-50 border-green-500 text-green-700'
                        : 'border-gray-300 text-gray-700 hover:bg-gray-50'
                    }`}
                  >
                    <Settings className="h-4 w-4 inline mr-2" />
                    Implemented
                  </button>
                </div>
              </div>
            </div>

            {/* Messages */}
            {error && (
              <div className="px-6 py-4 bg-red-50">
                <div className="flex">
                  <AlertCircle className="h-5 w-5 text-red-400" />
                  <p className="ml-3 text-sm text-red-700">{error}</p>
                </div>
              </div>
            )}

            {success && (
              <div className="px-6 py-4 bg-green-50">
                <div className="flex">
                  <Check className="h-5 w-5 text-green-400" />
                  <p className="ml-3 text-sm text-green-700">{success}</p>
                </div>
              </div>
            )}

            {/* Suggestions List */}
            <div className="p-6">
              <div className="space-y-4">
                {loading ? (
                  <div className="text-center py-8">
                    <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-purple-600 mx-auto"></div>
                    <p className="mt-2 text-gray-500">Loading suggestions...</p>
                  </div>
                ) : filteredSuggestions.length === 0 ? (
                  <div className="text-center py-8">
                    <p className="text-gray-500">No suggestions found</p>
                  </div>
                ) : (
                  filteredSuggestions.map((suggestion) => (
                    <div
                      key={suggestion.id}
                      className="bg-white border border-gray-200 rounded-lg p-6 shadow-sm"
                    >
                      <div className="flex justify-between items-start">
                        <div className="flex-1">
                          <div className="flex items-center space-x-3">
                            <h4 className="text-lg font-medium text-gray-900">
                              {suggestion.title}
                            </h4>
                          </div>
                          <p className="mt-2 text-gray-600">
                            {suggestion.description}
                          </p>
                          <div className="mt-2 flex items-center space-x-4 text-xs text-gray-500">
                            <span>
                              Submitted by {suggestion.created_by_email}
                            </span>
                            <span>•</span>
                            <span>
                              {new Date(suggestion.created_at).toLocaleDateString('en-US', {
                                year: 'numeric',
                                month: 'long',
                                day: 'numeric',
                                hour: '2-digit',
                                minute: '2-digit'
                              })}
                            </span>
                            <span>•</span>
                            <div className="flex items-center space-x-2">
                              <ThumbsUp className="h-4 w-4 text-green-500" />
                              <span>{suggestion.upvotes}</span>
                              <ThumbsDown className="h-4 w-4 text-red-500 ml-2" />
                              <span>{suggestion.downvotes}</span>
                            </div>
                          </div>
                        </div>
                        <div className="ml-4 flex items-center space-x-3">
                          <div className="flex items-center space-x-2">
                            <select
                              value={pendingStatusChanges[suggestion.id] || suggestion.status}
                              onChange={(e) => handleStatusSelect(suggestion.id, e.target.value)}
                              className="rounded-md border-gray-300 text-sm focus:ring-green-500 focus:border-green-500"
                            >
                              {statusOptions.map(option => (
                                <option key={option.value} value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                            </select>
                            {pendingStatusChanges[suggestion.id] && (
                              <button
                                onClick={() => handleStatusChange(suggestion.id)}
                                className="inline-flex items-center px-3 py-1.5 border border-transparent text-xs font-medium rounded-md text-white bg-green-600 hover:bg-green-700"
                              >
                                Update
                              </button>
                            )}
                          </div>
                          {suggestion.status !== 'pending' && (
                            <button
                              onClick={() => handleEditNotes(suggestion)}
                              className="inline-flex items-center px-3 py-1.5 border border-transparent text-xs font-medium rounded-md text-white bg-purple-600 hover:bg-purple-700"
                            >
                              <FileText className="h-3 w-3 mr-1" />
                              Edit Notes
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Enhancement Notes Modal */}
      {selectedSuggestion && (
        <EnhancementNotesModal
          isOpen={showNotesModal}
          onClose={() => {
            setShowNotesModal(false);
            setSelectedSuggestion(null);
          }}
          suggestionId={selectedSuggestion.id}
          suggestionTitle={selectedSuggestion.title}
        />
      )}
    </div>
  );
}