import { supabase } from '../../lib/supabase';
import { ProcessingProgress, ProcessingResult } from '../dataImportProcessor';

interface PatientData {
  patient_id: string;
  first_name: string;
  last_name: string;
  date_of_birth: string;
  sex: string;
}

interface BodyCompositionData {
  external_patient_id: string;
  measurement_date: string;
  procedure_code: string;
}

async function fetchPatientIds(patientIds: string[]): Promise<Map<string, string>> {
  const patientMap = new Map<string, string>();
  const BATCH_SIZE = 100; // Smaller batch size for patient ID queries

  for (let i = 0; i < patientIds.length; i += BATCH_SIZE) {
    const batch = patientIds.slice(i, i + BATCH_SIZE);
    try {
      const { data, error } = await supabase
        .from('patients')
        .select('id, patient_id')
        .in('patient_id', batch);

      if (error) {
        console.error('Error fetching patient batch:', error);
        continue;
      }

      data?.forEach(patient => {
        patientMap.set(patient.patient_id, patient.id);
      });
    } catch (error) {
      console.error('Error in patient batch fetch:', error);
    }
  }

  return patientMap;
}

export async function processBodyCompositionReport(
  lines: string[],
  onProgress?: (progress: ProcessingProgress) => void
): Promise<ProcessingResult> {
  try {
    const headers = lines[1].split(',').map(header => header.trim().toLowerCase());
    const totalLines = lines.length - 2; // Subtract header rows
    let processedCount = 0;
    let skippedCount = 0;
    let errorCount = 0;

    // Extract all patient and measurement data first
    const allPatients: PatientData[] = [];
    const allMeasurements: BodyCompositionData[] = [];
    const patientIds = new Set<string>();
    const duplicatesInFile = new Set<string>();

    // First pass: collect unique patients and measurements
    for (let i = 2; i < lines.length; i++) {
      const values = lines[i].split(',').map(value => value.trim());
      
      if (values.length !== headers.length) {
        continue; // Skip malformed rows
      }

      const patientId = values[headers.indexOf('patientid')];

      // Add measurement data
      allMeasurements.push({
        external_patient_id: patientId,
        measurement_date: values[headers.indexOf('srvday')],
        procedure_code: values[headers.indexOf('proccode')]
      });

      // Only add patient if not already seen in this file
      if (!patientIds.has(patientId)) {
        patientIds.add(patientId);
        allPatients.push({
          patient_id: patientId,
          first_name: values[headers.indexOf('patient firstname')],
          last_name: values[headers.indexOf('patient lastname')],
          date_of_birth: values[headers.indexOf('patientdob')],
          sex: values[headers.indexOf('patientsex')]
        });
      } else {
        duplicatesInFile.add(patientId);
      }

      // Report progress for data extraction
      if (onProgress) {
        onProgress({
          currentLine: i - 1,
          totalLines,
          processedCount,
          skippedCount
        });
      }
    }

    // First, ensure all patients exist
    const BATCH_SIZE = 50;
    for (let i = 0; i < allPatients.length; i += BATCH_SIZE) {
      const batch = allPatients.slice(i, i + BATCH_SIZE);
      
      try {
        const { error: upsertError } = await supabase
          .from('patients')
          .upsert(
            batch.map(p => ({
              patient_id: p.patient_id,
              first_name: p.first_name,
              last_name: p.last_name,
              date_of_birth: p.date_of_birth,
              sex: p.sex
            })),
            { onConflict: 'patient_id' }
          );

        if (upsertError) {
          console.error('Error upserting patients:', upsertError);
          errorCount++;
        }
      } catch (error: any) {
        console.error('Error processing patient batch:', error);
        errorCount++;
      }
    }

    // Get patient IDs map
    const patientMap = await fetchPatientIds(Array.from(patientIds));

    // Process measurements in batches
    let measurementsProcessed = 0;
    let measurementsSkipped = 0;

    // Process measurements in batches
    for (let i = 0; i < allMeasurements.length; i += BATCH_SIZE) {
      const batch = allMeasurements.slice(i, i + BATCH_SIZE);
      const measurementsToUpsert = batch
        .map(measurement => {
          const patientId = patientMap.get(measurement.external_patient_id);
          if (!patientId) {
            return null;
          }

          return {
            external_patient_id: measurement.external_patient_id,
            patient_id: patientId,
            measurement_date: new Date(measurement.measurement_date).toISOString().split('T')[0],
            procedure_code: measurement.procedure_code
          };
        })
        .filter((m): m is NonNullable<typeof m> => m !== null);

      if (measurementsToUpsert.length > 0) {
        try {
          // Use upsert with onConflict to handle duplicates
          const { error: upsertError } = await supabase
            .from('body_composition_measurements')
            .upsert(measurementsToUpsert, {
              onConflict: 'external_patient_id,measurement_date',
              ignoreDuplicates: true // This is key - it will skip duplicates instead of trying to update them
            });

          if (upsertError) {
            console.error('Error upserting measurements:', upsertError);
            measurementsSkipped += measurementsToUpsert.length;
          } else {
            measurementsProcessed += measurementsToUpsert.length;
          }
        } catch (error) {
          console.error('Error processing measurements batch:', error);
          measurementsSkipped += measurementsToUpsert.length;
        }
      } else {
        measurementsSkipped += batch.length;
      }

      // Report progress
      if (onProgress) {
        onProgress({
          currentLine: totalLines,
          totalLines,
          processedCount: measurementsProcessed,
          skippedCount: measurementsSkipped
        });
      }
    }

    // Record import history
    try {
      await supabase.from('import_history').insert({
        report_type: 'ACCESS_DATA_BODY_COMP',
        file_name: 'ACCESS Data - WtgMgt Body Comps Last Wk',
        processed_count: measurementsProcessed,
        skipped_count: measurementsSkipped
      });
    } catch (error) {
      console.error('Error recording import history:', error);
    }

    let message = `Processed ${allPatients.length - errorCount} patients and ${measurementsProcessed} new measurements`;
    if (measurementsSkipped > 0) {
      message += ` (${measurementsSkipped} measurements skipped)`;
    }
    if (duplicatesInFile.size > 0) {
      message += ` (${duplicatesInFile.size} duplicate patients in file)`;
    }
    if (errorCount > 0) {
      message += ` (${errorCount} errors occurred)`;
    }

    return {
      success: true,
      message,
      processedCount: measurementsProcessed,
      skippedCount: measurementsSkipped
    };
  } catch (error: any) {
    return {
      success: false,
      message: `Error processing file: ${error.message}`,
      processedCount: 0,
      skippedCount: 0
    };
  }
}