import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  ArrowLeft, 
  Leaf, 
  Plus, 
  Edit2, 
  Trash2, 
  AlertCircle,
  Check,
  Clock,
  Info
} from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import { supabase } from '../lib/supabase';
import { ServiceLevelModal } from '../components/ServiceLevelModal';

interface ServiceLevel {
  id: string;
  name: string;
  level: string;
  description: string;
  created_at: string;
}

export function ServiceLevels() {
  const navigate = useNavigate();
  const { userRoles } = useAuth();
  const isAdmin = userRoles.includes('admin');
  const [serviceLevels, setServiceLevels] = useState<ServiceLevel[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [editingLevel, setEditingLevel] = useState<ServiceLevel | null>(null);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState<string | null>(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (!isAdmin) {
      navigate('/settings');
      return;
    }
    fetchServiceLevels();
  }, [isAdmin, navigate]);

  const fetchServiceLevels = async () => {
    try {
      const { data, error } = await supabase
        .from('service_levels')
        .select('*')
        .order('name');

      if (error) throw error;
      setServiceLevels(data || []);
    } catch (error: any) {
      console.error('Error fetching service levels:', error);
      setError('Failed to load service levels');
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (levelId: string) => {
    try {
      const { error } = await supabase
        .from('service_levels')
        .delete()
        .eq('id', levelId);

      if (error) throw error;

      setSuccess('Service level successfully deleted');
      setShowDeleteConfirm(null);
      await fetchServiceLevels();

      setTimeout(() => setSuccess(null), 3000);
    } catch (error: any) {
      console.error('Error deleting service level:', error);
      setError('Failed to delete service level');
      setTimeout(() => setError(null), 3000);
    }
  };

  const handleModalSuccess = async () => {
    await fetchServiceLevels();
    setSuccess(editingLevel ? 'Service level updated successfully' : 'Service level created successfully');
    setTimeout(() => setSuccess(null), 3000);
  };

  // If not admin, don't render the page
  if (!isAdmin) {
    return null;
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-green-50 to-blue-50">
      <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        <div className="px-4 py-4 sm:px-0">
          {/* Header Section */}
          <div className="bg-white shadow-lg rounded-lg mb-6 overflow-hidden">
            <div className="relative">
              <div className="absolute inset-0 bg-gradient-to-r from-green-600 to-blue-600 opacity-90"></div>
              <div className="relative px-8 py-12">
                <div className="flex items-center justify-between">
                  <button
                    onClick={() => navigate('/settings')}
                    className="text-white hover:text-green-100 transition-colors duration-200"
                  >
                    <ArrowLeft className="h-6 w-6" />
                  </button>
                  <Leaf className="h-12 w-12 text-white opacity-20" />
                </div>
                
                <div className="mt-4 text-center">
                  <h1 className="text-4xl font-bold text-white mb-2">THRIVE</h1>
                  <p className="text-green-100 text-lg mb-4">
                    Tracking Health and Reducing Illness Via Efficiency
                  </p>
                  <div className="w-16 h-1 bg-white/30 mx-auto rounded-full"></div>
                  <h2 className="text-2xl font-semibold text-white mt-4">Service Levels</h2>
                </div>
              </div>
            </div>
          </div>

          {/* Main Content */}
          <div className="bg-white shadow-lg rounded-lg overflow-hidden">
            <div className="px-6 py-5 border-b border-gray-200">
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <Clock className="h-5 w-5 text-green-600" />
                  <h3 className="ml-2 text-lg font-medium text-gray-900">
                    Service Levels
                  </h3>
                </div>
                <button
                  onClick={() => {
                    setEditingLevel(null);
                    setShowModal(true);
                  }}
                  className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700"
                >
                  <Plus className="h-4 w-4 mr-2" />
                  Add Service Level
                </button>
              </div>
            </div>

            {/* Messages */}
            {error && (
              <div className="px-6 py-4 bg-red-50">
                <div className="flex">
                  <AlertCircle className="h-5 w-5 text-red-400" />
                  <p className="ml-3 text-sm text-red-700">{error}</p>
                </div>
              </div>
            )}

            {success && (
              <div className="px-6 py-4 bg-green-50">
                <div className="flex">
                  <Check className="h-5 w-5 text-green-400" />
                  <p className="ml-3 text-sm text-green-700">{success}</p>
                </div>
              </div>
            )}

            {/* Service Levels List */}
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Name
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Type
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Description
                    </th>
                    <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {loading ? (
                    <tr>
                      <td colSpan={4} className="px-6 py-4 text-center text-gray-500">
                        Loading service levels...
                      </td>
                    </tr>
                  ) : serviceLevels.length === 0 ? (
                    <tr>
                      <td colSpan={4} className="px-6 py-4 text-center text-gray-500">
                        <div className="flex flex-col items-center">
                          <Info className="h-12 w-12 text-gray-400 mb-2" />
                          <p>No service levels found</p>
                          <p className="text-sm text-gray-400 mt-1">
                            Click the "Add Service Level" button to create your first service level
                          </p>
                        </div>
                      </td>
                    </tr>
                  ) : (
                    serviceLevels.map((level) => (
                      <tr key={level.id} className="hover:bg-gray-50">
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          {level.name}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {level.level.split('_').map(word => 
                            word.charAt(0).toUpperCase() + word.slice(1)
                          ).join(' ')}
                        </td>
                        <td className="px-6 py-4 text-sm text-gray-500">
                          {level.description || '-'}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                          <div className="flex items-center justify-end space-x-3">
                            <button
                              onClick={() => {
                                setEditingLevel(level);
                                setShowModal(true);
                              }}
                              className="text-blue-600 hover:text-blue-900"
                            >
                              <Edit2 className="h-5 w-5" />
                            </button>
                            {showDeleteConfirm === level.id ? (
                              <div className="flex items-center space-x-2">
                                <button
                                  onClick={() => handleDelete(level.id)}
                                  className="text-red-600 hover:text-red-900"
                                >
                                  Confirm
                                </button>
                                <button
                                  onClick={() => setShowDeleteConfirm(null)}
                                  className="text-gray-600 hover:text-gray-900"
                                >
                                  Cancel
                                </button>
                              </div>
                            ) : (
                              <button
                                onClick={() => setShowDeleteConfirm(level.id)}
                                className="text-red-600 hover:text-red-900"
                              >
                                <Trash2 className="h-5 w-5" />
                              </button>
                            )}
                          </div>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <ServiceLevelModal
        serviceLevel={editingLevel}
        isOpen={showModal}
        onClose={() => {
          setShowModal(false);
          setEditingLevel(null);
        }}
        onSuccess={handleModalSuccess}
      />
    </div>
  );
}