import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  ArrowLeft, 
  Leaf, 
  Tags, 
  FileText,
  Check,
  X,
  AlertCircle,
  Info
} from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import { supabase } from '../lib/supabase';

interface ProcedureCode {
  code: string;
  description: string;
  milligrams: number;
  form: string;
  medication_type: string;
  category_id: string | null;
  category_name: string | null;
}

interface Category {
  id: string;
  name: string;
}

export function ProcedureCategories() {
  const navigate = useNavigate();
  const { userRoles } = useAuth();
  const isAdmin = userRoles.includes('admin');
  const [procedureCodes, setProcedureCodes] = useState<ProcedureCode[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [updatingCode, setUpdatingCode] = useState<string | null>(null);

  useEffect(() => {
    if (!isAdmin) {
      navigate('/settings');
      return;
    }
    fetchData();
  }, [isAdmin, navigate]);

  const fetchData = async () => {
    try {
      setLoading(true);
      
      // Fetch categories
      const { data: categoriesData, error: categoriesError } = await supabase
        .from('product_categories')
        .select('id, name')
        .order('name');

      if (categoriesError) throw categoriesError;
      setCategories(categoriesData || []);

      // Fetch procedure codes with categories
      const { data: codesData, error: codesError } = await supabase
        .rpc('get_procedure_codes_with_categories');

      if (codesError) throw codesError;
      setProcedureCodes(codesData || []);
    } catch (error: any) {
      console.error('Error fetching data:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleCategoryChange = async (procedureCode: string, categoryId: string | null) => {
    try {
      setUpdatingCode(procedureCode);

      // Optimistically update the UI
      setProcedureCodes(prev => prev.map(code => {
        if (code.code === procedureCode) {
          return {
            ...code,
            category_id: categoryId,
            category_name: categories.find(c => c.id === categoryId)?.name || null
          };
        }
        return code;
      }));

      // Make the API call
      const { error } = await supabase
        .rpc('update_procedure_code_category', {
          p_procedure_code: procedureCode,
          p_category_id: categoryId
        });

      if (error) throw error;

      setSuccess('Category mapping updated successfully');
      setTimeout(() => setSuccess(null), 3000);
    } catch (error: any) {
      console.error('Error updating category:', error);
      setError('Failed to update category mapping');
      setTimeout(() => setError(null), 3000);
      
      // Revert the optimistic update on error
      await fetchData();
    } finally {
      setUpdatingCode(null);
    }
  };

  const filteredProcedureCodes = procedureCodes.filter(code => 
    code.code.toLowerCase().includes(searchTerm.toLowerCase()) ||
    code.description?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    code.category_name?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // If not admin, don't render the page
  if (!isAdmin) {
    return null;
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-green-50 to-blue-50">
      <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        <div className="px-4 py-4 sm:px-0">
          {/* Header Section */}
          <div className="bg-white shadow-lg rounded-lg mb-6 overflow-hidden">
            <div className="relative">
              <div className="absolute inset-0 bg-gradient-to-r from-green-600 to-blue-600 opacity-90"></div>
              <div className="relative px-8 py-12">
                <div className="flex items-center justify-between">
                  <button
                    onClick={() => navigate('/settings')}
                    className="text-white hover:text-green-100 transition-colors duration-200"
                  >
                    <ArrowLeft className="h-6 w-6" />
                  </button>
                  <Leaf className="h-12 w-12 text-white opacity-20" />
                </div>
                
                <div className="mt-4 text-center">
                  <h1 className="text-4xl font-bold text-white mb-2">THRIVE</h1>
                  <p className="text-green-100 text-lg mb-4">
                    Tracking Health and Reducing Illness Via Efficiency
                  </p>
                  <div className="w-16 h-1 bg-white/30 mx-auto rounded-full"></div>
                  <h2 className="text-2xl font-semibold text-white mt-4">Procedure Code Categories</h2>
                </div>
              </div>
            </div>
          </div>

          {/* Main Content */}
          <div className="bg-white shadow-lg rounded-lg overflow-hidden">
            <div className="px-6 py-5 border-b border-gray-200">
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <Tags className="h-5 w-5 text-green-600" />
                  <h3 className="ml-2 text-lg font-medium text-gray-900">
                    Procedure Code Categories
                  </h3>
                </div>
                <button
                  onClick={() => navigate('/product-categories')}
                  className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700"
                >
                  <FileText className="h-4 w-4 mr-2" />
                  Manage Categories
                </button>
              </div>
            </div>

            {/* Search Bar */}
            <div className="px-6 py-4 border-b border-gray-200">
              <input
                type="text"
                placeholder="Search procedure codes..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
              />
            </div>

            {/* Messages */}
            {error && (
              <div className="px-6 py-4 bg-red-50">
                <div className="flex">
                  <AlertCircle className="h-5 w-5 text-red-400" />
                  <p className="ml-3 text-sm text-red-700">{error}</p>
                </div>
              </div>
            )}

            {success && (
              <div className="px-6 py-4 bg-green-50">
                <div className="flex">
                  <Check className="h-5 w-5 text-green-400" />
                  <p className="ml-3 text-sm text-green-700">{success}</p>
                </div>
              </div>
            )}

            {/* Procedure Codes List */}
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="w-[15%] px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Code
                    </th>
                    <th scope="col" className="w-[20%] px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Description
                    </th>
                    <th scope="col" className="w-[45%] px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Details
                    </th>
                    <th scope="col" className="w-[20%] px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Category
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {loading ? (
                    <tr>
                      <td colSpan={4} className="px-6 py-4 text-center text-gray-500">
                        Loading procedure codes...
                      </td>
                    </tr>
                  ) : filteredProcedureCodes.length === 0 ? (
                    <tr>
                      <td colSpan={4} className="px-6 py-4 text-center text-gray-500">
                        <div className="flex flex-col items-center">
                          <Info className="h-12 w-12 text-gray-400 mb-2" />
                          <p>No procedure codes found</p>
                        </div>
                      </td>
                    </tr>
                  ) : (
                    filteredProcedureCodes.map((code) => (
                      <tr key={code.code} className="hover:bg-gray-50">
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          {code.code}
                        </td>
                        <td className="px-6 py-4 text-sm text-gray-500">
                          {code.description || '-'}
                        </td>
                        <td className="px-6 py-4 text-sm text-gray-500">
                          {[
                            code.milligrams && `${code.milligrams} mg`,
                            code.form !== 'none' && code.form,
                            code.medication_type !== 'none' && code.medication_type
                          ]
                            .filter(Boolean)
                            .map(detail => 
                              typeof detail === 'string' 
                                ? detail.charAt(0).toUpperCase() + detail.slice(1) 
                                : detail
                            )
                            .join(' ; ')}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <select
                            value={code.category_id || ''}
                            onChange={(e) => handleCategoryChange(
                              code.code, 
                              e.target.value || null
                            )}
                            disabled={updatingCode === code.code}
                            className={`block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm rounded-md ${
                              updatingCode === code.code ? 'opacity-50 cursor-not-allowed' : ''
                            }`}
                          >
                            <option value="">No Category</option>
                            {categories.map((category) => (
                              <option key={category.id} value={category.id}>
                                {category.name}
                              </option>
                            ))}
                          </select>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}