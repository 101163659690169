import React from 'react';
import { DollarSign, Clock, CheckCircle2, XCircle as XCircle2, AlertCircle, Calendar } from 'lucide-react';
import { formatCurrency } from '../../utils/formatters';
import { formatPacificDate } from '../../utils/dateTime';
import {
  Membership,
  getMembershipStatusStyle,
  formatMembershipStatus,
  getMembershipIcon
} from '../../utils/membership';

interface MembershipStatusProps {
  memberships: Membership[];
  expanded?: boolean;
}

export function MembershipStatus({ memberships, expanded = false }: MembershipStatusProps) {
  const getStatusIcon = (status: string) => {
    switch (status?.toLowerCase()) {
      case 'success':
        return <CheckCircle2 className="h-3 w-3 text-green-600" />;
      case 'failed':
        return <XCircle2 className="h-3 w-3 text-red-600" />;
      default:
        return <AlertCircle className="h-3 w-3 text-yellow-600" />;
    }
  };

  return (
    <div className="flex flex-col gap-2">
      {memberships.map((membership, idx) => {
        const statusStyle = getMembershipStatusStyle(membership.status);

        return (
          <div key={idx} className={`flex flex-col ${expanded ? 'bg-white p-4 rounded-lg shadow-sm' : ''}`}>
            {/* Membership name and status */}
            <div className="flex items-center gap-1.5">
              <span className={`inline-flex items-center px-2 py-1 rounded-full text-xs font-medium ${
                statusStyle.bgColor} ${statusStyle.textColor}`}>
                {membership.name}
              </span>
              <span className="text-xs text-gray-500">•</span>
              <span className="text-xs text-gray-500 capitalize">
                {formatMembershipStatus(membership.status)}
              </span>
            </div>

            {/* Membership details */}
            <div className="mt-2 space-y-1.5">
              {/* Created and Start Date */}
              {expanded && membership.date_created && (
                <div className="flex items-center text-xs text-gray-500">
                  <Calendar className="h-3 w-3 mr-1" />
                  <span>Created: {formatPacificDate(membership.date_created, 'MMM d, yyyy')}</span>
                  {membership.start_date && (
                    <>
                      <span className="mx-1">•</span>
                      <span>Started: {formatPacificDate(membership.start_date, 'MMM d, yyyy')}</span>
                    </>
                  )}
                </div>
              )}

              {/* Charge amount and interval */}
              {typeof membership.charge_amount === 'number' && membership.charge_amount > 0 && (
                <div className="flex items-center text-xs text-gray-500">
                  <DollarSign className="h-3 w-3 mr-1" />
                  <span>
                    {formatCurrency(membership.charge_amount)}
                    {membership.internal_interval && ` per ${membership.internal_interval}`}
                  </span>
                </div>
              )}

              {/* Payment history */}
              {membership.last_run && (
                <div className="flex items-center text-xs text-gray-500">
                  <Clock className="h-3 w-3 mr-1" />
                  <span>Last payment: {formatPacificDate(membership.last_run, 'MMM d, yyyy')}</span>
                  {membership.last_run_status && (
                    <>
                      <span className="mx-1">•</span>
                      <span className="flex items-center">
                        {getStatusIcon(membership.last_run_status)}
                        <span className="ml-1 capitalize">{membership.last_run_status}</span>
                      </span>
                    </>
                  )}
                </div>
              )}

              {/* Next billing date */}
              {membership.next_run_date && membership.status === 'active' && (
                <div className="flex items-center text-xs text-gray-500">
                  <Clock className="h-3 w-3 mr-1" />
                  <span>Next billing: {formatPacificDate(membership.next_run_date, 'MMM d, yyyy')}</span>
                </div>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
}