import React, { useState, useEffect } from 'react';
import { X, Mail, Key, AlertCircle, Check } from 'lucide-react';
import { supabase } from '../lib/supabase';

interface Role {
  id: string;
  name: string;
  description: string;
}

interface UserModalProps {
  userId?: string;
  onClose: () => void;
  onSuccess: () => void;
}

export function UserModal({ userId, onClose, onSuccess }: UserModalProps) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [roles, setRoles] = useState<Role[]>([]);
  const [selectedRoles, setSelectedRoles] = useState<string[]>([]);
  const [forcePasswordChange, setForcePasswordChange] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const isEditing = !!userId;

  useEffect(() => {
    fetchRoles();
    if (isEditing) {
      fetchUserDetails();
    }
  }, [userId]);

  const fetchRoles = async () => {
    try {
      const { data, error } = await supabase
        .from('roles')
        .select('*')
        .order('name');
      
      if (error) throw error;
      setRoles(data || []);
    } catch (error: any) {
      console.error('Error fetching roles:', error);
      setError('Failed to load roles');
    }
  };

  const fetchUserDetails = async () => {
    if (!userId) return;

    try {
      const { data, error } = await supabase.rpc('get_user_details', {
        p_user_id: userId
      });

      if (error) throw error;
      if (data && data.length > 0) {
        const user = data[0];
        setEmail(user.email);
        setSelectedRoles(user.role_ids || []);
        setForcePasswordChange(user.force_password_change);
      }
    } catch (error: any) {
      console.error('Error fetching user details:', error);
      setError('Failed to load user details');
    }
  };

  const validateRoles = (roleIds: string[]): boolean => {
    // Check if all selected role IDs exist in the available roles
    return roleIds.every(roleId => roles.some(role => role.id === roleId));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    setLoading(true);

    try {
      // Validate selected roles
      if (!validateRoles(selectedRoles)) {
        throw new Error('One or more selected roles are invalid');
      }

      if (isEditing) {
        // Update existing user
        const { error } = await supabase.rpc('update_user_details', {
          p_user_id: userId,
          p_email: email,
          p_force_password_change: forcePasswordChange,
          p_role_ids: selectedRoles.length > 0 ? selectedRoles : null
        });

        if (error) throw error;
      } else {
        // Create new user
        const { data: userData, error: signUpError } = await supabase.auth.signUp({
          email,
          password
        });

        if (signUpError) throw signUpError;
        if (!userData.user) throw new Error('Failed to create user');

        // Assign roles and set force password change
        const { error: updateError } = await supabase.rpc('update_user_details', {
          p_user_id: userData.user.id,
          p_email: email,
          p_force_password_change: forcePasswordChange,
          p_role_ids: selectedRoles.length > 0 ? selectedRoles : null
        });

        if (updateError) throw updateError;
      }

      onSuccess();
      onClose();
    } catch (error: any) {
      console.error('Error saving user:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg shadow-xl max-w-md w-full">
        <div className="px-6 py-4 border-b border-gray-200 flex items-center justify-between">
          <h3 className="text-lg font-medium text-gray-900">
            {isEditing ? 'Edit User' : 'Add New User'}
          </h3>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-500"
          >
            <X className="h-5 w-5" />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="p-6">
          {error && (
            <div className="mb-4 p-4 bg-red-50 rounded-md">
              <div className="flex">
                <AlertCircle className="h-5 w-5 text-red-400" />
                <p className="ml-3 text-sm text-red-700">{error}</p>
              </div>
            </div>
          )}

          <div className="space-y-4">
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                Email Address
              </label>
              <div className="mt-1 relative rounded-md shadow-sm">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Mail className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="focus:ring-green-500 focus:border-green-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
                  placeholder="user@example.com"
                  required
                />
              </div>
            </div>

            {!isEditing && (
              <div>
                <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                  Initial Password
                </label>
                <div className="mt-1 relative rounded-md shadow-sm">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <Key className="h-5 w-5 text-gray-400" />
                  </div>
                  <input
                    type="password"
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="focus:ring-green-500 focus:border-green-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
                    placeholder="••••••••"
                    required
                  />
                </div>
              </div>
            )}

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Roles
              </label>
              <div className="space-y-2">
                {roles.map((role) => (
                  <label key={role.id} className="flex items-center">
                    <input
                      type="checkbox"
                      checked={selectedRoles.includes(role.id)}
                      onChange={() => {
                        setSelectedRoles(prev =>
                          prev.includes(role.id)
                            ? prev.filter(id => id !== role.id)
                            : [...prev, role.id]
                        );
                      }}
                      className="h-4 w-4 text-green-600 focus:ring-green-500 border-gray-300 rounded"
                    />
                    <span className="ml-2 text-sm text-gray-900">{role.name}</span>
                    {role.description && (
                      <span className="ml-2 text-xs text-gray-500">({role.description})</span>
                    )}
                  </label>
                ))}
              </div>
            </div>

            <div>
              <label className="flex items-center">
                <input
                  type="checkbox"
                  checked={forcePasswordChange}
                  onChange={(e) => setForcePasswordChange(e.target.checked)}
                  className="h-4 w-4 text-green-600 focus:ring-green-500 border-gray-300 rounded"
                />
                <span className="ml-2 text-sm text-gray-900">
                  Require password change at next login
                </span>
              </label>
            </div>
          </div>

          <div className="mt-6 flex justify-end space-x-3">
            <button
              type="button"
              onClick={onClose}
              className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700"
              disabled={loading}
            >
              {loading ? (
                <>
                  <span className="animate-spin rounded-full h-4 w-4 border-b-2 border-white mr-2" />
                  {isEditing ? 'Updating...' : 'Creating...'}
                </>
              ) : (
                isEditing ? 'Update User' : 'Create User'
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}