import React from 'react';
import { AlertTriangle, Check } from 'lucide-react';

interface StatusMessagesProps {
  error: string | null;
  success: string | null;
}

export function StatusMessages({ error, success }: StatusMessagesProps) {
  if (!error && !success) return null;

  return (
    <>
      {error && (
        <div className="p-4 bg-red-50">
          <div className="flex">
            <div className="flex-shrink-0">
              <AlertTriangle className="h-5 w-5 text-red-400" />
            </div>
            <div className="ml-3">
              <p className="text-sm text-red-700">{error}</p>
            </div>
          </div>
        </div>
      )}

      {success && (
        <div className="p-4 bg-green-50">
          <div className="flex">
            <div className="flex-shrink-0">
              <Check className="h-5 w-5 text-green-400" />
            </div>
            <div className="ml-3">
              <p className="text-sm text-green-700">{success}</p>
            </div>
          </div>
        </div>
      )}
    </>
  );
}