import jsPDF from 'jspdf';
import { formatPacificDate, formatPacificTime, getCurrentPacificDate } from './dateTime';
import { formatCurrency } from './formatters';

interface PatientDetails {
  id: uuid;
  patient_id: string;
  first_name: string;
  last_name: string;
  date_of_birth: string;
  sex: string;
  first_visit: string | null;
  last_visit: string | null;
  first_weight: number | null;
  last_weight: number | null;
  lowest_weight: number | null;
  weight_loss: number | null;
  activity_status: string;
  has_future_appointments: boolean;
  last_body_comp_date: string | null;
  weeks_since_body_comp: number | null;
  body_comp_overdue: boolean;
  memberships?: Array<{
    name: string;
    status: string;
    charge_amount?: number;
    date_created?: string;
    start_date?: string;
    internal_interval?: string;
    last_run?: string;
    last_run_status?: string;
    next_run_date?: string;
  }>;
}

interface NextAppointment {
  appointment_date: string;
  appointment_time: string;
  appointment_type: string;
  rendering_provider: string;
  service_department: string;
}

interface VisitHistory {
  visit_date: string;
  medications: Array<{
    procedure_code: string;
    description: string | null;
    units: number;
  }>;
  weight: number | null;
  has_body_composition: boolean;
}

interface PatientSummary {
  patient: PatientDetails;
  nextAppointment: NextAppointment | null;
  visitHistory: VisitHistory[];
}

function addPatientPage(
  doc: jsPDF,
  patientSummary: PatientSummary,
  isFirstPage: boolean
) {
  const { patient, nextAppointment, visitHistory } = patientSummary;

  if (!isFirstPage) {
    doc.addPage();
  }

  // Set initial font styles
  doc.setFont('helvetica', 'bold');
  doc.setFontSize(16);

  // Add header - split into two columns
  // Left side: Patient Name
  doc.text(`${patient.first_name} ${patient.last_name}`, 20, 15);
  doc.setFontSize(9);
  if (nextAppointment) {
    doc.text(
      `Next Visit: ${formatPacificDate(nextAppointment.appointment_date, 'EEEE, MMMM d, yyyy')} @ ${formatPacificTime(nextAppointment.appointment_time)}`,
      20,
      20
    );
  }

  // Right side: THRIVE title
  doc.setFontSize(16);
  doc.text('THRIVE', 190, 15, { align: 'right' });
  doc.setFontSize(9);
  doc.text('Tracking Health and Reducing Illness Via Efficiency', 190, 20, { align: 'right' });

  // Add horizontal line
  doc.setLineWidth(0.5);
  doc.line(20, 22, 190, 22);

  // Reset font for content
  doc.setFont('helvetica', 'normal');
  doc.setFontSize(11);

  // Define column widths and positions
  const col1X = 20;  // Patient Info
  const col2X = 80;  // Visit History
  const col3X = 140; // Weight History
  const startY = 28; // Reduced from 30
  let currentY = startY;

  // Column 1: Patient Information (Simplified)
  doc.setFont('helvetica', 'bold');
  doc.text('Patient Information', col1X, currentY);
  doc.setFont('helvetica', 'normal');
  currentY += 5; // Reduced from 7
  doc.text(`ID: ${patient.patient_id}`, col1X, currentY);
  currentY += 5;
  doc.text(`DOB: ${formatPacificDate(patient.date_of_birth, 'MMM d, yyyy')}`, col1X, currentY);

  // Column 2: Visit History Summary
  currentY = startY;
  doc.setFont('helvetica', 'bold');
  doc.text('Visit Summary', col2X, currentY);
  doc.setFont('helvetica', 'normal');
  currentY += 5; // Reduced from 7
  doc.text(`First Visit: ${patient.first_visit ? formatPacificDate(patient.first_visit, 'MMM d, yyyy') : 'N/A'}`, col2X, currentY);
  currentY += 5;
  doc.text(`Last Visit: ${patient.last_visit ? formatPacificDate(patient.last_visit, 'MMM d, yyyy') : 'N/A'}`, col2X, currentY);

  // Column 3: Weight History
  currentY = startY;
  doc.setFont('helvetica', 'bold');
  doc.text('Weight History', col3X, currentY);
  doc.setFont('helvetica', 'normal');
  currentY += 5; // Reduced from 7
  doc.text(`Initial: ${formatWeight(patient.first_weight)}`, col3X, currentY);
  currentY += 5;
  doc.text(`Current: ${formatWeight(patient.last_weight)}`, col3X, currentY);
  currentY += 5;
  doc.text(`Lowest: ${formatWeight(patient.lowest_weight)}`, col3X, currentY);

  // Calculate weight loss percentage
  const weightLossPercentage = patient.first_weight && patient.weight_loss
    ? (patient.weight_loss / patient.first_weight) * 100
    : null;

  if (patient.weight_loss && patient.weight_loss > 0) {
    currentY += 5;
    doc.setFont('helvetica', 'bold');
    doc.text(`Total Loss: ${patient.weight_loss.toFixed(1)} lbs`, col3X, currentY);
    
    if (weightLossPercentage && weightLossPercentage >= 5) {
      currentY += 5;
      doc.text(`(${weightLossPercentage.toFixed(1)}% reduction)`, col3X, currentY);
    }
  }

  // Add Membership Information Section
  currentY = 60;
  doc.setLineWidth(0.5);
  doc.line(20, currentY - 4, 190, currentY - 4);
  
  doc.setFont('helvetica', 'bold');
  doc.text('Membership Status', 20, currentY);
  doc.setFont('helvetica', 'normal');
  currentY += 7;

  if (patient.memberships && patient.memberships.length > 0) {
    patient.memberships.forEach((membership, index) => {
      // Set color based on status
      const statusColor = membership.status === 'active' 
        ? [34, 197, 94]  // green-600
        : membership.status === 'cancelled'
        ? [239, 68, 68]  // red-600
        : membership.status === 'pending'
        ? [234, 179, 8]  // yellow-600
        : [107, 114, 128]; // gray-600

      doc.setTextColor(...statusColor);
      doc.setFont('helvetica', 'bold');
      doc.text(membership.name, 20, currentY);
      doc.text(`• ${membership.status.charAt(0).toUpperCase() + membership.status.slice(1)}`, 100, currentY);
      
      // Add charge amount if available
      if (membership.charge_amount) {
        doc.setTextColor(0, 0, 0);
        currentY += 5;
        doc.text(`Charge Amount: ${formatCurrency(membership.charge_amount)}`, 30, currentY);
      }

      // Add last payment info if available
      if (membership.last_run) {
        currentY += 5;
        doc.text(`Last Payment: ${formatPacificDate(membership.last_run, 'MMM d, yyyy')}`, 30, currentY);
        
        if (membership.last_run_status) {
          doc.text(`• ${membership.last_run_status.charAt(0).toUpperCase() + membership.last_run_status.slice(1)}`, 100, currentY);
        }
      }

      // Add next billing date if available and membership is active
      if (membership.next_run_date && membership.status === 'active') {
        currentY += 5;
        doc.text(`Next Billing Date: ${formatPacificDate(membership.next_run_date, 'MMM d, yyyy')}`, 30, currentY);
      }

      if (index < patient.memberships.length - 1) {
        currentY += 7;
      }
    });
    doc.setTextColor(0, 0, 0); // Reset to black
  } else {
    doc.setTextColor(107, 114, 128); // gray-600
    doc.text('No active memberships found', 20, currentY);
    doc.setTextColor(0, 0, 0); // Reset to black
  }

  // Add Body Composition Alert
  currentY += 12;
  doc.setLineWidth(0.5);
  doc.line(20, currentY - 4, 190, currentY - 4);
  
  doc.setFont('helvetica', 'bold');
  doc.text('Body Composition Status', 20, currentY);
  doc.setFont('helvetica', 'normal');
  currentY += 7;

  if (patient.last_body_comp_date) {
    doc.text(
      `Last measurement: ${formatPacificDate(patient.last_body_comp_date, 'MMM d, yyyy')} (${patient.weeks_since_body_comp} weeks ago)`,
      20,
      currentY
    );
  } else {
    doc.text('No previous measurements recorded', 20, currentY);
  }

  if (patient.body_comp_overdue) {
    currentY += 5;
    doc.setTextColor(220, 38, 38);
    doc.text('⚠ Body composition measurement is due', 20, currentY);
    doc.setTextColor(0, 0, 0);
  }

  // Visit History Table
  if (visitHistory && visitHistory.length > 0) {
    currentY += 12;
    doc.setLineWidth(0.5);
    doc.line(20, currentY - 4, 190, currentY - 4);

    doc.setFont('helvetica', 'bold');
    doc.text('Visit History', 20, currentY);
    doc.setFontSize(8);
    doc.setFont('helvetica', 'normal');
    doc.text('(last 10 visits)', 65, currentY);
    doc.setFontSize(11);
    currentY += 7;

    // Table headers
    const headers = ['Date', 'Weight', 'Medications'];
    const colWidths = [30, 30, 110];
    let xPos = 20;

    // Draw header background
    doc.setFillColor(245, 245, 245);
    doc.rect(20, currentY - 4, 170, 6, 'F');

    // Draw header text
    headers.forEach((header, i) => {
      doc.text(header, xPos, currentY);
      xPos += colWidths[i];
    });

    // Table content
    doc.setFont('helvetica', 'normal');
    doc.setFontSize(10);
    currentY += 7;

    // Get last 10 visits
    const recentVisits = visitHistory.slice(0, 10);

    recentVisits.forEach((visit, index) => {
      if (index % 2 === 0) {
        doc.setFillColor(252, 252, 252);
        doc.rect(20, currentY - 4, 170, 6, 'F');
      }

      xPos = 20;
      
      doc.text(formatPacificDate(visit.visit_date, 'MMM d, yyyy'), xPos, currentY);
      
      xPos += colWidths[0];
      doc.text(visit.weight ? `${visit.weight.toFixed(1)} lbs` : '-', xPos, currentY);
      
      xPos += colWidths[1];
      const medications = visit.medications
        .map(med => {
          const description = med.description || med.procedure_code;
          return med.units > 1 ? `${description} (${med.units} units)` : description;
        })
        .join(', ');
      
      if (medications.length > 60) {
        const lines = doc.splitTextToSize(medications, 105);
        lines.forEach((line: string, lineIndex: number) => {
          if (currentY + (lineIndex * 5) <= 250) {
            doc.text(line, xPos, currentY + (lineIndex * 5));
          }
        });
        currentY += (lines.length - 1) * 5;
      } else {
        doc.text(medications || '-', xPos, currentY);
      }

      currentY += 6;
    });
  }

  // Add Vitals Section - Now follows immediately after Visit History
  currentY += 10;
  doc.setLineWidth(0.5);
  doc.line(20, currentY - 4, 190, currentY - 4);

  doc.setFont('helvetica', 'bold');
  doc.text('Current Vitals', 20, currentY);
  doc.setFont('helvetica', 'normal');
  currentY += 7;

  // Create vitals recording boxes in new order
  const vitalLabels = ['Weight:', 'Blood Pressure:', 'Pulse:', 'O2 Sat:'];
  const vitalBoxWidth = 40;
  let vitalX = 20;
  
  vitalLabels.forEach((label) => {
    doc.text(label, vitalX, currentY);
    doc.rect(vitalX, currentY + 2, vitalBoxWidth, 8);
    vitalX += 45;
  });

  // Add Side Effects Section with two columns and placeholder box
  currentY += 17;
  doc.setFont('helvetica', 'bold');
  doc.text('Side Effects', 20, currentY);
  doc.text('Meds Given', 110, currentY);
  doc.setFont('helvetica', 'normal');
  currentY += 2;

  // Create side effects checkboxes in two columns
  const leftColumnEffects = [
    'Nausea',
    'Vomiting',
    'Diarrhea',
    'Constipation'
  ];

  const rightColumnEffects = [
    'Heartburn',
    'Abdominal Pain',
    'Fatigue',
    'Other: __________'
  ];

  const checkboxSize = 4;
  const columnSpacing = 45; // Space between items in the same column
  let checkboxY = currentY;

  // Draw first column of side effects
  leftColumnEffects.forEach((effect, index) => {
    const x = 20;
    const y = checkboxY + (index * 7); // Reduced spacing between items
    doc.rect(x, y, checkboxSize, checkboxSize);
    doc.text(effect, x + checkboxSize + 3, y + checkboxSize);
  });

  // Draw second column of side effects
  rightColumnEffects.forEach((effect, index) => {
    const x = 20 + columnSpacing;
    const y = checkboxY + (index * 7); // Reduced spacing between items
    doc.rect(x, y, checkboxSize, checkboxSize);
    doc.text(effect, x + checkboxSize + 3, y + checkboxSize);
  });

  // Draw placeholder box on the right side
  const rightColumnX = 110;
  const boxWidth = 85;
  const boxHeight = 40;
  doc.setLineWidth(0.5);
  doc.rect(rightColumnX, checkboxY , boxWidth, boxHeight);

  // Draw text inside place holder box
  currentY+=7;
  doc.text('Semaglutide  /  Tirzepatide', 112, currentY);
  doc.text('Injection ________ mg', 112, currentY+10);
  doc.text('Syringe ________ mg    QTY ________', 112, currentY+20);
  doc.text('Vial ________ mg    QTY ________', 112, currentY+30);

  // Add footer with light gray background
  doc.setFillColor(245, 245, 245);
  doc.rect(0, 270, 210, 25, 'F');

  // Add office information
  doc.setFontSize(9);
  doc.setFont('helvetica', 'bold');
  doc.text('Magnolia Health and Wellness', 105, 275, { align: 'center' });
  doc.setFont('helvetica', 'normal');
  doc.text('Chico: 572 Rio Lindo Ave, Suite 108 | (530) 399-5600 • Redding: 68 Hartnell Ave | (530) 972-1200', 105, 281, { align: 'center' });
  doc.setFontSize(8);
  doc.text(
    `Generated on ${formatPacificDate(getCurrentPacificDate(), 'MMM d, yyyy')} at ${formatPacificTime(getCurrentPacificDate().toISOString().split('T')[1])}`,
    105,
    287,
    { align: 'center' }
  );
}

function formatWeight(weight: number | null): string {
  return weight ? `${weight.toFixed(1)} lbs` : 'N/A';
}

export function generatePatientSummaryPDF(
  patient: PatientDetails,
  nextAppointment: NextAppointment | null,
  visitHistory?: VisitHistory[]
) {
  const doc = new jsPDF();
  addPatientPage(doc, { patient, nextAppointment, visitHistory: visitHistory || [] }, true);
  doc.save(`${patient.last_name}_${patient.first_name}_Summary.pdf`);
}

export function generateBulkPatientSummaryPDF(patientSummaries: PatientSummary[]) {
  if (patientSummaries.length === 0) return;

  const doc = new jsPDF();
  
  patientSummaries.forEach((summary, index) => {
    addPatientPage(doc, summary, index === 0);
  });

  const timestamp = formatPacificDate(getCurrentPacificDate(), 'yyyy-MM-dd');
  doc.save(`Patient_Summaries_${timestamp}.pdf`);
}