import React, { useState, useEffect } from 'react';
import { useNavigate, Navigate } from 'react-router-dom';
import { 
  Users, 
  Settings, 
  Clock, 
  Lightbulb,
  Leaf,
  LogOut,
  Search,
  Filter,
  X,
  AlertCircle,
  Check,
  FileText,
  ChevronLeft,
  ChevronRight
} from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import { supabase } from '../lib/supabase';
import { generateBulkPatientSummaryPDF } from '../utils/pdfGenerator';
import { AppointmentListVertical } from '../components/dashboard/AppointmentListVertical';
import { MedicationsModal } from '../components/dashboard/MedicationsModal';
import { PatientDetailsModal } from '../components/PatientDetailsModal';
import { EnhancementSuggestionModal } from '../components/dashboard/EnhancementSuggestionModal';
import { getCurrentPacificDate, formatPacificDate } from '../utils/dateTime';

interface PatientInfo {
  id: string;
  firstName: string;
  lastName: string;
  memberships?: any[];
}

export function DashboardVertical() {
  const navigate = useNavigate();
  const { user, userRoles, signOut, loading } = useAuth();
  const [patientCount, setPatientCount] = useState<number>(0);
  const [activePatientCount, setActivePatientCount] = useState<number>(0);
  const [selectedDate, setSelectedDate] = useState(() => {
    const saved = localStorage.getItem('dashboardSelectedDate');
    return saved ? new Date(saved) : getCurrentPacificDate();
  });
  const [appointments, setAppointments] = useState([]);
  const [currentTime] = useState(getCurrentPacificDate());
  const [departments, setDepartments] = useState<string[]>([]);
  const [selectedDepartments, setSelectedDepartments] = useState<string[]>([]);
  const [showDepartmentFilter, setShowDepartmentFilter] = useState(false);
  const [showCancelled, setShowCancelled] = useState(false);
  const [generatingPDFs, setGeneratingPDFs] = useState(false);
  const [pdfProgress, setPdfProgress] = useState({ current: 0, total: 0 });
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [showMedicationsModal, setShowMedicationsModal] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState<PatientInfo | null>(null);
  const [appointmentDate, setAppointmentDate] = useState<string | null>(null);
  const [showPatientDetails, setShowPatientDetails] = useState(false);
  const [selectedPatientId, setSelectedPatientId] = useState<string | null>(null);
  const [showEnhancementModal, setShowEnhancementModal] = useState(false);

  // If loading, show nothing
  if (loading) {
    return null;
  }

  // If no user is authenticated, redirect to login
  if (!user) {
    return <Navigate to="/login" replace />;
  }

  useEffect(() => {
    fetchPatientCount();
    fetchActivePatientCount();
    fetchDepartments();
    if (user) {
      fetchUserPreferences();
    }
  }, [user]);

  useEffect(() => {
    fetchAppointments();
    localStorage.setItem('dashboardSelectedDate', selectedDate.toISOString());
  }, [selectedDate, selectedDepartments, showCancelled]);

  const fetchUserPreferences = async () => {
    try {
      const { data, error } = await supabase.rpc('get_or_create_user_preferences', {
        p_user_id: user!.id
      });
      
      if (error) throw error;
      
      const preferences = data as { selectedDepartments: string[], showCancelled: boolean };
      setSelectedDepartments(preferences.selectedDepartments || []);
      setShowCancelled(preferences.showCancelled || false);
    } catch (error) {
      console.error('Error fetching user preferences:', error);
    }
  };

  const updateUserPreferences = async (departments: string[], showCancelled: boolean) => {
    try {
      const preferences = {
        selectedDepartments: departments,
        showCancelled: showCancelled
      };

      const { error } = await supabase.rpc('update_user_preferences', {
        p_user_id: user!.id,
        p_preferences: preferences
      });

      if (error) throw error;
    } catch (error) {
      console.error('Error updating user preferences:', error);
    }
  };

  const fetchPatientCount = async () => {
    try {
      const { data, error } = await supabase.rpc('get_patient_count');
      if (error) throw error;
      setPatientCount(data[0]?.count || 0);
    } catch (error) {
      console.error('Error fetching patient count:', error);
    }
  };

  const fetchActivePatientCount = async () => {
    try {
      const { data, error } = await supabase.rpc('get_active_patients_count');
      if (error) throw error;
      setActivePatientCount(data[0]?.count || 0);
    } catch (error) {
      console.error('Error fetching active patient count:', error);
    }
  };

  const fetchDepartments = async () => {
    try {
      const { data, error } = await supabase.rpc('get_unique_departments');
      if (error) throw error;
      setDepartments(data.map(d => d.department));
    } catch (error) {
      console.error('Error fetching departments:', error);
    }
  };

  const fetchAppointments = async () => {
    try {
      const { data: appointmentsData, error: appointmentsError } = await supabase.rpc(
        'get_appointments_by_date',
        {
          p_date: formatPacificDate(selectedDate, 'yyyy-MM-dd'),
          p_show_cancelled: showCancelled
        }
      );
      if (appointmentsError) throw appointmentsError;

      const filteredAppointments = selectedDepartments.length > 0
        ? appointmentsData.filter((appointment) => 
            selectedDepartments.includes(appointment.service_department)
          )
        : appointmentsData;

      setAppointments(filteredAppointments || []);
    } catch (error) {
      console.error('Error fetching appointments:', error);
    }
  };

  const handleDepartmentToggle = async (department: string) => {
    const newSelection = selectedDepartments.includes(department)
      ? selectedDepartments.filter(d => d !== department)
      : [...selectedDepartments, department];
    
    setSelectedDepartments(newSelection);
    await updateUserPreferences(newSelection, showCancelled);
  };

  const handleCancelledToggle = async () => {
    const newShowCancelled = !showCancelled;
    setShowCancelled(newShowCancelled);
    await updateUserPreferences(selectedDepartments, newShowCancelled);
  };

  const isCurrentAppointment = (appointmentTime: string) => {
    if (!isSameDay(selectedDate, getCurrentPacificDate())) return false;
    
    const [hours, minutes] = appointmentTime.split(':').map(Number);
    const appointmentStart = new Date(selectedDate);
    appointmentStart.setHours(hours, minutes, 0);
    
    const appointmentEnd = new Date(appointmentStart);
    appointmentEnd.setMinutes(appointmentEnd.getMinutes() + 30);
    
    return currentTime >= appointmentStart && currentTime <= appointmentEnd;
  };

  const isSameDay = (date1: Date, date2: Date) => {
    return formatPacificDate(date1, 'yyyy-MM-dd') === formatPacificDate(date2, 'yyyy-MM-dd');
  };

  const adjustDate = (days: number) => {
    const newDate = new Date(selectedDate);
    newDate.setDate(newDate.getDate() + days);
    setSelectedDate(newDate);
  };

  const handleGenerateAllPDFs = async () => {
    if (generatingPDFs || appointments.length === 0) return;
    
    setGeneratingPDFs(true);
    setError(null);
    setSuccess(null);
    
    try {
      const nonCancelledAppointments = appointments.filter(a => !a.cancelled);
      setPdfProgress({ current: 0, total: nonCancelledAppointments.length });
      
      const patientSummaries = [];

      for (let i = 0; i < nonCancelledAppointments.length; i++) {
        const appointment = nonCancelledAppointments[i];
        setPdfProgress(prev => ({ ...prev, current: i + 1 }));

        const { data: patientData, error: patientError } = await supabase.rpc('get_patient_details', {
          p_patient_id: appointment.patient_id
        });

        if (patientError) throw patientError;
        if (!patientData || patientData.length === 0) continue;

        const { data: nextAppointmentData, error: appointmentError } = await supabase.rpc('get_next_appointment', {
          p_patient_id: appointment.patient_id
        });

        if (appointmentError) throw appointmentError;

        const { data: historyData, error: historyError } = await supabase.rpc('get_patient_visit_history', {
          p_patient_id: appointment.patient_id
        });

        if (historyError) throw historyError;

        patientSummaries.push({
          patient: patientData[0],
          nextAppointment: nextAppointmentData[0] || null,
          visitHistory: historyData || []
        });
      }

      generateBulkPatientSummaryPDF(patientSummaries);
      setSuccess('All patient summaries have been generated in a single PDF');
    } catch (error: any) {
      console.error('Error generating PDFs:', error);
      setError('Failed to generate patient summaries. Please try again.');
    } finally {
      setGeneratingPDFs(false);
      setPdfProgress({ current: 0, total: 0 });
    }
  };

  const handleCheckoutClick = (appointment: any) => {
    setSelectedPatient({
      id: appointment.patient_id,
      firstName: appointment.patient_first_name,
      lastName: appointment.patient_last_name,
      memberships: appointment.memberships
    });
    setShowMedicationsModal(true);
    setAppointmentDate(formatPacificDate(selectedDate, 'yyyy-MM-dd'));
  };

  const handlePatientClick = (patientId: string) => {
    setSelectedPatientId(patientId);
    setShowPatientDetails(true);
  };

  return (
    <div className="min-h-screen h-screen flex bg-gradient-to-br from-green-50 to-blue-50">
      {/* Sidebar */}
      <div className="w-64 bg-white shadow-lg flex flex-col">
        <div className="p-4 border-b border-gray-200">
          <div className="flex items-center">
            <Leaf className="h-6 w-6 text-green-600" />
            <div className="ml-2">
              <span className="text-xl font-semibold text-gray-900">THRIVE</span>
            </div>
          </div>
        </div>

        <div className="flex-1 p-4 space-y-4">
          {/* Stats Card */}
          <div className="bg-white rounded-lg shadow p-4 hover:shadow-md transition-shadow duration-200 cursor-pointer"
               onClick={() => navigate('/patients')}>
            <div className="flex items-center">
              <Users className="h-5 w-5 text-green-600" />
              <div className="ml-3">
                <div className="text-sm font-medium text-gray-500">Total Patients</div>
                <div className="text-lg font-semibold text-gray-900">{patientCount}</div>
              </div>
            </div>
          </div>

          {/* Membership Issues Card */}
          <div className="bg-white rounded-lg shadow p-4 hover:shadow-md transition-shadow duration-200 cursor-pointer"
               onClick={() => navigate('/membership-issues')}>
            <div className="flex items-center">
              <Clock className="h-5 w-5 text-yellow-600" />
              <div className="ml-3">
                <div className="text-sm font-medium text-gray-500">Membership Issues</div>
                <div className="text-lg font-semibold text-gray-900">View All</div>
              </div>
            </div>
          </div>

          {/* Settings Card */}
          <div className="bg-white rounded-lg shadow p-4 hover:shadow-md transition-shadow duration-200 cursor-pointer"
               onClick={() => navigate('/settings')}>
            <div className="flex items-center">
              <Settings className="h-5 w-5 text-gray-400" />
              <div className="ml-3">
                <div className="text-sm font-medium text-gray-500">Settings</div>
                <div className="text-lg font-semibold text-gray-900">Administration</div>
              </div>
            </div>
          </div>

          {/* Enhancements Card */}
          <div className="bg-white rounded-lg shadow p-4 hover:shadow-md transition-shadow duration-200 cursor-pointer"
               onClick={() => setShowEnhancementModal(true)}>
            <div className="flex items-center">
              <Lightbulb className="h-5 w-5 text-purple-600" />
              <div className="ml-3">
                <div className="text-sm font-medium text-gray-500">Future Enhancements</div>
                <div className="text-lg font-semibold text-gray-900">Submit Ideas</div>
              </div>
            </div>
          </div>
        </div>

        {/* User Info */}
        <div className="p-4 border-t border-gray-200">
          <div className="text-sm text-gray-700">{user?.email}</div>
          <div className="flex flex-wrap gap-2 mt-2">
            {userRoles.map((role) => (
              <span
                key={role}
                className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800"
              >
                {role}
              </span>
            ))}
          </div>
          <button
            onClick={() => signOut()}
            className="mt-4 w-full inline-flex items-center justify-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-green-600 hover:bg-green-700"
          >
            <LogOut className="h-4 w-4 mr-2" />
            Sign Out
          </button>
        </div>
      </div>

      {/* Main Content */}
      <div className="flex-1 flex flex-col h-screen overflow-hidden">
        {/* Date Navigation */}
        <div className="bg-white shadow-sm p-4">
          <div className="flex items-center justify-between">
            <h1 className="text-xl font-semibold text-gray-900">
              Daily Appointments ({appointments.length})
            </h1>
            <div className="flex items-center space-x-4">
              <button
                onClick={handleGenerateAllPDFs}
                disabled={generatingPDFs || appointments.length === 0}
                className={`inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white ${
                  generatingPDFs || appointments.length === 0
                    ? 'bg-gray-400 cursor-not-allowed'
                    : 'bg-green-600 hover:bg-green-700'
                }`}
              >
                <FileText className="h-4 w-4 mr-2" />
                {generatingPDFs ? (
                  <div className="flex items-center">
                    <span>Preparing Daily Slips ({pdfProgress.current}/{pdfProgress.total})</span>
                    <div className="ml-3 h-1 w-24 bg-white/20 rounded-full overflow-hidden">
                      <div 
                        className="h-full bg-white transition-all duration-300"
                        style={{ 
                          width: `${(pdfProgress.current / pdfProgress.total) * 100}%`
                        }}
                      />
                    </div>
                  </div>
                ) : (
                  'Prepare Daily Slips'
                )}
              </button>

              <button
                onClick={() => adjustDate(-1)}
                className="p-2 text-gray-400 hover:text-gray-600"
                title="Previous Day"
              >
                <ChevronLeft className="h-5 w-5" />
              </button>
              <button
                onClick={() => setSelectedDate(getCurrentPacificDate())}
                className="px-3 py-1 text-sm text-green-600 hover:text-green-700 font-medium"
              >
                Today
              </button>
              <button
                onClick={() => adjustDate(1)}
                className="p-2 text-gray-400 hover:text-gray-600"
                title="Next Day"
              >
                <ChevronRight className="h-5 w-5" />
              </button>
              <button
                onClick={() => navigate('/dashboard')}
                className="inline-flex items-center px-3 py-2 border border-gray-300 text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
              >
                Switch to Standard Layout
              </button>
            </div>
          </div>

          <div className="flex items-center justify-between mt-2">
            <p className="text-sm text-gray-500">
              {formatPacificDate(selectedDate)}
            </p>
            <div className="flex items-center space-x-2">
              <button
                onClick={handleCancelledToggle}
                className={`inline-flex items-center px-3 py-2 border rounded-md text-sm font-medium transition-colors duration-150 ${
                  showCancelled
                    ? 'border-red-500 text-red-700 bg-red-50 hover:bg-red-100'
                    : 'border-gray-300 text-gray-700 bg-white hover:bg-gray-50'
                }`}
              >
                {showCancelled ? 'Hide Cancelled' : 'Show Cancelled'}
              </button>
              <div className="relative">
                <button
                  onClick={() => setShowDepartmentFilter(!showDepartmentFilter)}
                  className={`inline-flex items-center px-3 py-2 border rounded-md text-sm font-medium transition-colors duration-150 ${
                    selectedDepartments.length > 0
                      ? 'border-green-500 text-green-700 bg-green-50 hover:bg-green-100'
                      : 'border-gray-300 text-gray-700 bg-white hover:bg-gray-50'
                  }`}
                >
                  <Filter className="h-4 w-4 mr-2" />
                  {selectedDepartments.length === 0
                    ? 'Filter Departments'
                    : `${selectedDepartments.length} Department${selectedDepartments.length === 1 ? '' : 's'}`}
                </button>

                {showDepartmentFilter && (
                  <div className="absolute right-0 mt-2 w-64 bg-white rounded-lg shadow-lg z-10 border border-gray-200">
                    <div className="p-4">
                      <div className="flex items-center justify-between mb-4">
                        <h4 className="text-sm font-medium text-gray-900">Departments</h4>
                        <button
                          onClick={() => setShowDepartmentFilter(false)}
                          className="text-gray-400 hover:text-gray-500"
                        >
                          <X className="h-4 w-4" />
                        </button>
                      </div>
                      <div className="space-y-2">
                        {departments.map((department) =>
                          <label key={department} className="flex items-center">
                            <input
                              type="checkbox"
                              checked={selectedDepartments.includes(department)}
                              onChange={() => handleDepartmentToggle(department)}
                              className="rounded border-gray-300 text-green-600 focus:ring-green-500"
                            />
                            <span className="ml-2 text-sm text-gray-900">{department}</span>
                          </label>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* Appointments List */}
        <div className="flex-1 overflow-hidden">
          <AppointmentListVertical 
            appointments={appointments}
            isCurrentAppointment={isCurrentAppointment}
            onCheckoutClick={handleCheckoutClick}
            onPatientClick={handlePatientClick}
            selectedDate={selectedDate}
          />
        </div>
      </div>

      {/* Modals */}
      <MedicationsModal
        isOpen={showMedicationsModal}
        onClose={() => {
          setShowMedicationsModal(false);
          setAppointmentDate(null);
        }}
        patient={selectedPatient}
        appointmentDate={appointmentDate || undefined}
      />

      <PatientDetailsModal
        patientId={selectedPatientId || ''}
        isOpen={showPatientDetails}
        onClose={() => {
          setShowPatientDetails(false);
          setSelectedPatientId(null);
        }}
      />

      <EnhancementSuggestionModal 
        isOpen={showEnhancementModal}
        onClose={() => setShowEnhancementModal(false)}
      />

      {/* Status Messages */}
      {(success || error) && (
        <div className={`fixed bottom-4 right-4 p-4 rounded-lg shadow-lg ${
          success ? 'bg-green-50 text-green-800' : 'bg-red-50 text-red-800'
        }`}>
          <div className="flex items-center">
            {success ? (
              <Check className="h-5 w-5 mr-2" />
            ) : (
              <AlertCircle className="h-5 w-5 mr-2" />
            )}
            <p>{success || error}</p>
            <button
              onClick={() => {
                setSuccess(null);
                setError(null);
              }}
              className="ml-4 text-gray-400 hover:text-gray-500"
            >
              <X className="h-4 w-4" />
            </button>
          </div>
        </div>
      )}
    </div>
  );
}