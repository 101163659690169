import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { 
  ArrowLeft, 
  User, 
  Calendar, 
  Scale, 
  Activity, 
  Leaf, 
  TrendingDown, 
  Pill, 
  Ruler, 
  Clock, 
  CalendarClock,
  FileText,
  AlertCircle,
  CreditCard,
  DollarSign,
  CalendarDays,
  RotateCcw,
  CheckCircle2,
  XCircle,
  Clock3,
  Check,
  Edit,
  MoreVertical,
  LogOut,
  Syringe,
  ShoppingCart,
  Trash2,
  Edit2
} from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import { supabase } from '../lib/supabase';
import { generatePatientSummaryPDF } from '../utils/pdfGenerator';
import { MembershipStatus } from '../components/dashboard/MembershipStatus';
import { MedicationsModal } from '../components/dashboard/MedicationsModal';
import { formatPacificDate, getCurrentPacificDate } from '../utils/dateTime';

interface PatientDetails {
  id: uuid;
  patient_id: string;
  first_name: string;
  last_name: string;
  date_of_birth: string;
  sex: string;
  first_visit: string | null;
  last_visit: string | null;
  first_weight: number | null;
  last_weight: number | null;
  lowest_weight: number | null;
  weight_loss: number | null;
  activity_status: string;
  has_future_appointments: boolean;
  last_body_comp_date: string | null;
  weeks_since_body_comp: number | null;
  body_comp_overdue: boolean;
  memberships?: any[];
}

interface BodyCompMeasurement {
  id: uuid;
  measurement_date: string;
}

interface MedicationRecord {
  id: string;
  service_date: string;
  description: string;
  milligrams: number;
  form: string;
  units: number;
  procedure_code: string;
}

export function PatientServices() {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { user, userRoles, signOut } = useAuth();
  const [patient, setPatient] = useState<PatientDetails | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [bodyCompHistory, setBodyCompHistory] = useState<BodyCompMeasurement[]>([]);
  const [hoveredMeasurement, setHoveredMeasurement] = useState<string | null>(null);
  const [deleteConfirmation, setDeleteConfirmation] = useState<string | null>(null);
  const [bodyCompStatus, setBodyCompStatus] = useState({
    loading: false,
    hasBodyComp: false
  });
  const [medications, setMedications] = useState<MedicationRecord[]>([]);
  const [showMedicationsModal, setShowMedicationsModal] = useState(false);
  const [medicationToDelete, setMedicationToDelete] = useState<string | null>(null);
  const [medicationToEdit, setMedicationToEdit] = useState<MedicationRecord | null>(null);

  useEffect(() => {
    if (id) {
      fetchPatientData();
      checkBodyCompStatus();
      fetchBodyCompHistory();
      fetchMedications();
    }
  }, [id]);

  const fetchPatientData = async () => {
    try {
      const { data, error } = await supabase.rpc('get_patient_details', {
        p_patient_id: id
      });

      if (error) throw error;
      if (!data || data.length === 0) {
        setError('Patient not found');
        return;
      }

      setPatient(data[0]);
    } catch (error: any) {
      console.error('Error fetching patient data:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const checkBodyCompStatus = async () => {
    if (!id) return;

    try {
      setBodyCompStatus(prev => ({ ...prev, loading: true }));

      const today = getCurrentPacificDate().toISOString().split('T')[0];
      const { data, error } = await supabase
        .from('body_composition_measurements')
        .select('id')
        .eq('external_patient_id', id)
        .eq('measurement_date', today)
        .limit(1);

      if (error) throw error;
      setBodyCompStatus(prev => ({
        ...prev,
        hasBodyComp: data && data.length > 0
      }));
    } catch (error) {
      console.error('Error checking body composition status:', error);
    } finally {
      setBodyCompStatus(prev => ({ ...prev, loading: false }));
    }
  };

  const fetchBodyCompHistory = async () => {
    if (!id) return;

    try {
      const { data, error } = await supabase
        .from('body_composition_measurements')
        .select('id, measurement_date')
        .eq('external_patient_id', id)
        .order('measurement_date', { ascending: false });

      if (error) throw error;
      setBodyCompHistory(data || []);
    } catch (error) {
      console.error('Error fetching body composition history:', error);
    }
  };

  const handleBodyCompToggle = async () => {
    if (!id || !patient) return;

    try {
      setBodyCompStatus(prev => ({ ...prev, loading: true }));

      if (bodyCompStatus.hasBodyComp) {
        // Remove body composition record
        const { error } = await supabase
          .from('body_composition_measurements')
          .delete()
          .eq('external_patient_id', id)
          .eq('measurement_date', getCurrentPacificDate().toISOString().split('T')[0]);

        if (error) throw error;
      } else {
        // Add body composition record using the new function
        const { data, error } = await supabase.rpc('upsert_body_composition', {
          p_patient_id: patient.id,
          p_external_patient_id: id,
          p_measurement_date: getCurrentPacificDate().toISOString().split('T')[0]
        });

        if (error) throw error;
        if (!data.success) throw new Error(data.error);
      }

      // Update status
      setBodyCompStatus(prev => ({
        loading: false,
        hasBodyComp: !prev.hasBodyComp
      }));

      // Show success message
      setSuccess(`Body composition measurement ${bodyCompStatus.hasBodyComp ? 'removed' : 'recorded'} successfully`);
      setTimeout(() => setSuccess(null), 3000);

      // Refresh body comp history
      await fetchBodyCompHistory();
    } catch (error: any) {
      console.error('Error toggling body composition:', error);
      setError('Failed to update body composition measurement');
      setTimeout(() => setError(null), 3000);
      setBodyCompStatus(prev => ({ ...prev, loading: false }));
    }
  };

  const handleDeleteClick = (measurementId: string, date: string) => {
    setDeleteConfirmation({ id: measurementId, date });
  };

  const handleMedicationsModalClose = () => {
    setShowMedicationsModal(false);
    setMedicationToEdit(null);
    fetchMedications();
  };

  const fetchMedications = async () => {
    if (!id) return;

    try {
      const { data, error } = await supabase
        .from('medications_administered')
        .select(`
          id,
          service_date,
          procedure_code,
          units,
          procedure_codes (
            description,
            milligrams,
            form
          )
        `)
        .eq('external_patient_id', id)
        .order('service_date', { ascending: false });

      if (error) throw error;

      const formattedMedications = data.map(record => ({
        id: record.id,
        service_date: record.service_date,
        procedure_code: record.procedure_code,
        description: record.procedure_codes?.description || 'Unknown',
        milligrams: record.procedure_codes?.milligrams || 0,
        form: record.procedure_codes?.form || 'none',
        units: record.units
      }));

      setMedications(formattedMedications);
    } catch (error) {
      console.error('Error fetching medications:', error);
    }
  };

  const handleDeleteMedication = async () => {
    if (!medicationToDelete) return;

    try {
      const { error } = await supabase
        .from('medications_administered')
        .delete()
        .eq('id', medicationToDelete);

      if (error) throw error;

      setSuccess('Medication record deleted successfully');
      fetchMedications();
      setTimeout(() => setSuccess(null), 3000);
    } catch (error: any) {
      console.error('Error deleting medication:', error);
      setError('Failed to delete medication record');
      setTimeout(() => setError(null), 3000);
    } finally {
      setMedicationToDelete(null);
    }
  };

  const handleGeneratePDF = () => {
    if (patient) {
      generatePatientSummaryPDF(patient, null, []);
    }
  };

  const calculateWeightLossPercentage = () => {
    if (!patient?.first_weight || !patient?.weight_loss) return null;
    return (patient.weight_loss / patient.first_weight) * 100;
  };

  const getActivityStatusDetails = (status: string) => {
    switch (status) {
      case 'active':
        return {
          label: 'Active Patient',
          color: 'text-green-100',
          bgColor: 'bg-green-600/10',
          gradient: 'from-green-600 to-blue-600'
        };
      case 'possibly_inactive':
        return {
          label: 'Possibly Inactive',
          color: 'text-yellow-100',
          bgColor: 'bg-yellow-600/10',
          gradient: 'from-yellow-600 to-orange-600'
        };
      case 'inactive':
        return {
          label: 'Inactive Patient',
          color: 'text-red-100',
          bgColor: 'bg-red-600/10',
          gradient: 'from-red-600 to-pink-600'
        };
      case 'future_patient':
        return {
          label: 'Future Patient',
          color: 'text-blue-100',
          bgColor: 'bg-blue-600/10',
          gradient: 'from-blue-600 to-indigo-600'
        };
      default:
        return {
          label: 'Unknown Status',
          color: 'text-gray-100',
          bgColor: 'bg-gray-600/10',
          gradient: 'from-gray-600 to-gray-600'
        };
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-green-50 to-blue-50 flex items-center justify-center">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-green-600"></div>
      </div>
    );
  }

  if (error || !patient) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-green-50 to-blue-50 flex items-center justify-center">
        <div className="text-center">
          <h2 className="text-xl font-semibold text-gray-900 mb-2">Error</h2>
          <p className="text-gray-600">{error || 'Failed to load patient details'}</p>
          <button
            onClick={() => navigate('/patients')}
            className="mt-4 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700"
          >
            <ArrowLeft className="h-4 w-4 mr-2" />
            Back to Patients
          </button>
        </div>
      </div>
    );
  }

  const weightLossPercentage = calculateWeightLossPercentage();
  const activityStatus = getActivityStatusDetails(patient.activity_status);

  return (
    <div className="min-h-screen bg-gradient-to-br from-green-50 to-blue-50">
      <nav className="bg-white shadow-sm">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16">
            <div className="flex items-center">
              <Leaf className="h-6 w-6 text-green-600" />
              <div className="ml-2">
                <span className="text-xl font-semibold text-gray-900">THRIVE</span>
                <span className="ml-2 text-sm text-gray-500">Record Services</span>
              </div>
            </div>
            <div className="flex items-center space-x-4">
              <span className="text-sm text-gray-700">{user?.email}</span>
              <div className="flex flex-wrap gap-2">
                {userRoles.map((role) => (
                  <span
                    key={role}
                    className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800"
                  >
                    {role}
                  </span>
                ))}
              </div>
              <button
                onClick={() => signOut()}
                className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-green-600 hover:bg-green-700"
              >
                <LogOut className="h-4 w-4 mr-2" />
                Sign Out
              </button>
            </div>
          </div>
        </div>
      </nav>

      <main className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        <div className="px-4 sm:px-0">
          {/* Header Section */}
          <div className="bg-white shadow-lg rounded-lg mb-6 overflow-hidden">
            <div className="relative">
              <div className="absolute inset-0 bg-gradient-to-r from-green-600 to-blue-600 opacity-90"></div>
              <div className="relative px-8 py-8">
                <div className="flex items-center justify-between">
                  <button
                    onClick={() => navigate('/patients')}
                    className="text-white hover:text-green-100 transition-colors duration-200"
                  >
                    <ArrowLeft className="h-6 w-6" />
                  </button>
                  <div className="flex items-center space-x-4">
                    <button
                      onClick={handleGeneratePDF}
                      className="bg-white/10 backdrop-blur-sm rounded-lg px-4 py-2 flex items-center text-white hover:bg-white/20"
                    >
                      <FileText className="h-5 w-5 mr-2" />
                      Generate PDF
                    </button>
                    {patient.weight_loss && patient.weight_loss > 0 && (
                      <div className="bg-white/10 backdrop-blur-sm rounded-lg px-4 py-2 flex items-center">
                        <TrendingDown className="h-5 w-5 text-green-200 mr-2" />
                        <span className="text-white font-semibold">
                          {patient.weight_loss.toFixed(1)} lbs lost
                        </span>
                      </div>
                    )}
                    {weightLossPercentage && weightLossPercentage >= 5 && (
                      <div className="bg-white/10 backdrop-blur-sm rounded-lg px-4 py-2 flex items-center">
                        <Activity className="h-5 w-5 text-green-200 mr-2" />
                        <span className="text-white font-semibold">
                          {weightLossPercentage.toFixed(1)}% reduction
                        </span>
                      </div>
                    )}
                    <div className={`${activityStatus.bgColor} backdrop-blur-sm rounded-lg px-4 py-2 flex items-center`}>
                      <Clock className={`h-5 w-5 ${activityStatus.color} mr-2`} />
                      <span className={`font-semibold ${activityStatus.color}`}>
                        {activityStatus.label}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="mt-4">
                  <h1 className="text-3xl font-bold text-white">
                    {patient.first_name} {patient.last_name}
                  </h1>
                  <p className="mt-2 text-green-100">
                    Patient ID: {patient.patient_id}
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Membership Information */}
          <div className="bg-white shadow-lg rounded-lg overflow-hidden mb-6">
            <div className="px-6 py-5 border-b border-gray-200">
              <div className="flex items-center">
                <CreditCard className="h-5 w-5 text-green-600" />
                <h3 className="ml-2 text-lg font-medium text-gray-900">Membership Information</h3>
              </div>
            </div>
            <div className="px-6 py-5">
              {patient.memberships && patient.memberships.length > 0 ? (
                <MembershipStatus memberships={patient.memberships} expanded={true} />
              ) : (
                <p className="text-gray-500 text-center py-4">
                  No membership information found
                </p>
              )}
            </div>
          </div>

          {/* Body Composition Section */}
          <div className="bg-white shadow-lg rounded-lg overflow-hidden mb-6">
            <div className="px-6 py-5 border-b border-gray-200">
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <Ruler className="h-5 w-5 text-green-600" />
                  <h3 className="ml-2 text-lg font-medium text-gray-900">Body Composition</h3>
                </div>
                <button
                  onClick={handleBodyCompToggle}
                  disabled={bodyCompStatus.loading}
                  className={`inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white ${
                    bodyCompStatus.loading
                      ? 'bg-gray-400 cursor-not-allowed'
                      : bodyCompStatus.hasBodyComp
                      ? 'bg-red-600 hover:bg-red-700'
                      : 'bg-green-600 hover:bg-green-700'
                  }`}
                >
                  <Ruler className="h-4 w-4 mr-2" />
                  {bodyCompStatus.loading
                    ? 'Processing...'
                    : bodyCompStatus.hasBodyComp
                    ? 'Remove Body Composition'
                    : 'Record Body Composition'}
                </button>
              </div>
            </div>
            <div className="p-6">
              {/* Today's Status */}
              {bodyCompStatus.hasBodyComp && (
                <div className="mb-6 text-center">
                  <div className="inline-flex items-center px-4 py-2 bg-green-100 text-green-800 rounded-lg">
                    <Check className="h-5 w-5 mr-2" />
                    Body composition recorded for today
                  </div>
                </div>
              )}

              {/* History Timeline */}
              {bodyCompHistory.length > 0 ? (
                <div className="relative">
                  <div className="absolute top-0 bottom-0 left-8 w-0.5 bg-gray-200"></div>
                  <ul className="space-y-4">
                    {bodyCompHistory.map((measurement, index) => (
                      <li 
                        key={measurement.measurement_date} 
                        className="relative"
                        onMouseEnter={() => setHoveredMeasurement(measurement.id)}
                        onMouseLeave={() => setHoveredMeasurement(null)}
                      >
                        <div className="flex items-center">
                          <div className="absolute left-8 -ml-2 h-4 w-4 rounded-full bg-green-500 border-2 border-white"></div>
                          <div className="ml-12 flex items-center text-sm">
                            <Calendar className="h-4 w-4 text-gray-400 mr-2" />
                            <span className="font-medium text-gray-900">
                              {formatPacificDate(measurement.measurement_date)}
                            </span>
                            {index === 0 && (
                              <span className="ml-2 text-xs text-gray-500">
                                (Most Recent)
                              </span>
                            )}
                            {hoveredMeasurement === measurement.id && (
                              <button
                                onClick={() => handleDeleteClick(measurement.id, measurement.measurement_date)}
                                className="ml-4 text-red-600 hover:text-red-800 transition-colors duration-150"
                                title="Delete measurement"
                              >
                                <Trash2 className="h-4 w-4" />
                              </button>
                            )}
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              ) : (
                <p className="text-center text-gray-500">
                  No body composition measurements recorded
                </p>
              )}
            </div>
          </div>

          {/* Medications Received Section */}
          <div className="bg-white shadow-lg rounded-lg overflow-hidden mb-6">
            <div className="px-6 py-5 border-b border-gray-200">
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <Syringe className="h-5 w-5 text-green-600" />
                  <h3 className="ml-2 text-lg font-medium text-gray-900">Medications Received</h3>
                </div>
                <button
                  onClick={() => {
                    setMedicationToEdit(null);
                    setShowMedicationsModal(true);
                  }}
                  className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700"
                >
                  <ShoppingCart className="h-4 w-4 mr-2" />
                  Record Medications
                </button>
              </div>
            </div>
            <div className="p-6">
              {medications.length > 0 ? (
                <div className="relative">
                  <div className="absolute top-0 bottom-0 left-8 w-0.5 bg-gray-200"></div>
                  <ul className="space-y-4">
                    {medications.map((medication, index) => (
                      <li key={`${medication.service_date}-${index}`} className="relative">
                        <div className="flex items-center">
                          <div className="absolute left-8 -ml-2 h-4 w-4 rounded-full bg-blue-500 border-2 border-white"></div>
                          <div className="ml-12 flex flex-col flex-1">
                            <div className="flex items-center justify-between">
                              <div className="flex items-center text-sm">
                                <Calendar className="h-4 w-4 text-gray-400 mr-2" />
                                <span className="font-medium text-gray-900">
                                  {formatPacificDate(medication.service_date)}
                                </span>
                              </div>
                              <div className="flex items-center space-x-2">
                                <button
                                  onClick={() => {
                                    setMedicationToEdit(medication);
                                    setShowMedicationsModal(true);
                                  }}
                                  className="text-blue-600 hover:text-blue-800 p-1 rounded"
                                  title="Edit medication"
                                >
                                  <Edit2 className="h-4 w-4" />
                                </button>
                                <button
                                  onClick={() => setMedicationToDelete(medication.id)}
                                  className="text-red-600 hover:text-red-800 p-1 rounded"
                                  title="Delete medication"
                                >
                                  <Trash2 className="h-4 w-4" />
                                </button>
                              </div>
                            </div>
                            <div className="mt-1 text-sm text-gray-600">
                              <div className="flex items-center">
                                <Pill className="h-4 w-4 text-blue-500 mr-2" />
                                <span className="font-medium">{medication.description}</span>
                              </div>
                              <div className="ml-6 mt-1 flex items-center space-x-3 text-gray-500">
                                {medication.milligrams > 0 && (
                                  <span>{medication.milligrams} mg</span>
                                )}
                                {medication.form !== 'none' && (
                                  <>
                                    <span>•</span>
                                    <span>{medication.form.charAt(0).toUpperCase() + medication.form.slice(1)}</span>
                                  </>
                                )}
                                <span>•</span>
                                <span>Qty: {medication.units}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              ) : (
                <p className="text-gray-500 text-center py-4">
                  No medications recorded
                </p>
              )}
            </div>
          </div>

          {/* Services Content Placeholder */}
          <div className="bg-white shadow-lg rounded-lg overflow-hidden">
            <div className="px-6 py-5 border-b border-gray-200">
              <h3 className="text-lg font-medium text-gray-900">Additional Services</h3>
            </div>
            <div className="p-6">
              <p className="text-gray-500 text-center">
                Additional service recording interface will be implemented here
              </p>
            </div>
          </div>
        </div>
      </main>

      {/* Delete Medication Confirmation Modal */}
      {medicationToDelete && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-[60]">
          <div className="bg-white rounded-lg shadow-xl max-w-md w-full p-6">
            <div className="flex items-center justify-center mb-4">
              <XCircle className="h-12 w-12 text-red-500" />
            </div>
            <h3 className="text-lg font-medium text-gray-900 text-center mb-2">
              Confirm Delete
            </h3>
            <p className="text-sm text-gray-500 text-center mb-6">
              Are you sure you want to delete this medication record?
              This action cannot be undone.
            </p>
            <div className="flex justify-center space-x-4">
              <button
                onClick={() => setMedicationToDelete(null)}
                className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50"
              >
                Cancel
              </button>
              <button
                onClick={handleDeleteMedication}
                className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Medications Modal */}
      <MedicationsModal
        isOpen={showMedicationsModal}
        onClose={handleMedicationsModalClose}
        patient={patient ? {
          id: patient.patient_id,
          firstName: patient.first_name,
          lastName: patient.last_name,
          memberships: patient.memberships
        } : null}
        appointmentDate={medicationToEdit ? medicationToEdit.service_date : getCurrentPacificDate().toISOString().split('T')[0]}
      />

      {/* Status Messages */}
      {(success || error) && (
        <div className={`fixed bottom-4 right-4 px-4 py-3 rounded-lg shadow-lg flex items-center z-50 ${
          success ? 'bg-green-50 text-green-800' : 'bg-red-50 text-red-800'
        }`}>
          {success ? (
            <Check className="h-5 w-5 mr-2" />
          ) : (
            <AlertCircle className="h-5 w-5 mr-2" />
          )}
          <span>{success || error}</span>
        </div>
      )}
    </div>
  );
}