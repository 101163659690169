import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  ArrowLeft, 
  Leaf, 
  Clock, 
  Check, 
  X, 
  AlertCircle, 
  Shield, 
  Download,
  Search,
  Ban,
  Globe2,
  Monitor,
  Calendar
} from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import { supabase } from '../lib/supabase';
import { 
  formatPacificDateTime,
  getCurrentPacificDate,
  startOfPacificDay,
  subPacificDays,
  getPacificDateForQuery
} from '../utils/dateTime';

interface LoginAttempt {
  id: string;
  email: string;
  ip_address: string;
  user_agent: string;
  success: boolean;
  failure_reason: string | null;
  country: string | null;
  city: string | null;
  created_at: string;
}

export function LoginActivity() {
  const navigate = useNavigate();
  const { userRoles } = useAuth();
  const isAdmin = userRoles.includes('admin');
  const [loginHistory, setLoginHistory] = useState<LoginAttempt[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterSuccess, setFilterSuccess] = useState<boolean | null>(null);
  const [dateRange, setDateRange] = useState<'today' | 'week' | 'month' | 'all'>('all');

  useEffect(() => {
    if (!isAdmin) {
      navigate('/settings');
      return;
    }
    fetchLoginHistory();
  }, [isAdmin, navigate]);

  const fetchLoginHistory = async () => {
    try {
      setLoading(true);
      const { data, error } = await supabase.rpc('get_login_history');
      if (error) throw error;
      setLoginHistory(data || []);
    } catch (error: any) {
      console.error('Error fetching login history:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const downloadCSV = () => {
    const headers = ['Date/Time', 'Email', 'Status', 'Reason', 'IP Address', 'Location', 'Browser'];
    const rows = filteredHistory.map(attempt => [
      formatPacificDateTime(attempt.created_at),
      attempt.email,
      attempt.success ? 'Success' : 'Failed',
      attempt.failure_reason || '',
      attempt.ip_address,
      [attempt.city, attempt.country].filter(Boolean).join(', '),
      attempt.user_agent
    ]);

    const csvContent = [
      headers.join(','),
      ...rows.map(row => row.map(cell => `"${cell}"`).join(','))
    ].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `login_activity_${getPacificDateForQuery(getCurrentPacificDate())}.csv`;
    link.click();
  };

  const getDateFilter = (date: string) => {
    const attemptDate = new Date(date);
    const now = getCurrentPacificDate();
    const dayStart = startOfPacificDay(now);

    switch (dateRange) {
      case 'today':
        return attemptDate >= dayStart;
      case 'week':
        const weekStart = subPacificDays(dayStart, 7);
        return attemptDate >= weekStart;
      case 'month':
        const monthStart = subPacificDays(dayStart, 30);
        return attemptDate >= monthStart;
      default:
        return true;
    }
  };

  const filteredHistory = loginHistory.filter(attempt => {
    const matchesSearch = 
      attempt.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
      attempt.ip_address.includes(searchTerm) ||
      (attempt.city && attempt.city.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (attempt.country && attempt.country.toLowerCase().includes(searchTerm.toLowerCase()));

    const matchesStatus = filterSuccess === null || attempt.success === filterSuccess;
    const matchesDate = getDateFilter(attempt.created_at);

    return matchesSearch && matchesStatus && matchesDate;
  });

  const stats = {
    total: filteredHistory.length,
    successful: filteredHistory.filter(a => a.success).length,
    failed: filteredHistory.filter(a => !a.success).length,
    failureRate: filteredHistory.length > 0
      ? ((filteredHistory.filter(a => !a.success).length / filteredHistory.length) * 100).toFixed(1)
      : '0'
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-green-50 to-blue-50">
      <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        <div className="px-4 py-4 sm:px-0">
          {/* Header Section */}
          <div className="bg-white shadow-lg rounded-lg mb-6 overflow-hidden">
            <div className="relative">
              <div className="absolute inset-0 bg-gradient-to-r from-green-600 to-blue-600 opacity-90"></div>
              <div className="relative px-8 py-12">
                <div className="flex items-center justify-between">
                  <button
                    onClick={() => navigate('/settings')}
                    className="text-white hover:text-green-100 transition-colors duration-200"
                  >
                    <ArrowLeft className="h-6 w-6" />
                  </button>
                  <Leaf className="h-12 w-12 text-white opacity-20" />
                </div>
                
                <div className="mt-4 text-center">
                  <h1 className="text-4xl font-bold text-white mb-2">THRIVE</h1>
                  <p className="text-green-100 text-lg mb-4">
                    Tracking Health and Reducing Illness Via Efficiency
                  </p>
                  <div className="w-16 h-1 bg-white/30 mx-auto rounded-full"></div>
                  <h2 className="text-2xl font-semibold text-white mt-4">Login Activity</h2>
                </div>
              </div>
            </div>
          </div>

          {/* Stats Cards */}
          <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4 mb-6">
            <div className="bg-white overflow-hidden shadow-lg rounded-lg">
              <div className="p-5">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <Clock className="h-6 w-6 text-gray-400" />
                  </div>
                  <div className="ml-5 w-0 flex-1">
                    <dl>
                      <dt className="text-sm font-medium text-gray-500 truncate">
                        Total Attempts
                      </dt>
                      <dd className="text-lg font-semibold text-gray-900">
                        {stats.total}
                      </dd>
                    </dl>
                  </div>
                </div>
              </div>
            </div>

            <div className="bg-white overflow-hidden shadow-lg rounded-lg">
              <div className="p-5">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <Check className="h-6 w-6 text-green-500" />
                  </div>
                  <div className="ml-5 w-0 flex-1">
                    <dl>
                      <dt className="text-sm font-medium text-gray-500 truncate">
                        Successful Logins
                      </dt>
                      <dd className="text-lg font-semibold text-gray-900">
                        {stats.successful}
                      </dd>
                    </dl>
                  </div>
                </div>
              </div>
            </div>

            <div className="bg-white overflow-hidden shadow-lg rounded-lg">
              <div className="p-5">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <Ban className="h-6 w-6 text-red-500" />
                  </div>
                  <div className="ml-5 w-0 flex-1">
                    <dl>
                      <dt className="text-sm font-medium text-gray-500 truncate">
                        Failed Attempts
                      </dt>
                      <dd className="text-lg font-semibold text-gray-900">
                        {stats.failed}
                      </dd>
                    </dl>
                  </div>
                </div>
              </div>
            </div>

            <div className="bg-white overflow-hidden shadow-lg rounded-lg">
              <div className="p-5">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <Shield className="h-6 w-6 text-yellow-500" />
                  </div>
                  <div className="ml-5 w-0 flex-1">
                    <dl>
                      <dt className="text-sm font-medium text-gray-500 truncate">
                        Failure Rate
                      </dt>
                      <dd className="text-lg font-semibold text-gray-900">
                        {stats.failureRate}%
                      </dd>
                    </dl>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Main Content */}
          <div className="bg-white shadow-lg rounded-lg overflow-hidden">
            <div className="px-6 py-5 border-b border-gray-200">
              <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between">
                <h3 className="text-lg font-medium text-gray-900">
                  Login History
                </h3>
                <div className="mt-3 sm:mt-0 sm:ml-4 flex flex-col sm:flex-row gap-3">
                  <button
                    onClick={downloadCSV}
                    className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700"
                  >
                    <Download className="h-4 w-4 mr-2" />
                    Export CSV
                  </button>
                  <button
                    onClick={fetchLoginHistory}
                    className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
                  >
                    <Clock className="h-4 w-4 mr-2" />
                    Refresh
                  </button>
                </div>
              </div>
            </div>

            {/* Filters */}
            <div className="px-6 py-4 border-b border-gray-200 bg-gray-50">
              <div className="flex flex-col sm:flex-row gap-4">
                <div className="flex-1">
                  <div className="relative">
                    <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
                    <input
                      type="text"
                      placeholder="Search by email, IP, or location..."
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      className="pl-10 pr-4 py-2 w-full rounded-lg border border-gray-300 focus:ring-2 focus:ring-green-500 focus:border-transparent"
                    />
                  </div>
                </div>
                <div className="flex gap-2">
                  <select
                    value={dateRange}
                    onChange={(e) => setDateRange(e.target.value as any)}
                    className="rounded-lg border border-gray-300 focus:ring-2 focus:ring-green-500 focus:border-transparent"
                  >
                    <option value="all">All Time</option>
                    <option value="today">Today</option>
                    <option value="week">Last 7 Days</option>
                    <option value="month">Last 30 Days</option>
                  </select>
                  <select
                    value={filterSuccess === null ? 'all' : filterSuccess.toString()}
                    onChange={(e) => setFilterSuccess(e.target.value === 'all' ? null : e.target.value === 'true')}
                    className="rounded-lg border border-gray-300 focus:ring-2 focus:ring-green-500 focus:border-transparent"
                  >
                    <option value="all">All Status</option>
                    <option value="true">Successful</option>
                    <option value="false">Failed</option>
                  </select>
                </div>
              </div>
            </div>

            {error && (
              <div className="px-6 py-4 bg-red-50">
                <div className="flex">
                  <AlertCircle className="h-5 w-5 text-red-400" />
                  <div className="ml-3">
                    <p className="text-sm text-red-700">{error}</p>
                  </div>
                </div>
              </div>
            )}

            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      <div className="flex items-center">
                        <Calendar className="h-4 w-4 mr-2" />
                        Date/Time
                      </div>
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Email
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Status
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      <div className="flex items-center">
                        <Globe2 className="h-4 w-4 mr-2" />
                        Location
                      </div>
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      <div className="flex items-center">
                        <Monitor className="h-4 w-4 mr-2" />
                        Browser
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {loading ? (
                    <tr>
                      <td colSpan={5} className="px-6 py-4 text-center text-gray-500">
                        Loading login history...
                      </td>
                    </tr>
                  ) : filteredHistory.length === 0 ? (
                    <tr>
                      <td colSpan={5} className="px-6 py-4 text-center text-gray-500">
                        No login attempts found
                      </td>
                    </tr>
                  ) : (
                    filteredHistory.map((attempt) => (
                      <tr 
                        key={attempt.id}
                        className={attempt.success ? '' : 'bg-red-50'}
                      >
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                          {formatPacificDateTime(attempt.created_at)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                          {attempt.email}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {attempt.success ? (
                            <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                              <Check className="h-3 w-3 mr-1" />
                              Success
                            </span>
                          ) : (
                            <div>
                              <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                                <X className="h-3 w-3 mr-1" />
                                Failed
                              </span>
                              {attempt.failure_reason && (
                                <p className="mt-1 text-xs text-red-600">
                                  {attempt.failure_reason}
                                </p>
                              )}
                            </div>
                          )}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {attempt.ip_address}
                          {(attempt.city || attempt.country) && (
                            <p className="text-xs text-gray-400">
                              {[attempt.city, attempt.country].filter(Boolean).join(', ')}
                            </p>
                          )}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {attempt.user_agent}
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}