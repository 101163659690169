import { supabase } from '../../lib/supabase';
import { ProcessingProgress, ProcessingResult } from '../dataImportProcessor';

interface WeightMeasurement {
  external_patient_id: string;
  encounter_id: string;
  weight: number;
  measurement_date: string;
}

function parseWeight(weightStr: string): number | null {
  try {
    // Remove 'lbs' and any extra whitespace
    const cleanWeight = weightStr.toLowerCase().replace('lbs', '').trim();
    const weight = parseFloat(cleanWeight);
    return isNaN(weight) ? null : weight;
  } catch {
    return null;
  }
}

function parseDate(dateStr: string): string | null {
  try {
    const date = new Date(dateStr);
    if (isNaN(date.getTime())) {
      return null;
    }
    return date.toISOString().split('T')[0];
  } catch {
    return null;
  }
}

async function fetchPatientIds(patientIds: string[]): Promise<Map<string, string>> {
  const patientMap = new Map<string, string>();
  const BATCH_SIZE = 100; // Smaller batch size for patient ID queries

  for (let i = 0; i < patientIds.length; i += BATCH_SIZE) {
    const batch = patientIds.slice(i, i + BATCH_SIZE);
    try {
      const { data, error } = await supabase
        .from('patients')
        .select('id, patient_id')
        .in('patient_id', batch);

      if (error) {
        console.error('Error fetching patient batch:', error);
        continue;
      }

      data?.forEach(patient => {
        patientMap.set(patient.patient_id, patient.id);
      });
    } catch (error) {
      console.error('Error in patient batch fetch:', error);
    }
  }

  return patientMap;
}

export async function processWeightMeasurementsReport(
  lines: string[],
  onProgress?: (progress: ProcessingProgress) => void
): Promise<ProcessingResult> {
  try {
    const headers = lines[1].split(',').map(header => header.trim().toLowerCase());
    const totalLines = lines.length - 2; // Subtract header rows
    let processedCount = 0;
    let skippedCount = 0;

    // Extract all weight measurements
    const allMeasurements: WeightMeasurement[] = [];
    const patientIds = new Set<string>();

    // First pass: collect measurements
    for (let i = 2; i < lines.length; i++) {
      const values = lines[i].split(',').map(value => value.trim());
      
      if (values.length !== headers.length) {
        skippedCount++;
        continue; // Skip malformed rows
      }

      const patientId = values[headers.indexOf('patientid')];
      const weightStr = values[headers.indexOf('enc wt')];
      const dateStr = values[headers.indexOf('enc wt date')];

      // Parse weight and date
      const weight = parseWeight(weightStr);
      const date = parseDate(dateStr);

      // Skip rows with missing or invalid data
      if (!patientId || !weight || !date) {
        skippedCount++;
        continue;
      }

      patientIds.add(patientId);

      allMeasurements.push({
        external_patient_id: patientId,
        encounter_id: values[headers.indexOf('cln enc appointment id')],
        weight,
        measurement_date: date
      });

      // Report progress for data extraction
      if (onProgress) {
        onProgress({
          currentLine: i - 1,
          totalLines,
          processedCount,
          skippedCount
        });
      }
    }

    // Get existing patient IDs in batches
    const patientMap = await fetchPatientIds(Array.from(patientIds));

    // Process measurements in batches
    const BATCH_SIZE = 50;
    let measurementsProcessed = 0;
    let measurementsSkipped = 0;

    // Get all existing measurements for these patients in batches
    const existingMeasurementsSet = new Set<string>();
    for (let i = 0; i < patientIds.size; i += BATCH_SIZE) {
      const batchIds = Array.from(patientIds).slice(i, i + BATCH_SIZE);
      const { data, error } = await supabase
        .from('weight_measurements')
        .select('external_patient_id, measurement_date')
        .in('external_patient_id', batchIds);

      if (!error && data) {
        data.forEach(measurement => {
          const key = `${measurement.external_patient_id}-${measurement.measurement_date}`;
          existingMeasurementsSet.add(key);
        });
      }
    }

    // Process measurements in batches
    for (let i = 0; i < allMeasurements.length; i += BATCH_SIZE) {
      const batch = allMeasurements.slice(i, i + BATCH_SIZE);
      const measurementsToInsert = batch
        .filter(measurement => {
          // Skip if patient doesn't exist in our system
          if (!patientMap.has(measurement.external_patient_id)) {
            return false;
          }
          // Skip if measurement already exists for this patient on this date
          const key = `${measurement.external_patient_id}-${measurement.measurement_date}`;
          return !existingMeasurementsSet.has(key);
        })
        .map(measurement => ({
          ...measurement,
          patient_id: patientMap.get(measurement.external_patient_id),
          measurement_date: measurement.measurement_date
        }));

      if (measurementsToInsert.length > 0) {
        try {
          const { error: insertError } = await supabase
            .from('weight_measurements')
            .upsert(measurementsToInsert, {
              onConflict: 'external_patient_id,measurement_date'
            });

          if (insertError) {
            console.error('Error inserting weight measurements:', insertError);
            measurementsSkipped += measurementsToInsert.length;
          } else {
            measurementsProcessed += measurementsToInsert.length;
          }
        } catch (error) {
          console.error('Error processing weight measurements batch:', error);
          measurementsSkipped += measurementsToInsert.length;
        }
      } else {
        measurementsSkipped += batch.length;
      }

      // Report progress
      if (onProgress) {
        onProgress({
          currentLine: totalLines,
          totalLines,
          processedCount: measurementsProcessed,
          skippedCount: measurementsSkipped
        });
      }
    }

    // Record import history
    try {
      await supabase.from('import_history').insert({
        report_type: 'ACCESS_DATA_WEIGHT',
        file_name: 'ACCESS Data - WtgMgt Weight 10 days',
        processed_count: measurementsProcessed,
        skipped_count: measurementsSkipped
      });
    } catch (error) {
      console.error('Error recording import history:', error);
    }

    let message = `Processed ${measurementsProcessed} weight measurements`;
    if (measurementsSkipped > 0) {
      message += ` (${measurementsSkipped} measurements skipped)`;
    }

    return {
      success: true,
      message,
      processedCount: measurementsProcessed,
      skippedCount: measurementsSkipped
    };
  } catch (error: any) {
    return {
      success: false,
      message: `Error processing file: ${error.message}`,
      processedCount: 0,
      skippedCount: 0
    };
  }
}