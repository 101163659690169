export function validateDateTime(value: any): Date | null {
  if (!value) return null;
  
  // Handle special text cases
  if (typeof value === 'string') {
    // Return null for "Not run" or similar text values
    if (value.toLowerCase().includes('not run') || 
        value.toLowerCase() === 'n/a' || 
        value.toLowerCase() === 'none') {
      return null;
    }

    // Try to parse the date string
    try {
      const date = new Date(value);
      // Check if date is valid
      if (!isNaN(date.getTime())) {
        return date;
      }

      // Try parsing common date formats
      const formats = [
        { regex: /^(\d{1,2})\/(\d{1,2})\/(\d{4})$/, order: [2, 0, 1] }, // MM/DD/YYYY
        { regex: /^(\d{4})-(\d{1,2})-(\d{1,2})$/, order: [0, 1, 2] },   // YYYY-MM-DD
        { regex: /^(\d{1,2})-(\d{1,2})-(\d{4})$/, order: [2, 0, 1] }    // MM-DD-YYYY
      ];

      for (const format of formats) {
        const match = value.match(format.regex);
        if (match) {
          const [_, ...parts] = match;
          const [yearIndex, monthIndex, dayIndex] = format.order;
          const year = parseInt(parts[yearIndex]);
          const month = parseInt(parts[monthIndex]) - 1;
          const day = parseInt(parts[dayIndex]);
          
          const date = new Date(year, month, day);
          if (!isNaN(date.getTime())) {
            return date;
          }
        }
      }
    } catch (error) {
      console.error('Error parsing date:', error);
      return null;
    }
  }
  
  // Handle Excel date serial numbers
  if (typeof value === 'number') {
    try {
      // Excel dates are number of days since 1900-01-01
      // Subtract 1 because Excel incorrectly considers 1900 as a leap year
      const date = new Date(Date.UTC(1900, 0, value - 1));
      if (!isNaN(date.getTime())) {
        return date;
      }
    } catch (error) {
      console.error('Error parsing Excel date:', error);
      return null;
    }
  }

  return null;
}

export function formatDateForDatabase(date: Date | null): string | null {
  if (!date || isNaN(date.getTime())) {
    return null;
  }
  return date.toISOString().split('T')[0];
}