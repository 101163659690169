import React, { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft, Upload, FileSpreadsheet, X, Check, AlertCircle, Leaf } from 'lucide-react';
import { useDropzone } from 'react-dropzone';
import * as XLSX from 'xlsx';
import { supabase } from '../lib/supabase';
import { formatPacificDateTime } from '../utils/dateTime';

interface PreviewData {
  headers: string[];
  rows: any[][];
}

interface ProcessingStats {
  total: number;
  processed: number;
  mapped: number;
  unmapped: number;
  errors: number;
}

type LogType = 'info' | 'error' | 'success';

interface LogEntry {
  type: LogType;
  message: string;
  timestamp: Date;
}

export function MembershipImport() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [preview, setPreview] = useState<PreviewData | null>(null);
  const [logs, setLogs] = useState<LogEntry[]>([]);
  const [stats, setStats] = useState<ProcessingStats | null>(null);

  const addLog = (type: LogType, message: string) => {
    setLogs(prev => [...prev, { type, message, timestamp: new Date() }]);
  };

  const processFile = async (file: File) => {
    setLoading(true);
    setStats(null);
    addLog('info', `Processing file: ${file.name}`);
    addLog('info', `File size: ${(file.size / 1024).toFixed(2)} KB`);

    const reader = new FileReader();
    
    reader.onload = async (e) => {
      try {
        const data = e.target?.result;
        addLog('info', 'File loaded successfully, parsing contents...');
        
        const workbook = XLSX.read(data, { type: 'binary' });
        addLog('info', `Workbook loaded with ${workbook.SheetNames.length} sheet(s)`);
        
        const firstSheetName = workbook.SheetNames[0];
        addLog('info', `Processing first sheet: ${firstSheetName}`);
        
        const worksheet = workbook.Sheets[firstSheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        
        if (jsonData.length < 2) {
          throw new Error('File has insufficient data');
        }

        // Skip the first row (title) and use second row as headers
        const headers = jsonData[1] as string[];
        addLog('info', `Found ${headers.length} columns`);
        
        // Set up preview data
        const previewHeaders = ['Client Name', 'Client ID', 'Location'];
        const previewRows = jsonData.slice(3, 17).map(row => [
          (row as any[])[1] || '', // Client Name (Column B)
          (row as any[])[2] || '', // Client ID (Column C)
          (row as any[])[3] || ''  // Location (Column D)
        ]);
        setPreview({ headers: previewHeaders, rows: previewRows });

        // Process the data rows
        const rows = jsonData.slice(3) as any[][]; // Skip header rows
        addLog('info', `Processing ${rows.length} records...`);

        const stats: ProcessingStats = {
          total: rows.length,
          processed: 0,
          mapped: 0,
          unmapped: 0,
          errors: 0
        };

        // Process each row
        for (let i = 0; i < rows.length; i++) {
          const row = rows[i];
          const clientName = row[1]; // Column B
          const clientId = row[2];   // Column C
          const location = row[3];   // Column D

          if (clientName && clientId) {
            const result = await processMembershipRow(clientName, clientId, location || '');
            stats.processed++;

            if (result.success) {
              if (result.mapped) {
                stats.mapped++;
                addLog('success', `Processed and mapped record ${stats.processed} of ${stats.total}`);
              } else {
                stats.unmapped++;
                addLog('info', `Processed record ${stats.processed} of ${stats.total} (no match found)`);
              }
            } else {
              stats.errors++;
              addLog('error', `Error processing record ${stats.processed}: ${result.error}`);
            }

            // Update stats every 10 records
            if (stats.processed % 10 === 0) {
              setStats({ ...stats });
            }
          } else {
            stats.errors++;
            addLog('error', `Skipping row ${stats.processed + 1}: Missing required data`);
          }
        }

        setStats(stats);
        addLog('success', 'File processing complete');
        addLog('info', `Processed: ${stats.processed}, Mapped: ${stats.mapped}, Unmapped: ${stats.unmapped}, Errors: ${stats.errors}`);

      } catch (error: any) {
        addLog('error', `Error processing file: ${error.message}`);
        console.error('Error:', error);
      } finally {
        setLoading(false);
      }
    };

    reader.onerror = () => {
      addLog('error', 'Error reading file');
      setLoading(false);
    };

    reader.readAsBinaryString(file);
  };

  const onDrop = useCallback((acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) {
      processFile(acceptedFiles[0]);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
      'application/vnd.ms-excel': ['.xls']
    },
    multiple: false
  });

  return (
    <div className="min-h-screen bg-gradient-to-br from-green-50 to-blue-50">
      <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        <div className="px-4 py-4 sm:px-0">
          <div className="flex items-center mb-6">
            <button
              onClick={() => navigate('/settings')}
              className="mr-4 text-gray-600 hover:text-gray-900"
            >
              <ArrowLeft className="h-6 w-6" />
            </button>
            <h1 className="text-2xl font-bold text-gray-900">Import Membership Information</h1>
          </div>

          <div className="bg-white shadow-lg rounded-lg overflow-hidden">
            <div className="relative">
              <div className="absolute inset-0 bg-gradient-to-r from-green-600 to-blue-600 opacity-90"></div>
              <div className="relative px-8 py-12">
                <div className="flex items-center justify-between">
                  <Leaf className="h-12 w-12 text-white opacity-20" />
                </div>
                <div className="mt-4 text-center">
                  <h1 className="text-4xl font-bold text-white mb-2">THRIVE</h1>
                  <p className="text-green-100 text-lg mb-4">
                    Tracking Health and Reducing Illness Via Efficiency
                  </p>
                  <div className="w-16 h-1 bg-white/30 mx-auto rounded-full"></div>
                  <h2 className="text-2xl font-semibold text-white mt-4">Membership Import</h2>
                </div>
              </div>
            </div>

            <div className="p-6">
              <div className="mb-6">
                <h2 className="text-lg font-medium text-gray-900 mb-2">Import Instructions</h2>
                <p className="text-sm text-gray-500">
                  Upload an Excel file containing membership information:
                </p>
                <ul className="list-disc list-inside text-sm text-gray-500 mt-2">
                  <li>File should be in .xlsx or .xls format</li>
                  <li>Column B should contain client names in "Last, First" format</li>
                  <li>Column C should contain client IDs</li>
                  <li>Column D should contain location information</li>
                </ul>
              </div>

              <div 
                {...getRootProps()} 
                className={`border-2 border-dashed rounded-lg p-12 text-center cursor-pointer transition-all duration-300 ${
                  isDragActive 
                    ? 'border-indigo-500 bg-indigo-50' 
                    : 'border-gray-300 hover:border-indigo-500 hover:bg-gray-50'
                }`}
              >
                <input {...getInputProps()} />
                <div className="flex flex-col items-center">
                  <Upload className="h-12 w-12 text-gray-400 mb-4" />
                  <p className="text-lg font-medium text-gray-900 mb-1">
                    {isDragActive 
                      ? 'Drop the file here...' 
                      : 'Drag and drop your membership file here'}
                  </p>
                  <p className="text-sm text-gray-500 mb-4">
                    or click to select a file
                  </p>
                  <div className="flex items-center space-x-2 text-sm text-gray-500">
                    <FileSpreadsheet className="h-5 w-5" />
                    <span>Accepts Excel files (.xlsx, .xls)</span>
                  </div>
                </div>
              </div>

              {/* Preview Section */}
              {preview && (
                <div className="mt-8">
                  <h3 className="text-lg font-medium text-gray-900 mb-4">Data Preview</h3>
                  <div className="overflow-x-auto">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50">
                        <tr>
                          {preview.headers.map((header, index) => (
                            <th
                              key={index}
                              scope="col"
                              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              {header}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200">
                        {preview.rows.map((row, rowIndex) => (
                          <tr key={rowIndex}>
                            {row.map((cell, cellIndex) => (
                              <td
                                key={cellIndex}
                                className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                              >
                                {cell}
                              </td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}

              {/* Processing Logs */}
              <div className="mt-8">
                <h3 className="text-lg font-medium text-gray-900 mb-4">Processing Log</h3>
                <div className="bg-gray-50 rounded-lg p-4 h-64 overflow-y-auto">
                  {logs.map((log, index) => (
                    <div
                      key={index}
                      className={`flex items-start space-x-2 mb-2 text-sm ${
                        log.type === 'error' 
                          ? 'text-red-600' 
                          : log.type === 'success'
                          ? 'text-green-600'
                          : 'text-gray-600'
                      }`}
                    >
                      {log.type === 'error' && <AlertCircle className="h-4 w-4 mt-0.5 flex-shrink-0" />}
                      {log.type === 'success' && <Check className="h-4 w-4 mt-0.5 flex-shrink-0" />}
                      {log.type === 'info' && <FileSpreadsheet className="h-4 w-4 mt-0.5 flex-shrink-0" />}
                      <span className="flex-1">{log.message}</span>
                      <span className="text-xs text-gray-400">
                        {formatPacificDateTime(log.timestamp.toISOString())}
                      </span>
                    </div>
                  ))}
                </div>
              </div>

              {/* Processing Stats */}
              {stats && (
                <div className="mt-8">
                  <h3 className="text-lg font-medium text-gray-900 mb-4">Processing Statistics</h3>
                  <div className="grid grid-cols-1 gap-5 sm:grid-cols-5">
                    <div className="bg-white overflow-hidden shadow rounded-lg">
                      <div className="px-4 py-5 sm:p-6">
                        <dt className="text-sm font-medium text-gray-500 truncate">Total Records</dt>
                        <dd className="mt-1 text-3xl font-semibold text-gray-900">{stats.total}</dd>
                      </div>
                    </div>
                    <div className="bg-white overflow-hidden shadow rounded-lg">
                      <div className="px-4 py-5 sm:p-6">
                        <dt className="text-sm font-medium text-gray-500 truncate">Processed</dt>
                        <dd className="mt-1 text-3xl font-semibold text-gray-900">{stats.processed}</dd>
                      </div>
                    </div>
                    <div className="bg-white overflow-hidden shadow rounded-lg">
                      <div className="px-4 py-5 sm:p-6">
                        <dt className="text-sm font-medium text-gray-500 truncate">Mapped</dt>
                        <dd className="mt-1 text-3xl font-semibold text-green-600">{stats.mapped}</dd>
                      </div>
                    </div>
                    <div className="bg-white overflow-hidden shadow rounded-lg">
                      <div className="px-4 py-5 sm:p-6">
                        <dt className="text-sm font-medium text-gray-500 truncate">Unmapped</dt>
                        <dd className="mt-1 text-3xl font-semibold text-yellow-600">{stats.unmapped}</dd>
                      </div>
                    </div>
                    <div className="bg-white overflow-hidden shadow rounded-lg">
                      <div className="px-4 py-5 sm:p-6">
                        <dt className="text-sm font-medium text-gray-500 truncate">Errors</dt>
                        <dd className="mt-1 text-3xl font-semibold text-red-600">{stats.errors}</dd>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

async function processMembershipRow(clientName: string, clientId: string, location: string) {
  try {
    if (!clientId || !clientName) {
      throw new Error(`Missing required fields - clientId: "${clientId}", clientName: "${clientName}"`);
    }

    // Parse the name (expected format: "Last, First")
    const nameParts = clientName.split(',').map(part => part.trim());
    if (nameParts.length !== 2) {
      throw new Error(`Invalid name format: ${clientName}`);
    }

    const lastName = nameParts[0];
    const firstName = nameParts[1];

    // Process the membership data
    const { data, error } = await supabase.rpc('process_membership_data', {
      p_external_id: clientId,
      p_first_name: firstName,
      p_last_name: lastName,
      p_location: location
    });

    if (error) throw error;

    return {
      success: true,
      mapped: data.mapping_status === 'mapped'
    };
  } catch (error: any) {
    console.error('Error processing row:', error);
    return {
      success: false,
      error: error.message
    };
  }
}