import React, { useState } from 'react';
import { AlertTriangle, Scale, X } from 'lucide-react';
import { formatPacificDate } from '../utils/dateTime';

interface WeightEditModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (weight: number) => Promise<void>;
  currentWeight: number;
  date: string;
}

export function WeightEditModal({ isOpen, onClose, onConfirm, currentWeight, date }: WeightEditModalProps) {
  const [weight, setWeight] = useState(currentWeight);
  const [loading, setLoading] = useState(false);

  if (!isOpen) return null;

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    try {
      await onConfirm(weight);
      onClose();
    } catch (error) {
      console.error('Error updating weight:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg shadow-xl max-w-lg w-full">
        <div className="px-6 py-4 border-b border-gray-200 flex items-center justify-between">
          <div className="flex items-center">
            <Scale className="h-5 w-5 text-green-600 mr-2" />
            <h3 className="text-lg font-medium text-gray-900">
              Edit Weight Measurement
            </h3>
          </div>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-500"
          >
            <X className="h-5 w-5" />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="p-6">
          <div className="mb-6">
            <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <AlertTriangle className="h-5 w-5 text-yellow-400" />
                </div>
                <div className="ml-3">
                  <h3 className="text-sm font-medium text-yellow-800">
                    Important Note
                  </h3>
                  <div className="mt-2 text-sm text-yellow-700">
                    <p>This change will only update the weight in the THRIVE database.</p>
                    <p className="mt-1">To maintain data consistency:</p>
                    <ul className="list-disc list-inside mt-1">
                      <li>Update the weight in Athena as well</li>
                      <li>Changes may be overwritten during the next Athena sync</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mb-6">
            <label htmlFor="weight" className="block text-sm font-medium text-gray-700 mb-2">
              Weight for {formatPacificDate(date)}
            </label>
            <div className="mt-1 relative rounded-md shadow-sm">
              <input
                type="number"
                step="0.1"
                min="0"
                max="1000"
                id="weight"
                value={weight}
                onChange={(e) => setWeight(parseFloat(e.target.value))}
                className="focus:ring-green-500 focus:border-green-500 block w-full pr-12 sm:text-sm border-gray-300 rounded-md"
                placeholder="0.0"
              />
              <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <span className="text-gray-500 sm:text-sm">lbs</span>
              </div>
            </div>
          </div>

          <div className="mt-6 flex justify-end space-x-3">
            <button
              type="button"
              onClick={onClose}
              className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
              disabled={loading}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 disabled:opacity-50 disabled:cursor-not-allowed"
              disabled={loading || weight === currentWeight}
            >
              {loading ? 'Updating...' : 'Update Weight'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}