import React from 'react';
import { AlertTriangle } from 'lucide-react';

interface BulkDeleteConfirmModalProps {
  isOpen: boolean;
  selectedCount: number;
  onConfirm: () => void;
  onCancel: () => void;
}

export function BulkDeleteConfirmModal({
  isOpen,
  selectedCount,
  onConfirm,
  onCancel
}: BulkDeleteConfirmModalProps) {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg shadow-xl max-w-lg w-full p-6">
        <div className="flex items-center justify-center mb-4">
          <AlertTriangle className="h-12 w-12 text-red-500" />
        </div>
        <h3 className="text-lg font-medium text-gray-900 text-center mb-2">
          Confirm Bulk Delete
        </h3>
        <p className="text-sm text-gray-500 text-center mb-6">
          Are you sure you want to remove {selectedCount} patient{selectedCount !== 1 ? 's' : ''} from the list?
          This action cannot be undone.
        </p>
        <div className="flex justify-center space-x-4">
          <button
            onClick={onCancel}
            className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50"
          >
            Cancel
          </button>
          <button
            onClick={onConfirm}
            className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700"
          >
            Delete {selectedCount} Patient{selectedCount !== 1 ? 's' : ''}
          </button>
        </div>
      </div>
    </div>
  );
}