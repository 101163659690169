import React from 'react';

interface LogoProps {
  className?: string;
}

export function Logo({ className = "h-8 w-8" }: LogoProps) {
  return (
    <img
      src="/Thrive icon.ico"
      alt="THRIVE Logo"
      className={className}
    />
  );
}