import React from 'react';
import { AlertCircle, Check, FileSpreadsheet, Info } from 'lucide-react';
import { formatPacificDateTime } from '../../utils/dateTime';

type LogType = 'info' | 'error' | 'success' | 'data' | 'verification';

interface LogEntry {
  type: LogType;
  message: string;
  timestamp: Date;
  data?: any;
  lineNumber?: number;
  lineContent?: string;
}

interface ProcessingLogProps {
  logs: LogEntry[];
  showDetailedLogs?: boolean;
}

export function ProcessingLog({ logs, showDetailedLogs = false }: ProcessingLogProps) {
  const getLogIcon = (type: LogType) => {
    switch (type) {
      case 'error':
        return <AlertCircle className="h-4 w-4 text-red-600" />;
      case 'success':
        return <Check className="h-4 w-4 text-green-600" />;
      case 'verification':
        return <Check className="h-4 w-4 text-blue-600" />;
      case 'data':
        return <FileSpreadsheet className="h-4 w-4 text-blue-600" />;
      default:
        return <Info className="h-4 w-4 text-gray-600" />;
    }
  };

  const getLogClass = (type: LogType) => {
    switch (type) {
      case 'error':
        return 'text-red-600';
      case 'success':
        return 'text-green-600';
      case 'verification':
        return 'text-blue-600';
      case 'data':
        return 'text-blue-600';
      default:
        return 'text-gray-600';
    }
  };

  return (
    <div className="mt-8">
      <h3 className="text-lg font-medium text-gray-900 mb-4">Processing Log</h3>
      <div className="bg-gray-50 rounded-lg p-4 h-96 overflow-y-auto">
        {logs.map((log, index) => (
          <div
            key={index}
            className={`flex items-start space-x-2 mb-2 text-sm ${getLogClass(log.type)}`}
          >
            {getLogIcon(log.type)}
            <div className="flex-1">
              <div className="flex items-center justify-between">
                <span>{log.message}</span>
                <span className="text-xs text-gray-400">
                  {formatPacificDateTime(log.timestamp.toISOString())}
                </span>
              </div>
              {showDetailedLogs && log.lineNumber && log.lineContent && (
                <div className="mt-1 text-xs bg-white p-2 rounded overflow-x-auto font-mono">
                  <span className="text-gray-400">Line {log.lineNumber}:</span> {log.lineContent}
                </div>
              )}
              {log.data && (
                <pre className="mt-1 text-xs bg-white p-2 rounded overflow-x-auto">
                  {JSON.stringify(log.data, null, 2)}
                </pre>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}