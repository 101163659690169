import { ValidationResult } from './accessDataValidator';

export function validateWeightMeasurementsReport(lines: string[]): ValidationResult {
  // Check minimum required lines (title + headers)
  if (lines.length < 2) {
    return {
      success: false,
      message: 'File has insufficient data',
      processedCount: 0,
      skippedCount: 0
    };
  }

  // Verify report title
  const expectedTitle = 'REPORT NAME : ACCESS DATA - WTGMGT WEIGHT 10 DAYS';
  if (!lines[0].toUpperCase().includes(expectedTitle)) {
    return {
      success: false,
      message: 'Invalid report format: Incorrect report title',
      processedCount: 0,
      skippedCount: 0
    };
  }

  // Verify headers
  const headers = lines[1].split(',').map(header => header.trim().toLowerCase());
  const expectedHeaders = [
    'patientid',
    'cln enc appointment id',
    'enc wt',
    'enc wt date'
  ];

  const missingHeaders = expectedHeaders.filter(header => !headers.includes(header));
  
  if (missingHeaders.length > 0) {
    return {
      success: false,
      message: `Invalid file format: Missing required headers: ${missingHeaders.join(', ')}`,
      processedCount: 0,
      skippedCount: 0
    };
  }

  return {
    success: true,
    message: 'Validation successful',
    processedCount: 0,
    skippedCount: 0
  };
}