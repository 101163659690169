import React from 'react';
import { X, DollarSign } from 'lucide-react';

interface ProcedureCode {
  code: string;
  description: string;
  price: number;
}

interface ProductSelectionModalProps {
  isOpen: boolean;
  onClose: () => void;
  categoryName: string;
  medicationType: string | null;
  procedures: ProcedureCode[];
  loading: boolean;
  onSelect: (procedure: ProcedureCode) => void;
}

export function ProductSelectionModal({
  isOpen,
  onClose,
  categoryName,
  medicationType,
  procedures,
  loading,
  onSelect
}: ProductSelectionModalProps) {
  if (!isOpen) return null;

  // Handle click outside
  const handleBackdropClick = (e: React.MouseEvent) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div 
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-[60]"
      onClick={handleBackdropClick}
    >
      <div className="bg-white rounded-lg shadow-xl w-full max-w-2xl flex flex-col max-h-[80vh]">
        {/* Header - Fixed */}
        <div className="px-6 py-4 border-b border-gray-200">
          <div className="flex items-center justify-between">
            <h3 className="text-lg font-medium text-gray-900">
              {categoryName}
              {medicationType && (
                <span className="ml-2 text-sm text-gray-500">
                  • {medicationType}
                </span>
              )}
            </h3>
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-gray-500"
            >
              <X className="h-5 w-5" />
            </button>
          </div>
        </div>

        {/* Content - Scrollable */}
        <div className="flex-1 overflow-y-auto p-6">
          {loading ? (
            <div className="text-center py-8">
              <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-green-600 mx-auto"></div>
              <p className="mt-2 text-gray-500">Loading available items...</p>
            </div>
          ) : procedures.length === 0 ? (
            <div className="text-center py-8 text-gray-500">
              No items available
            </div>
          ) : (
            <div className="space-y-3">
              {procedures.map(procedure => (
                <button
                  key={procedure.code}
                  onClick={() => {
                    onSelect(procedure);
                    onClose();
                  }}
                  className="w-full p-4 text-left bg-white rounded-lg border border-gray-200 hover:border-green-500 hover:shadow-md transition-all duration-200"
                >
                  <div className="flex justify-between items-center">
                    <span className="font-medium text-gray-900">
                      {procedure.description}
                    </span>
                    <span className="flex items-center text-green-600 font-medium">
                      <DollarSign className="h-4 w-4 mr-1" />
                      {procedure.price.toFixed(2)}
                    </span>
                  </div>
                </button>
              ))}
            </div>
          )}
        </div>

        {/* Footer - Fixed */}
        <div className="px-6 py-4 border-t border-gray-200 bg-gray-50">
          <div className="flex justify-end">
            <button
              onClick={onClose}
              className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}