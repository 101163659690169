import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  ArrowLeft, 
  Leaf, 
  LogOut, 
  MessageSquare, 
  Plus, 
  Check, 
  AlertCircle, 
  Clock, 
  User, 
  Eye, 
  EyeOff,
  X,
  Edit,
  Trash2
} from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import { supabase } from '../lib/supabase';
import { formatPacificDateTime } from '../utils/dateTime';

interface Message {
  id: string;
  title: string;
  content: string;
  created_by: string;
  created_by_email: string;
  created_at: string;
  is_active: boolean;
  is_read: boolean;
}

interface MessageModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (title: string, content: string) => Promise<void>;
  title: string;
  content: string;
  setTitle: (title: string) => void;
  setContent: (content: string) => void;
  isSubmitting: boolean;
}

function MessageModal({ 
  isOpen, 
  onClose, 
  onSubmit, 
  title, 
  content, 
  setTitle, 
  setContent, 
  isSubmitting 
}: MessageModalProps) {
  if (!isOpen) return null;

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    await onSubmit(title, content);
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg shadow-xl max-w-2xl w-full">
        <div className="px-6 py-4 border-b border-gray-200 flex items-center justify-between">
          <h3 className="text-lg font-medium text-gray-900">
            Create New Message
          </h3>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-500"
          >
            <X className="h-5 w-5" />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="p-6">
          <div className="space-y-4">
            <div>
              <label htmlFor="title" className="block text-sm font-medium text-gray-700">
                Title
              </label>
              <input
                type="text"
                id="title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                placeholder="Enter message title"
                required
              />
            </div>

            <div>
              <label htmlFor="content" className="block text-sm font-medium text-gray-700">
                Content
              </label>
              <textarea
                id="content"
                value={content}
                onChange={(e) => setContent(e.target.value)}
                rows={6}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                placeholder="Enter message content"
                required
              />
            </div>
          </div>

          <div className="mt-6 flex justify-end space-x-3">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
              disabled={isSubmitting}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 disabled:opacity-50"
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <>
                  <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-white mr-2" />
                  Saving...
                </>
              ) : (
                'Post Message'
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export function MessageBoard() {
  const navigate = useNavigate();
  const { user, userRoles, signOut } = useAuth();
  const isAdmin = userRoles.includes('admin');
  const [messages, setMessages] = useState<Message[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [expandedMessages, setExpandedMessages] = useState<Set<string>>(new Set());

  useEffect(() => {
    fetchMessages();
  }, []);

  const fetchMessages = async () => {
    try {
      setLoading(true);
      setError(null);
      
      console.log('Fetching messages...');
      const { data, error } = await supabase.rpc('get_active_messages');
      
      if (error) {
        console.error('Error from get_active_messages RPC:', error);
        throw error;
      }
      
      console.log('Received messages:', data);
      setMessages(data || []);
    } catch (error: any) {
      console.error('Error fetching messages:', error);
      setError('Error fetching messages: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleCreateMessage = async (title: string, content: string) => {
    try {
      setIsSubmitting(true);
      setError(null);
      
      console.log('Creating message:', { title, content });
      const { data, error } = await supabase.rpc('create_message', {
        p_title: title,
        p_content: content
      });
      
      if (error) {
        console.error('Error from create_message RPC:', error);
        throw error;
      }
      
      if (!data.success) {
        console.error('Error in create_message response:', data);
        throw new Error(data.error);
      }
      
      console.log('Message created successfully:', data);
      setSuccess('Message created successfully');
      setShowModal(false);
      setTitle('');
      setContent('');
      
      // Fetch messages again to update the list
      await fetchMessages();
      
      setTimeout(() => setSuccess(null), 3000);
    } catch (error: any) {
      console.error('Error creating message:', error);
      setError(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleMarkAsRead = async (messageId: string) => {
    try {
      console.log('Marking message as read:', messageId);
      const { error } = await supabase.rpc('mark_message_as_read', {
        p_message_id: messageId
      });
      
      if (error) {
        console.error('Error from mark_message_as_read RPC:', error);
        throw error;
      }
      
      // Update local state
      setMessages(prev => 
        prev.map(message => 
          message.id === messageId 
            ? { ...message, is_read: true } 
            : message
        )
      );
    } catch (error: any) {
      console.error('Error marking message as read:', error);
    }
  };

  const handleToggleExpand = (messageId: string) => {
    setExpandedMessages(prev => {
      const newSet = new Set(prev);
      if (newSet.has(messageId)) {
        newSet.delete(messageId);
      } else {
        newSet.add(messageId);
        // Mark as read when expanded
        if (!messages.find(m => m.id === messageId)?.is_read) {
          handleMarkAsRead(messageId);
        }
      }
      return newSet;
    });
  };

  const handleUpdateMessageStatus = async (messageId: string, isActive: boolean) => {
    try {
      console.log('Updating message status:', { messageId, isActive });
      const { error } = await supabase.rpc('update_message_status', {
        p_message_id: messageId,
        p_is_active: isActive
      });
      
      if (error) {
        console.error('Error from update_message_status RPC:', error);
        throw error;
      }
      
      // Update local state
      if (!isActive) {
        setMessages(prev => prev.filter(message => message.id !== messageId));
        setSuccess('Message archived successfully');
      } else {
        fetchMessages();
        setSuccess('Message activated successfully');
      }
      
      setTimeout(() => setSuccess(null), 3000);
    } catch (error: any) {
      console.error('Error updating message status:', error);
      setError(error.message);
      setTimeout(() => setError(null), 3000);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-green-50 to-blue-50">
      <nav className="bg-white shadow-sm">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16">
            <div className="flex items-center">
              <Leaf className="h-6 w-6 text-green-600" />
              <div className="ml-2">
                <span className="text-xl font-semibold text-gray-900">THRIVE</span>
                <span className="ml-2 text-sm text-gray-500">Message Board</span>
              </div>
            </div>
            <div className="flex items-center space-x-4">
              <span className="text-sm text-gray-700">{user?.email}</span>
              <div className="flex flex-wrap gap-2">
                {userRoles.map((role) => (
                  <span
                    key={role}
                    className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800"
                  >
                    {role}
                  </span>
                ))}
              </div>
              <button
                onClick={() => signOut()}
                className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-green-600 hover:bg-green-700"
              >
                <LogOut className="h-4 w-4 mr-2" />
                Sign Out
              </button>
            </div>
          </div>
        </div>
      </nav>

      <main className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        <div className="px-4 py-4 sm:px-0">
          {/* Header Section */}
          <div className="bg-white shadow-lg rounded-lg mb-6 overflow-hidden">
            <div className="relative">
              <div className="absolute inset-0 bg-gradient-to-r from-green-600 to-blue-600 opacity-90"></div>
              <div className="relative px-8 py-12">
                <div className="flex items-center justify-between">
                  <button
                    onClick={() => navigate('/dashboard')}
                    className="text-white hover:text-green-100 transition-colors duration-200"
                  >
                    <ArrowLeft className="h-6 w-6" />
                  </button>
                  <Leaf className="h-12 w-12 text-white opacity-20" />
                </div>
                
                <div className="mt-4 text-center">
                  <h1 className="text-4xl font-bold text-white mb-2">THRIVE</h1>
                  <p className="text-green-100 text-lg mb-4">
                    Tracking Health and Reducing Illness Via Efficiency
                  </p>
                  <div className="w-16 h-1 bg-white/30 mx-auto rounded-full"></div>
                  <h2 className="text-2xl font-semibold text-white mt-4">Message Board</h2>
                </div>
              </div>
            </div>
          </div>

          {/* Status Messages */}
          {error && (
            <div className="mb-6 p-4 bg-red-50 rounded-lg">
              <div className="flex">
                <AlertCircle className="h-5 w-5 text-red-400" />
                <div className="ml-3">
                  <p className="text-sm text-red-700">{error}</p>
                </div>
              </div>
            </div>
          )}

          {success && (
            <div className="mb-6 p-4 bg-green-50 rounded-lg">
              <div className="flex">
                <Check className="h-5 w-5 text-green-400" />
                <div className="ml-3">
                  <p className="text-sm text-green-700">{success}</p>
                </div>
              </div>
            </div>
          )}

          {/* Message Board */}
          <div className="bg-white shadow-lg rounded-lg overflow-hidden">
            <div className="px-6 py-5 border-b border-gray-200">
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <MessageSquare className="h-5 w-5 text-green-600" />
                  <h3 className="ml-2 text-lg font-medium text-gray-900">
                    Messages
                  </h3>
                </div>
                {isAdmin && (
                  <button
                    onClick={() => setShowModal(true)}
                    className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700"
                  >
                    <Plus className="h-4 w-4 mr-2" />
                    New Message
                  </button>
                )}
              </div>
            </div>

            <div className="p-6">
              {loading ? (
                <div className="flex justify-center items-center py-12">
                  <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-green-600"></div>
                </div>
              ) : messages.length === 0 ? (
                <div className="text-center py-12">
                  <MessageSquare className="h-12 w-12 text-gray-300 mx-auto mb-4" />
                  <p className="text-gray-500">No messages available</p>
                  {isAdmin && (
                    <p className="text-gray-400 mt-2">
                      Click the "New Message" button to create your first message
                    </p>
                  )}
                </div>
              ) : (
                <div className="space-y-6">
                  {messages.map((message) => {
                    const isExpanded = expandedMessages.has(message.id);
                    return (
                      <div 
                        key={message.id} 
                        className={`bg-white border rounded-lg shadow-sm transition-all duration-200 ${
                          message.is_read ? 'border-gray-200' : 'border-green-300 bg-green-50'
                        }`}
                      >
                        <div 
                          className="p-4 cursor-pointer"
                          onClick={() => handleToggleExpand(message.id)}
                        >
                          <div className="flex items-center justify-between">
                            <div className="flex items-center space-x-2">
                              <h4 className="text-lg font-medium text-gray-900">
                                {message.title}
                              </h4>
                              {!message.is_read && (
                                <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                                  New
                                </span>
                              )}
                            </div>
                            <div className="flex items-center space-x-2">
                              {isAdmin && (
                                <button
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleUpdateMessageStatus(message.id, false);
                                  }}
                                  className="text-red-500 hover:text-red-700 p-1"
                                  title="Archive message"
                                >
                                  <Trash2 className="h-4 w-4" />
                                </button>
                              )}
                              <button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleToggleExpand(message.id);
                                }}
                                className="text-gray-500 hover:text-gray-700 p-1"
                              >
                                {isExpanded ? (
                                  <EyeOff className="h-4 w-4" />
                                ) : (
                                  <Eye className="h-4 w-4" />
                                )}
                              </button>
                            </div>
                          </div>
                          <div className="flex items-center text-sm text-gray-500 mt-1">
                            <User className="h-3 w-3 mr-1" />
                            <span>{message.created_by_email}</span>
                            <span className="mx-2">•</span>
                            <Clock className="h-3 w-3 mr-1" />
                            <span>{formatPacificDateTime(message.created_at)}</span>
                          </div>
                        </div>
                        
                        {isExpanded && (
                          <div className="px-4 pb-4 pt-2 border-t border-gray-100">
                            <div className="prose prose-sm max-w-none">
                              <p className="whitespace-pre-wrap">{message.content}</p>
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
      </main>

      {/* Message Modal */}
      {isAdmin && (
        <MessageModal
          isOpen={showModal}
          onClose={() => {
            setShowModal(false);
            setTitle('');
            setContent('');
          }}
          onSubmit={handleCreateMessage}
          title={title}
          content={content}
          setTitle={setTitle}
          setContent={setContent}
          isSubmitting={isSubmitting}
        />
      )}
    </div>
  );
}