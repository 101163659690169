import { formatCurrency } from './formatters';
import { formatPacificDate } from './dateTime';

export interface Membership {
  name: string;
  status: string;
  charge_amount?: number;
  date_created?: string;
  start_date?: string;
  internal_interval?: string;
  last_run?: string;
  last_run_status?: string;
  next_run_date?: string;
}

export function getMembershipStatusStyle(status: string): {
  bgColor: string;
  textColor: string;
  borderColor?: string;
} {
  switch (status?.toLowerCase()) {
    case 'active':
      return {
        bgColor: 'bg-green-100',
        textColor: 'text-green-800',
        borderColor: 'border-green-500'
      };
    case 'cancelled':
      return {
        bgColor: 'bg-red-100',
        textColor: 'text-red-800',
        borderColor: 'border-red-500'
      };
    case 'pending':
      return {
        bgColor: 'bg-yellow-100',
        textColor: 'text-yellow-800',
        borderColor: 'border-yellow-500'
      };
    case 'failed':
      return {
        bgColor: 'bg-red-100',
        textColor: 'text-red-800',
        borderColor: 'border-red-500'
      };
    case 'expired':
      return {
        bgColor: 'bg-gray-100',
        textColor: 'text-gray-800',
        borderColor: 'border-gray-500'
      };
    case 'inactive':
      return {
        bgColor: 'bg-gray-100',
        textColor: 'text-gray-800',
        borderColor: 'border-gray-500'
      };
    default:
      return {
        bgColor: 'bg-gray-100',
        textColor: 'text-gray-800',
        borderColor: 'border-gray-500'
      };
  }
}

export function formatMembershipStatus(status: string): string {
  return status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();
}

export function formatMembershipDetails(membership: Membership): string[] {
  const details: string[] = [];

  if (membership.charge_amount) {
    details.push(`${formatCurrency(membership.charge_amount)} per ${membership.internal_interval}`);
  }

  if (membership.last_run) {
    const lastRunDetail = `Last payment: ${formatPacificDate(membership.last_run, 'MMM d, yyyy')}`;
    if (membership.last_run_status) {
      details.push(`${lastRunDetail} • ${formatMembershipStatus(membership.last_run_status)}`);
    } else {
      details.push(lastRunDetail);
    }
  }

  if (membership.next_run_date && membership.status === 'active') {
    details.push(`Next billing: ${formatPacificDate(membership.next_run_date, 'MMM d, yyyy')}`);
  }

  return details;
}

export function getMembershipIcon(status: string): string {
  switch (status?.toLowerCase()) {
    case 'active':
      return 'check-circle-2';
    case 'cancelled':
      return 'x-circle';
    case 'pending':
      return 'clock';
    case 'failed':
      return 'alert-circle';
    case 'expired':
      return 'ban';
    case 'inactive':
      return 'minus-circle';
    default:
      return 'help-circle';
  }
}

export function sortMemberships(memberships: Membership[]): Membership[] {
  return [...memberships].sort((a, b) => {
    // Active memberships first
    if (a.status === 'active' && b.status !== 'active') return -1;
    if (a.status !== 'active' && b.status === 'active') return 1;

    // Then sort by date created (most recent first)
    if (a.date_created && b.date_created) {
      return new Date(b.date_created).getTime() - new Date(a.date_created).getTime();
    }

    // If no dates, sort by name
    return a.name.localeCompare(b.name);
  });
}

export function hasMembership(memberships: Membership[] | undefined): boolean {
  return !!memberships && memberships.length > 0;
}

export function hasActiveMembership(memberships: Membership[] | undefined): boolean {
  return !!memberships && memberships.some(m => m.status?.toLowerCase() === 'active');
}

export function getActiveMemberships(memberships: Membership[] | undefined): Membership[] {
  return memberships?.filter(m => m.status?.toLowerCase() === 'active') || [];
}

export function getMembershipsByStatus(memberships: Membership[] | undefined, status: string): Membership[] {
  return memberships?.filter(m => m.status?.toLowerCase() === status.toLowerCase()) || [];
}

export function getMembershipsByType(memberships: Membership[] | undefined, type: string): Membership[] {
  return memberships?.filter(m => m.name?.toLowerCase().includes(type.toLowerCase())) || [];
}

export function calculateTotalMonthlyCharges(memberships: Membership[] | undefined): number {
  return memberships?.reduce((total, membership) => {
    if (membership.status?.toLowerCase() === 'active' && membership.charge_amount) {
      return total + membership.charge_amount;
    }
    return total;
  }, 0) || 0;
}