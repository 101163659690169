import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  ArrowLeft, 
  Leaf, 
  Users, 
  Activity, 
  Calendar, 
  Clock, 
  Building2,
  Pill,
  Syringe,
  LogOut,
  ChevronLeft,
  ChevronRight,
  CalendarDays,
  Edit2,
  AlertCircle,
  RefreshCw,
  Check,
  FileSpreadsheet,
  Info,
  TrendingDown,
  UserCheck,
  Users2,
  Scale
} from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import { supabase } from '../lib/supabase';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartData,
  ChartOptions
} from 'chart.js';

// Register ChartJS components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface WeightLossSummary {
  total_patients: number;
  total_weight_loss: number;
  average_weight_loss: number;
  most_recent_update: string;
}

interface DurationStats {
  duration_range: string;
  average_loss: number;
  patient_count: number;
}

interface PercentageStats {
  duration_range: string;
  average_percentage: number;
  patient_count: number;
}

interface MilestoneStats {
  milestone: string;
  patient_count: number;
  average_days: number;
}

interface RetentionStats {
  retention_period: string;
  patient_count: number;
  average_weight_loss: number;
  retention_rate: number;
}

interface VisitFrequencyStats {
  frequency_group: string;
  patient_count: number;
  average_weight_loss: number;
  average_monthly_loss: number;
}

interface ProcessingLog {
  type: 'info' | 'success' | 'error';
  message: string;
  timestamp: Date;
  details?: string;
}

export function WeightLossData() {
  const navigate = useNavigate();
  const { user, userRoles } = useAuth();
  const isAdmin = userRoles.includes('admin');
  const [summary, setSummary] = useState<WeightLossSummary | null>(null);
  const [durationStats, setDurationStats] = useState<DurationStats[]>([]);
  const [percentageStats, setPercentageStats] = useState<PercentageStats[]>([]);
  const [milestoneStats, setMilestoneStats] = useState<MilestoneStats[]>([]);
  const [retentionStats, setRetentionStats] = useState<RetentionStats[]>([]);
  const [visitFrequencyStats, setVisitFrequencyStats] = useState<VisitFrequencyStats[]>([]);
  const [loading, setLoading] = useState(true);
  const [updating, setUpdating] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [processingLogs, setProcessingLogs] = useState<ProcessingLog[]>([]);

  // Helper function to format numbers with commas
  const formatNumber = (num: number) => {
    return num.toLocaleString('en-US', {
      minimumFractionDigits: 1,
      maximumFractionDigits: 1
    });
  };

  const addLog = (type: ProcessingLog['type'], message: string, details?: string) => {
    setProcessingLogs(prev => [...prev, {
      type,
      message,
      timestamp: new Date(),
      details
    }]);
  };

  useEffect(() => {
    if (isAdmin) {
      fetchAnalytics();
    }
  }, [isAdmin]);

  const fetchAnalytics = async () => {
    try {
      setLoading(true);
      setError(null);

      // Fetch summary statistics
      const { data: summaryData, error: summaryError } = await supabase
        .rpc('get_weight_loss_summary');

      if (summaryError) throw summaryError;
      setSummary(summaryData[0]);

      // Fetch duration-based stats
      const { data: durationData, error: durationError } = await supabase
        .rpc('get_weight_loss_by_duration');

      if (durationError) throw durationError;
      setDurationStats(durationData);

      // Fetch percentage-based stats
      const { data: percentageData, error: percentageError } = await supabase
        .rpc('get_percentage_weight_loss_by_duration');

      if (percentageError) throw percentageError;
      setPercentageStats(percentageData);

      // Fetch milestone stats
      const { data: milestoneData, error: milestoneError } = await supabase
        .rpc('get_weight_loss_milestones');

      if (milestoneError) throw milestoneError;
      setMilestoneStats(milestoneData);

      // Fetch retention stats
      const { data: retentionData, error: retentionError } = await supabase
        .rpc('get_retention_analysis');

      if (retentionError) throw retentionError;
      setRetentionStats(retentionData);

      // Fetch visit frequency stats
      const { data: frequencyData, error: frequencyError } = await supabase
        .rpc('get_visit_frequency_impact');

      if (frequencyError) throw frequencyError;
      setVisitFrequencyStats(frequencyData);

    } catch (error: any) {
      console.error('Error fetching analytics:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateData = async () => {
    try {
      setUpdating(true);
      setError(null);
      setSuccess(null);
      setProcessingLogs([]); // Clear previous logs

      addLog('info', 'Starting weight loss data update...');

      // Get all patients with weight measurements
      const { data: patients, error: patientsError } = await supabase
        .from('patients')
        .select('id, patient_id, first_name, last_name')
        .not('deleted', 'is', true);

      if (patientsError) throw patientsError;

      addLog('info', `Found ${patients.length} patients to process`);

      let processedCount = 0;
      let skippedCount = 0;
      let errorCount = 0;
      const batchSize = 50;

      // Process patients in batches
      for (let i = 0; i < patients.length; i += batchSize) {
        const batch = patients.slice(i, Math.min(i + batchSize, patients.length));
        addLog('info', `Processing batch ${Math.floor(i / batchSize) + 1} of ${Math.ceil(patients.length / batchSize)}`);
        
        for (const patient of batch) {
          try {
            // Get weight measurements for patient
            const { data: weights, error: weightsError } = await supabase
              .from('weight_measurements')
              .select('weight, measurement_date')
              .eq('external_patient_id', patient.patient_id)
              .order('measurement_date', { ascending: true });

            if (weightsError) {
              addLog('error', `Error fetching weights for ${patient.first_name} ${patient.last_name}`, weightsError.message);
              errorCount++;
              continue;
            }

            if (!weights || weights.length === 0) {
              addLog('info', `No weight measurements found for ${patient.first_name} ${patient.last_name}`);
              skippedCount++;
              continue;
            }

            // Get visit dates
            const { data: visits, error: visitsError } = await supabase
              .from('appointments')
              .select('appointment_date')
              .eq('external_patient_id', patient.patient_id)
              .is('cancelled_date', null)
              .order('appointment_date', { ascending: true });

            if (visitsError) {
              addLog('error', `Error fetching visits for ${patient.first_name} ${patient.last_name}`, visitsError.message);
              errorCount++;
              continue;
            }

            const firstVisit = visits?.[0]?.appointment_date;
            const lastVisit = visits?.[visits.length - 1]?.appointment_date;
            const startingWeight = weights[0].weight;
            const currentWeight = weights[weights.length - 1].weight;
            const lowestWeight = Math.min(...weights.map(w => w.weight));
            const lowestWeightRecord = weights.find(w => w.weight === lowestWeight);

            // Update weight loss data
            const { error: updateError } = await supabase.rpc('update_weight_loss_data', {
              p_patient_id: patient.patient_id,
              p_first_visit_date: firstVisit,
              p_most_recent_visit_date: lastVisit,
              p_starting_weight: startingWeight,
              p_most_recent_weight: currentWeight,
              p_lowest_weight: lowestWeight,
              p_lowest_weight_date: lowestWeightRecord?.measurement_date
            });

            if (updateError) {
              addLog('error', `Error updating data for ${patient.first_name} ${patient.last_name}`, updateError.message);
              errorCount++;
              continue;
            }

            processedCount++;
            addLog('success', `Updated data for ${patient.first_name} ${patient.last_name}`, 
              `Starting: ${startingWeight}lbs, Current: ${currentWeight}lbs, Loss: ${(startingWeight - currentWeight).toFixed(1)}lbs`);
          } catch (error: any) {
            addLog('error', `Error processing ${patient.first_name} ${patient.last_name}`, error.message);
            errorCount++;
          }
        }
      }

      const summaryMessage = `Processing complete. ${processedCount} updated, ${skippedCount} skipped, ${errorCount} errors`;
      addLog('info', summaryMessage);
      setSuccess(summaryMessage);
      await fetchAnalytics(); // Refresh the analytics
    } catch (error: any) {
      console.error('Error updating weight loss data:', error);
      setError(error.message);
      addLog('error', 'Fatal error during processing', error.message);
    } finally {
      setUpdating(false);
    }
  };

  // Chart configurations
  const durationChartData: ChartData<'bar'> = {
    labels: durationStats.map(d => d.duration_range),
    datasets: [
      {
        label: 'Average Weight Loss (lbs)',
        data: durationStats.map(d => d.average_loss),
        backgroundColor: 'rgba(34, 197, 94, 0.5)',
        borderColor: 'rgb(34, 197, 94)',
        borderWidth: 1,
        yAxisID: 'y'
      },
      {
        label: 'Number of Patients',
        data: durationStats.map(d => d.patient_count),
        backgroundColor: 'rgba(59, 130, 246, 0.5)',
        borderColor: 'rgb(59, 130, 246)',
        borderWidth: 1,
        yAxisID: 'y1'
      }
    ]
  };

  const percentageChartData: ChartData<'bar'> = {
    labels: percentageStats.map(d => d.duration_range),
    datasets: [
      {
        label: 'Average Weight Loss (%)',
        data: percentageStats.map(d => d.average_percentage),
        backgroundColor: 'rgba(34, 197, 94, 0.5)',
        borderColor: 'rgb(34, 197, 94)',
        borderWidth: 1,
        yAxisID: 'y'
      },
      {
        label: 'Number of Patients',
        data: percentageStats.map(d => d.patient_count),
        backgroundColor: 'rgba(59, 130, 246, 0.5)',
        borderColor: 'rgb(59, 130, 246)',
        borderWidth: 1,
        yAxisID: 'y1'
      }
    ]
  };

  const milestonesChartData: ChartData<'bar'> = {
    labels: milestoneStats.map(m => m.milestone),
    datasets: [
      {
        label: 'Number of Patients',
        data: milestoneStats.map(m => m.patient_count),
        backgroundColor: 'rgba(34, 197, 94, 0.5)',
        borderColor: 'rgb(34, 197, 94)',
        borderWidth: 1,
        yAxisID: 'y'
      },
      {
        label: 'Average Days to Achieve',
        data: milestoneStats.map(m => m.average_days),
        backgroundColor: 'rgba(59, 130, 246, 0.5)',
        borderColor: 'rgb(59, 130, 246)',
        borderWidth: 1,
        yAxisID: 'y1'
      }
    ]
  };

  const retentionChartData: ChartData<'bar'> = {
    labels: retentionStats.map(r => r.retention_period),
    datasets: [
      {
        label: 'Average Weight Loss (lbs)',
        data: retentionStats.map(r => r.average_weight_loss),
        backgroundColor: 'rgba(34, 197, 94, 0.5)',
        borderColor: 'rgb(34, 197, 94)',
        borderWidth: 1,
        yAxisID: 'y'
      },
      {
        label: 'Retention Rate (%)',
        data: retentionStats.map(r => r.retention_rate),
        backgroundColor: 'rgba(59, 130, 246, 0.5)',
        borderColor: 'rgb(59, 130, 246)',
        borderWidth: 1,
        yAxisID: 'y1'
      }
    ]
  };

  const visitFrequencyChartData: ChartData<'bar'> = {
    labels: visitFrequencyStats.map(v => v.frequency_group),
    datasets: [
      {
        label: 'Average Weight Loss (lbs)',
        data: visitFrequencyStats.map(v => v.average_weight_loss),
        backgroundColor: 'rgba(34, 197, 94, 0.5)',
        borderColor: 'rgb(34, 197, 94)',
        borderWidth: 1,
        yAxisID: 'y'
      },
      {
        label: 'Average Monthly Loss (lbs)',
        data: visitFrequencyStats.map(v => v.average_monthly_loss),
        backgroundColor: 'rgba(59, 130, 246, 0.5)',
        borderColor: 'rgb(59, 130, 246)',
        borderWidth: 1,
        yAxisID: 'y1'
      }
    ]
  };

  const chartOptions: ChartOptions<'bar'> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: 'Weight Loss by Program Duration'
      }
    },
    scales: {
      y: {
        type: 'linear',
        display: true,
        position: 'left',
        title: {
          display: true,
          text: 'Average Weight Loss (lbs)'
        }
      },
      y1: {
        type: 'linear',
        display: true,
        position: 'right',
        title: {
          display: true,
          text: 'Number of Patients'
        },
        grid: {
          drawOnChartArea: false
        }
      }
    }
  };

  const percentageChartOptions: ChartOptions<'bar'> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: 'Percentage Weight Loss by Program Duration'
      }
    },
    scales: {
      y: {
        type: 'linear',
        display: true,
        position: 'left',
        title: {
          display: true,
          text: 'Average Weight Loss (%)'
        }
      },
      y1: {
        type: 'linear',
        display: true,
        position: 'right',
        title: {
          display: true,
          text: 'Number of Patients'
        },
        grid: {
          drawOnChartArea: false
        }
      }
    }
  };

  const milestonesChartOptions: ChartOptions<'bar'> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: 'Weight Loss Milestones'
      }
    },
    scales: {
      y: {
        type: 'linear',
        display: true,
        position: 'left',
        title: {
          display: true,
          text: 'Number of Patients'
        }
      },
      y1: {
        type: 'linear',
        display: true,
        position: 'right',
        title: {
          display: true,
          text: 'Average Days to Achieve'
        },
        grid: {
          drawOnChartArea: false
        }
      }
    }
  };

  const retentionChartOptions: ChartOptions<'bar'> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: 'Patient Retention Analysis'
      }
    },
    scales: {
      y: {
        type: 'linear',
        display: true,
        position: 'left',
        title: {
          display: true,
          text: 'Average Weight Loss (lbs)'
        }
      },
      y1: {
        type: 'linear',
        display: true,
        position: 'right',
        title: {
          display: true,
          text: 'Retention Rate (%)'
        },
        grid: {
          drawOnChartArea: false
        }
      }
    }
  };

  const visitFrequencyChartOptions: ChartOptions<'bar'> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: 'Visit Frequency Impact'
      }
    },
    scales: {
      y: {
        type: 'linear',
        display: true,
        position: 'left',
        title: {
          display: true,
          text: 'Weight Loss (lbs)'
        }
      },
      y1: {
        type: 'linear',
        display: true,
        position: 'right',
        title: {
          display: true,
          text: 'Monthly Loss (lbs)'
        },
        grid: {
          drawOnChartArea: false
        }
      }
    }
  };

  // Redirect non-admin users
  if (!isAdmin) {
    return null;
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-green-50 to-blue-50">
      <nav className="bg-white shadow-sm">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16">
            <div className="flex items-center">
              <Leaf className="h-6 w-6 text-green-600" />
              <div className="ml-2">
                <span className="text-xl font-semibold text-gray-900">THRIVE</span>
                <span className="ml-2 text-sm text-gray-500">Weight Loss Data</span>
              </div>
            </div>
            <div className="flex items-center space-x-4">
              <span className="text-sm text-gray-700">{user?.email}</span>
              <div className="flex flex-wrap gap-2">
                {userRoles.map((role) => (
                  <span
                    key={role}
                    className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800"
                  >
                    {role}
                  </span>
                ))}
              </div>
              <button
                onClick={() => signOut()}
                className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-green-600 hover:bg-green-700"
              >
                <LogOut className="h-4 w-4 mr-2" />
                Sign Out
              </button>
            </div>
          </div>
        </div>
      </nav>

      <main className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        <div className="px-4 py-4 sm:px-0">
          {/* Header Section */}
          <div className="bg-white shadow-lg rounded-lg mb-6 overflow-hidden">
            <div className="relative">
              <div className="absolute inset-0 bg-gradient-to-r from-green-600 to-blue-600 opacity-90"></div>
              <div className="relative px-8 py-12">
                <div className="flex items-center justify-between">
                  <button
                    onClick={() => navigate('/settings')}
                    className="text-white hover:text-green-100 transition-colors duration-200"
                  >
                    <ArrowLeft className="h-6 w-6" />
                  </button>
                  <div className="flex items-center space-x-4">
                    <button
                      onClick={handleUpdateData}
                      disabled={updating}
                      className="bg-white/10 backdrop-blur-sm rounded-lg px-4 py-2 text-white hover:bg-white/20 disabled:opacity-50 disabled:cursor-not-allowed"
                    >
                      <RefreshCw className={`h-4 w-4 mr-2 ${updating ? 'animate-spin' : ''}`} />
                      {updating ? 'Updating Data...' : 'Update Weight Loss Data'}
                    </button>
                    <Leaf className="h-12 w-12 text-white opacity-20" />
                  </div>
                </div>
                
                <div className="mt-4 text-center">
                  <h1 className="text-4xl font-bold text-white mb-2">THRIVE</h1>
                  <p className="text-green-100 text-lg mb-4">
                    Tracking Health and Reducing Illness Via Efficiency
                  </p>
                  <div className="w-16 h-1 bg-white/30 mx-auto rounded-full"></div>
                  <h2 className="text-2xl font-semibold text-white mt-4">Weight Loss Analytics</h2>
                </div>
              </div>
            </div>
          </div>

          {/* Status Messages */}
          {error && (
            <div className="mb-6 p-4 bg-red-50 rounded-lg">
              <div className="flex">
                <AlertCircle className="h-5 w-5 text-red-400" />
                <div className="ml-3">
                  <h3 className="text-sm font-medium text-red-800">Error</h3>
                  <div className="mt-2 text-sm text-red-700">{error}</div>
                </div>
              </div>
            </div>
          )}

          {success && (
            <div className="mb-6 p-4 bg-green-50 rounded-lg">
              <div className="flex">
                <Check className="h-5 w-5 text-green-400" />
                <div className="ml-3">
                  <h3 className="text-sm font-medium text-green-800">Success</h3>
                  <div className="mt-2 text-sm text-green-700">{success}</div>
                </div>
              </div>
            </div>
          )}

          {/* Processing Log */}
          {updating && (
            <div className="mb-6 bg-white shadow-lg rounded-lg overflow-hidden">
              <div className="px-6 py-4 border-b border-gray-200">
                <div className="flex items-center">
                  <FileSpreadsheet className="h-5 w-5 text-green-600" />
                  <h3 className="ml-2 text-lg font-medium text-gray-900">Processing Log</h3>
                </div>
              </div>
              <div className="p-6">
                <div className="bg-gray-50 rounded-lg p-4 h-64 overflow-y-auto">
                  {processingLogs.map((log, index) => (
                    <div
                      key={index}
                      className={`flex items-start space-x-2 mb-2 text-sm ${
                        log.type === 'error' 
                          ? 'text-red-600' 
                          : log.type === 'success'
                          ? 'text-green-600'
                          : 'text-gray-600'
                      }`}
                    >
                      {log.type === 'error' && <AlertCircle className="h-4 w-4 mt-0.5 flex-shrink-0" />}
                      {log.type === 'success' && <Check className="h-4 w-4 mt-0.5 flex-shrink-0" />}
                      {log.type === 'info' && <Info className="h-4 w-4 mt-0.5 flex-shrink-0" />}
                      <div className="flex-1">
                        <div className="flex items-center justify-between">
                          <span>{log.message}</span>
                          <span className="text-xs text-gray-400">
                            {log.timestamp.toLocaleTimeString()}
                          </span>
                        </div>
                        {log.details && (
                          <div className="mt-1 text-xs text-gray-500">
                            {log.details}
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}

          {/* Summary Stats */}
          <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4 mb-8">
            <div className="bg-white overflow-hidden shadow-lg rounded-lg">
              <div className="p-5">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <Users className="h-6 w-6 text-green-600" />
                  </div>
                  <div className="ml-5 w-0 flex-1">
                    <dl>
                      <dt className="text-sm font-medium text-gray-500 truncate">
                        Total Patients
                      </dt>
                      <dd className="text-lg font-semibold text-gray-900">
                        {loading ? (
                          <div className="animate-pulse h-6 w-16 bg-gray-200 rounded"></div>
                        ) : (
                          summary?.total_patients.toLocaleString()
                        )}
                      </dd>
                    </dl>
                  </div>
                </div>
              </div>
            </div>

            <div className="bg-white overflow-hidden shadow-lg rounded-lg">
              <div className="p-5">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <Scale className="h-6 w-6 text-green-600" />
                  </div>
                  <div className="ml-5 w-0 flex-1">
                    <dl>
                      <dt className="text-sm font-medium text-gray-500 truncate">
                        Total Weight Loss
                      </dt>
                      <dd className="text-lg font-semibold text-gray-900">
                        {loading ? (
                          <div className="animate-pulse h-6 w-16 bg-gray-200 rounded"></div>
                        ) : (
                          `${formatNumber(summary?.total_weight_loss || 0)} lbs`
                        )}
                      </dd>
                    </dl>
                  </div>
                </div>
              </div>
            </div>

            <div className="bg-white overflow-hidden shadow-lg rounded-lg">
              <div className="p-5">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <Activity className="h-6 w-6 text-green-600" />
                  </div>
                  <div className="ml-5 w-0 flex-1">
                    <dl>
                      <dt className="text-sm font-medium text-gray-500 truncate">
                        Average Weight Loss
                      </dt>
                      <dd className="text-lg font-semibold text-gray-900">
                        {loading ? (
                          <div className="animate-pulse h-6 w-16 bg-gray-200 rounded"></div>
                        ) : (
                          `${formatNumber(summary?.average_weight_loss || 0)} lbs`
                        )}
                      </dd>
                    </dl>
                  </div>
                </div>
              </div>
            </div>

            <div className="bg-white overflow-hidden shadow-lg rounded-lg">
              <div className="p-5">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <Calendar className="h-6 w-6 text-green-600" />
                  </div>
                  <div className="ml-5 w-0 flex-1">
                    <dl>
                      <dt className="text-sm font-medium text-gray-500 truncate">
                        Last Updated
                      </dt>
                      <dd className="text-lg font-semibold text-gray-900">
                        {loading ? (
                          <div className="animate-pulse h-6 w-16 bg-gray-200 rounded">
                          </div>
                        ) : (
                          new Date(summary?.most_recent_update || '').toLocaleDateString()
                        )}
                      </dd>
                    </dl>
                  </div>
                </div>
              </div>
            </div>

            {/* Charts Section */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 col-span-full">
              {/* Weight Loss by Duration Chart */}
              <div className="bg-white shadow-lg rounded-lg overflow-hidden">
                <div className="p-6">
                  <div style={{ height: '400px' }}>
                    <Bar data={durationChartData} options={chartOptions} />
                  </div>
                </div>
              </div>

              {/* Percentage Weight Loss Chart */}
              <div className="bg-white shadow-lg rounded-lg overflow-hidden">
                <div className="p-6">
                  <div style={{ height: '400px' }}>
                    <Bar data={percentageChartData} options={percentageChartOptions} />
                  </div>
                </div>
              </div>

              {/* Weight Loss Milestones Chart */}
              <div className="bg-white shadow-lg rounded-lg overflow-hidden">
                <div className="p-6">
                  <div style={{ height: '400px' }}>
                    <Bar data={milestonesChartData} options={milestonesChartOptions} />
                  </div>
                </div>
              </div>

              {/* Patient Retention Chart */}
              <div className="bg-white shadow-lg rounded-lg overflow-hidden">
                <div className="p-6">
                  <div style={{ height: '400px' }}>
                    <Bar data={retentionChartData} options={retentionChartOptions} />
                  </div>
                </div>
              </div>

              {/* Visit Frequency Impact Chart */}
              <div className="bg-white shadow-lg rounded-lg overflow-hidden col-span-2">
                <div className="p-6">
                  <div style={{ height: '400px' }}>
                    <Bar data={visitFrequencyChartData} options={visitFrequencyChartOptions} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}