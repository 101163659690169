import React, { useCallback, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft, Upload, FileSpreadsheet, X, Check } from 'lucide-react';
import { useDropzone } from 'react-dropzone';
import { processReport } from '../utils/dataImportProcessor';
import { useAuth } from '../contexts/AuthContext';
import { supabase } from '../lib/supabase';

interface FileStatus {
  name: string;
  status: 'processing' | 'success' | 'error';
  message?: string;
  processedCount?: number;
  skippedCount?: number;
  progress?: {
    currentLine: number;
    totalLines: number;
    percentage: number;
  };
}

export function ImportData() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [files, setFiles] = useState<FileStatus[]>([]);

  // Auto-remove success/error messages after 20 seconds
  useEffect(() => {
    const timeouts = files.map((file, index) => {
      if (file.status === 'success' || file.status === 'error') {
        return setTimeout(() => {
          setFiles(prev => prev.filter((_, i) => i !== index));
        }, 20000);
      }
      return null;
    });

    return () => {
      timeouts.forEach(timeout => timeout && clearTimeout(timeout));
    };
  }, [files]);

  const recordImportHistory = async (
    reportType: string,
    fileName: string,
    processedCount: number,
    skippedCount: number
  ) => {
    try {
      await supabase.from('import_history').insert({
        report_type: reportType,
        file_name: fileName,
        processed_count: processedCount,
        skipped_count: skippedCount,
        imported_by: user?.id
      });
    } catch (error) {
      console.error('Failed to record import history:', error);
    }
  };

  const processFile = async (file: File) => {
    try {
      setFiles(prev => [...prev, { name: file.name, status: 'processing' }]);

      const fileExtension = file.name.split('.').pop()?.toLowerCase();
      
      if (fileExtension !== 'csv') {
        throw new Error('Only CSV files are supported for import');
      }

      const text = await file.text();
      const result = await processReport(text, (progress) => {
        setFiles(prev => 
          prev.map(f => 
            f.name === file.name 
              ? { 
                  ...f, 
                  progress: {
                    currentLine: progress.currentLine,
                    totalLines: progress.totalLines,
                    percentage: Math.round((progress.currentLine / progress.totalLines) * 100)
                  }
                }
              : f
          )
        );
      });

      if (result.success) {
        await recordImportHistory(
          'ACCESS_DATA_WTGMGT', // This will be dynamic when we support multiple report types
          file.name,
          result.processedCount,
          result.skippedCount
        );
      }

      setFiles(prev => 
        prev.map(f => 
          f.name === file.name 
            ? { 
                ...f, 
                status: result.success ? 'success' : 'error',
                message: result.message,
                processedCount: result.processedCount,
                skippedCount: result.skippedCount
              }
            : f
        )
      );
    } catch (error: any) {
      setFiles(prev => 
        prev.map(f => 
          f.name === file.name 
            ? { ...f, status: 'error', message: error.message }
            : f
        )
      );
    }
  };

  const onDrop = useCallback((acceptedFiles: File[]) => {
    acceptedFiles.forEach(processFile);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'text/csv': ['.csv']
    },
    multiple: false
  });

  return (
    <div className="min-h-screen bg-gray-100">
      <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        <div className="px-4 py-4 sm:px-0">
          <div className="flex items-center mb-6">
            <button
              onClick={() => navigate('/settings')}
              className="mr-4 text-gray-600 hover:text-gray-900"
            >
              <ArrowLeft className="h-6 w-6" />
            </button>
            <h1 className="text-2xl font-bold text-gray-900">Import Data</h1>
          </div>

          <div className="bg-white shadow rounded-lg overflow-hidden">
            <div className="p-6">
              <div className="mb-6">
                <h2 className="text-lg font-medium text-gray-900 mb-2">Import Instructions</h2>
                <p className="text-sm text-gray-500">
                  Upload a CSV file for data import:
                </p>
                <ul className="list-disc list-inside text-sm text-gray-500 mt-2">
                  <li>Reports from Athena should begin with "ACCESS Data"</li>
                  <li>Files must be in CSV format</li>
                  <li>Files should contain all required information for the report type</li>
                  <li>Data should be properly formatted according to specifications</li>
                </ul>
              </div>

              <div 
                {...getRootProps()} 
                className={`border-2 border-dashed rounded-lg p-12 text-center cursor-pointer transition-all duration-300 ${
                  isDragActive 
                    ? 'border-indigo-500 bg-indigo-50' 
                    : 'border-gray-300 hover:border-indigo-500 hover:bg-gray-50'
                }`}
              >
                <input {...getInputProps()} />
                <div className="flex flex-col items-center">
                  <Upload className="h-12 w-12 text-gray-400 mb-4" />
                  <p className="text-lg font-medium text-gray-900 mb-1">
                    {isDragActive 
                      ? 'Drop the file here...' 
                      : 'Drag and drop your file here'}
                  </p>
                  <p className="text-sm text-gray-500 mb-4">
                    or click to select a file
                  </p>
                  <div className="flex items-center space-x-2 text-sm text-gray-500">
                    <FileSpreadsheet className="h-5 w-5" />
                    <span>Accepts CSV files</span>
                  </div>
                </div>
              </div>

              {/* File Status List */}
              {files.length > 0 && (
                <div className="mt-6">
                  <h3 className="text-lg font-medium text-gray-900 mb-4">Processed Files</h3>
                  <div className="space-y-3">
                    {files.map((file, index) => (
                      <div 
                        key={index}
                        className={`flex flex-col p-4 rounded-lg transition-opacity duration-500 ${
                          file.status === 'processing' ? 'bg-gray-50' :
                          file.status === 'success' ? 'bg-green-50' : 'bg-red-50'
                        }`}
                      >
                        <div className="flex items-center justify-between">
                          <div className="flex items-center">
                            <FileSpreadsheet className="h-5 w-5 text-gray-400 mr-3" />
                            <div>
                              <p className="text-sm font-medium text-gray-900">{file.name}</p>
                              {file.message && (
                                <p className={`text-sm ${
                                  file.status === 'error' ? 'text-red-600' : 'text-green-600'
                                }`}>
                                  {file.message}
                                </p>
                              )}
                              {file.status === 'success' && (
                                <p className="text-sm text-gray-500">
                                  Processed: {file.processedCount} | Skipped: {file.skippedCount}
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="flex items-center">
                            {file.status === 'processing' && (
                              <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-indigo-600" />
                            )}
                            {file.status === 'success' && (
                              <Check className="h-5 w-5 text-green-500" />
                            )}
                            {file.status === 'error' && (
                              <X className="h-5 w-5 text-red-500" />
                            )}
                          </div>
                        </div>

                        {/* Progress Bar */}
                        {file.status === 'processing' && file.progress && (
                          <div className="mt-3">
                            <div className="flex justify-between text-sm text-gray-500 mb-1">
                              <span>Processing lines...</span>
                              <span>
                                {file.progress.currentLine} of {file.progress.totalLines} 
                                ({file.progress.percentage}%)
                              </span>
                            </div>
                            <div className="w-full bg-gray-200 rounded-full h-2">
                              <div 
                                className="bg-indigo-600 h-2 rounded-full transition-all duration-300"
                                style={{ width: `${file.progress.percentage}%` }}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}