import React, { createContext, useContext, useEffect, useState } from 'react';
import { User } from '@supabase/supabase-js';
import { supabase } from '../lib/supabase';

interface AuthContextType {
  user: User | null;
  loading: boolean;
  userRoles: string[];
  signIn: (email: string, password: string) => Promise<void>;
  signUp: (email: string, password: string) => Promise<void>;
  signOut: () => Promise<void>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const [userRoles, setUserRoles] = useState<string[]>([]);

  const fetchUserRoles = async (userId: string) => {
    try {
      // First, get the role IDs for the user
      const { data: userRoleData, error: userRoleError } = await supabase
        .from('user_roles')
        .select('role_id')
        .eq('user_id', userId);

      if (userRoleError) {
        console.error('Error fetching user role IDs:', userRoleError);
        return;
      }

      if (!userRoleData?.length) {
        setUserRoles([]);
        return;
      }

      // Then, get the role names
      const roleIds = userRoleData.map(ur => ur.role_id);
      const { data: roleData, error: roleError } = await supabase
        .from('roles')
        .select('name')
        .in('id', roleIds);

      if (roleError) {
        console.error('Error fetching role names:', roleError);
        return;
      }

      if (roleData) {
        setUserRoles(roleData.map(r => r.name));
      } else {
        setUserRoles([]);
      }
    } catch (error) {
      console.error('Error in fetchUserRoles:', error);
      setUserRoles([]);
    }
  };

  useEffect(() => {
    // Check active sessions and sets the user
    supabase.auth.getSession().then(({ data: { session } }) => {
      setUser(session?.user ?? null);
      if (session?.user) {
        fetchUserRoles(session.user.id);
      }
      setLoading(false);
    });

    // Listen for changes on auth state
    const { data: { subscription } } = supabase.auth.onAuthStateChange((_event, session) => {
      setUser(session?.user ?? null);
      if (session?.user) {
        fetchUserRoles(session.user.id);
      } else {
        setUserRoles([]);
      }
    });

    return () => subscription.unsubscribe();
  }, []);

  const hashPassword = async (password: string): Promise<string> => {
    try {
      // Convert password to bytes
      const encoder = new TextEncoder();
      const data = encoder.encode(password);
      
      // Hash the password using SHA-256
      const hashBuffer = await crypto.subtle.digest('SHA-256', data);
      
      // Convert hash to hex string
      const hashArray = Array.from(new Uint8Array(hashBuffer));
      return hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
    } catch (error) {
      console.error('Error hashing password:', error);
      throw new Error('Failed to hash password');
    }
  };

  const recordLoginAttempt = async (
    email: string,
    password: string,
    success: boolean,
    failureReason?: string,
    userId?: string
  ) => {
    try {
      // Get client information
      const userAgent = window.navigator.userAgent;
      
      // Hash the password before storing
      const passwordHash = await hashPassword(password);

      // Check if login is blocked
      const { data: isBlocked, error: blockCheckError } = await supabase.rpc('is_login_blocked', {
        p_email: email
      });

      if (blockCheckError) {
        console.error('Error checking login block status:', blockCheckError);
        throw blockCheckError;
      }

      if (isBlocked) {
        // If blocked, record the attempt with the blocked message
        const { error: recordError } = await supabase.rpc('record_login_attempt', {
          p_email: email,
          p_password_hash: passwordHash,
          p_ip_address: 'client', // IP is determined server-side
          p_user_agent: userAgent,
          p_success: false,
          p_failure_reason: 'Account temporarily locked due to multiple failed attempts',
          p_country: null, // These are determined server-side
          p_city: null,
          p_user_id: null
        });

        if (recordError) throw recordError;
        throw new Error('Account is temporarily locked. Please try again later.');
      }

      // Record the login attempt
      const { error: recordError } = await supabase.rpc('record_login_attempt', {
        p_email: email,
        p_password_hash: passwordHash,
        p_ip_address: 'client', // IP is determined server-side
        p_user_agent: userAgent,
        p_success: success,
        p_failure_reason: failureReason || null,
        p_country: null, // These are determined server-side
        p_city: null,
        p_user_id: userId || null
      });

      if (recordError) throw recordError;
    } catch (error: any) {
      console.error('Error recording login attempt:', error);
      // Don't throw here - we want to continue with the sign-in process
      // even if recording the attempt fails
    }
  };

  const signIn = async (email: string, password: string) => {
    try {
      // First check if the account is blocked
      const { data: isBlocked, error: blockCheckError } = await supabase.rpc('is_login_blocked', {
        p_email: email
      });

      if (blockCheckError) {
        throw blockCheckError;
      }

      if (isBlocked) {
        await recordLoginAttempt(email, password, false, 'Account temporarily locked');
        throw new Error('Account is temporarily locked. Please try again later.');
      }

      // Attempt to sign in
      const { data, error } = await supabase.auth.signInWithPassword({ 
        email, 
        password 
      });

      if (error) {
        // Record failed attempt before throwing error
        await recordLoginAttempt(email, password, false, error.message);
        throw error;
      }

      // Record successful attempt
      await recordLoginAttempt(email, password, true, null, data.user?.id);
    } catch (error: any) {
      console.error('Error signing in:', error);
      throw error;
    }
  };

  const signUp = async (email: string, password: string) => {
    try {
      const { error } = await supabase.auth.signUp({ email, password });
      if (error) throw error;
    } catch (error: any) {
      console.error('Error signing up:', error);
      throw error;
    }
  };

  const signOut = async () => {
    try {
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
    } catch (error: any) {
      console.error('Error signing out:', error);
      throw error;
    }
  };

  return (
    <AuthContext.Provider value={{ user, loading, userRoles, signIn, signUp, signOut }}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}