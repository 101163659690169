import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Leaf, 
  LogOut, 
  Search, 
  Link2, 
  Link2Off,
  UserSearch,
  Check,
  X,
  AlertCircle,
  Clock,
  CreditCard,
  Clock3
} from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import { supabase } from '../lib/supabase';
import { PatientDetailsModal } from '../components/PatientDetailsModal';
import { MembershipStatus } from '../components/dashboard/MembershipStatus';

interface MembershipInfo {
  id: string;
  membership_name: string;
  status: string;
  charge_amount?: number;
  date_created?: string;
  start_date?: string;
  internal_interval?: string;
  last_run?: string;
  last_run_status?: string;
  next_run_date?: string;
  ignore?: boolean;
}

interface MembershipPatient {
  id: uuid;
  external_id: string;
  first_name: string;
  last_name: string;
  location: string;
  mapped_patient_id: string | null;
  external_patient_id: string | null;
  mapping_confidence: number | null;
  mapping_status: string;
  memberships?: MembershipInfo[];
}

interface FilterState {
  declined: boolean;
  pending: boolean;
}

export function MembershipIssues() {
  const navigate = useNavigate();
  const { user, userRoles, signOut } = useAuth();
  const [patients, setPatients] = useState<MembershipPatient[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedPatient, setSelectedPatient] = useState<string | null>(null);
  const [filters, setFilters] = useState<FilterState>({
    declined: true,
    pending: false
  });

  // Fetch data when filters change
  useEffect(() => {
    fetchMembershipIssues();
  }, [filters]);

  const fetchPatientIds = async (mappedPatientIds: string[]) => {
    const batchSize = 100; // Process 100 IDs at a time
    const patientIdMap = new Map<string, string>();

    for (let i = 0; i < mappedPatientIds.length; i += batchSize) {
      const batch = mappedPatientIds.slice(i, i + batchSize);
      const { data, error } = await supabase
        .from('patients')
        .select('id, patient_id')
        .in('id', batch);

      if (error) {
        console.error('Error fetching patient batch:', error);
        continue;
      }

      data?.forEach(p => patientIdMap.set(p.id, p.patient_id));
    }

    return patientIdMap;
  };

  const fetchMembershipIssues = async () => {
    try {
      setLoading(true);
      const { data, error } = await supabase
        .from('membership_patients')
        .select(`
          id,
          external_id,
          first_name,
          last_name,
          mapped_patient_id,
          mapping_status,
          memberships (
            id,
            membership_name,
            status,
            charge_amount,
            date_created,
            start_date,
            internal_interval,
            last_run,
            last_run_status,
            next_run_date,
            ignore
          )
        `)
        .eq('mapping_status', 'mapped')
        .order('last_name', { ascending: true });

      if (error) throw error;

      // Get mapped patient IDs and fetch their external IDs in batches
      const mappedPatientIds = data?.map(p => p.mapped_patient_id).filter(Boolean) || [];
      const patientIdMap = await fetchPatientIds(mappedPatientIds);

      // Filter and map the data
      const patientsWithIssues = data
        ?.filter(patient => 
          patient.memberships.some(m => {
            if (m.status === 'inactive' || m.ignore) return false;
            return (
              (filters.declined && m.last_run_status === 'declined') ||
              (filters.pending && m.last_run_status === 'pending')
            );
          })
        )
        .map(patient => ({
          id: patient.id,
          patient_id: patient.external_id,
          mapped_patient_id: patient.mapped_patient_id,
          external_patient_id: patientIdMap.get(patient.mapped_patient_id) || null,
          first_name: patient.first_name,
          last_name: patient.last_name,
          memberships: patient.memberships
            .filter(m => !m.ignore && m.status !== 'inactive')
            .map(m => ({
              id: m.id,
              name: m.membership_name,
              status: m.status,
              charge_amount: m.charge_amount,
              date_created: m.date_created,
              start_date: m.start_date,
              internal_interval: m.internal_interval,
              last_run: m.last_run,
              last_run_status: m.last_run_status,
              next_run_date: m.next_run_date,
              ignore: m.ignore
            }))
        })) || [];

      setPatients(patientsWithIssues);
    } catch (error) {
      console.error('Error fetching membership issues:', error);
    } finally {
      setLoading(false);
    }
  };

  const toggleFilter = (filterName: keyof FilterState) => {
    setFilters(prev => ({
      ...prev,
      [filterName]: !prev[filterName]
    }));
  };

  const filteredPatients = patients.filter(patient => {
    const searchLower = searchTerm.toLowerCase();
    return (
      patient.first_name.toLowerCase().includes(searchLower) ||
      patient.last_name.toLowerCase().includes(searchLower)
    );
  });

  const handlePatientClick = (patient: MembershipPatient) => {
    if (patient.external_patient_id) {
      setSelectedPatient(patient.external_patient_id);
    } else {
      console.error('No external patient ID found for mapped patient:', patient.mapped_patient_id);
    }
  };

  return (
    <div className="min-h-screen flex flex-col bg-gradient-to-br from-green-50 to-blue-50">
      {/* Fixed Header */}
      <nav className="bg-white shadow-sm">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16">
            <div className="flex items-center">
              <Leaf className="h-6 w-6 text-green-600" />
              <div className="ml-2">
                <span className="text-xl font-semibold text-gray-900">THRIVE</span>
                <span className="ml-2 text-sm text-gray-500">Membership Issues</span>
              </div>
            </div>
            <div className="flex items-center space-x-4">
              <span className="text-sm text-gray-700">{user?.email}</span>
              <div className="flex flex-wrap gap-2">
                {userRoles.map((role) => (
                  <span
                    key={role}
                    className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800"
                  >
                    {role}
                  </span>
                ))}
              </div>
              <button
                onClick={() => signOut()}
                className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-green-600 hover:bg-green-700"
              >
                <LogOut className="h-4 w-4 mr-2" />
                Sign Out
              </button>
            </div>
          </div>
        </div>
      </nav>

      {/* Fixed Search Bar */}
      <div className="bg-white shadow-sm">
        <div className="max-w-7xl mx-auto px-4 py-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between">
            <button
              onClick={() => navigate('/dashboard')}
              className="mr-4 text-gray-600 hover:text-gray-900"
            >
              <X className="h-6 w-6" />
            </button>
            <div className="flex-1 relative max-w-2xl ml-4">
              <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
              <input
                type="text"
                placeholder="Search by name..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="pl-10 pr-4 py-2 w-full rounded-lg border border-gray-300 focus:ring-2 focus:ring-green-500 focus:border-transparent"
              />
            </div>
          </div>
        </div>
      </div>

      {/* Scrollable Content Area */}
      <div className="flex-1 overflow-hidden">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 h-full py-6">
          <div className="bg-white shadow-lg rounded-lg h-[calc(100vh-12rem)] flex flex-col">
            <div className="px-6 py-5 border-b border-gray-200">
              <div className="flex flex-col space-y-4">
                <div className="flex items-center">
                  <Clock className="h-5 w-5 text-red-600" />
                  <h3 className="ml-2 text-lg font-medium text-gray-900">
                    Payment Issues ({filteredPatients.length})
                  </h3>
                </div>

                {/* Filter Toggles */}
                <div className="flex gap-3">
                  <button
                    onClick={() => toggleFilter('declined')}
                    className={`inline-flex items-center px-4 py-2 rounded-lg text-sm font-medium transition-colors duration-200 ${
                      filters.declined
                        ? 'bg-red-100 text-red-800 border-2 border-red-200'
                        : 'bg-gray-100 text-gray-700 border-2 border-gray-200 hover:bg-gray-200'
                    }`}
                  >
                    <X className={`h-4 w-4 mr-2 ${filters.declined ? 'text-red-600' : 'text-gray-500'}`} />
                    Declined
                  </button>
                  <button
                    onClick={() => toggleFilter('pending')}
                    className={`inline-flex items-center px-4 py-2 rounded-lg text-sm font-medium transition-colors duration-200 ${
                      filters.pending
                        ? 'bg-yellow-100 text-yellow-800 border-2 border-yellow-200'
                        : 'bg-gray-100 text-gray-700 border-2 border-gray-200 hover:bg-gray-200'
                    }`}
                  >
                    <Clock3 className={`h-4 w-4 mr-2 ${filters.pending ? 'text-yellow-600' : 'text-gray-500'}`} />
                    Pending
                  </button>
                </div>
              </div>
            </div>

            <div className="flex-1 overflow-hidden">
              <div className="h-full overflow-auto">
                {loading ? (
                  <div className="flex items-center justify-center h-full">
                    <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-green-600"></div>
                  </div>
                ) : filteredPatients.length === 0 ? (
                  <div className="flex flex-col items-center justify-center h-full text-gray-500">
                    <AlertCircle className="h-12 w-12 mb-4" />
                    <p className="text-lg">No payment issues found</p>
                  </div>
                ) : (
                  <div className="grid grid-cols-1 gap-4 p-6">
                    {filteredPatients.map((patient) => (
                      <div
                        key={patient.patient_id}
                        onClick={() => handlePatientClick(patient)}
                        className="bg-white border border-red-200 rounded-lg p-4 hover:shadow-md transition-shadow duration-200 cursor-pointer"
                      >
                        <div className="flex justify-between items-start mb-4">
                          <div>
                            <h4 className="text-lg font-medium text-gray-900">
                              {patient.last_name}, {patient.first_name}
                            </h4>
                          </div>
                        </div>
                        <MembershipStatus memberships={patient.memberships} expanded={true} />
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Patient Details Modal */}
      <PatientDetailsModal
        patientId={selectedPatient || ''}
        isOpen={!!selectedPatient}
        onClose={() => setSelectedPatient(null)}
      />
    </div>
  );
}