import { supabase } from '../../lib/supabase';
import { ProcessingProgress, ProcessingResult } from '../dataImportProcessor';

interface PatientData {
  patient_id: string;
  first_name: string;
  last_name: string;
  sex: string;
  date_of_birth: string; // Added to match schema requirement
}

interface AppointmentData {
  appointment_id: string;
  external_patient_id: string;
  appointment_date: string;
  appointment_time: string;
  appointment_type: string;
  rendering_provider: string;
  service_department: string;
  cancelled_date: string | null;
  notes: string | null;
}

export async function processAppointmentsReport(
  lines: string[],
  onProgress?: (progress: ProcessingProgress) => void
): Promise<ProcessingResult> {
  try {
    const headers = lines[1].split(',').map(header => header.trim().toLowerCase());
    const totalLines = lines.length - 2; // Subtract header rows
    let processedCount = 0;
    let skippedCount = 0;
    let errorCount = 0;

    // Extract all patient and appointment data first
    const allPatients: PatientData[] = [];
    const allAppointments: AppointmentData[] = [];
    const patientIds = new Set<string>();
    const duplicatesInFile = new Set<string>();

    // First pass: collect unique patients and appointments
    for (let i = 2; i < lines.length; i++) {
      const values = lines[i].split(',').map(value => value.trim());
      
      if (values.length !== headers.length) {
        continue; // Skip malformed rows
      }

      const patientId = values[headers.indexOf('patientid')];

      // Add appointment data
      allAppointments.push({
        appointment_id: values[headers.indexOf('apptid')],
        external_patient_id: patientId,
        appointment_date: values[headers.indexOf('apptdate')],
        appointment_time: values[headers.indexOf('apptstarttime')],
        appointment_type: values[headers.indexOf('appttype')],
        rendering_provider: values[headers.indexOf('rndrng prvdr')],
        service_department: values[headers.indexOf('svc dprtmnt')] || '',
        cancelled_date: values[headers.indexOf('apptcancelleddate')] || null,
        notes: values[headers.indexOf('apptnote')] || null
      });

      // Only add patient if not already seen in this file
      if (!patientIds.has(patientId)) {
        patientIds.add(patientId);
        allPatients.push({
          patient_id: patientId,
          first_name: values[headers.indexOf('patient firstname')],
          last_name: values[headers.indexOf('patient lastname')],
          sex: values[headers.indexOf('patientsex')],
          date_of_birth: new Date().toISOString().split('T')[0] // Default value for required field
        });
      } else {
        duplicatesInFile.add(patientId);
      }

      // Report progress for data extraction
      if (onProgress) {
        onProgress({
          currentLine: i - 1,
          totalLines,
          processedCount,
          skippedCount
        });
      }
    }

    // Process patients in batches using upsert
    const BATCH_SIZE = 50;
    const existingPatientMap = new Map<string, string>();

    for (let i = 0; i < allPatients.length; i += BATCH_SIZE) {
      const batch = allPatients.slice(i, i + BATCH_SIZE);
      
      try {
        // First, upsert patients to ensure they exist
        const { error: upsertError } = await supabase
          .from('patients')
          .upsert(
            batch.map(p => ({
              patient_id: p.patient_id,
              first_name: p.first_name,
              last_name: p.last_name,
              sex: p.sex,
              date_of_birth: p.date_of_birth
            })),
            { onConflict: 'patient_id' }
          );

        if (upsertError) {
          console.error('Error upserting patients:', upsertError);
          errorCount++;
          continue;
        }

        // Then get all patient IDs for this batch
        const { data: batchPatients, error: selectError } = await supabase
          .from('patients')
          .select('id, patient_id')
          .in('patient_id', batch.map(p => p.patient_id));

        if (selectError) {
          console.error('Error fetching patient IDs:', selectError);
          continue;
        }

        // Update the patient map
        batchPatients?.forEach(p => {
          existingPatientMap.set(p.patient_id, p.id);
        });

      } catch (error: any) {
        console.error('Error processing patient batch:', error);
        errorCount++;
      }
    }

    // Process appointments in batches
    let appointmentsProcessed = 0;
    let appointmentsSkipped = 0;

    // Get all existing appointments for these patients in one query
    const { data: existingAppointments, error: existingAppointmentsError } = await supabase
      .from('appointments')
      .select('appointment_id')
      .in('external_patient_id', Array.from(patientIds));

    // Create a Set of existing appointments for fast lookup
    const existingAppointmentsSet = new Set<string>();
    if (!existingAppointmentsError && existingAppointments) {
      existingAppointments.forEach(appointment => {
        existingAppointmentsSet.add(appointment.appointment_id);
      });
    }

    // Process appointments in batches
    for (let i = 0; i < allAppointments.length; i += BATCH_SIZE) {
      const batch = allAppointments.slice(i, i + BATCH_SIZE);
      const appointmentsToInsert = batch
        .map(appointment => ({
          ...appointment,
          patient_id: existingPatientMap.get(appointment.external_patient_id),
          appointment_date: new Date(appointment.appointment_date).toISOString().split('T')[0],
          cancelled_date: appointment.cancelled_date 
            ? new Date(appointment.cancelled_date).toISOString().split('T')[0]
            : null
        }))
        .filter(appointment => appointment.patient_id); // Only include appointments where we have a valid patient_id

      if (appointmentsToInsert.length > 0) {
        try {
          const { error: insertError } = await supabase
            .from('appointments')
            .upsert(appointmentsToInsert, { onConflict: 'appointment_id' });

          if (insertError) {
            console.error('Error inserting appointments:', insertError);
            appointmentsSkipped += appointmentsToInsert.length;
          } else {
            appointmentsProcessed += appointmentsToInsert.length;
          }
        } catch (error) {
          console.error('Error processing appointments batch:', error);
          appointmentsSkipped += appointmentsToInsert.length;
        }
      } else {
        appointmentsSkipped += batch.length;
      }

      // Report progress
      if (onProgress) {
        onProgress({
          currentLine: totalLines,
          totalLines,
          processedCount: appointmentsProcessed,
          skippedCount: appointmentsSkipped
        });
      }
    }

    // Record import history
    try {
      await supabase.from('import_history').insert({
        report_type: 'ACCESS_DATA_VISITS',
        file_name: 'ACCESS Data - WtgMgt VISITS',
        processed_count: appointmentsProcessed,
        skipped_count: appointmentsSkipped
      });
    } catch (error) {
      console.error('Error recording import history:', error);
    }

    let message = `Processed ${appointmentsProcessed} new appointments`;
    if (appointmentsSkipped > 0) {
      message += ` (${appointmentsSkipped} appointments skipped)`;
    }
    if (duplicatesInFile.size > 0) {
      message += ` (${duplicatesInFile.size} duplicate patients in file)`;
    }
    if (errorCount > 0) {
      message += ` (${errorCount} errors occurred)`;
    }

    return {
      success: true,
      message,
      processedCount: appointmentsProcessed,
      skippedCount: appointmentsSkipped
    };
  } catch (error: any) {
    return {
      success: false,
      message: `Error processing file: ${error.message}`,
      processedCount: 0,
      skippedCount: 0
    };
  }
}