export function parseChargeAmount(value: any): number {
  if (!value) return 0;

  // If it's already a number, return it
  if (typeof value === 'number') return value;

  // If it's a string, clean it and parse it
  if (typeof value === 'string') {
    // Remove currency symbols, commas, and whitespace
    const cleanValue = value.replace(/[$,\s]/g, '');
    
    // Try to parse as float
    const amount = parseFloat(cleanValue);
    
    // Return the amount if valid, otherwise 0
    return isNaN(amount) ? 0 : Math.abs(amount);
  }

  return 0;
}