import React, { useState, useEffect, useRef } from 'react';
import { 
  X, 
  User, 
  Calendar, 
  Scale, 
  Activity, 
  Leaf, 
  TrendingDown, 
  Pill, 
  Ruler, 
  Clock, 
  CalendarClock,
  FileText,
  AlertCircle,
  CreditCard,
  DollarSign,
  CalendarDays,
  RotateCcw,
  CheckCircle2,
  XCircle,
  Clock3,
  Check,
  Edit2,
  ArrowLeft
} from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../lib/supabase';
import { generatePatientSummaryPDF } from '../utils/pdfGenerator';
import { WeightEditModal } from './WeightEditModal';
import {
  formatPacificDate,
  formatPacificTime,
  formatPacificDateTime,
  getCurrentPacificDate,
  getPacificDateForQuery,
  toPacificTime
} from '../utils/dateTime';

interface PatientDetails {
  id: uuid;
  patient_id: string;
  first_name: string;
  last_name: string;
  date_of_birth: string;
  sex: string;
  first_visit: string | null;
  last_visit: string | null;
  first_weight: number | null;
  last_weight: number | null;
  lowest_weight: number | null;
  weight_loss: number | null;
  activity_status: 'active' | 'possibly_inactive' | 'inactive' | 'future_patient';
  has_future_appointments: boolean;
  last_body_comp_date: string | null;
  weeks_since_body_comp: number | null;
  body_comp_overdue: boolean;
  memberships?: Array<{
    name: string;
    status: string;
    charge_amount?: number;
    date_created?: string;
    start_date?: string;
    internal_interval?: string;
    last_run?: string;
    last_run_status?: string;
    next_run_date?: string;
  }>;
}

interface NextAppointment {
  appointment_date: string;
  appointment_time: string;
  appointment_type: string;
  rendering_provider: string;
  service_department: string;
}

interface VisitHistory {
  visit_date: string;
  medications: Array<{
    procedure_code: string;
    description: string | null;
    units: number;
  }>;
  weight: number | null;
  has_body_composition: boolean;
}

interface WeightEdit {
  date: string;
  weight: number;
}

interface PatientDetailsModalProps {
  patientId: string;
  isOpen: boolean;
  onClose: () => void;
}

export function PatientDetailsModal({ patientId, isOpen, onClose }: PatientDetailsModalProps) {
  const navigate = useNavigate();
  const [patient, setPatient] = useState<PatientDetails | null>(null);
  const [nextAppointment, setNextAppointment] = useState<NextAppointment | null>(null);
  const [visitHistory, setVisitHistory] = useState<VisitHistory[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [editingWeight, setEditingWeight] = useState<WeightEdit | null>(null);
  const [updateError, setUpdateError] = useState<string | null>(null);
  const [updateSuccess, setUpdateSuccess] = useState<string | null>(null);
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!isOpen) {
      setPatient(null);
      setNextAppointment(null);
      setVisitHistory([]);
      setLoading(true);
      setError(null);
      setEditingWeight(null);
      setUpdateError(null);
      setUpdateSuccess(null);
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen && patientId) {
      fetchPatientData();
    }
  }, [isOpen, patientId]);

  useEffect(() => {
    const handleEscape = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    const handleClickOutside = (event: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('keydown', handleEscape);
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('keydown', handleEscape);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, onClose]);

  const fetchPatientData = async () => {
    try {
      const { data: patientData, error: patientError } = await supabase
        .rpc('get_patient_details', { p_patient_id: patientId });

      if (patientError) throw patientError;
      if (!patientData || patientData.length === 0) {
        setError('Patient not found');
        return;
      }

      setPatient(patientData[0]);

      const { data: appointmentData, error: appointmentError } = await supabase
        .rpc('get_next_appointment', { p_patient_id: patientId });

      if (appointmentError) throw appointmentError;
      setNextAppointment(appointmentData[0] || null);

      const { data: historyData, error: historyError } = await supabase
        .rpc('get_patient_visit_history', { p_patient_id: patientId });

      if (historyError) throw historyError;
      setVisitHistory(historyData || []);
    } catch (error: any) {
      console.error('Error fetching patient data:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleWeightUpdate = async (newWeight: number) => {
    if (!editingWeight) return;

    try {
      const { error } = await supabase
        .from('weight_measurements')
        .update({ weight: newWeight })
        .eq('external_patient_id', patientId)
        .eq('measurement_date', editingWeight.date);

      if (error) throw error;

      await fetchPatientData();
      setUpdateSuccess('Weight updated successfully');
      setTimeout(() => setUpdateSuccess(null), 3000);
    } catch (error: any) {
      console.error('Error updating weight:', error);
      setUpdateError('Failed to update weight');
      setTimeout(() => setUpdateError(null), 3000);
    }
  };

  const handleGeneratePDF = () => {
    if (patient) {
      generatePatientSummaryPDF(patient, nextAppointment, visitHistory);
    }
  };

  const formatCurrency = (amount: number | undefined): string => {
    if (!amount) return 'N/A';
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    }).format(amount);
  };

  const calculateWeightLossPercentage = () => {
    if (!patient?.first_weight || !patient?.weight_loss) return null;
    return (patient.weight_loss / patient.first_weight) * 100;
  };

  const getActivityStatusDetails = (status: string) => {
    switch (status) {
      case 'active':
        return {
          label: 'Active Patient',
          color: 'text-green-100',
          bgColor: 'bg-green-600/10',
          gradient: 'from-green-600 to-blue-600'
        };
      case 'possibly_inactive':
        return {
          label: 'Possibly Inactive',
          color: 'text-yellow-100',
          bgColor: 'bg-yellow-600/10',
          gradient: 'from-yellow-600 to-orange-600'
        };
      case 'inactive':
        return {
          label: 'Inactive Patient',
          color: 'text-red-100',
          bgColor: 'bg-red-600/10',
          gradient: 'from-red-600 to-pink-600'
        };
      case 'future_patient':
        return {
          label: 'Future Patient',
          color: 'text-blue-100',
          bgColor: 'bg-blue-600/10',
          gradient: 'from-blue-600 to-indigo-600'
        };
      default:
        return {
          label: 'Unknown Status',
          color: 'text-gray-100',
          bgColor: 'bg-gray-600/10',
          gradient: 'from-gray-600 to-gray-600'
        };
    }
  };

  if (!isOpen) return null;

  if (loading) {
    return (
      <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-[50]">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-green-600"></div>
      </div>
    );
  }

  if (error || !patient) {
    return (
      <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-[50]">
        <div className="bg-white rounded-lg p-6 max-w-md w-full">
          <div className="text-center">
            <h2 className="text-xl font-semibold text-gray-900 mb-2">Error</h2>
            <p className="text-gray-600">{error || 'Failed to load patient details'}</p>
            <button
              onClick={onClose}
              className="mt-4 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700"
            >
              <X className="h-4 w-4 mr-2" />
              Close
            </button>
          </div>
        </div>
      </div>
    );
  }

  const weightLossPercentage = calculateWeightLossPercentage();
  const activityStatus = getActivityStatusDetails(patient.activity_status);

  return (
    <div 
      className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center p-4 z-[50]"
      onClick={(e) => {
        if (e.target === e.currentTarget) onClose();
      }}
    >
      <div 
        ref={modalRef}
        className={`bg-white rounded-lg shadow-xl max-w-6xl w-full max-h-[90vh] overflow-hidden transform transition-all duration-300 ease-out relative z-[51] ${
          isOpen 
            ? 'translate-y-0 scale-100 opacity-100' 
            : 'translate-y-4 scale-95 opacity-0'
        }`}
        onClick={(e) => e.stopPropagation()}
      >
        {/* Header */}
        <div className="px-6 py-4 bg-gradient-to-r from-green-600 to-blue-600">
          <div className="flex items-center justify-between">
            <h2 className="text-2xl font-bold text-white">
              {patient.first_name} {patient.last_name}
            </h2>
            <div className="flex items-center space-x-4">
              <button
                onClick={handleGeneratePDF}
                className="bg-white/10 backdrop-blur-sm rounded-lg px-4 py-2 text-white hover:bg-white/20"
              >
                <FileText className="h-5 w-5 inline mr-2" />
                Generate PDF
              </button>
              {patient.weight_loss && patient.weight_loss > 0 && (
                <div className="bg-white/10 backdrop-blur-sm rounded-lg px-4 py-2 flex items-center">
                  <TrendingDown className="h-5 w-5 text-green-200 mr-2" />
                  <span className="text-white font-semibold">
                    {patient.weight_loss.toFixed(1)} lbs lost
                  </span>
                </div>
              )}
              {weightLossPercentage && weightLossPercentage >= 5 && (
                <div className="bg-white/10 backdrop-blur-sm rounded-lg px-4 py-2 flex items-center">
                  <Activity className="h-5 w-5 text-green-200 mr-2" />
                  <span className="text-white font-semibold">
                    {weightLossPercentage.toFixed(1)}% reduction
                  </span>
                </div>
              )}
              <div className={`${activityStatus.bgColor} backdrop-blur-sm rounded-lg px-4 py-2 flex items-center`}>
                <Clock className={`h-5 w-5 ${activityStatus.color} mr-2`} />
                <span className={`font-semibold ${activityStatus.color}`}>
                  {activityStatus.label}
                </span>
              </div>
              <button
                onClick={onClose}
                className="text-white hover:text-gray-200"
              >
                <X className="h-6 w-6" />
              </button>
            </div>
          </div>
          <p className="text-green-100 mt-1">Patient ID: {patient.patient_id}</p>
        </div>

        {/* Content */}
        <div className="p-6 overflow-y-auto max-h-[calc(90vh-80px)]">
          <div className="space-y-6">
            {/* Stats Grid */}
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              <div className="bg-gray-50 p-4 rounded-lg">
                <h3 className="text-sm font-medium text-gray-500">Weight Progress</h3>
                <div className="mt-2 space-y-1">
                  <p className="text-lg font-semibold">
                    {patient?.first_weight ? `${patient.first_weight.toFixed(1)} lbs → ` : 'N/A'}
                    {patient?.last_weight ? `${patient.last_weight.toFixed(1)} lbs` : 'N/A'}
                  </p>
                  {patient?.weight_loss && patient.weight_loss > 0 && (
                    <p className="text-green-600">
                      {patient.weight_loss.toFixed(1)} lbs lost
                      {weightLossPercentage && ` (${weightLossPercentage.toFixed(1)}%)`}
                    </p>
                  )}
                </div>
              </div>

              <div className="bg-gray-50 p-4 rounded-lg">
                <h3 className="text-sm font-medium text-gray-500">Body Composition</h3>
                <div className="mt-2">
                  {patient?.last_body_comp_date ? (
                    <>
                      <p>Last measured: {formatPacificDate(patient.last_body_comp_date)}</p>
                      <p className={patient.body_comp_overdue ? 'text-red-600' : 'text-green-600'}>
                        {patient.weeks_since_body_comp} weeks ago
                      </p>
                    </>
                  ) : (
                    <p>No measurements recorded</p>
                  )}
                </div>
              </div>

              <div className="bg-gray-50 p-4 rounded-lg">
                <h3 className="text-sm font-medium text-gray-500">Next Appointment</h3>
                <div className="mt-2">
                  {nextAppointment ? (
                    <>
                      <p className="font-semibold">
                        {formatPacificDate(nextAppointment.appointment_date)}
                      </p>
                      <p className="text-sm text-gray-600">
                        {nextAppointment.appointment_type} with {nextAppointment.rendering_provider}
                      </p>
                    </>
                  ) : (
                    <p>No upcoming appointments</p>
                  )}
                </div>
              </div>
            </div>

            {/* Membership Information Card */}
            <div className="bg-white shadow-lg rounded-lg overflow-hidden lg:col-span-2">
              <div className="px-6 py-5 border-b border-gray-200">
                <div className="flex items-center">
                  <CreditCard className="h-5 w-5 text-green-600" />
                  <h3 className="ml-2 text-lg font-medium text-gray-900">Membership Information</h3>
                </div>
              </div>
              <div className="px-6 py-5">
                {patient?.memberships && patient.memberships.length > 0 ? (
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    {patient.memberships.map((membership, index) => (
                      <div 
                        key={index}
                        className={`bg-gray-50 rounded-lg p-6 border-l-4 ${
                          membership.status === 'active'
                            ? 'border-green-500'
                            : membership.status === 'cancelled'
                            ? 'border-red-500'
                            : membership.status === 'pending'
                            ? 'border-yellow-500'
                            : 'border-gray-500'
                        }`}
                      >
                        <div className="flex items-center justify-between mb-4">
                          <h4 className="text-lg font-semibold text-gray-900">{membership.name}</h4>
                          <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                            membership.status === 'active'
                              ? 'bg-green-100 text-green-800'
                              : membership.status === 'cancelled'
                              ? 'bg-red-100 text-red-800'
                              : membership.status === 'pending'
                              ? 'bg-yellow-100 text-yellow-800'
                              : 'bg-gray-100 text-gray-800'
                          }`}>
                            {membership.status === 'active' && <CheckCircle2 className="h-3 w-3 mr-1" />}
                            {membership.status === 'cancelled' && <XCircle className="h-3 w-3 mr-1" />}
                            {membership.status === 'pending' && <Clock3 className="h-3 w-3 mr-1" />}
                            {membership.status.charAt(0).toUpperCase() + membership.status.slice(1)}
                          </span>
                        </div>

                        <div className="space-y-3">
                          {membership.charge_amount && (
                            <div className="flex items-center text-sm">
                              <DollarSign className="h-4 w-4 text-gray-400 mr-2" />
                              <span className="text-gray-600">Charge Amount:</span>
                              <span className="ml-2 font-medium text-gray-900">
                                {formatCurrency(membership.charge_amount)}
                              </span>
                            </div>
                          )}

                          {membership.date_created && (
                            <div className="flex items-center text-sm">
                              <Calendar className="h-4 w-4 text-gray-400 mr-2" />
                              <span className="text-gray-600">Created:</span>
                              <span className="ml-2 font-medium text-gray-900">
                                {formatPacificDate(membership.date_created)}
                              </span>
                            </div>
                          )}

                          {membership.start_date && (
                            <div className="flex items-center text-sm">
                              <CalendarDays className="h-4 w-4 text-gray-400 mr-2" />
                              <span className="text-gray-600">
                                {toPacificTime(membership.start_date) > getCurrentPacificDate() ? 'To start:' : 'Started:'}
                              </span>
                              <span className="ml-2 font-medium text-gray-900">
                                {formatPacificDate(membership.start_date)}
                              </span>
                            </div>
                          )}

                          {membership.internal_interval && (
                            <div className="flex items-center text-sm">
                              <Clock className="h-4 w-4 text-gray-400 mr-2" />
                              <span className="text-gray-600">Interval:</span>
                              <span className="ml-2 font-medium text-gray-900">
                                {membership.internal_interval}
                              </span>
                            </div>
                          )}

                          {membership.last_run && (
                            <div className="flex items-center text-sm">
                              <RotateCcw className="h-4 w-4 text-gray-400 mr-2" />
                              <span className="text-gray-600">Last Run:</span>
                              <span className="ml-2 font-medium text-gray-900">
                                {formatPacificDate(membership.last_run)}
                              </span>
                              {membership.last_run_status && (
                                <span className={`ml-2 px-2 py-0.5 rounded text-xs font-medium ${
                                  membership.last_run_status === 'success'
                                    ? 'bg-green-100 text-green-800'
                                    : membership.last_run_status === 'failed'
                                    ? 'bg-red-100 text-red-800'
                                    : 'bg-gray-100 text-gray-800'
                                }`}>
                                  {membership.last_run_status}
                                </span>
                              )}
                            </div>
                          )}

                          {membership.next_run_date && membership.status === 'active' && (
                            <div className="flex items-center text-sm">
                              <CalendarClock className="h-4 w-4 text-gray-400 mr-2" />
                              <span className="text-gray-600">Next Billing Date:</span>
                              <span className="ml-2 font-medium text-gray-900">
                                {formatPacificDate(membership.next_run_date)}
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <p className="text-gray-500 text-center py-4">
                    No membership information found
                  </p>
                )}
              </div>
            </div>

            {/* Visit History */}
            <div className="bg-white shadow-lg rounded-lg overflow-hidden">
              <div className="px-6 py-5 border-b border-gray-200">
                <div className="flex items-center">
                  <Calendar className="h-5 w-5 text-green-600" />
                  <h3 className="ml-2 text-lg font-medium text-gray-900">Visit Details</h3>
                </div>
              </div>
              <div className="px-6 py-5">
                <div className="flow-root">
                  <ul className="-mb-8">
                    {visitHistory.map((visit, visitIdx) => (
                      <li key={visit.visit_date}>
                        <div className="relative pb-8">
                          {visitIdx !== visitHistory.length - 1 ? (
                            <span
                              className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
                              aria-hidden="true"
                            />
                          ) : null}
                          <div className="relative flex space-x-3">
                            <div>
                              <span className="h-8 w-8 rounded-full bg-green-100 flex items-center justify-center ring-8 ring-white">
                                <Calendar className="h-4 w-4 text-green-600" />
                              </span>
                            </div>
                            <div className="flex-1 min-w-0">
                              <div className="text-sm font-medium text-gray-900">
                                {formatPacificDate(visit.visit_date)}
                              </div>
                              <div className="mt-2 space-y-2">
                                {visit.medications.length > 0 && (
                                  <div className="flex items-center text-sm text-gray-500">
                                    <Pill className="h-4 w-4 text-green-500 mr-2" />
                                    <span>
                                      Medications: {visit.medications.map(med => {
                                        const description = med.description || med.procedure_code;
                                        return med.units > 1 
                                          ? `${description} (${med.units} units)`
                                          : description;
                                      }).join(', ')}
                                    </span>
                                  </div>
                                )}
                                {visit.weight && (
                                  <div className="flex items-center text-sm text-gray-500">
                                    <Scale className="h-4 w-4 text-green-500 mr-2" />
                                    <span>Weight: {visit.weight.toFixed(1)} lbs</span>
                                    <button
                                      onClick={() => setEditingWeight({
                                        date: visit.visit_date,
                                        weight: visit.weight!
                                      })}
                                      className="ml-2 text-green-600 hover:text-green-700"
                                    >
                                      <Edit2 className="h-3 w-3" />
                                    </button>
                                  </div>
                                )}
                                {visit.has_body_composition && (
                                  <div className="flex items-center text-sm text-gray-500">
                                    <Ruler className="h-4 w-4 text-green-500 mr-2" />
                                    <span>Body composition measurement taken</span>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {editingWeight && (
        <WeightEditModal
          isOpen={true}
          onClose={() => setEditingWeight(null)}
          onConfirm={handleWeightUpdate}
          currentWeight={editingWeight.weight}
          date={editingWeight.date}
        />
      )}

      {/* Status Messages */}
      {(updateSuccess || updateError) && (
        <div className={`fixed bottom-4 right-4 p-4 rounded-lg shadow-lg z-[52] ${
          updateSuccess ? 'bg-green-50 text-green-800' : 'bg-red-50 text-red-800'
        }`}>
          <div className="flex items-center">
            {updateSuccess ? (
              <Check className="h-5 w-5 mr-2" />
            ) : (
              <AlertCircle className="h-5 w-5 mr-2" />
            )}
            <p>{updateSuccess || updateError}</p>
          </div>
        </div>
      )}
    </div>
  );
}