import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft, Plus, Check, X, Save, AlertCircle, Leaf } from 'lucide-react';
import { supabase } from '../lib/supabase';

interface ProcedureCode {
  code: string;
  description: string;
  milligrams: number;
  form: 'vial' | 'syringe' | 'injection' | 'none';
  medication_type: 'semaglutide' | 'tirzepatide' | 'vitamin_b12' | 'none';
}

interface UniqueProcedureCode {
  code: string;
  mapped: boolean;
  description?: string;
  milligrams?: number;
  form?: 'vial' | 'syringe' | 'injection' | 'none';
  medication_type?: 'semaglutide' | 'tirzepatide' | 'vitamin_b12' | 'none';
}

export function ProcedureCodes() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [procedureCodes, setProcedureCodes] = useState<UniqueProcedureCode[]>([]);
  const [editingCode, setEditingCode] = useState<string | null>(null);
  const [formData, setFormData] = useState<ProcedureCode>({
    code: '',
    description: '',
    milligrams: 0,
    form: 'none',
    medication_type: 'none'
  });
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const descriptionInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    fetchProcedureCodes();
  }, []);

  useEffect(() => {
    // Focus the description input when editing starts
    if (editingCode && descriptionInputRef.current) {
      descriptionInputRef.current.focus();
    }
  }, [editingCode]);

  const fetchProcedureCodes = async () => {
    try {
      // First get all unique codes
      const { data: uniqueCodes, error: uniqueError } = await supabase.rpc('get_unique_procedure_codes');
      if (uniqueError) throw uniqueError;

      // Then get all mapped codes
      const { data: mappedCodes, error: mappedError } = await supabase
        .from('procedure_codes')
        .select('*');
      if (mappedError) throw mappedError;

      // Combine the data
      const combinedCodes = uniqueCodes.map(code => {
        const mappedCode = mappedCodes?.find(mc => mc.code === code.code);
        return {
          ...code,
          description: mappedCode?.description,
          milligrams: mappedCode?.milligrams,
          form: mappedCode?.form,
          medication_type: mappedCode?.medication_type
        };
      });

      setProcedureCodes(combinedCodes || []);
    } catch (error: any) {
      console.error('Error fetching procedure codes:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = async (code: string) => {
    const existingCode = procedureCodes.find(pc => pc.code === code);
    
    setFormData({
      code,
      description: existingCode?.description || '',
      milligrams: existingCode?.milligrams || 0,
      form: existingCode?.form || 'none',
      medication_type: existingCode?.medication_type || 'none'
    });
    
    setEditingCode(code);
  };

  const handleSave = async () => {
    try {
      setError(null);
      setSuccess(null);

      const { error } = await supabase
        .from('procedure_codes')
        .upsert({
          code: formData.code,
          description: formData.description,
          milligrams: formData.milligrams,
          form: formData.form,
          medication_type: formData.medication_type
        });

      if (error) throw error;

      setSuccess('Procedure code saved successfully');
      setEditingCode(null);
      fetchProcedureCodes();
    } catch (error: any) {
      console.error('Error saving procedure code:', error);
      setError(error.message);
    }
  };

  const handleCancel = () => {
    setEditingCode(null);
    setError(null);
    setSuccess(null);
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-green-50 to-blue-50">
      <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        <div className="px-4 py-4 sm:px-0">
          {/* Header Section */}
          <div className="bg-white shadow-lg rounded-lg mb-6 overflow-hidden">
            <div className="relative">
              <div className="absolute inset-0 bg-gradient-to-r from-green-600 to-blue-600 opacity-90"></div>
              <div className="relative px-8 py-12">
                <div className="flex items-center justify-between">
                  <button
                    onClick={() => navigate('/settings')}
                    className="text-white hover:text-green-100 transition-colors duration-200"
                  >
                    <ArrowLeft className="h-6 w-6" />
                  </button>
                  <Leaf className="h-12 w-12 text-white opacity-20" />
                </div>
                
                <div className="mt-4 text-center">
                  <h1 className="text-4xl font-bold text-white mb-2">THRIVE</h1>
                  <p className="text-green-100 text-lg mb-4">
                    Tracking Health and Reducing Illness Via Efficiency
                  </p>
                  <div className="w-16 h-1 bg-white/30 mx-auto rounded-full"></div>
                  <h2 className="text-2xl font-semibold text-white mt-4">Procedure Code Mapping</h2>
                </div>
              </div>
            </div>
          </div>

          {/* Main Content */}
          <div className="bg-white shadow-lg rounded-lg overflow-hidden">
            <div className="px-6 py-5 border-b border-gray-200">
              <h2 className="text-lg font-medium text-gray-900">
                Available Procedure Codes
              </h2>
              <p className="mt-1 text-sm text-gray-500">
                Map procedure codes to medication details
              </p>
            </div>

            {/* Messages */}
            {error && (
              <div className="mx-6 mt-4 p-4 bg-red-50 rounded-md">
                <div className="flex">
                  <AlertCircle className="h-5 w-5 text-red-400" />
                  <div className="ml-3">
                    <p className="text-sm text-red-700">{error}</p>
                  </div>
                </div>
              </div>
            )}

            {success && (
              <div className="mx-6 mt-4 p-4 bg-green-50 rounded-md">
                <div className="flex">
                  <Check className="h-5 w-5 text-green-400" />
                  <div className="ml-3">
                    <p className="text-sm text-green-700">{success}</p>
                  </div>
                </div>
              </div>
            )}

            {/* Procedure Codes List */}
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Code
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Status
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Description
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Milligrams
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Form
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Medication Type
                    </th>
                    <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {loading ? (
                    <tr>
                      <td colSpan={7} className="px-6 py-4 text-center text-gray-500">
                        Loading procedure codes...
                      </td>
                    </tr>
                  ) : procedureCodes.length === 0 ? (
                    <tr>
                      <td colSpan={7} className="px-6 py-4 text-center text-gray-500">
                        No procedure codes found
                      </td>
                    </tr>
                  ) : (
                    procedureCodes.map((code) => (
                      <tr key={code.code}>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          {code.code}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                            code.mapped
                              ? 'bg-green-100 text-green-800'
                              : 'bg-yellow-100 text-yellow-800'
                          }`}>
                            {code.mapped ? 'Mapped' : 'Unmapped'}
                          </span>
                        </td>
                        {editingCode === code.code ? (
                          <>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <input
                                ref={descriptionInputRef}
                                type="text"
                                value={formData.description}
                                onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                                className="w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                                placeholder="Enter description"
                              />
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <input
                                type="number"
                                value={formData.milligrams}
                                onChange={(e) => setFormData({ ...formData, milligrams: parseFloat(e.target.value) })}
                                className="w-24 rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                                step="0.01"
                              />
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <select
                                value={formData.form}
                                onChange={(e) => setFormData({ ...formData, form: e.target.value as 'vial' | 'syringe' | 'injection' | 'none' })}
                                className="rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                              >
                                <option value="none">None</option>
                                <option value="vial">Vial</option>
                                <option value="syringe">Syringe</option>
                                <option value="injection">Injection</option>
                              </select>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <select
                                value={formData.medication_type}
                                onChange={(e) => setFormData({ ...formData, medication_type: e.target.value as 'semaglutide' | 'tirzepatide' | 'vitamin_b12' | 'none' })}
                                className="rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                              >
                                <option value="none">None</option>
                                <option value="semaglutide">Semaglutide</option>
                                <option value="tirzepatide">Tirzepatide</option>
                                <option value="vitamin_b12">Vitamin B12</option>
                              </select>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium space-x-2">
                              <button
                                onClick={handleSave}
                                className="text-green-600 hover:text-green-900"
                              >
                                <Save className="h-5 w-5" />
                              </button>
                              <button
                                onClick={handleCancel}
                                className="text-gray-600 hover:text-gray-900"
                              >
                                <X className="h-5 w-5" />
                              </button>
                            </td>
                          </>
                        ) : (
                          <>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                              {code.description || '-'}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                              {code.milligrams || '-'}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                              {code.form === 'none' ? '-' : code.form || '-'}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                              {code.medication_type === 'none' ? '-' : code.medication_type || '-'}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                              <button
                                onClick={() => handleEdit(code.code)}
                                className="text-green-600 hover:text-green-900"
                              >
                                {code.mapped ? 'Edit' : 'Map'}
                              </button>
                            </td>
                          </>
                        )}
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}