import React from 'react';
import { Calendar, Scale, CreditCard, Loader2, AlertTriangle, CheckSquare, Square, Trash2 } from 'lucide-react';
import { formatPacificDate } from '../../utils/dateTime';
import { formatCurrency } from '../../utils/formatters';

interface PatientTableProps {
  loading: boolean;
  patients: any[];
  bulkDeleteMode: boolean;
  selectedPatients: Set<string>;
  showDeleteConfirm: string | null;
  onPatientSelect: (patientId: string) => void;
  onSelectAll: () => void;
  onPatientClick: (patientId: string) => void;
  onDeleteConfirm: (patientId: string) => void;
  onDeleteCancel: () => void;
  onDeleteRequest: (patientId: string) => void;
}

export function PatientTable({
  loading,
  patients,
  bulkDeleteMode,
  selectedPatients,
  showDeleteConfirm,
  onPatientSelect,
  onSelectAll,
  onPatientClick,
  onDeleteConfirm,
  onDeleteCancel,
  onDeleteRequest
}: PatientTableProps) {
  const handleRowClick = (patientId: string) => {
    if (bulkDeleteMode) {
      onPatientSelect(patientId);
    } else {
      onPatientClick(patientId);
    }
  };

  if (loading) {
    return (
      <div className="flex flex-col items-center justify-center py-12">
        <Loader2 className="h-8 w-8 text-green-500 animate-spin mb-2" />
        <p className="text-gray-500">Loading patient data...</p>
        <p className="text-xs text-gray-400 mt-2">This may take a moment for large datasets</p>
      </div>
    );
  }

  if (patients.length === 0) {
    return (
      <div className="flex flex-col items-center justify-center py-12">
        <AlertTriangle className="h-12 w-12 text-gray-300 mb-4" />
        <p className="text-gray-500 text-lg">No patients found</p>
      </div>
    );
  }

  return (
    <table className="min-w-full divide-y divide-gray-200">
      <thead className="bg-gray-50">
        <tr>
          {bulkDeleteMode && (
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              <div className="flex items-center">
                <button
                  onClick={onSelectAll}
                  className="text-gray-400 hover:text-gray-500"
                >
                  {selectedPatients.size === patients.length ? (
                    <CheckSquare className="h-4 w-4" />
                  ) : (
                    <Square className="h-4 w-4" />
                  )}
                </button>
              </div>
            </th>
          )}
          <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Last Name
          </th>
          <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            First Name
          </th>
          <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            First Visit
          </th>
          <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Last Visit
          </th>
          <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            First Weight
          </th>
          <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Last Weight
          </th>
          <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Membership
          </th>
          {!bulkDeleteMode && (
            <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
              Actions
            </th>
          )}
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200">
        {patients.map((patient) => (
          <tr 
            key={patient.id}
            className={`hover:bg-gray-50 transition-colors duration-150 cursor-pointer ${
              selectedPatients.has(patient.patient_id) ? 'bg-blue-50' : ''
            }`}
            onClick={() => handleRowClick(patient.patient_id)}
          >
            {bulkDeleteMode && (
              <td className="px-6 py-4 whitespace-nowrap" onClick={(e) => e.stopPropagation()}>
                <button
                  onClick={() => onPatientSelect(patient.patient_id)}
                  className="text-gray-400 hover:text-gray-500"
                >
                  {selectedPatients.has(patient.patient_id) ? (
                    <CheckSquare className="h-5 w-5 text-blue-500" />
                  ) : (
                    <Square className="h-5 w-5" />
                  )}
                </button>
              </td>
            )}
            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
              {patient.last_name}
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
              {patient.first_name}
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              <div className="flex items-center">
                <Calendar className="h-4 w-4 mr-2 text-gray-400" />
                {patient.first_visit_date ? (
                  formatPacificDate(patient.first_visit_date)
                ) : (
                  <span className="text-gray-400">No data</span>
                )}
              </div>
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              <div className="flex items-center">
                <Calendar className="h-4 w-4 mr-2 text-gray-400" />
                {patient.last_visit_date ? (
                  formatPacificDate(patient.last_visit_date)
                ) : (
                  <span className="text-gray-400">No data</span>
                )}
              </div>
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              <div className="flex items-center">
                <Scale className="h-4 w-4 mr-2 text-gray-400" />
                {patient.first_weight ? (
                  `${patient.first_weight.toFixed(1)} lbs`
                ) : (
                  <span className="text-gray-400">No data</span>
                )}
              </div>
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              <div className="flex items-center">
                <Scale className="h-4 w-4 mr-2 text-gray-400" />
                {patient.last_weight ? (
                  `${patient.last_weight.toFixed(1)} lbs`
                ) : (
                  <span className="text-gray-400">No data</span>
                )}
              </div>
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              {patient.memberships && patient.memberships.length > 0 ? (
                <div className="space-y-1">
                  {patient.memberships.map((membership: any, idx: number) => (
                    <div 
                      key={idx} 
                      className="flex items-center space-x-2"
                    >
                      <CreditCard className={`h-4 w-4 ${
                        membership.status === 'active' ? 'text-green-500' :
                        membership.status === 'cancelled' ? 'text-red-500' :
                        'text-gray-400'
                      }`} />
                      <span className={`text-xs font-medium ${
                        membership.status === 'active' ? 'text-green-600' :
                        membership.status === 'cancelled' ? 'text-red-600' :
                        'text-gray-500'
                      }`}>
                        {membership.name}
                      </span>
                      {membership.charge_amount && (
                        <span className="text-xs text-gray-500">
                          ({formatCurrency(membership.charge_amount)})
                        </span>
                      )}
                    </div>
                  ))}
                </div>
              ) : (
                <span className="text-xs text-gray-400">No membership</span>
              )}
            </td>
            {!bulkDeleteMode && (
              <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium" onClick={(e) => e.stopPropagation()}>
                <div className="flex items-center justify-end space-x-3">
                  {showDeleteConfirm === patient.patient_id ? (
                    <div className="flex items-center space-x-2">
                      <button
                        onClick={() => onDeleteConfirm(patient.patient_id)}
                        className="text-red-600 hover:text-red-900"
                      >
                        Confirm
                      </button>
                      <button
                        onClick={onDeleteCancel}
                        className="text-gray-600 hover:text-gray-900"
                      >
                        Cancel
                      </button>
                    </div>
                  ) : (
                    <button
                      onClick={() => onDeleteRequest(patient.patient_id)}
                      className="text-red-600 hover:text-red-900"
                    >
                      <Trash2 className="h-4 w-4" />
                    </button>
                  )}
                </div>
              </td>
            )}
          </tr>
        ))}
      </tbody>
    </table>
  );
}