import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  ArrowLeft, 
  Leaf, 
  LogOut, 
  Search, 
  Link2, 
  Link2Off,
  UserSearch,
  Check,
  X,
  AlertCircle,
  Loader2,
  FileSpreadsheet
} from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import { supabase } from '../lib/supabase';

interface MembershipInfo {
  membership_name: string;
  status: string;
}

interface MembershipPatient {
  id: uuid;
  external_id: string;
  first_name: string;
  last_name: string;
  location: string;
  mapped_patient_id: string | null;
  mapping_confidence: number | null;
  mapping_status: string;
  memberships?: MembershipInfo[];
}

interface Patient {
  id: string;
  patient_id: string;
  first_name: string;
  last_name: string;
}

interface PatientSearchModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSelect: (patientId: string) => void;
  membershipPatient: MembershipPatient;
}

type LogType = 'info' | 'error' | 'success';

interface LogEntry {
  type: LogType;
  message: string;
  timestamp: Date;
}

function PatientSearchModal({ isOpen, onClose, onSelect, membershipPatient }: PatientSearchModalProps) {
  const [searchTerm, setSearchTerm] = useState('');
  const [patients, setPatients] = useState<Patient[]>([]);
  const [loading, setLoading] = useState(false);
  const searchInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isOpen && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen && searchTerm.length >= 2) {
      searchPatients();
    }
  }, [searchTerm, isOpen]);

  const searchPatients = async () => {
    setLoading(true);
    try {
      const { data, error } = await supabase
        .from('patients')
        .select('id, patient_id, first_name, last_name')
        .or(`first_name.ilike.%${searchTerm}%,last_name.ilike.%${searchTerm}%`)
        .limit(10);

      if (error) throw error;
      setPatients(data || []);
    } catch (error) {
      console.error('Error searching patients:', error);
    } finally {
      setLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg shadow-xl max-w-2xl w-full">
        <div className="px-6 py-4 border-b border-gray-200 flex items-center justify-between">
          <h3 className="text-lg font-medium text-gray-900">
            Select Patient for {membershipPatient.first_name} {membershipPatient.last_name}
          </h3>
          <button onClick={onClose} className="text-gray-400 hover:text-gray-500">
            <X className="h-5 w-5" />
          </button>
        </div>

        <div className="p-6">
          <div className="mb-4">
            <div className="relative">
              <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
              <input
                ref={searchInputRef}
                type="text"
                placeholder="Search patients by name..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="pl-10 pr-4 py-2 w-full rounded-lg border border-gray-300 focus:ring-2 focus:ring-green-500 focus:border-transparent"
              />
            </div>
          </div>

          <div className="overflow-y-auto max-h-96">
            {loading ? (
              <div className="flex items-center justify-center py-8">
                <Loader2 className="h-8 w-8 text-green-600 animate-spin" />
              </div>
            ) : patients.length === 0 ? (
              <p className="text-center text-gray-500 py-8">No matching patients found</p>
            ) : (
              <div className="space-y-2">
                {patients.map((patient) => (
                  <button
                    key={patient.id}
                    onClick={() => onSelect(patient.id)}
                    className="w-full p-4 text-left rounded-lg hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-green-500"
                  >
                    <div className="font-medium text-gray-900">
                      {patient.first_name} {patient.last_name}
                    </div>
                    <div className="text-sm text-gray-500">
                      ID: {patient.patient_id}
                    </div>
                  </button>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export function MembershipMapping() {
  const navigate = useNavigate();
  const { user, userRoles, signOut } = useAuth();
  const [membershipPatients, setMembershipPatients] = useState<MembershipPatient[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [showMappedOnly, setShowMappedOnly] = useState(false);
  const [showUnmappedOnly, setShowUnmappedOnly] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState<MembershipPatient | null>(null);
  const [showPatientSearch, setShowPatientSearch] = useState(false);
  const [processingAutoMap, setProcessingAutoMap] = useState(false);
  const [logs, setLogs] = useState<LogEntry[]>([]);

  const addLog = (type: LogType, message: string) => {
    setLogs(prev => [...prev, { type, message, timestamp: new Date() }]);
  };

  useEffect(() => {
    fetchMembershipPatients();
  }, []);

  const fetchMembershipPatients = async () => {
    try {
      // First get all membership patients
      const { data: patients, error: patientsError } = await supabase
        .from('membership_patients')
        .select('*')
        .order('last_name', { ascending: true });

      if (patientsError) throw patientsError;

      // Then get memberships for each patient
      const patientsWithMemberships = await Promise.all(
        (patients || []).map(async (patient) => {
          const { data: memberships, error: membershipsError } = await supabase
            .from('memberships')
            .select('membership_name, status')
            .eq('membership_patient_id', patient.id)
            .order('date_created', { ascending: false });

          if (membershipsError) {
            console.error('Error fetching memberships for patient:', membershipsError);
            return patient;
          }

          return {
            ...patient,
            memberships: memberships || []
          };
        })
      );

      setMembershipPatients(patientsWithMemberships);
    } catch (error: any) {
      console.error('Error fetching membership patients:', error);
      setError('Failed to load membership patients');
    } finally {
      setLoading(false);
    }
  };

  const handleAutoMap = async () => {
    setProcessingAutoMap(true);
    setError(null);
    setSuccess(null);
    setLogs([]); // Clear previous logs
    
    try {
      let mapped = 0;
      let skipped = 0;
      let errors: string[] = [];

      addLog('info', 'Starting auto-mapping process...');
      addLog('info', `Found ${membershipPatients.length} total patients to process`);

      for (const patient of membershipPatients) {
        try {
          if (patient.mapping_status === 'unmapped') {
            addLog('info', `Processing ${patient.first_name} ${patient.last_name} (ID: ${patient.external_id})`);

            // Attempt to find matching patient
            const { data: mappingResults, error: mappingError } = await supabase.rpc(
              'attempt_patient_mapping',
              {
                p_first_name: patient.first_name,
                p_last_name: patient.last_name
              }
            );

            if (mappingError) {
              const errorMsg = `Mapping error for ${patient.external_id}: ${mappingError.message}`;
              errors.push(errorMsg);
              addLog('error', errorMsg);
              continue;
            }

            // Check if we got a valid result
            // Note: mappingResults will be an array with a single object containing patient_id and confidence
            if (mappingResults && mappingResults.length > 0) {
              const match = mappingResults[0];
              addLog('info', `Found match with confidence: ${match.confidence.toFixed(1)}%`);

              // Update membership patient with mapping
              const { error: updateError } = await supabase
                .from('membership_patients')
                .update({
                  mapped_patient_id: match.patient_id,
                  mapping_confidence: match.confidence,
                  mapping_status: 'mapped'
                })
                .eq('id', patient.id);

              if (updateError) {
                const errorMsg = `Update error for ${patient.external_id}: ${updateError.message}`;
                errors.push(errorMsg);
                addLog('error', errorMsg);
                continue;
              }

              mapped++;
              addLog('success', `Successfully mapped ${patient.first_name} ${patient.last_name}`);
            } else {
              skipped++;
              addLog('info', `No match found for ${patient.first_name} ${patient.last_name}`);
            }
          }
        } catch (patientError: any) {
          const errorMsg = `Unexpected error processing ${patient.external_id}: ${patientError.message}`;
          errors.push(errorMsg);
          addLog('error', errorMsg);
        }
      }

      await fetchMembershipPatients();

      // Set final status message
      let statusMessage = `Auto-mapping complete: ${mapped} patients mapped, ${skipped} skipped`;
      if (errors.length > 0) {
        statusMessage += `, ${errors.length} errors`;
        setError(`Completed with errors: ${errors.join('; ')}`);
      } else {
        setSuccess(statusMessage);
      }

      addLog('info', statusMessage);

    } catch (error: any) {
      const errorMsg = `Failed to complete auto-mapping process: ${error.message}`;
      console.error('Error during auto-mapping:', error);
      setError(errorMsg);
      addLog('error', errorMsg);
    } finally {
      setProcessingAutoMap(false);
    }
  };

  const handleManualMap = async (patientId: string) => {
    if (!selectedPatient) return;

    try {
      const { error } = await supabase
        .from('membership_patients')
        .update({
          mapped_patient_id: patientId,
          mapping_status: 'mapped',
          mapping_confidence: 100
        })
        .eq('id', selectedPatient.id);

      if (error) throw error;

      setSuccess('Patient successfully mapped');
      setShowPatientSearch(false);
      setSelectedPatient(null);
      await fetchMembershipPatients();
    } catch (error: any) {
      console.error('Error mapping patient:', error);
      setError('Failed to map patient');
    }
  };

  const handleUnmap = async (membershipPatient: MembershipPatient) => {
    try {
      const { error } = await supabase
        .from('membership_patients')
        .update({
          mapped_patient_id: null,
          mapping_status: 'unmapped',
          mapping_confidence: null
        })
        .eq('id', membershipPatient.id);

      if (error) throw error;

      setSuccess('Patient successfully unmapped');
      await fetchMembershipPatients();
    } catch (error: any) {
      console.error('Error unmapping patient:', error);
      setError('Failed to unmap patient');
    }
  };

  const filteredPatients = membershipPatients.filter(patient => {
    const matchesSearch = 
      patient.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      patient.last_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      patient.external_id.toLowerCase().includes(searchTerm.toLowerCase());

    const matchesFilter = 
      (!showMappedOnly && !showUnmappedOnly) ||
      (showMappedOnly && patient.mapping_status === 'mapped') ||
      (showUnmappedOnly && patient.mapping_status === 'unmapped');

    return matchesSearch && matchesFilter;
  });

  return (
    <div className="min-h-screen bg-gradient-to-br from-green-50 to-blue-50">
      <nav className="bg-white shadow-sm">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16">
            <div className="flex items-center">
              <Leaf className="h-6 w-6 text-green-600" />
              <div className="ml-2">
                <span className="text-xl font-semibold text-gray-900">THRIVE</span>
                <span className="ml-2 text-sm text-gray-500">Membership Mapping</span>
              </div>
            </div>
            <div className="flex items-center space-x-4">
              <span className="text-sm text-gray-700">{user?.email}</span>
              <div className="flex flex-wrap gap-2">
                {userRoles.map((role) => (
                  <span
                    key={role}
                    className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800"
                  >
                    {role}
                  </span>
                ))}
              </div>
              <button
                onClick={() => signOut()}
                className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-green-600 hover:bg-green-700"
              >
                <LogOut className="h-4 w-4 mr-2" />
                Sign Out
              </button>
            </div>
          </div>
        </div>
      </nav>

      <main className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        <div className="px-4 py-4 sm:px-0">
          <div className="flex items-center justify-between mb-6">
            <div className="flex items-center">
              <button
                onClick={() => navigate('/settings')}
                className="mr-4 text-gray-600 hover:text-gray-900"
              >
                <ArrowLeft className="h-6 w-6" />
              </button>
              <h1 className="text-2xl font-bold text-gray-900">Membership Mapping</h1>
            </div>
            <button
              onClick={handleAutoMap}
              disabled={processingAutoMap}
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {processingAutoMap ? (
                <>
                  <Loader2 className="h-4 w-4 mr-2 animate-spin" />
                  Processing...
                </>
              ) : (
                <>
                  <UserSearch className="h-4 w-4 mr-2" />
                  Attempt Auto-Mapping
                </>
              )}
            </button>
          </div>

          {/* Messages */}
          {error && (
            <div className="mb-4 p-4 bg-red-50 rounded-md">
              <div className="flex">
                <AlertCircle className="h-5 w-5 text-red-400" />
                <p className="ml-3 text-sm text-red-700">{error}</p>
              </div>
            </div>
          )}

          {success && (
            <div className="mb-4 p-4 bg-green-50 rounded-md">
              <div className="flex">
                <Check className="h-5 w-5 text-green-400" />
                <p className="ml-3 text-sm text-green-700">{success}</p>
              </div>
            </div>
          )}

          {/* Processing Logs section - Only show when there are logs */}
          {logs.length > 0 && (
            <div className="mb-6">
              <div className="bg-white shadow-lg rounded-lg overflow-hidden">
                <div className="px-6 py-5 border-b border-gray-200">
                  <h3 className="text-lg font-medium text-gray-900">Processing Log</h3>
                </div>
                <div className="p-6">
                  <div className="bg-gray-50 rounded-lg p-4 h-64 overflow-y-auto">
                    {logs.map((log, index) => (
                      <div
                        key={index}
                        className={`flex items-start space-x-2 mb-2 text-sm ${
                          log.type === 'error' 
                            ? 'text-red-600' 
                            : log.type === 'success'
                            ? 'text-green-600'
                            : 'text-gray-600'
                        }`}
                      >
                        {log.type === 'error' && <AlertCircle className="h-4 w-4 mt-0.5 flex-shrink-0" />}
                        {log.type === 'success' && <Check className="h-4 w-4 mt-0.5 flex-shrink-0" />}
                        {log.type === 'info' && <FileSpreadsheet className="h-4 w-4 mt-0.5 flex-shrink-0" />}
                        <span className="flex-1">{log.message}</span>
                        <span className="text-xs text-gray-400">
                          {log.timestamp.toLocaleTimeString()}
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Filters */}
          <div className="bg-white shadow-lg rounded-lg mb-6">
            <div className="p-6">
              <div className="flex flex-col sm:flex-row gap-4">
                <div className="flex-1">
                  <div className="relative">
                    <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
                    <input
                      type="text"
                      placeholder="Search by name or ID..."
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      className="pl-10 pr-4 py-2 w-full rounded-lg border border-gray-300 focus:ring-2 focus:ring-green-500 focus:border-transparent"
                    />
                  </div>
                </div>
                <div className="flex gap-2">
                  <button
                    onClick={() => {
                      setShowMappedOnly(true);
                      setShowUnmappedOnly(false);
                    }}
                    className={`px-4 py-2 rounded-lg border ${
                      showMappedOnly
                        ? 'bg-green-50 border-green-500 text-green-700'
                        : 'border-gray-300 text-gray-700 hover:bg-gray-50'
                    }`}
                  >
                    Mapped Only
                  </button>
                  <button
                    onClick={() => {
                      setShowMappedOnly(false);
                      setShowUnmappedOnly(true);
                    }}
                    className={`px-4 py-2 rounded-lg border ${
                      showUnmappedOnly
                        ? 'bg-yellow-50 border-yellow-500 text-yellow-700'
                        : 'border-gray-300 text-gray-700 hover:bg-gray-50'
                    }`}
                  >
                    Unmapped Only
                  </button>
                  <button
                    onClick={() => {
                      setShowMappedOnly(false);
                      setShowUnmappedOnly(false);
                    }}
                    className={`px-4 py-2 rounded-lg border ${
                      !showMappedOnly && !showUnmappedOnly
                        ? 'bg-gray-50 border-gray-500 text-gray-700'
                        : 'border-gray-300 text-gray-700 hover:bg-gray-50'
                    }`}
                  >
                    Show All
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* Patients Table */}
          <div className="bg-white shadow-lg rounded-lg overflow-hidden">
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Member ID
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Name
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Location
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Mapping Status
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Confidence
                    </th>
                    <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {loading ? (
                    <tr>
                      <td colSpan={6} className="px-6 py-4 text-center text-gray-500">
                        Loading membership patients...
                      </td>
                    </tr>
                  ) : filteredPatients.length === 0 ? (
                    <tr>
                      <td colSpan={6} className="px-6 py-4 text-center text-gray-500">
                        No membership patients found
                      </td>
                    </tr>
                  ) : (
                    filteredPatients.map((patient) => (
                      <tr key={patient.id}>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                          {patient.external_id}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm font-medium text-gray-900">
                            {patient.first_name} {patient.last_name}
                          </div>
                          {patient.memberships && patient.memberships.length > 0 && (
                            <div className="mt-1 text-xs text-gray-500">
                              {patient.memberships.map((membership, idx) => (
                                <div key={idx} className="flex items-center">
                                  <span className={`inline-flex items-center px-1.5 py-0.5 rounded-full text-xs ${
                                    membership.status === 'active' 
                                      ? 'bg-green-100 text-green-800'
                                      : membership.status === 'cancelled'
                                      ? 'bg-red-100 text-red-800'
                                      : membership.status === 'pending'
                                      ? 'bg-yellow-100 text-yellow-800'
                                      : 'bg-gray-100 text-gray-800'
                                  }`}>
                                    {membership.membership_name}
                                  </span>
                                  <span className="mx-1">•</span>
                                  <span className="capitalize">{membership.status}</span>
                                </div>
                              ))}
                            </div>
                          )}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {patient.location}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                            patient.mapping_status === 'mapped'
                              ? 'bg-green-100 text-green-800'
                              : 'bg-yellow-100 text-yellow-800'
                          }`}>
                            {patient.mapping_status === 'mapped' ? (
                              <Link2 className="h-3 w-3 mr-1" />
                            ) : (
                              <Link2Off className="h-3 w-3 mr-1" />
                            )}
                            {patient.mapping_status === 'mapped' ? 'Mapped' : 'Unmapped'}
                          </span>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {patient.mapping_confidence 
                            ? `${patient.mapping_confidence.toFixed(1)}%`
                            : '-'
                          }
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                          {patient.mapping_status === 'mapped' ? (
                            <button
                              onClick={() => handleUnmap(patient)}
                              className="text-red-600 hover:text-red-900"
                            >
                              Unmap
                            </button>
                          ) : (
                            <button
                              onClick={() => {
                                setSelectedPatient(patient);
                                setShowPatientSearch(true);
                              }}
                              className="text-green-600 hover:text-green-900"
                            >
                              Map
                            </button>
                          )}
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </main>

      {/* Patient Search Modal */}
      {showPatientSearch && selectedPatient && (
        <PatientSearchModal
          isOpen={showPatientSearch}
          onClose={() => {
            setShowPatientSearch(false);
            setSelectedPatient(null);
          }}
          onSelect={handleManualMap}
          membershipPatient={selectedPatient}
        />
      )}
    </div>
  );
}