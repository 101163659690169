import React from 'react';
import { useDropzone } from 'react-dropzone';
import { Upload, FileSpreadsheet } from 'lucide-react';

interface FileUploaderProps {
  onDrop: (acceptedFiles: File[]) => void;
}

export function FileUploader({ onDrop }: FileUploaderProps) {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
      'application/vnd.ms-excel': ['.xls']
    },
    multiple: false
  });

  return (
    <div 
      {...getRootProps()} 
      className={`border-2 border-dashed rounded-lg p-12 text-center cursor-pointer transition-all duration-300 ${
        isDragActive 
          ? 'border-indigo-500 bg-indigo-50' 
          : 'border-gray-300 hover:border-indigo-500 hover:bg-gray-50'
      }`}
    >
      <input {...getInputProps()} />
      <div className="flex flex-col items-center">
        <Upload className="h-12 w-12 text-gray-400 mb-4" />
        <p className="text-lg font-medium text-gray-900 mb-1">
          {isDragActive 
            ? 'Drop the file here...' 
            : 'Drag and drop your membership activity file here'}
        </p>
        <p className="text-sm text-gray-500 mb-4">
          or click to select a file
        </p>
        <div className="flex items-center space-x-2 text-sm text-gray-500">
          <FileSpreadsheet className="h-5 w-5" />
          <span>Accepts Excel files (.xlsx, .xls)</span>
        </div>
      </div>
    </div>
  );
}