import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AlertTriangle } from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import { supabase } from '../lib/supabase';
import { PatientDetailsModal } from '../components/PatientDetailsModal';
import { Header } from '../components/population/Header';
import { HeroHeader } from '../components/population/HeroHeader';
import { SearchAndFilters } from '../components/population/SearchAndFilters';
import { PatientTable } from '../components/population/PatientTable';
import { BulkDeleteConfirmModal } from '../components/population/BulkDeleteConfirmModal';
import { StatusMessages } from '../components/population/StatusMessages';

type FilterType = 'no_first_visit' | 'no_first_weight' | null;

export function PopulationManagement() {
  const navigate = useNavigate();
  const { userRoles } = useAuth();
  const isAdmin = userRoles.includes('admin');
  const [patients, setPatients] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [activeFilter, setActiveFilter] = useState<FilterType>(null);
  const [selectedPatient, setSelectedPatient] = useState<string | null>(null);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState<string | null>(null);
  const [bulkDeleteMode, setBulkDeleteMode] = useState(false);
  const [selectedPatients, setSelectedPatients] = useState<Set<string>>(new Set());
  const [showBulkDeleteConfirm, setShowBulkDeleteConfirm] = useState(false);
  const [refreshing, setRefreshing] = useState(false);

  useEffect(() => {
    if (!isAdmin) {
      navigate('/settings');
      return;
    }
    fetchPatientData();
  }, [isAdmin, navigate]);

  const fetchPatientData = async () => {
    try {
      setLoading(true);
      setError(null);
      
      const { data, error } = await supabase.rpc('get_population_management_data');
      
      if (error) throw error;
      setPatients(data || []);
    } catch (error: any) {
      console.error('Error fetching patient data:', error);
      setError(error.message);
    } finally {
      setLoading(false);
      setRefreshing(false);
    }
  };

  const handleDelete = async (patientId: string) => {
    try {
      setError(null);
      const { error } = await supabase.rpc('soft_delete_patient', {
        p_patient_id: patientId
      });

      if (error) throw error;

      setSuccess('Patient successfully removed from list');
      setShowDeleteConfirm(null);
      setPatients(prev => prev.filter(p => p.patient_id !== patientId));

      setTimeout(() => setSuccess(null), 3000);
    } catch (error: any) {
      console.error('Error deleting patient:', error);
      setError('Failed to delete patient: ' + error.message);
    }
  };

  const handleBulkDelete = async () => {
    try {
      setError(null);
      setLoading(true);

      let successCount = 0;
      let errorCount = 0;

      for (const patientId of selectedPatients) {
        try {
          const { error } = await supabase.rpc('soft_delete_patient', {
            p_patient_id: patientId
          });

          if (error) {
            errorCount++;
            console.error(`Error deleting patient ${patientId}:`, error);
          } else {
            successCount++;
          }
        } catch (error) {
          errorCount++;
          console.error(`Error deleting patient ${patientId}:`, error);
        }
      }

      setPatients(prev => prev.filter(p => !selectedPatients.has(p.patient_id)));
      setSelectedPatients(new Set());
      setBulkDeleteMode(false);
      setShowBulkDeleteConfirm(false);

      if (errorCount === 0) {
        setSuccess(`Successfully removed ${successCount} patient${successCount !== 1 ? 's' : ''}`);
        setTimeout(() => setSuccess(null), 3000);
      } else {
        setError(`Deleted ${successCount} patient${successCount !== 1 ? 's' : ''}, but failed to delete ${errorCount} patient${errorCount !== 1 ? 's' : ''}`);
      }
    } catch (error: any) {
      console.error('Error in bulk delete:', error);
      setError('Failed to complete bulk delete operation');
    } finally {
      setLoading(false);
    }
  };

  const filteredPatients = patients.filter(patient => {
    const searchLower = searchTerm.toLowerCase();
    const matchesSearch = 
      patient.first_name?.toLowerCase().includes(searchLower) ||
      patient.last_name?.toLowerCase().includes(searchLower) ||
      patient.patient_id?.toLowerCase().includes(searchLower);

    if (activeFilter === 'no_first_visit') {
      return matchesSearch && !patient.first_visit_date;
    } else if (activeFilter === 'no_first_weight') {
      return matchesSearch && !patient.first_weight;
    }

    return matchesSearch;
  });

  // If not admin, don't render the page
  if (!isAdmin) {
    return null;
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-green-50 to-blue-50">
      <Header />

      <main className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        <div className="px-4 py-4 sm:px-0">
          <HeroHeader
            loading={loading}
            refreshing={refreshing}
            bulkDeleteMode={bulkDeleteMode}
            onRefresh={() => {
              setRefreshing(true);
              fetchPatientData();
            }}
            onToggleBulkDelete={() => setBulkDeleteMode(!bulkDeleteMode)}
          />

          <SearchAndFilters
            searchTerm={searchTerm}
            onSearchChange={setSearchTerm}
            activeFilter={activeFilter}
            onFilterClick={(filter) => setActiveFilter(activeFilter === filter ? null : filter)}
          />

          <div className="bg-white shadow-lg rounded-lg overflow-hidden">
            <div className="px-6 py-5 border-b border-gray-200">
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <h3 className="text-lg font-medium text-gray-900">
                    Patient Population Data
                  </h3>
                </div>
                <div className="text-sm text-gray-500">
                  {filteredPatients.length} patients
                </div>
              </div>
            </div>

            <StatusMessages error={error} success={success} />

            {bulkDeleteMode && selectedPatients.size > 0 && (
              <div className="p-4 bg-yellow-50 border-b border-yellow-200">
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <AlertTriangle className="h-5 w-5 text-yellow-400 mr-2" />
                    <span className="text-sm text-yellow-700">
                      {selectedPatients.size} patient{selectedPatients.size !== 1 ? 's' : ''} selected
                    </span>
                  </div>
                  <button
                    onClick={() => setShowBulkDeleteConfirm(true)}
                    className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700"
                  >
                    Delete Selected
                  </button>
                </div>
               </div>
            )}

            <div className="overflow-x-auto">
              <PatientTable
                loading={loading}
                patients={filteredPatients}
                bulkDeleteMode={bulkDeleteMode}
                selectedPatients={selectedPatients}
                showDeleteConfirm={showDeleteConfirm}
                onPatientSelect={(patientId) => {
                  setSelectedPatients(prev => {
                    const newSet = new Set(prev);
                    if (newSet.has(patientId)) {
                      newSet.delete(patientId);
                    } else {
                      newSet.add(patientId);
                    }
                    return newSet;
                  });
                }}
                onSelectAll={() => {
                  if (selectedPatients.size === filteredPatients.length) {
                    setSelectedPatients(new Set());
                  } else {
                    setSelectedPatients(new Set(filteredPatients.map(p => p.patient_id)));
                  }
                }}
                onPatientClick={(patientId) => setSelectedPatient(patientId)}
                onDeleteConfirm={handleDelete}
                onDeleteCancel={() => setShowDeleteConfirm(null)}
                onDeleteRequest={(patientId) => setShowDeleteConfirm(patientId)}
              />
            </div>
          </div>
        </div>
      </main>

      <PatientDetailsModal
        patientId={selectedPatient || ''}
        isOpen={!!selectedPatient}
        onClose={() => setSelectedPatient(null)}
      />

      <BulkDeleteConfirmModal
        isOpen={showBulkDeleteConfirm}
        selectedCount={selectedPatients.size}
        onConfirm={handleBulkDelete}
        onCancel={() => setShowBulkDeleteConfirm(false)}
      />
    </div>
  );
}