import { supabase } from '../../lib/supabase';
import { ProcessingProgress, ProcessingResult } from '../dataImportProcessor';

interface MembershipData {
  client_id: string;
  client_name: string;
  location: string;
}

export async function processMembershipReport(
  lines: string[],
  onProgress?: (progress: ProcessingProgress) => void
): Promise<ProcessingResult> {
  try {
    // Skip first two rows (title and empty line)
    const headers = lines[2].split(',').map(header => header.trim().toLowerCase());
    const totalLines = lines.length - 3; // Subtract header rows
    let processedCount = 0;
    let skippedCount = 0;
    let unmappedCount = 0;

    // Process each line starting from row 4
    for (let i = 3; i < lines.length; i++) {
      const values = lines[i].split(',').map(value => value.trim());
      
      if (values.length < 15) { // Ensure we have at least up to column O
        skippedCount++;
        continue;
      }

      try {
        // Parse client name (Column B) which is in format "lastname, firstname"
        const clientNameParts = values[1].split(',').map(part => part.trim());
        if (clientNameParts.length !== 2) {
          console.warn(`Invalid client name format: ${values[1]}`);
          skippedCount++;
          continue;
        }

        const membershipData: MembershipData = {
          client_id: values[0], // Column A
          client_name: values[1], // Column B (full name for reference)
          location: values[3], // Column D
        };

        // Process membership data
        const { data, error } = await supabase.rpc('process_membership_data', {
          p_external_id: membershipData.client_id,
          p_first_name: clientNameParts[1], // firstname is second part
          p_last_name: clientNameParts[0], // lastname is first part
          p_location: membershipData.location
        });

        if (error) throw error;

        // Check if the record was mapped to a patient
        const { data: membershipRecord } = await supabase
          .from('membership_patients')
          .select('mapping_status')
          .eq('id', data)
          .single();

        if (membershipRecord?.mapping_status === 'unmapped') {
          unmappedCount++;
        }

        processedCount++;
      } catch (error) {
        console.error('Error processing membership record:', error);
        skippedCount++;
      }

      // Report progress
      if (onProgress) {
        onProgress({
          currentLine: i - 2,
          totalLines,
          processedCount,
          skippedCount
        });
      }
    }

    // Record import history
    try {
      await supabase.from('import_history').insert({
        report_type: 'MEMBERSHIP_REPORT',
        file_name: 'Memberships Report',
        processed_count: processedCount,
        skipped_count: skippedCount
      });
    } catch (error) {
      console.error('Error recording import history:', error);
    }

    let message = `Processed ${processedCount} membership records`;
    if (skippedCount > 0) {
      message += ` (${skippedCount} records skipped)`;
    }
    if (unmappedCount > 0) {
      message += ` (${unmappedCount} records need manual mapping)`;
    }

    return {
      success: true,
      message,
      processedCount,
      skippedCount
    };
  } catch (error: any) {
    return {
      success: false,
      message: `Error processing file: ${error.message}`,
      processedCount: 0,
      skippedCount: 0
    };
  }
}