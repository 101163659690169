import React, { useState, useEffect } from 'react';
import { Clock, Ruler, ShoppingCart, X, Check, Loader2 } from 'lucide-react';
import { formatPacificTime } from '../../utils/dateTime';
import { MembershipStatus } from './MembershipStatus';
import { Membership, hasMembership } from '../../utils/membership';
import { supabase } from '../../lib/supabase';

interface Appointment {
  appointment_id: string;
  appointment_time: string;
  appointment_type: string;
  rendering_provider: string;
  service_department: string;
  cancelled: boolean;
  patient_id: string;
  patient_first_name: string;
  patient_last_name: string;
  has_membership: boolean;
  memberships?: Membership[];
  body_comp_overdue: boolean;
}

interface AppointmentListProps {
  appointments: Appointment[];
  isCurrentAppointment: (time: string) => boolean;
  onCheckoutClick: (appointment: Appointment) => void;
  onPatientClick: (patientId: string) => void;
  selectedDate: Date;
}

export function AppointmentList({ appointments, isCurrentAppointment, onCheckoutClick, onPatientClick, selectedDate }: AppointmentListProps) {
  const [medicationStatus, setMedicationStatus] = useState<Record<string, boolean>>({});
  const [loadingStatus, setLoadingStatus] = useState<Record<string, boolean>>({});
  const [bodyCompStatus, setBodyCompStatus] = useState<Record<string, boolean>>({});

  useEffect(() => {
    // Reset status when appointments or date changes
    setMedicationStatus({});
    setLoadingStatus({});
    setBodyCompStatus({});
    
    // Check status for each appointment
    appointments.forEach(appointment => {
      checkAppointmentStatus(appointment);
    });
  }, [appointments, selectedDate]);

  const checkAppointmentStatus = async (appointment: Appointment) => {
    try {
      // Set loading state for this appointment
      setLoadingStatus(prev => ({ ...prev, [appointment.appointment_id]: true }));

      // Format the date as YYYY-MM-DD
      const appointmentDate = selectedDate.toISOString().split('T')[0];

      // Check medications
      const { data: medsData, error: medsError } = await supabase
        .from('medications_administered')
        .select('id')
        .eq('external_patient_id', appointment.patient_id)
        .eq('service_date', appointmentDate)
        .limit(1);

      if (medsError) throw medsError;

      // Check body composition
      const { data: bodyCompData, error: bodyCompError } = await supabase
        .from('body_composition_measurements')
        .select('id')
        .eq('external_patient_id', appointment.patient_id)
        .eq('measurement_date', appointmentDate)
        .limit(1);

      if (bodyCompError) throw bodyCompError;

      // Update status
      setMedicationStatus(prev => ({
        ...prev,
        [appointment.appointment_id]: medsData && medsData.length > 0
      }));

      setBodyCompStatus(prev => ({
        ...prev,
        [appointment.appointment_id]: bodyCompData && bodyCompData.length > 0
      }));
    } catch (error) {
      console.error('Error checking appointment status:', error);
    } finally {
      // Clear loading state
      setLoadingStatus(prev => ({ ...prev, [appointment.appointment_id]: false }));
    }
  };

  return (
    <div className="flex flex-col h-[calc(100vh-24rem)]">
      <div className="bg-gray-50 border-b border-gray-200">
        <table className="min-w-full">
          <thead>
            <tr>
              <th scope="col" className="w-[10%] px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Time
              </th>
              <th scope="col" className="w-[35%] px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Patient
              </th>
              <th scope="col" className="w-[15%] px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Provider
              </th>
              <th scope="col" className="w-[20%] px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Department
              </th>
              <th scope="col" className="w-[20%] px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Status
              </th>
            </tr>
          </thead>
        </table>
      </div>
      <div className="overflow-y-auto">
        <table className="min-w-full">
          <tbody className="bg-white divide-y divide-gray-200">
            {appointments.length === 0 ? (
              <tr>
                <td colSpan={5} className="px-6 py-4 text-center text-gray-500">
                  No appointments scheduled for this day
                </td>
              </tr>
            ) : (
              appointments.map((appointment) => {
                const isCurrentlyActive = isCurrentAppointment(appointment.appointment_time);
                const isLoading = loadingStatus[appointment.appointment_id];
                const hasMeds = medicationStatus[appointment.appointment_id];
                const hasBodyComp = bodyCompStatus[appointment.appointment_id];
                const needsBodyComp = appointment.body_comp_overdue && !hasBodyComp;
                
                return (
                  <tr 
                    key={appointment.appointment_id}
                    className={`hover:bg-gray-50 cursor-pointer transition-colors duration-150 ${
                      isCurrentlyActive ? 'bg-green-50' : ''
                    }`}
                    onClick={() => onPatientClick(appointment.patient_id)}
                  >
                    <td className="px-6 py-4 whitespace-nowrap w-[10%]">
                      <div className="space-y-2">
                        <div className="flex items-center">
                          <Clock className={`h-4 w-4 mr-2 ${
                            isCurrentlyActive ? 'text-green-600' : 'text-gray-400'
                          }`} />
                          <span className="text-sm text-gray-900">
                            {formatPacificTime(appointment.appointment_time)}
                          </span>
                        </div>
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            onCheckoutClick(appointment);
                          }}
                          className={`inline-flex items-center px-2 py-1 text-xs font-medium rounded-md w-full justify-center ${
                            isLoading
                              ? 'bg-gray-100 text-gray-500'
                              : hasMeds
                              ? 'bg-green-100 text-green-700 hover:bg-green-200'
                              : 'bg-red-100 text-red-700 hover:bg-red-200'
                          }`}
                          disabled={isLoading}
                        >
                          {isLoading ? (
                            <>
                              <Loader2 className="h-3 w-3 mr-1 animate-spin" />
                              Loading...
                            </>
                          ) : (
                            <>
                              <ShoppingCart className="h-3 w-3 mr-1" />
                              {hasMeds ? 'Meds Recorded' : 'Enter Meds'}
                            </>
                          )}
                        </button>
                      </div>
                    </td>
                    <td className="px-6 py-4 w-[35%]">
                      <div className="text-sm font-medium text-gray-900">
                        {appointment.patient_first_name} {appointment.patient_last_name}
                      </div>
                      <div className="mt-1">
                        {hasMembership(appointment.memberships) ? (
                          <MembershipStatus memberships={appointment.memberships!} />
                        ) : (
                          <span className="text-xs text-gray-400">No membership found</span>
                        )}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap w-[15%]">
                      <div className="text-sm text-gray-900 truncate">
                        {appointment.rendering_provider}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap w-[20%]">
                      <div className="text-sm text-gray-900">
                        {appointment.service_department}
                      </div>
                    </td>
                    <td className="px-6 py-4 w-[20%]">
                      <div className="flex flex-wrap gap-2">
                        <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                          appointment.cancelled
                            ? 'bg-red-100 text-red-800'
                            : isCurrentlyActive
                            ? 'bg-green-100 text-green-800'
                            : 'bg-gray-100 text-gray-800'
                        }`}>
                          {appointment.cancelled ? 'Cancelled' : isCurrentlyActive ? 'In Progress' : 'Scheduled'}
                        </span>
                        {needsBodyComp && (
                          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800">
                            <Ruler className="h-3 w-3 mr-1" />
                            Body Comp Due
                          </span>
                        )}
                      </div>
                    </td>
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}