import React from 'react';

export function ImportInstructions() {
  return (
    <div className="mb-6">
      <h2 className="text-lg font-medium text-gray-900 mb-2">Import Instructions</h2>
      <p className="text-sm text-gray-500">
        Upload a CSV file from Athena containing patient data:
      </p>
      <ul className="list-disc list-inside text-sm text-gray-500 mt-2">
        <li>File must be in CSV format</li>
        <li>Reports from Athena should begin with "ACCESS Data"</li>
        <li>Column C should contain client IDs</li>
        <li>Column E should contain membership names</li>
        <li>Column F should contain membership status</li>
        <li>Column G should contain charge amounts</li>
        <li>Columns H-N should contain dates and activity details</li>
      </ul>
    </div>
  );
}