import { supabase } from '../../lib/supabase';
import { ProcessingProgress, ProcessingResult } from '../dataImportProcessor';

interface PatientData {
  patient_id: string;
  first_name: string;
  last_name: string;
  date_of_birth: string;
  sex: string;
}

interface MedicationData {
  external_patient_id: string;
  service_date: string;
  claim_id: string;
  procedure_code: string;
  units: number;
}

function validateAndFormatDate(dateStr: string): string | null {
  try {
    // Handle common date formats
    let date: Date | null = null;
    
    // Try MM/DD/YYYY format
    if (dateStr.match(/^\d{1,2}\/\d{1,2}\/\d{4}$/)) {
      const [month, day, year] = dateStr.split('/').map(Number);
      date = new Date(year, month - 1, day);
    }
    // Try YYYY-MM-DD format
    else if (dateStr.match(/^\d{4}-\d{2}-\d{2}$/)) {
      date = new Date(dateStr);
    }
    // Try MM-DD-YYYY format
    else if (dateStr.match(/^\d{2}-\d{2}-\d{4}$/)) {
      const [month, day, year] = dateStr.split('-').map(Number);
      date = new Date(year, month - 1, day);
    }

    // Validate the date
    if (date && !isNaN(date.getTime())) {
      // Check if date is not in the future
      if (date > new Date()) {
        console.error('Invalid date of birth: Date is in the future:', dateStr);
        return null;
      }
      
      // Return in YYYY-MM-DD format
      return date.toISOString().split('T')[0];
    }

    console.error('Invalid date format:', dateStr);
    return null;
  } catch (error) {
    console.error('Error parsing date:', dateStr, error);
    return null;
  }
}

export async function processPatientMedicationsReport(
  lines: string[],
  onProgress?: (progress: ProcessingProgress) => void
): Promise<ProcessingResult> {
  try {
    const headers = lines[1].split(',').map(header => header.trim().toLowerCase());
    const totalLines = lines.length - 2; // Subtract header rows
    let processedCount = 0;
    let skippedCount = 0;
    let errorCount = 0;

    // Calculate total work units (data extraction + patient processing + medication processing)
    const totalWorkUnits = totalLines * 3; // Multiply by 3 for the three main phases
    let currentWorkUnit = 0;

    const reportProgress = () => {
      if (onProgress) {
        onProgress({
          currentLine: Math.min(currentWorkUnit, totalWorkUnits),
          totalLines: totalWorkUnits,
          processedCount,
          skippedCount
        });
      }
    };

    // Extract all patient and medication data first
    const allPatients: PatientData[] = [];
    const allMedications: MedicationData[] = [];
    const patientIds = new Set<string>();
    const duplicatesInFile = new Set<string>();
    const medicationKeys = new Set<string>(); // Track unique medication records

    // First pass: collect unique patients and medications
    console.log('Starting data extraction phase...');
    for (let i = 2; i < lines.length; i++) {
      const values = lines[i].split(',').map(value => value.trim());
      
      if (values.length !== headers.length) {
        continue; // Skip malformed rows
      }

      const patientId = values[headers.indexOf('patientid')];
      const serviceDate = values[headers.indexOf('srvday')];
      const procedureCode = values[headers.indexOf('proccode')];
      const dobStr = values[headers.indexOf('patientdob')];

      // Validate date of birth
      const validatedDob = validateAndFormatDate(dobStr);
      if (!validatedDob) {
        console.error(`Invalid date of birth for patient ${patientId}: ${dobStr}`);
        skippedCount++;
        continue;
      }

      // Create a unique key for the medication record
      const medicationKey = `${patientId}-${serviceDate}-${procedureCode}`;

      // Skip if we've already seen this exact medication record
      if (medicationKeys.has(medicationKey)) {
        skippedCount++;
        continue;
      }

      medicationKeys.add(medicationKey);

      // Add medication data
      allMedications.push({
        external_patient_id: patientId,
        service_date: serviceDate,
        claim_id: values[headers.indexOf('claimid')],
        procedure_code: procedureCode,
        units: parseFloat(values[headers.indexOf('chg units sum')]) || 0
      });

      // Only add patient if not already seen in this file
      if (!patientIds.has(patientId)) {
        patientIds.add(patientId);
        allPatients.push({
          patient_id: patientId,
          first_name: values[headers.indexOf('patient firstname')],
          last_name: values[headers.indexOf('patient lastname')],
          date_of_birth: validatedDob,
          sex: values[headers.indexOf('patientsex')]
        });
      } else {
        duplicatesInFile.add(patientId);
      }

      currentWorkUnit++;
      reportProgress();
    }

    console.log(`Data extraction complete. Found ${allPatients.length} patients and ${allMedications.length} medications`);

    // Process patients in batches using upsert
    const BATCH_SIZE = 50;
    const existingPatientMap = new Map<string, string>();

    console.log('Starting patient processing phase...');
    for (let i = 0; i < allPatients.length; i += BATCH_SIZE) {
      const batch = allPatients.slice(i, i + BATCH_SIZE);
      
      try {
        // First, get existing patients to check their current data
        const { data: existingPatients, error: selectError } = await supabase
          .from('patients')
          .select('id, patient_id, date_of_birth')
          .in('patient_id', batch.map(p => p.patient_id));

        if (selectError) {
          console.error('Error fetching existing patients:', selectError);
          continue;
        }

        // Create a map of existing patient data
        const existingPatientData = new Map(
          existingPatients?.map(p => [p.patient_id, p]) || []
        );

        // Prepare upsert data, preserving existing DOB if valid
        const upsertData = batch.map(p => {
          const existing = existingPatientData.get(p.patient_id);
          return {
            patient_id: p.patient_id,
            first_name: p.first_name,
            last_name: p.last_name,
            // Only update DOB if current one is invalid or missing
            date_of_birth: existing?.date_of_birth || p.date_of_birth,
            sex: p.sex
          };
        });

        // Perform the upsert
        const { error: upsertError } = await supabase
          .from('patients')
          .upsert(upsertData, { onConflict: 'patient_id' });

        if (upsertError) {
          console.error('Error upserting patients:', upsertError);
          errorCount++;
          continue;
        }

        // Update the patient map for medication processing
        const { data: batchPatients, error: mapError } = await supabase
          .from('patients')
          .select('id, patient_id')
          .in('patient_id', batch.map(p => p.patient_id));

        if (mapError) {
          console.error('Error fetching patient IDs:', mapError);
          continue;
        }

        batchPatients?.forEach(p => {
          existingPatientMap.set(p.patient_id, p.id);
        });

      } catch (error: any) {
        console.error('Error processing patient batch:', error);
        errorCount++;
      }

      // Update progress for patient processing phase
      currentWorkUnit += batch.length;
      reportProgress();
    }

    console.log('Patient processing complete. Starting medication processing...');

    // Process medications in batches
    let medicationsProcessed = 0;
    let medicationsSkipped = 0;

    // Process medications in batches
    for (let i = 0; i < allMedications.length; i += BATCH_SIZE) {
      const batch = allMedications.slice(i, i + BATCH_SIZE);
      const medicationsToInsert = batch
        .map(med => ({
          ...med,
          patient_id: existingPatientMap.get(med.external_patient_id),
          // Ensure date is in correct format
          service_date: validateAndFormatDate(med.service_date) || new Date().toISOString().split('T')[0]
        }))
        .filter(med => med.patient_id); // Only include medications where we have a valid patient_id

      if (medicationsToInsert.length > 0) {
        try {
          // Use RPC call to handle duplicates properly
          const { error: insertError } = await supabase
            .rpc('insert_medications_administered', {
              p_medications: medicationsToInsert
            });

          if (insertError) {
            console.error('Error inserting medications:', insertError);
            medicationsSkipped += medicationsToInsert.length;
          } else {
            medicationsProcessed += medicationsToInsert.length;
          }
        } catch (error) {
          console.error('Error processing medications batch:', error);
          medicationsSkipped += medicationsToInsert.length;
        }
      } else {
        medicationsSkipped += batch.length;
      }

      // Update progress for medication processing phase
      currentWorkUnit += batch.length;
      reportProgress();
    }

    console.log('Medication processing complete.');

    // Record import history
    try {
      await supabase.from('import_history').insert({
        report_type: 'ACCESS_DATA_WTGMGT',
        file_name: 'ACCESS Data - WtgMgt Pts Last Week',
        processed_count: medicationsProcessed,
        skipped_count: medicationsSkipped
      });
    } catch (error) {
      console.error('Error recording import history:', error);
    }

    let message = `Processed ${allPatients.length - errorCount} patients and ${medicationsProcessed} new medications`;
    if (medicationsSkipped > 0) {
      message += ` (${medicationsSkipped} medications skipped)`;
    }
    if (duplicatesInFile.size > 0) {
      message += ` (${duplicatesInFile.size} duplicate patients in file)`;
    }
    if (errorCount > 0) {
      message += ` (${errorCount} errors occurred)`;
    }

    return {
      success: true,
      message,
      processedCount: medicationsProcessed,
      skippedCount: medicationsSkipped
    };
  } catch (error: any) {
    return {
      success: false,
      message: `Error processing file: ${error.message}`,
      processedCount: 0,
      skippedCount: 0
    };
  }
}