import React, { useState, useEffect } from 'react';
import { X, CreditCard, Syringe, Pill, DollarSign, Minus, Plus, Trash2, History, AlertCircle, Ruler } from 'lucide-react';
import { supabase } from '../../lib/supabase';
import { MembershipStatus } from './MembershipStatus';
import { ProductSelectionModal } from './ProductSelectionModal';
import { formatPacificDate, getPacificDateForQuery } from '../../utils/dateTime';

interface MedicationsModalProps {
  isOpen: boolean;
  onClose: () => void;
  patient: {
    id: string;  // This is the external_patient_id
    firstName: string;
    lastName: string;
    memberships?: any[];
  } | null;
  appointmentDate?: string;
}

interface Category {
  id: string;
  name: string;
  display_order: number;
  is_medication: boolean;
}

interface ProcedureCode {
  code: string;
  description: string;
  price: number;
}

interface CartItem {
  code: string;
  description: string;
  price: number;
  quantity: number;
  categoryName?: string;
  medicationType?: string;
}

interface PreviousMedication {
  service_date: string;
  description: string;
  form: string;
  units: number;
}

interface BodyCompStatus {
  hasBodyComp: boolean;
  loading: boolean;
}

export function MedicationsModal({ isOpen, onClose, patient, appointmentDate }: MedicationsModalProps) {
  const [medicationType, setMedicationType] = useState<'semaglutide' | 'tirzepatide' | null>(null);
  const [categories, setCategories] = useState<Category[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<Category | null>(null);
  const [procedures, setProcedures] = useState<ProcedureCode[]>([]);
  const [cart, setCart] = useState<CartItem[]>([]);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [showProductSelection, setShowProductSelection] = useState(false);
  const [previousMedication, setPreviousMedication] = useState<PreviousMedication | null>(null);
  const [hasChanges, setHasChanges] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [initialCart, setInitialCart] = useState<CartItem[]>([]);
  const [bodyCompStatus, setBodyCompStatus] = useState<BodyCompStatus>({
    hasBodyComp: false,
    loading: false
  });

  useEffect(() => {
    const cartChanged = JSON.stringify(cart) !== JSON.stringify(initialCart);
    setHasChanges(cartChanged);
  }, [cart, initialCart]);

  useEffect(() => {
    if (isOpen && patient?.id && appointmentDate) {
      fetchCategories();
      loadExistingMedications();
      loadPreviousMedications();
      checkBodyCompStatus();
    } else {
      resetState();
    }
  }, [isOpen, patient?.id, appointmentDate]);

  const resetState = () => {
    setMedicationType(null);
    setSelectedCategory(null);
    setProcedures([]);
    setCart([]);
    setShowProductSelection(false);
    setInitialCart([]);
    setHasChanges(false);
    setShowConfirmDialog(false);
    setBodyCompStatus({ hasBodyComp: false, loading: false });
  };

  const handleClose = () => {
    if (hasChanges) {
      setShowConfirmDialog(true);
    } else {
      onClose();
    }
  };

  const handleConfirmClose = () => {
    setShowConfirmDialog(false);
    onClose();
  };

  const handleCancelClose = () => {
    setShowConfirmDialog(false);
  };

  const checkBodyCompStatus = async () => {
    if (!patient?.id || !appointmentDate) return;

    try {
      setBodyCompStatus(prev => ({ ...prev, loading: true }));

      // First get the patient's UUID
      const { data: patientData, error: patientError } = await supabase
        .from('patients')
        .select('id')
        .eq('patient_id', patient.id)
        .single();

      if (patientError) throw patientError;

      // Then check for body comp measurement
      const { data, error } = await supabase
        .from('body_composition_measurements')
        .select('id')
        .eq('external_patient_id', patient.id)
        .eq('measurement_date', appointmentDate)
        .limit(1);

      if (error) throw error;

      setBodyCompStatus({
        hasBodyComp: data && data.length > 0,
        loading: false
      });
    } catch (error) {
      console.error('Error checking body composition status:', error);
      setBodyCompStatus({ hasBodyComp: false, loading: false });
    }
  };

  const handleBodyCompToggle = async () => {
    if (!patient?.id || !appointmentDate) return;

    try {
      setBodyCompStatus(prev => ({ ...prev, loading: true }));

      // First get the patient's UUID
      const { data: patientData, error: patientError } = await supabase
        .from('patients')
        .select('id')
        .eq('patient_id', patient.id)
        .single();

      if (patientError) throw patientError;

      if (bodyCompStatus.hasBodyComp) {
        // Remove body composition record
        const { error } = await supabase
          .from('body_composition_measurements')
          .delete()
          .eq('external_patient_id', patient.id)
          .eq('measurement_date', appointmentDate);

        if (error) throw error;
      } else {
        // Add body composition record with both IDs
        const { error } = await supabase
          .from('body_composition_measurements')
          .insert({
            patient_id: patientData.id, // UUID from patients table
            external_patient_id: patient.id, // External ID
            measurement_date: appointmentDate,
            procedure_code: 'MISCBODYCOMP' // Using the specified procedure code
          });

        if (error) throw error;
      }

      // Update status
      setBodyCompStatus(prev => ({
        loading: false,
        hasBodyComp: !prev.hasBodyComp
      }));
    } catch (error) {
      console.error('Error toggling body composition:', error);
      setBodyCompStatus(prev => ({ ...prev, loading: false }));
    }
  };

  const loadPreviousMedications = async () => {
    if (!patient?.id || !appointmentDate) return;

    try {
      const { data, error } = await supabase
        .from('medications_administered')
        .select(`
          service_date,
          units,
          procedure_codes (
            description,
            form
          )
        `)
        .eq('external_patient_id', patient.id)
        .lt('service_date', appointmentDate)
        .order('service_date', { ascending: false })
        .limit(1);

      if (error) throw error;

      if (data && data.length > 0) {
        setPreviousMedication({
          service_date: data[0].service_date,
          description: data[0].procedure_codes?.description || 'Unknown',
          form: data[0].procedure_codes?.form || 'none',
          units: data[0].units
        });
      } else {
        setPreviousMedication(null);
      }
    } catch (error) {
      console.error('Error loading previous medications:', error);
    }
  };

  const loadExistingMedications = async () => {
    if (!patient?.id || !appointmentDate) return;

    try {
      setLoading(true);

      const { data: medications, error } = await supabase
        .from('medications_administered')
        .select(`
          procedure_code,
          units,
          procedure_codes (
            code,
            description,
            medication_type,
            procedure_pricing (
              price
            )
          )
        `)
        .eq('external_patient_id', patient.id)
        .eq('service_date', appointmentDate);

      if (error) throw error;

      if (medications && medications.length > 0) {
        const cartItems = medications.map(med => ({
          code: med.procedure_code,
          description: med.procedure_codes.description,
          price: med.procedure_codes.procedure_pricing[0]?.price || 0,
          quantity: med.units,
          medicationType: med.procedure_codes.medication_type
        }));

        setCart(cartItems);
        setInitialCart(cartItems);

        const medType = medications[0].procedure_codes.medication_type;
        if (medType === 'semaglutide' || medType === 'tirzepatide') {
          setMedicationType(medType);
        }
      } else {
        setCart([]);
        setInitialCart([]);
      }
    } catch (error) {
      console.error('Error loading medications:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchCategories = async () => {
    try {
      const { data, error } = await supabase
        .from('product_categories')
        .select('*')
        .order('display_order');

      if (error) throw error;
      
      // Filter out both 'Body Comp' and any vitamins/products categories
      const filteredCategories = (data || []).filter(
        category => 
          category.name.toLowerCase() !== 'body comp' &&
          !category.name.toLowerCase().includes('vitamin') &&
          !category.name.toLowerCase().includes('product')
      );
      
      setCategories(filteredCategories);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  const fetchProcedures = async (categoryId: string) => {
    try {
      setLoading(true);
      let query = supabase
        .from('procedure_category_mappings')
        .select(`
          procedure_code,
          procedure_codes!inner (
            code,
            description,
            medication_type,
            procedure_pricing!inner (
              price
            )
          )
        `)
        .eq('category_id', categoryId)
        .eq('procedure_codes.is_active', true);

      if (medicationType) {
        query = query.eq('procedure_codes.medication_type', medicationType);
      }

      const { data, error } = await query;

      if (error) throw error;

      setProcedures(
        data?.map(item => ({
          code: item.procedure_codes.code,
          description: item.procedure_codes.description,
          price: item.procedure_codes.procedure_pricing[0].price
        })) || []
      );
    } catch (error) {
      console.error('Error fetching procedures:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleCategoryClick = async (category: Category) => {
    if (!medicationType) return;
    
    setSelectedCategory(category);
    await fetchProcedures(category.id);
    setShowProductSelection(true);
  };

  const handleAddToCart = (procedure: ProcedureCode) => {
    setCart(prev => {
      const existingItem = prev.find(item => item.code === procedure.code);
      if (existingItem) {
        return prev.map(item =>
          item.code === procedure.code
            ? { ...item, quantity: item.quantity + 1 }
            : item
        );
      }
      return [...prev, { 
        ...procedure, 
        quantity: 1,
        categoryName: selectedCategory?.name,
        medicationType: medicationType
      }];
    });
  };

  const handleUpdateQuantity = (code: string, delta: number) => {
    setCart(prev => {
      return prev.map(item => {
        if (item.code === code) {
          const newQuantity = item.quantity + delta;
          return newQuantity > 0 ? { ...item, quantity: newQuantity } : item;
        }
        return item;
      });
    });
  };

  const handleRemoveItem = (code: string) => {
    setCart(prev => prev.filter(item => item.code !== code));
  };

  const calculateTotal = () => {
    return cart.reduce((sum, item) => sum + (item.price * item.quantity), 0);
  };

  const handleSave = async () => {
    if (!patient?.id || !appointmentDate) return;

    try {
      setSaving(true);

      // Delete existing medications
      await supabase
        .from('medications_administered')
        .delete()
        .eq('external_patient_id', patient.id)
        .eq('service_date', appointmentDate);

      // Insert new medications if any
      if (cart.length > 0) {
        const medications = cart.map(item => ({
          external_patient_id: patient.id,
          service_date: appointmentDate,
          procedure_code: item.code,
          units: item.quantity,
          claim_id: 'THRIVE'
        }));

        const { error } = await supabase
          .from('medications_administered')
          .insert(medications);

        if (error) throw error;
      }

      setInitialCart(cart);
      setHasChanges(false);
      
      // Trigger a refresh of the appointment list by closing the modal
      onClose();
    } catch (error) {
      console.error('Error saving medications:', error);
    } finally {
      setSaving(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div 
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50"
      onClick={(e) => {
        if (e.target === e.currentTarget) {
          handleClose();
        }
      }}
    >
      <div className="bg-white rounded-lg shadow-xl w-full max-w-6xl max-h-[90vh] flex flex-col">
        {/* Header */}
        <div className="px-6 py-4 border-b border-gray-200">
          <div className="flex items-center justify-between">
            <h2 className="text-xl font-semibold text-gray-900">Medications Administered</h2>
            <button
              onClick={handleClose}
              className="text-gray-400 hover:text-gray-500"
            >
              <X className="h-5 w-5" />
            </button>
          </div>
        </div>

        {/* Patient Info */}
        {patient && (
          <div className="px-6 py-4 bg-gray-50 border-b border-gray-200">
            <div className="flex items-center justify-between">
              <div>
                <h3 className="text-lg font-medium text-gray-900">
                  {patient.firstName} {patient.lastName}
                </h3>
                {patient.memberships && patient.memberships.length > 0 ? (
                  <div className="mt-2">
                    <MembershipStatus memberships={patient.memberships} />
                  </div>
                ) : (
                  <div className="mt-1 text-sm text-gray-500 flex items-center">
                    <CreditCard className="h-4 w-4 mr-1" />
                    No active memberships
                  </div>
                )}
              </div>
              {appointmentDate && (
                <div className="text-sm text-gray-500">
                  Service Date: {new Date(appointmentDate).toLocaleDateString()}
                </div>
              )}
            </div>
          </div>
        )}

        {/* Main Content Area */}
        <div className="flex-1 flex overflow-hidden">
          {/* Left Column - Categories, Previous Meds, Type Selection */}
          <div className="w-1/3 border-r border-gray-200 flex flex-col">
            {/* Previous Medication */}
            {previousMedication && (
              <div className="p-4 border-b border-gray-200">
                <div className="flex items-center mb-2">
                  <History className="h-4 w-4 text-gray-500 mr-2" />
                  <h4 className="text-sm font-medium text-gray-700">Last Medication Given</h4>
                </div>
                <div className="bg-gray-50 rounded-lg p-3">
                  <div className="text-sm font-medium text-gray-900">
                    {previousMedication.description}
                  </div>
                  <div className="flex items-center justify-between mt-1">
                    <div className="flex items-center space-x-2 text-xs text-gray-500">
                      <span>{formatPacificDate(previousMedication.service_date)}</span>
                      {previousMedication.form !== 'none' && (
                        <>
                          <span>•</span>
                          <span className="capitalize">{previousMedication.form}</span>
                        </>
                      )}
                    </div>
                    <span className="text-xs font-medium text-gray-600">
                      {previousMedication.units} unit{previousMedication.units !== 1 ? 's' : ''}
                    </span>
                  </div>
                </div>
              </div>
            )}

            {/* Body Composition Button */}
            <div className="p-4 border-b border-gray-200">
              <button
                onClick={handleBodyCompToggle}
                disabled={bodyCompStatus.loading}
                className={`w-full py-3 px-4 rounded-lg border-2 transition-all duration-200 ${
                  bodyCompStatus.loading
                    ? 'opacity-50 cursor-not-allowed border-gray-200 bg-gray-50'
                    : bodyCompStatus.hasBodyComp
                    ? 'border-green-500 bg-green-50 text-green-700'
                    : 'border-gray-200 hover:border-green-500 hover:bg-green-50'
                }`}
              >
                <div className="flex items-center justify-center">
                  <Ruler className={`h-5 w-5 mr-2 ${
                    bodyCompStatus.hasBodyComp ? 'text-green-600' : 'text-gray-400'
                  }`} />
                  <span className="font-medium">
                    {bodyCompStatus.loading
                      ? 'Updating...'
                      : bodyCompStatus.hasBodyComp
                      ? 'Body Composition Recorded'
                      : 'Record Body Composition'}
                  </span>
                </div>
              </button>
            </div>

            {/* Medication Type Selection */}
            <div className="p-4 border-b border-gray-200">
              <h4 className="text-sm font-medium text-gray-700 mb-3">Medication Type</h4>
              <div className="flex gap-3">
                <button
                  onClick={() => setMedicationType('semaglutide')}
                  className={`flex items-center px-4 py-2 rounded-lg border-2 transition-colors duration-200 ${
                    medicationType === 'semaglutide'
                      ? 'bg-green-50 border-green-500 text-green-700'
                      : 'border-gray-200 text-gray-700 hover:bg-gray-50'
                  }`}
                >
                  <Pill className="h-4 w-4 mr-2" />
                  Semaglutide
                </button>
                <button
                  onClick={() => setMedicationType('tirzepatide')}
                  className={`flex items-center px-4 py-2 rounded-lg border-2 transition-colors duration-200 ${
                    medicationType === 'tirzepatide'
                      ? 'bg-green-50 border-green-500 text-green-700'
                      : 'border-gray-200 text-gray-700 hover:bg-gray-50'
                  }`}
                >
                  <Syringe className="h-4 w-4 mr-2" />
                  Tirzepatide
                </button>
              </div>
            </div>

            {/* Categories */}
            <div className="flex-1 overflow-y-auto p-4">
              <div className="grid grid-cols-1 gap-4">
                {categories.map(category => (
                  <button
                    key={category.id}
                    onClick={() => handleCategoryClick(category)}
                    disabled={!medicationType}
                    className={`w-full p-4 text-left rounded-lg border-2 transition-all duration-200 ${
                      !medicationType
                        ? 'opacity-50 cursor-not-allowed border-gray-200 bg-gray-50'
                        : 'hover:border-green-500 hover:shadow-md border-gray-200'
                    }`}
                  >
                    <h3 className="font-medium text-gray-900">{category.name}</h3>
                  </button>
                ))}
              </div>
            </div>
          </div>

          {/* Right Column - Cart */}
          <div className="w-2/3 bg-green-50 flex flex-col">
            <div className="flex-1 overflow-y-auto p-6 space-y-4">
              {cart.length === 0 ? (
                <div className="text-center text-gray-500 py-8">
                  No items selected
                </div>
              ) : (
                cart.map(item => (
                  <div 
                    key={item.code}
                    className="bg-white rounded-lg p-4 shadow-sm"
                  >
                    <div className="flex items-start justify-between">
                      <div>
                        <div className="font-medium text-gray-900">{item.description}</div>
                        <div className="text-sm text-gray-500 mt-1">
                          ${item.price.toFixed(2)} each
                        </div>
                      </div>
                      <div className="flex items-center space-x-3">
                        <button
                          onClick={() => handleUpdateQuantity(item.code, -1)}
                          className="text-gray-400 hover:text-gray-600"
                          disabled={item.quantity <= 1}
                        >
                          <Minus className="h-4 w-4" />
                        </button>
                        <span className="text-lg font-medium w-8 text-center">
                          {item.quantity}
                        </span>
                        <button
                          onClick={() => handleUpdateQuantity(item.code, 1)}
                          className="text-gray-400 hover:text-gray-600"
                        >
                          <Plus className="h-4 w-4" />
                        </button>
                        <button
                          onClick={() => handleRemoveItem(item.code)}
                          className="text-red-400 hover:text-red-600"
                        >
                          <Trash2 className="h-4 w-4" />
                        </button>
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>

            <div className="p-6 bg-white border-t border-gray-200 space-y-4">
              {/* Total Box */}
              <div className="flex items-center justify-between text-lg font-medium bg-white border border-gray-200 p-4 rounded-lg">
                <span>Total:</span>
                <div className="flex items-center">
                  <DollarSign className="h-5 w-5 mr-1" />
                  {calculateTotal().toFixed(2)}
                </div>
              </div>

              {/* Payment Disclaimer */}
              <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4">
                <div className="flex">
                  <AlertCircle className="h-5 w-5 text-yellow-400 flex-shrink-0" />
                  <div className="ml-3">
                    <p className="text-sm text-yellow-700">
                      Please disregard dollar values for now. All medications that require payment should be processed through Athena until further notice.
                    </p>
                  </div>
                </div>
              </div>
              
              <div className="flex space-x-3">
                <button
                  onClick={handleClose}
                  className="flex-1 px-4 py-2 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200"
                  disabled={saving}
                >
                  Cancel
                </button>
                <button
                  onClick={handleSave}
                  className="flex-1 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 disabled:opacity-50"
                  disabled={saving}
                >
                  {saving ? 'Saving...' : 'Save'}
                </button>
                <button
                  disabled
                  className="flex-1 px-4 py-2 bg-green-600 text-white rounded-lg opacity-50 cursor-not-allowed"
                >
                  Take Payment
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showConfirmDialog && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[60]">
          <div className="bg-white rounded-lg p-6 max-w-md w-full mx-4">
            <div className="flex items-center mb-4">
              <AlertCircle className="h-6 w-6 text-yellow-500 mr-2" />
              <h3 className="text-lg font-medium text-gray-900">
                Unsaved Changes
              </h3>
            </div>
            <p className="text-gray-500 mb-6">
              You have unsaved changes. Would you like to save them before closing?
            </p>
            <div className="flex justify-end space-x-3">
              <button
                onClick={handleConfirmClose}
                className="px-4 py-2 text-gray-700 hover:bg-gray-100 rounded-md"
              >
                Discard
              </button>
              <button
                onClick={handleCancelClose}
                className="px-4 py-2 text-gray-700 hover:bg-gray-100 rounded-md"
              >
                Cancel
              </button>
              <button
                onClick={handleSave}
                className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}

      <ProductSelectionModal
        isOpen={showProductSelection}
        onClose={() => setShowProductSelection(false)}
        categoryName={selectedCategory?.name || ''}
        medicationType={medicationType}
        procedures={procedures}
        loading={loading}
        onSelect={handleAddToCart}
      />
    </div>
  );
}