import { validatePatientMedicationsReport } from './reports/patientMedicationsValidator';
import { processPatientMedicationsReport } from './reports/patientMedicationsProcessor';
import { validateBodyCompositionReport } from './reports/bodyCompositionValidator';
import { processBodyCompositionReport } from './reports/bodyCompositionProcessor';
import { validateAppointmentsReport } from './reports/appointmentsValidator';
import { processAppointmentsReport } from './reports/appointmentsProcessor';
import { validateWeightMeasurementsReport } from './reports/weightMeasurementsValidator';
import { processWeightMeasurementsReport } from './reports/weightMeasurementsProcessor';
import { validateMembershipReport } from './reports/membershipValidator';
import { processMembershipReport } from './reports/membershipProcessor';
import Papa from 'papaparse';

export type ReportType = 
  | 'ACCESS_DATA_WTGMGT' 
  | 'ACCESS_DATA_BODY_COMP'
  | 'ACCESS_DATA_VISITS'
  | 'ACCESS_DATA_WEIGHT'
  | 'MEMBERSHIP_REPORT'
  | 'FUTURE_REPORT_1' 
  | 'FUTURE_REPORT_2';

export interface ProcessingProgress {
  currentLine: number;
  totalLines: number;
  processedCount: number;
  skippedCount: number;
}

export interface ProcessingResult {
  success: boolean;
  message: string;
  processedCount: number;
  skippedCount: number;
}

function parseCSV(csvContent: string): string[] {
  try {
    console.log('Parsing CSV content...');
    
    // Split into lines and process each line
    const lines = csvContent.split('\n').map(line => {
      let result = '';
      let inQuotes = false;
      let currentField = '';
      let i = 0;

      // Process each character in the line
      while (i < line.length) {
        const char = line[i];
        const nextChar = line[i + 1];

        if (char === '"') {
          if (nextChar === '"') {
            // Double quotes - add a single quote to the field
            currentField += '"';
            i += 2;
          } else {
            // Toggle quote mode
            inQuotes = !inQuotes;
            i++;
          }
        } else if (char === ',' && !inQuotes) {
          // End of field - add to result
          result += currentField + ',';
          currentField = '';
          i++;
        } else {
          // Regular character - if in quotes and it's a comma, replace with space
          currentField += inQuotes && char === ',' ? ' ' : char;
          i++;
        }
      }

      // Add the last field
      result += currentField;

      // Clean up any remaining quotes at the start/end of fields
      const fields = result.split(',').map(field => {
        field = field.trim();
        if (field.startsWith('"') && field.endsWith('"')) {
          field = field.slice(1, -1);
        }
        return field;
      });

      return fields.join(',');
    });

    // Filter out empty lines
    const nonEmptyLines = lines.filter(line => line.trim() !== '');

    console.log('CSV parsing successful. Found', nonEmptyLines.length, 'non-empty lines');
    console.log('Headers:', nonEmptyLines[0]);
    console.log('First few rows:', nonEmptyLines.slice(1, 4));

    return nonEmptyLines;
  } catch (error) {
    console.error('Error parsing CSV:', error);
    throw new Error(`Failed to parse CSV content: ${error.message}`);
  }
}

export function identifyReportType(firstLine: string): ReportType | null {
  try {
    // Clean up the line and standardize format
    const cleanLine = firstLine.trim().toUpperCase();
    console.log('Attempting to identify report type from first line:', cleanLine);

    // Check for membership report - more flexible matching
    if (cleanLine.includes('MEMBERSHIPS REPORT') || 
        cleanLine.includes('MEMBERSHIP REPORT') ||
        cleanLine.match(/MEMBERS?\s+REPORT/i)) {
      console.log('Identified as MEMBERSHIP_REPORT');
      return 'MEMBERSHIP_REPORT';
    }

    // Check for known report types
    if (cleanLine.includes('REPORT NAME : ACCESS DATA - WTGMGT PTS LAST WEEK')) {
      console.log('Identified as ACCESS_DATA_WTGMGT');
      return 'ACCESS_DATA_WTGMGT';
    }
    
    if (cleanLine.includes('REPORT NAME : ACCESS DATA - WTGMGT BODY COMPS LAST WK')) {
      console.log('Identified as ACCESS_DATA_BODY_COMP');
      return 'ACCESS_DATA_BODY_COMP';
    }

    if (cleanLine.includes('REPORT NAME : ACCESS DATA - WTGMGT VISITS')) {
      console.log('Identified as ACCESS_DATA_VISITS');
      return 'ACCESS_DATA_VISITS';
    }

    if (cleanLine.includes('REPORT NAME : ACCESS DATA - WTGMGT WEIGHT 10 DAYS')) {
      console.log('Identified as ACCESS_DATA_WEIGHT');
      return 'ACCESS_DATA_WEIGHT';
    }

    console.log('No matching report type found for line:', cleanLine);
    return null;
  } catch (error) {
    console.error('Error identifying report type:', error);
    return null;
  }
}

export async function processReport(
  fileContent: string | File,
  onProgress?: (progress: ProcessingProgress) => void
): Promise<ProcessingResult> {
  try {
    let lines: string[];
    console.log('Starting report processing...');
    
    if (fileContent instanceof File) {
      console.log('Processing file:', {
        name: fileContent.name,
        type: fileContent.type,
        size: fileContent.size
      });
      
      // Only accept CSV files
      const extension = fileContent.name.split('.').pop()?.toLowerCase();
      if (extension !== 'csv') {
        return {
          success: false,
          message: 'Only CSV files are supported. Please upload a .csv file.',
          processedCount: 0,
          skippedCount: 0
        };
      }
      
      // Read the CSV file
      const text = await fileContent.text();
      lines = parseCSV(text);
    } else {
      if (typeof fileContent !== 'string') {
        return {
          success: false,
          message: 'Invalid input type. Expected CSV file or string.',
          processedCount: 0,
          skippedCount: 0
        };
      }
      
      lines = parseCSV(fileContent);
    }
    
    if (lines.length === 0) {
      return {
        success: false,
        message: 'File is empty or contains no valid data',
        processedCount: 0,
        skippedCount: 0
      };
    }

    console.log('Total lines found:', lines.length);
    console.log('First line:', lines[0]);

    // Identify report type from first line
    const reportType = identifyReportType(lines[0]);
    
    if (!reportType) {
      return {
        success: false,
        message: 'Unknown or unsupported report type. Please ensure you are using a valid report format.',
        processedCount: 0,
        skippedCount: 0
      };
    }

    console.log('Processing report of type:', reportType);

    // Process based on report type
    switch (reportType) {
      case 'MEMBERSHIP_REPORT':
        const membershipValidation = validateMembershipReport(lines);
        if (!membershipValidation.success) {
          return membershipValidation;
        }
        return await processMembershipReport(lines, onProgress);

      case 'ACCESS_DATA_WTGMGT':
        const validationResult = validatePatientMedicationsReport(lines);
        if (!validationResult.success) {
          return validationResult;
        }
        return await processPatientMedicationsReport(lines, onProgress);

      case 'ACCESS_DATA_BODY_COMP':
        const bodyCompValidation = validateBodyCompositionReport(lines);
        if (!bodyCompValidation.success) {
          return bodyCompValidation;
        }
        return await processBodyCompositionReport(lines, onProgress);

      case 'ACCESS_DATA_VISITS':
        const appointmentsValidation = validateAppointmentsReport(lines);
        if (!appointmentsValidation.success) {
          return appointmentsValidation;
        }
        return await processAppointmentsReport(lines, onProgress);

      case 'ACCESS_DATA_WEIGHT':
        const weightValidation = validateWeightMeasurementsReport(lines);
        if (!weightValidation.success) {
          return weightValidation;
        }
        return await processWeightMeasurementsReport(lines, onProgress);

      default:
        return {
          success: false,
          message: 'Unsupported report type',
          processedCount: 0,
          skippedCount: 0
        };
    }
  } catch (error: any) {
    console.error('Error processing report:', error);
    return {
      success: false,
      message: `Error processing file: ${error.message}`,
      processedCount: 0,
      skippedCount: 0
    };
  }
}