import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  ArrowLeft, 
  Leaf, 
  Search, 
  AlertCircle,
  Check,
  Info,
  Link,
  Link2Off,
  FileCode2
} from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import { supabase } from '../lib/supabase';
import { ProcedureCodeModal } from '../components/ProcedureCodeModal';

interface ProcedureCode {
  code: string;
  description: string | null;
  milligrams: number | null;
  form: string;
  medication_type: string;
  mapped: boolean;
}

export function AdministeredMedicationsMapping() {
  const navigate = useNavigate();
  const { userRoles } = useAuth();
  const isAdmin = userRoles.includes('admin');
  const [procedureCodes, setProcedureCodes] = useState<ProcedureCode[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [showMappedOnly, setShowMappedOnly] = useState(false);
  const [showUnmappedOnly, setShowUnmappedOnly] = useState(false);
  const [selectedCode, setSelectedCode] = useState<ProcedureCode | null>(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (!isAdmin) {
      navigate('/settings');
      return;
    }
    fetchProcedureCodes();
  }, [isAdmin, navigate]);

const fetchProcedureCodes = async () => {
  try {
    const { data, error } = await supabase
      .rpc('get_administered_medications');

    if (error) throw error;
    
    // Log the received data
    console.log('Received procedure codes:', {
      total: data?.length || 0,
      mapped: data?.filter(c => c.mapped).length || 0,
      unmapped: data?.filter(c => !c.mapped).length || 0,
      codes: data
    });

    setProcedureCodes(data || []);
  } catch (error: any) {
    console.error('Error fetching procedure codes:', error);
    setError(error.message);
  } finally {
    setLoading(false);
  }
};



  const handleModalSuccess = async () => {
    await fetchProcedureCodes();
    setSuccess('Procedure code updated successfully');
    setTimeout(() => setSuccess(null), 3000);
  };

  const filteredCodes = procedureCodes.filter(code => {
    const matchesSearch = 
      code.code.toLowerCase().includes(searchTerm.toLowerCase()) ||
      code.description?.toLowerCase().includes(searchTerm.toLowerCase());

    const matchesFilter = 
      (!showMappedOnly && !showUnmappedOnly) ||
      (showMappedOnly && code.mapped) ||
      (showUnmappedOnly && !code.mapped);

    return matchesSearch && matchesFilter;
  });

  if (!isAdmin) return null;

  return (
    <div className="min-h-screen bg-gradient-to-br from-green-50 to-blue-50">
      <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        <div className="px-4 py-4 sm:px-0">
          {/* Header */}
          <div className="bg-white shadow-lg rounded-lg mb-6 overflow-hidden">
            <div className="relative">
              <div className="absolute inset-0 bg-gradient-to-r from-green-600 to-blue-600 opacity-90"></div>
              <div className="relative px-8 py-12">
                <div className="flex items-center justify-between">
                  <button
                    onClick={() => navigate('/settings')}
                    className="text-white hover:text-green-100 transition-colors duration-200"
                  >
                    <ArrowLeft className="h-6 w-6" />
                  </button>
                  <Leaf className="h-12 w-12 text-white opacity-20" />
                </div>
                
                <div className="mt-4 text-center">
                  <h1 className="text-4xl font-bold text-white mb-2">THRIVE</h1>
                  <p className="text-green-100 text-lg mb-4">
                    Tracking Health and Reducing Illness Via Efficiency
                  </p>
                  <div className="w-16 h-1 bg-white/30 mx-auto rounded-full"></div>
                  <h2 className="text-2xl font-semibold text-white mt-4">Administered Medications Mapping</h2>
                </div>
              </div>
            </div>
          </div>

          {/* Main Content */}
          <div className="bg-white shadow-lg rounded-lg overflow-hidden">
            <div className="px-6 py-5 border-b border-gray-200">
              <div className="flex items-center">
                <FileCode2 className="h-5 w-5 text-green-600" />
                <h3 className="ml-2 text-lg font-medium text-gray-900">
                  Procedure Code Mapping
                </h3>
              </div>
            </div>

            {/* Search and Filters */}
            <div className="px-6 py-4 border-b border-gray-200">
              <div className="flex flex-col sm:flex-row gap-4">
                <div className="flex-1">
                  <div className="relative">
                    <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
                    <input
                      type="text"
                      placeholder="Search procedure codes..."
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      className="pl-10 pr-4 py-2 w-full border border-gray-300 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                    />
                  </div>
                </div>
                <div className="flex gap-2">
                  <button
                    onClick={() => {
                      setShowMappedOnly(true);
                      setShowUnmappedOnly(false);
                    }}
                    className={`px-4 py-2 rounded-lg border ${
                      showMappedOnly
                        ? 'bg-green-50 border-green-500 text-green-700'
                        : 'border-gray-300 text-gray-700 hover:bg-gray-50'
                    }`}
                  >
                    <Link className="h-4 w-4 inline mr-2" />
                    Mapped Only
                  </button>
                  <button
                    onClick={() => {
                      setShowMappedOnly(false);
                      setShowUnmappedOnly(true);
                    }}
                    className={`px-4 py-2 rounded-lg border ${
                      showUnmappedOnly
                        ? 'bg-yellow-50 border-yellow-500 text-yellow-700'
                        : 'border-gray-300 text-gray-700 hover:bg-gray-50'
                    }`}
                  >
                    <Link2Off className="h-4 w-4 inline mr-2" />
                    Unmapped Only
                  </button>
                  <button
                    onClick={() => {
                      setShowMappedOnly(false);
                      setShowUnmappedOnly(false);
                    }}
                    className={`px-4 py-2 rounded-lg border ${
                      !showMappedOnly && !showUnmappedOnly
                        ? 'bg-gray-50 border-gray-500 text-gray-700'
                        : 'border-gray-300 text-gray-700 hover:bg-gray-50'
                    }`}
                  >
                    Show All
                  </button>
                </div>
              </div>
            </div>

            {/* Messages */}
            {error && (
              <div className="px-6 py-4 bg-red-50">
                <div className="flex">
                  <AlertCircle className="h-5 w-5 text-red-400" />
                  <p className="ml-3 text-sm text-red-700">{error}</p>
                </div>
              </div>
            )}

            {success && (
              <div className="px-6 py-4 bg-green-50">
                <div className="flex">
                  <Check className="h-5 w-5 text-green-400" />
                  <p className="ml-3 text-sm text-green-700">{success}</p>
                </div>
              </div>
            )}

            {/* Procedure Codes List */}
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Code
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Status
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Description
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Details
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {loading ? (
                    <tr>
                      <td colSpan={4} className="px-6 py-4 text-center text-gray-500">
                        Loading procedure codes...
                      </td>
                    </tr>
                  ) : filteredCodes.length === 0 ? (
                    <tr>
                      <td colSpan={4} className="px-6 py-4 text-center text-gray-500">
                        <div className="flex flex-col items-center">
                          <Info className="h-12 w-12 text-gray-400 mb-2" />
                          <p>No procedure codes found</p>
                        </div>
                      </td>
                    </tr>
                  ) : (
                    filteredCodes.map((code) => (
                      <tr 
                        key={code.code} 
                        className="hover:bg-gray-50 cursor-pointer"
                        onClick={() => {
                          setSelectedCode(code);
                          setShowModal(true);
                        }}
                      >
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          {code.code}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                            code.mapped
                              ? 'bg-green-100 text-green-800'
                              : 'bg-yellow-100 text-yellow-800'
                          }`}>
                            {code.mapped ? (
                              <>
                                <Link className="h-3 w-3 mr-1" />
                                Mapped
                              </>
                            ) : (
                              <>
                                <Link2Off className="h-3 w-3 mr-1" />
                                Unmapped
                              </>
                            )}
                          </span>
                        </td>
                        <td className="px-6 py-4 text-sm text-gray-500">
                          {code.description || 'No description'}
                        </td>
                        <td className="px-6 py-4 text-sm text-gray-500">
                          {[
                            code.milligrams && `${code.milligrams} mg`,
                            code.form !== 'none' && code.form,
                            code.medication_type !== 'none' && code.medication_type
                          ]
                            .filter(Boolean)
                            .map(detail => 
                              typeof detail === 'string' 
                                ? detail.charAt(0).toUpperCase() + detail.slice(1) 
                                : detail
                            )
                            .join(' • ')}
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <ProcedureCodeModal
        procedureCode={selectedCode}
        isOpen={showModal}
        onClose={() => {
          setShowModal(false);
          setSelectedCode(null);
        }}
        onSuccess={handleModalSuccess}
      />
    </div>
  );
}