import React, { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft, Leaf } from 'lucide-react';
import * as XLSX from 'xlsx';
import { supabase } from '../lib/supabase';
import { FileUploader } from '../components/membership/FileUploader';
import { DataPreview } from '../components/membership/DataPreview';
import { ProcessingLog } from '../components/membership/ProcessingLog';
import { ProcessingStats } from '../components/membership/ProcessingStats';
import { ImportInstructions } from '../components/membership/ImportInstructions';
import { validateDateTime, formatDateForDatabase } from '../utils/membership/dateUtils';
import { parseChargeAmount } from '../utils/membership/amountUtils';
import { verifyMembershipDetails } from '../utils/membership/verificationUtils';

interface PreviewData {
  headers: string[];
  rows: any[][];
}

interface ProcessingStats {
  total: number;
  processed: number;
  skipped: number;
  errors: number;
}

type LogType = 'info' | 'error' | 'success' | 'data' | 'verification';

interface LogEntry {
  type: LogType;
  message: string;
  timestamp: Date;
  data?: any;
}

type MembershipStatus = 'active' | 'inactive' | 'cancelled' | 'expired' | 'pending' | 'failed' | 'declined';

const normalizeStatus = (status: string): MembershipStatus => {
  const normalized = status.toLowerCase().trim();
  
  switch (normalized) {
    case 'active':
    case 'current':
    case 'valid':
      return 'active';
    
    case 'inactive':
    case 'dormant':
      return 'inactive';
    
    case 'cancelled':
    case 'canceled':
    case 'terminated':
      return 'cancelled';
    
    case 'expired':
    case 'lapsed':
      return 'expired';
    
    case 'pending':
    case 'processing':
    case 'in progress':
      return 'pending';
    
    case 'failed':
    case 'failure':
    case 'error':
      return 'failed';
    
    case 'declined':
    case 'rejected':
      return 'declined';
    
    default:
      return 'pending';
  }
};

export function MembershipActivityImport() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [preview, setPreview] = useState<PreviewData | null>(null);
  const [logs, setLogs] = useState<LogEntry[]>([]);
  const [stats, setStats] = useState<ProcessingStats | null>(null);

  const addLog = (type: LogType, message: string, data?: any) => {
    setLogs(prev => [...prev, { type, message, timestamp: new Date(), data }]);
  };

  const processFile = async (file: File) => {
    setLoading(true);
    setStats(null);
    addLog('info', `Processing file: ${file.name}`);
    addLog('info', `File size: ${(file.size / 1024).toFixed(2)} KB`);

    const reader = new FileReader();
    
    reader.onload = async (e) => {
      try {
        const data = e.target?.result;
        addLog('info', 'File loaded successfully, parsing contents...');
        
        const workbook = XLSX.read(data, { type: 'binary', cellDates: true });
        addLog('info', `Workbook loaded with ${workbook.SheetNames.length} sheet(s)`);
        
        const firstSheetName = workbook.SheetNames[0];
        addLog('info', `Processing first sheet: ${firstSheetName}`);
        
        const worksheet = workbook.Sheets[firstSheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { 
          header: 1,
          raw: false,
          dateNF: 'yyyy-mm-dd'
        });
        
        if (jsonData.length < 2) {
          throw new Error('File has insufficient data');
        }

        // Set up preview data
        const previewHeaders = [
          'Client ID',
          'Membership',
          'Status',
          'Charge Amount',
          'Date Created',
          'Start Date',
          'Interval',
          'Last Run',
          'Last Run Status',
          'Next Run Date'
        ];

        const previewRows = jsonData.slice(3, 8).map(row => [
          (row as any[])[2] || '',  // Client ID
          (row as any[])[4] || '',  // Membership
          (row as any[])[5] || '',  // Status
          (row as any[])[6] || '',  // Charge Amount
          (row as any[])[7] || '',  // Date Created
          (row as any[])[8] || '',  // Start Date
          (row as any[])[9] || '',  // Interval
          (row as any[])[10] || '', // Last Run
          (row as any[])[11] || '', // Last Run Status
          (row as any[])[13] || ''  // Next Run Date
        ]);

        setPreview({ headers: previewHeaders, rows: previewRows });

        // Process the data rows
        const rows = jsonData.slice(3) as any[][]; // Skip header rows
        addLog('info', `Found ${rows.length} records to process`);

        const stats: ProcessingStats = {
          total: rows.length,
          processed: 0,
          skipped: 0,
          errors: 0
        };

        // Process each row
        for (let i = 0; i < rows.length; i++) {
          const row = rows[i];
          const clientId = row[2];
          const membership = row[4];
          const rawStatus = row[5] || 'pending';
          const status = normalizeStatus(rawStatus);
          const chargeAmount = parseChargeAmount(row[6]);
          const internalInterval = row[9]?.trim() || '';

          // Validate and parse dates
          const dateCreated = validateDateTime(row[7]);
          const startDate = validateDateTime(row[8]);
          const lastRun = validateDateTime(row[10]);
          const nextRunDate = validateDateTime(row[13]);

          // Log the data being processed
          addLog('data', 'Processing record:', {
            clientId,
            membership,
            status,
            chargeAmount,
            internalInterval,
            dateCreated: dateCreated?.toISOString(),
            startDate: startDate?.toISOString(),
            lastRun: lastRun?.toISOString(),
            nextRunDate: nextRunDate?.toISOString()
          });

          if (!clientId || !membership) {
            stats.skipped++;
            addLog('error', `Skipping row ${i + 1}: Missing required data (Client ID or Membership)`);
            continue;
          }

          if (!dateCreated || !startDate) {
            stats.skipped++;
            addLog('error', `Skipping row ${i + 1}: Invalid date format for Created Date or Start Date`);
            continue;
          }

          try {
            // First get the membership_patient_id
            const { data: patientData, error: patientError } = await supabase
              .from('membership_patients')
              .select('id')
              .eq('external_id', clientId)
              .single();

            if (patientError) throw patientError;
            if (!patientData) {
              addLog('error', `No membership patient found for client ID: ${clientId}`);
              stats.skipped++;
              continue;
            }

            // Process membership details
            const { data: membershipData, error: membershipError } = await supabase.rpc(
              'process_membership_details',
              {
                p_membership_patient_id: patientData.id,
                p_membership_name: membership,
                p_charge_amount: chargeAmount,
                p_status: status,
                p_date_created: formatDateForDatabase(dateCreated),
                p_start_date: formatDateForDatabase(startDate),
                p_internal_interval: internalInterval,
                p_last_run: formatDateForDatabase(lastRun),
                p_next_run_date: formatDateForDatabase(nextRunDate)
              }
            );

            if (membershipError) throw membershipError;

            // Verify the saved data
            const verifiedData = await verifyMembershipDetails(patientData.id);
            if (verifiedData) {
              addLog('verification', 'Verified saved membership details:', {
                ...verifiedData,
                internal_interval: verifiedData.internal_interval || 'Not set'
              });
            }

            stats.processed++;
            addLog('success', `Processed membership record ${stats.processed} of ${stats.total}`);
          } catch (error: any) {
            console.error('Error processing row:', error);
            stats.errors++;
            addLog('error', `Error processing record ${i + 1}: ${error.message}`);
          }

          // Update stats every 10 records
          if (i % 10 === 0) {
            setStats({ ...stats });
          }
        }

        setStats(stats);
        addLog('success', 'File processing complete');
        addLog('info', `Processed: ${stats.processed}, Skipped: ${stats.skipped}, Errors: ${stats.errors}`);

      } catch (error: any) {
        addLog('error', `Error processing file: ${error.message}`);
        console.error('Error:', error);
      } finally {
        setLoading(false);
      }
    };

    reader.onerror = () => {
      addLog('error', 'Error reading file');
      setLoading(false);
    };

    reader.readAsBinaryString(file);
  };

  const onDrop = useCallback((acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) {
      processFile(acceptedFiles[0]);
    }
  }, []);

  return (
    <div className="min-h-screen bg-gradient-to-br from-green-50 to-blue-50">
      <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        <div className="px-4 py-4 sm:px-0">
          <div className="flex items-center mb-6">
            <button
              onClick={() => navigate('/settings')}
              className="mr-4 text-gray-600 hover:text-gray-900"
            >
              <ArrowLeft className="h-6 w-6" />
            </button>
            <h1 className="text-2xl font-bold text-gray-900">Import Membership Activity</h1>
          </div>

          <div className="bg-white shadow-lg rounded-lg overflow-hidden">
            <div className="relative">
              <div className="absolute inset-0 bg-gradient-to-r from-green-600 to-blue-600 opacity-90"></div>
              <div className="relative px-8 py-12">
                <div className="flex items-center justify-between">
                  <Leaf className="h-12 w-12 text-white opacity-20" />
                </div>
                <div className="mt-4 text-center">
                  <h1 className="text-4xl font-bold text-white mb-2">THRIVE</h1>
                  <p className="text-green-100 text-lg mb-4">
                    Tracking Health and Reducing Illness Via Efficiency
                  </p>
                  <div className="w-16 h-1 bg-white/30 mx-auto rounded-full"></div>
                  <h2 className="text-2xl font-semibold text-white mt-4">Membership Activity Import</h2>
                </div>
              </div>
            </div>

            <div className="p-6">
              <ImportInstructions />
              <FileUploader onDrop={onDrop} />
              {preview && <DataPreview headers={preview.headers} rows={preview.rows} />}
              <ProcessingLog logs={logs} />
              <ProcessingStats stats={stats} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

