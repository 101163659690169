import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  ArrowLeft, 
  Leaf, 
  LogOut,
  DollarSign,
  ChevronLeft,
  ChevronRight,
  Users,
  TrendingUp,
  Clock,
  UserCheck
} from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import { supabase } from '../lib/supabase';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartData,
  ChartOptions
} from 'chart.js';
import { format, parseISO } from 'date-fns';
import { formatCurrency } from '../utils/formatters';
import { formatPacificDateTime } from '../utils/dateTime';

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

interface Transaction {
  membership_id: string;
  membership_name: string;
  charge_amount: number;
  charge_date: string;
  charge_status: string;
}

export function MembershipIncome() {
  const navigate = useNavigate();
  const { user, userRoles, signOut } = useAuth();
  const isAdmin = userRoles.includes('admin');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [monthlyIncome, setMonthlyIncome] = useState<number>(0);
  const [uniquePatients, setUniquePatients] = useState<number>(0);
  const [incomeHistory, setIncomeHistory] = useState<{month: string; amount: number}[]>([]);
  const [memberHistory, setMemberHistory] = useState<{month: string; count: number}[]>([]);
  const [transactions, setTransactions] = useState<Transaction[]>([]);

  // Redirect non-admin users
  if (!isAdmin) {
    navigate('/settings');
    return null;
  }

  useEffect(() => {
    fetchMonthlyData();
    fetchHistoricalData();
    fetchTransactions();
    fetchUniquePatients();
  }, [currentMonth]);

  const fetchMonthlyData = async () => {
    try {
      const { data, error } = await supabase.rpc('get_monthly_membership_income', {
        p_input_date: currentMonth.toISOString()
      });

      if (error) throw error;

      if (data && data.length > 0) {
        // Find the current month's data
        const currentMonthData = data.find(item => 
          item.month_year === format(currentMonth, 'MM/yyyy')
        );
        setMonthlyIncome(currentMonthData?.total_amount || 0);
      } else {
        setMonthlyIncome(0);
      }
    } catch (error: any) {
      console.error('Error fetching monthly data:', error);
      setError(error.message);
    }
  };

  const fetchUniquePatients = async () => {
    try {
      const startOfMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), 1);
      const endOfMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 0);

      const { data, error } = await supabase
        .from('membership_activity')
        .select('membership_id')
        .gte('Last_ran', startOfMonth.toISOString())
        .lte('Last_ran', endOfMonth.toISOString())
        .eq('charge_status', 'success');

      if (error) throw error;

      // Get unique membership IDs
      const uniqueMemberships = new Set(data.map(item => item.membership_id));
      setUniquePatients(uniqueMemberships.size);
    } catch (error: any) {
      console.error('Error fetching unique patients:', error);
      setError(error.message);
    }
  };

  const fetchHistoricalData = async () => {
    try {
      // Fetch income history
      const { data: incomeData, error: incomeError } = await supabase.rpc('get_monthly_membership_income', {
        p_input_date: currentMonth.toISOString()
      });

      if (incomeError) throw incomeError;

      // Fetch member history
      const { data: memberData, error: memberError } = await supabase.rpc('get_active_members_by_month', {
        p_input_date: currentMonth.toISOString()
      });

      if (memberError) throw memberError;

      setIncomeHistory(
        incomeData.map((item: any) => ({
          month: item.month_year,
          amount: item.total_amount
        }))
      );

      setMemberHistory(
        memberData.map((item: any) => ({
          month: item.month_year,
          count: item.active_members
        }))
      );
    } catch (error: any) {
      console.error('Error fetching historical data:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchTransactions = async () => {
    try {
      const startOfMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), 1);
      const endOfMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 0);

      const { data, error } = await supabase
        .from('membership_activity')
        .select(`
          membership_id,
          memberships (
            membership_name
          ),
          charge_amount,
          "Last_ran" as charge_date,
          charge_status
        `)
        .gte('Last_ran', startOfMonth.toISOString())
        .lte('Last_ran', endOfMonth.toISOString())
        .order('Last_ran', { ascending: false });

      if (error) throw error;

      setTransactions(data.map(item => ({
        membership_id: item.membership_id,
        membership_name: item.memberships?.membership_name || 'Unknown',
        charge_amount: item.charge_amount,
        charge_date: item.charge_date,
        charge_status: item.charge_status
      })));
    } catch (error: any) {
      console.error('Error fetching transactions:', error);
      setError(error.message);
    }
  };

  const navigateMonth = (direction: 'prev' | 'next') => {
    setCurrentMonth(prev => {
      const newDate = new Date(prev);
      if (direction === 'prev') {
        newDate.setMonth(newDate.getMonth() - 1);
      } else {
        newDate.setMonth(newDate.getMonth() + 1);
      }
      return newDate;
    });
  };

  // Chart options and data
  const chartOptions: ChartOptions<'line'> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: false
      }
    },
    scales: {
      y: {
        beginAtZero: true
      }
    }
  };

  const incomeChartData: ChartData<'line'> = {
    labels: incomeHistory.map(item => item.month),
    datasets: [
      {
        label: 'Monthly Income',
        data: incomeHistory.map(item => item.amount),
        borderColor: 'rgb(34, 197, 94)',
        backgroundColor: 'rgba(34, 197, 94, 0.5)',
        tension: 0.3
      }
    ]
  };

  const memberChartData: ChartData<'line'> = {
    labels: memberHistory.map(item => item.month),
    datasets: [
      {
        label: 'Active Members',
        data: memberHistory.map(item => item.count),
        borderColor: 'rgb(59, 130, 246)',
        backgroundColor: 'rgba(59, 130, 246, 0.5)',
        tension: 0.3
      }
    ]
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-green-50 to-blue-50">
      <nav className="bg-white shadow-sm">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16">
            <div className="flex items-center">
              <Leaf className="h-6 w-6 text-green-600" />
              <div className="ml-2">
                <span className="text-xl font-semibold text-gray-900">THRIVE</span>
                <span className="ml-2 text-sm text-gray-500">Membership Income</span>
              </div>
            </div>
            <div className="flex items-center space-x-4">
              <span className="text-sm text-gray-700">{user?.email}</span>
              <div className="flex flex-wrap gap-2">
                {userRoles.map((role) => (
                  <span
                    key={role}
                    className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800"
                  >
                    {role}
                  </span>
                ))}
              </div>
              <button
                onClick={() => signOut()}
                className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-green-600 hover:bg-green-700"
              >
                <LogOut className="h-4 w-4 mr-2" />
                Sign Out
              </button>
            </div>
          </div>
        </div>
      </nav>

      <main className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        <div className="px-4 py-4 sm:px-0">
          {/* Header Section */}
          <div className="bg-white shadow-lg rounded-lg mb-6 overflow-hidden">
            <div className="relative">
              <div className="absolute inset-0 bg-gradient-to-r from-green-600 to-blue-600 opacity-90"></div>
              <div className="relative px-8 py-12">
                <div className="flex items-center justify-between">
                  <button
                    onClick={() => navigate('/settings')}
                    className="text-white hover:text-green-100 transition-colors duration-200"
                  >
                    <ArrowLeft className="h-6 w-6" />
                  </button>
                  <Leaf className="h-12 w-12 text-white opacity-20" />
                </div>
                
                <div className="mt-4 text-center">
                  <h1 className="text-4xl font-bold text-white mb-2">THRIVE</h1>
                  <p className="text-green-100 text-lg mb-4">
                    Tracking Health and Reducing Illness Via Efficiency
                  </p>
                  <div className="w-16 h-1 bg-white/30 mx-auto rounded-full"></div>
                  <h2 className="text-2xl font-semibold text-white mt-4">Membership Income</h2>
                </div>
              </div>
            </div>
          </div>

          {/* Monthly Income Panel */}
          <div className="bg-white shadow-lg rounded-lg overflow-hidden mb-6">
            <div className="px-6 py-5 border-b border-gray-200">
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <DollarSign className="h-5 w-5 text-green-600" />
                  <h3 className="ml-2 text-lg font-medium text-gray-900">
                    Monthly Membership Income
                  </h3>
                </div>
                <div className="flex items-center space-x-4">
                  <button
                    onClick={() => navigateMonth('prev')}
                    className="p-2 text-gray-400 hover:text-gray-600"
                  >
                    <ChevronLeft className="h-5 w-5" />
                  </button>
                  <span className="text-sm font-medium text-gray-900">
                    {format(currentMonth, 'MMMM yyyy')}
                  </span>
                  <button
                    onClick={() => navigateMonth('next')}
                    className="p-2 text-gray-400 hover:text-gray-600"
                  >
                    <ChevronRight className="h-5 w-5" />
                  </button>
                </div>
              </div>
            </div>
            <div className="p-6">
              <div className="grid grid-cols-2 gap-6 mb-6">
                <div className="text-center">
                  <p className="text-sm text-gray-500">Total Income for {format(currentMonth, 'MMMM yyyy')}</p>
                  <p className="mt-2 text-3xl font-bold text-gray-900">
                    {formatCurrency(monthlyIncome)}
                  </p>
                </div>
                <div className="text-center">
                  <div className="flex items-center justify-center">
                    <UserCheck className="h-5 w-5 text-blue-600 mr-2" />
                    <p className="text-sm text-gray-500">Unique Patients with Activity</p>
                  </div>
                  <p className="mt-2 text-3xl font-bold text-gray-900">
                    {uniquePatients}
                  </p>
                </div>
              </div>

              {/* Transactions Table */}
              <div className="mt-6">
                <h4 className="text-sm font-medium text-gray-700 mb-4">Transaction History</h4>
                <div className="overflow-y-auto" style={{ maxHeight: '300px' }}>
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50 sticky top-0">
                      <tr>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Date
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Membership
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Amount
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Status
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {transactions.map((transaction, index) => (
                        <tr key={`${transaction.membership_id}-${index}`}>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            <div className="flex items-center">
                              <Clock className="h-4 w-4 text-gray-400 mr-2" />
                              {formatPacificDateTime(transaction.charge_date)}
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                            {transaction.membership_name}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                            {formatCurrency(transaction.charge_amount)}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                              transaction.charge_status === 'success'
                                ? 'bg-green-100 text-green-800'
                                : 'bg-red-100 text-red-800'
                            }`}>
                              {transaction.charge_status}
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          {/* Charts Grid */}
          <div className="grid grid-cols-1 gap-6 lg:grid-cols-2">
            {/* Income History Chart */}
            <div className="bg-white shadow-lg rounded-lg overflow-hidden">
              <div className="px-6 py-5 border-b border-gray-200">
                <div className="flex items-center">
                  <TrendingUp className="h-5 w-5 text-green-600" />
                  <h3 className="ml-2 text-lg font-medium text-gray-900">
                    Income History (12 Months)
                  </h3>
                </div>
              </div>
              <div className="p-6">
                <div style={{ height: '300px' }}>
                  <Line options={chartOptions} data={incomeChartData} />
                </div>
              </div>
            </div>

            {/* Active Members Chart */}
            <div className="bg-white shadow-lg rounded-lg overflow-hidden">
              <div className="px-6 py-5 border-b border-gray-200">
                <div className="flex items-center">
                  <Users className="h-5 w-5 text-blue-600" />
                  <h3 className="ml-2 text-lg font-medium text-gray-900">
                    Active Members (12 Months)
                  </h3>
                </div>
              </div>
              <div className="p-6">
                <div style={{ height: '300px' }}>
                  <Line options={chartOptions} data={memberChartData} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}