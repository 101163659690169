import React from 'react';

interface ProcessingStatsProps {
  stats: {
    total: number;
    processed: number;
    skipped: number;
    errors: number;
  } | null;
}

export function ProcessingStats({ stats }: ProcessingStatsProps) {
  if (!stats) return null;

  return (
    <div className="mt-8">
      <h3 className="text-lg font-medium text-gray-900 mb-4">Processing Statistics</h3>
      <div className="grid grid-cols-1 gap-5 sm:grid-cols-4">
        <div className="bg-white overflow-hidden shadow rounded-lg">
          <div className="px-4 py-5 sm:p-6">
            <dt className="text-sm font-medium text-gray-500 truncate">Total Records</dt>
            <dd className="mt-1 text-3xl font-semibold text-gray-900">{stats.total}</dd>
          </div>
        </div>
        <div className="bg-white overflow-hidden shadow rounded-lg">
          <div className="px-4 py-5 sm:p-6">
            <dt className="text-sm font-medium text-gray-500 truncate">Processed</dt>
            <dd className="mt-1 text-3xl font-semibold text-green-600">{stats.processed}</dd>
          </div>
        </div>
        <div className="bg-white overflow-hidden shadow rounded-lg">
          <div className="px-4 py-5 sm:p-6">
            <dt className="text-sm font-medium text-gray-500 truncate">Skipped</dt>
            <dd className="mt-1 text-3xl font-semibold text-yellow-600">{stats.skipped}</dd>
          </div>
        </div>
        <div className="bg-white overflow-hidden shadow rounded-lg">
          <div className="px-4 py-5 sm:p-6">
            <dt className="text-sm font-medium text-gray-500 truncate">Errors</dt>
            <dd className="mt-1 text-3xl font-semibold text-red-600">{stats.errors}</dd>
          </div>
        </div>
      </div>
    </div>
  );
}