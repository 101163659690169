import { format, parseISO } from 'date-fns';
import { formatInTimeZone, utcToZonedTime } from 'date-fns-tz';

const TIMEZONE = 'America/Los_Angeles';

export function toPacificTime(date: Date | string): Date {
  const parsedDate = typeof date === 'string' ? parseISO(date) : date;
  return utcToZonedTime(parsedDate, TIMEZONE);
}

export function fromPacificTime(date: Date): string {
  return formatInTimeZone(date, TIMEZONE, "yyyy-MM-dd'T'HH:mm:ssXXX");
}

export function formatPacificDate(date: Date | string | null, formatStr: string = 'EEEE, MMMM d, yyyy'): string {
  if (!date) return 'N/A';
  const parsedDate = typeof date === 'string' ? parseISO(date) : date;
  return formatInTimeZone(parsedDate, TIMEZONE, formatStr);
}

export function formatPacificTime(time: string): string {
  // Handle time strings (HH:mm:ss)
  const today = new Date();
  const dateTime = new Date(`${format(today, 'yyyy-MM-dd')}T${time}`);
  return formatInTimeZone(dateTime, TIMEZONE, 'h:mm a');
}

export function getCurrentPacificDate(): Date {
  return utcToZonedTime(new Date(), TIMEZONE);
}

export function startOfPacificDay(date: Date): Date {
  const pacificDate = utcToZonedTime(date, TIMEZONE);
  pacificDate.setHours(0, 0, 0, 0);
  return pacificDate;
}

export function endOfPacificDay(date: Date): Date {
  const pacificDate = utcToZonedTime(date, TIMEZONE);
  pacificDate.setHours(23, 59, 59, 999);
  return pacificDate;
}

export function formatPacificDateTime(date: Date | string): string {
  return formatPacificDate(date, 'MMMM d, yyyy h:mm aa');
}

export function formatPacificDateRange(start: Date, end: Date): {
  start: string;
  end: string;
} {
  return {
    start: formatPacificDate(start, 'EEEE, MMMM d, yyyy'),
    end: formatPacificDate(end, 'EEEE, MMMM d, yyyy')
  };
}

export function getPacificDateForQuery(date: Date): string {
  return formatInTimeZone(date, TIMEZONE, 'yyyy-MM-dd');
}

export function isSamePacificDay(date1: Date | string, date2: Date | string): boolean {
  const d1 = typeof date1 === 'string' ? parseISO(date1) : date1;
  const d2 = typeof date2 === 'string' ? parseISO(date2) : date2;
  return getPacificDateForQuery(d1) === getPacificDateForQuery(d2);
}

export function addPacificDays(date: Date, days: number): Date {
  const pacificDate = utcToZonedTime(date, TIMEZONE);
  pacificDate.setDate(pacificDate.getDate() + days);
  return pacificDate;
}

export function subPacificDays(date: Date, days: number): Date {
  return addPacificDays(date, -days);
}