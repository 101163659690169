import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  ArrowLeft, 
  Leaf, 
  DollarSign,
  Search,
  AlertCircle,
  Check,
  Info
} from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import { supabase } from '../lib/supabase';
import { PricingModal } from '../components/PricingModal';

interface ProcedureCode {
  code: string;
  description: string;
  is_active: boolean;
  pricing: {
    [key: string]: {
      service_level_id: string;
      price: number;
    };
  };
}

interface ServiceLevel {
  id: string;
  name: string;
}

export function ProcedurePricing() {
  const navigate = useNavigate();
  const { userRoles } = useAuth();
  const isAdmin = userRoles.includes('admin');
  const [procedureCodes, setProcedureCodes] = useState<ProcedureCode[]>([]);
  const [serviceLevels, setServiceLevels] = useState<ServiceLevel[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCode, setSelectedCode] = useState<ProcedureCode | null>(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (!isAdmin) {
      navigate('/settings');
      return;
    }
    fetchData();
  }, [isAdmin, navigate]);

  const fetchData = async () => {
    try {
      setLoading(true);
      
      // Fetch service levels
      const { data: levelsData, error: levelsError } = await supabase
        .from('service_levels')
        .select('id, name')
        .order('name');

      if (levelsError) throw levelsError;
      setServiceLevels(levelsData || []);

      // Fetch procedure codes with pricing
      const { data: codesData, error: codesError } = await supabase
        .rpc('get_procedure_codes_with_pricing');

      if (codesError) throw codesError;
      setProcedureCodes(codesData || []);
    } catch (error: any) {
      console.error('Error fetching data:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleModalSuccess = async (updatedCode: string) => {
    try {
      // Only fetch the updated procedure code
      const { data, error } = await supabase
        .rpc('get_procedure_codes_with_pricing')
        .eq('code', updatedCode)
        .single();

      if (error) throw error;

      // Update just the changed code in the state
      setProcedureCodes(prev => 
        prev.map(code => 
          code.code === updatedCode ? data : code
        )
      );

      setSuccess('Prices updated successfully');
      setTimeout(() => setSuccess(null), 3000);
    } catch (error: any) {
      console.error('Error updating procedure code:', error);
      setError(error.message);
      setTimeout(() => setError(null), 3000);
    }
  };

  const filteredProcedureCodes = procedureCodes.filter(code => 
    code.code.toLowerCase().includes(searchTerm.toLowerCase()) ||
    code.description?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (!isAdmin) return null;

  return (
    <div className="min-h-screen bg-gradient-to-br from-green-50 to-blue-50">
      <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        <div className="px-4 py-4 sm:px-0">
          {/* Header */}
          <div className="bg-white shadow-lg rounded-lg mb-6 overflow-hidden">
            <div className="relative">
              <div className="absolute inset-0 bg-gradient-to-r from-green-600 to-blue-600 opacity-90"></div>
              <div className="relative px-8 py-12">
                <div className="flex items-center justify-between">
                  <button
                    onClick={() => navigate('/settings')}
                    className="text-white hover:text-green-100 transition-colors duration-200"
                  >
                    <ArrowLeft className="h-6 w-6" />
                  </button>
                  <Leaf className="h-12 w-12 text-white opacity-20" />
                </div>
                
                <div className="mt-4 text-center">
                  <h1 className="text-4xl font-bold text-white mb-2">THRIVE</h1>
                  <p className="text-green-100 text-lg mb-4">
                    Tracking Health and Reducing Illness Via Efficiency
                  </p>
                  <div className="w-16 h-1 bg-white/30 mx-auto rounded-full"></div>
                  <h2 className="text-2xl font-semibold text-white mt-4">Procedure Pricing</h2>
                </div>
              </div>
            </div>
          </div>

          {/* Main Content */}
          <div className="bg-white shadow-lg rounded-lg overflow-hidden">
            <div className="px-6 py-5 border-b border-gray-200">
              <div className="flex items-center">
                <DollarSign className="h-5 w-5 text-green-600" />
                <h3 className="ml-2 text-lg font-medium text-gray-900">
                  Procedure Code Pricing
                </h3>
              </div>
            </div>

            {/* Search */}
            <div className="px-6 py-4 border-b border-gray-200">
              <div className="relative">
                <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
                <input
                  type="text"
                  placeholder="Search procedure codes..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                />
              </div>
            </div>

            {/* Messages */}
            {error && (
              <div className="px-6 py-4 bg-red-50">
                <div className="flex">
                  <AlertCircle className="h-5 w-5 text-red-400" />
                  <p className="ml-3 text-sm text-red-700">{error}</p>
                </div>
              </div>
            )}

            {success && (
              <div className="px-6 py-4 bg-green-50">
                <div className="flex">
                  <Check className="h-5 w-5 text-green-400" />
                  <p className="ml-3 text-sm text-green-700">{success}</p>
                </div>
              </div>
            )}

            {/* Pricing Table */}
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Code
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Status
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Description
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Current Pricing
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {loading ? (
                    <tr>
                      <td colSpan={4} className="px-6 py-4 text-center text-gray-500">
                        Loading procedure codes...
                      </td>
                    </tr>
                  ) : filteredProcedureCodes.length === 0 ? (
                    <tr>
                      <td colSpan={4} className="px-6 py-4 text-center text-gray-500">
                        <div className="flex flex-col items-center">
                          <Info className="h-12 w-12 text-gray-400 mb-2" />
                          <p>No procedure codes found</p>
                        </div>
                      </td>
                    </tr>
                  ) : (
                    filteredProcedureCodes.map((code) => (
                      <tr 
                        key={code.code} 
                        className={`hover:bg-gray-50 cursor-pointer ${
                          !code.is_active ? 'bg-gray-50' : ''
                        }`}
                        onClick={() => {
                          setSelectedCode(code);
                          setShowModal(true);
                        }}
                      >
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          {code.code}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                            code.is_active
                              ? 'bg-green-100 text-green-800'
                              : 'bg-gray-100 text-gray-800'
                          }`}>
                            {code.is_active ? 'Active' : 'Inactive'}
                          </span>
                        </td>
                        <td className="px-6 py-4 text-sm text-gray-500">
                          {code.description || '-'}
                        </td>
                        <td className="px-6 py-4 text-sm text-gray-500">
                          {Object.entries(code.pricing).map(([name, data]) => (
                            <div key={name} className="flex items-center space-x-2">
                              <span className="font-medium">{name}:</span>
                              <span>${data.price.toFixed(2)}</span>
                            </div>
                          ))}
                          {Object.keys(code.pricing).length === 0 && (
                            <span className="text-gray-400">No prices set</span>
                          )}
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <PricingModal
        procedureCode={selectedCode}
        serviceLevels={serviceLevels}
        isOpen={showModal}
        onClose={() => {
          setShowModal(false);
          setSelectedCode(null);
        }}
        onSuccess={() => handleModalSuccess(selectedCode?.code || '')}
      />
    </div>
  );
}