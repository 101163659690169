import { ValidationResult } from './accessDataValidator';

export function validateMembershipReport(lines: string[]): ValidationResult {
  // Check minimum required lines (title + empty line + headers + at least one data row)
  if (lines.length < 4) {
    return {
      success: false,
      message: 'File has insufficient data',
      processedCount: 0,
      skippedCount: 0
    };
  }

  // Verify report title format
  const titleRegex = /^Memberships Report - generated \d{2}\/\d{2}\/\d{4}$/;
  if (!titleRegex.test(lines[0].trim())) {
    return {
      success: false,
      message: 'Invalid report format: Incorrect report title',
      processedCount: 0,
      skippedCount: 0
    };
  }

  // Verify second line is empty
  if (lines[1].trim() !== '') {
    return {
      success: false,
      message: 'Invalid report format: Second line should be empty',
      processedCount: 0,
      skippedCount: 0
    };
  }

  // Verify headers on third line
  const headers = lines[2].split(',').map(header => header.trim().toLowerCase());
  
  // Check if we have at least 15 columns (A through O)
  if (headers.length < 15) {
    return {
      success: false,
      message: 'Invalid report format: Missing required columns (should have columns A through O)',
      processedCount: 0,
      skippedCount: 0
    };
  }

  // Verify required columns exist
  if (!headers[1].includes('client')) {
    return {
      success: false,
      message: 'Invalid report format: Missing Client column (Column B)',
      processedCount: 0,
      skippedCount: 0
    };
  }

  if (!headers[3].includes('location')) {
    return {
      success: false,
      message: 'Invalid report format: Missing Location column (Column D)',
      processedCount: 0,
      skippedCount: 0
    };
  }

  return {
    success: true,
    message: 'Validation successful',
    processedCount: 0,
    skippedCount: 0
  };
}