import React, { useState, useEffect, useRef } from 'react';
import { X, AlertCircle, Check, ToggleLeft, ToggleRight } from 'lucide-react';
import { supabase } from '../lib/supabase';

interface ServiceLevel {
  id: string;
  name: string;
}

interface ProcedureCode {
  code: string;
  description: string;
  is_active: boolean;
  pricing: {
    [key: string]: {
      service_level_id: string;
      price: number;
    };
  };
}

interface PricingModalProps {
  procedureCode: ProcedureCode | null;
  serviceLevels: ServiceLevel[];
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

export function PricingModal({ procedureCode, serviceLevels, isOpen, onClose, onSuccess }: PricingModalProps) {
  const [prices, setPrices] = useState<{[key: string]: string}>({});
  const [isActive, setIsActive] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const firstInputRef = useRef<HTMLInputElement>(null);
  const formRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    if (isOpen && firstInputRef.current) {
      firstInputRef.current.focus();
    }
  }, [isOpen]);

  useEffect(() => {
    if (procedureCode) {
      // Initialize prices from existing pricing
      const initialPrices: {[key: string]: string} = {};
      serviceLevels.forEach(level => {
        const price = procedureCode.pricing[level.name]?.price;
        initialPrices[level.id] = price !== undefined ? price.toString() : '';
      });
      setPrices(initialPrices);
      setIsActive(procedureCode.is_active);
    } else {
      setPrices({});
      setIsActive(true);
    }
  }, [procedureCode, serviceLevels]);

  const handlePriceChange = (serviceLevelId: string, value: string) => {
    // Only allow numbers and a single decimal point
    const numericValue = value.replace(/[^\d.]/g, '');
    
    // Prevent multiple decimal points
    const parts = numericValue.split('.');
    const sanitizedValue = parts[0] + (parts.length > 1 ? '.' + parts[1] : '');
    
    // Limit to 2 decimal places
    const finalValue = sanitizedValue.includes('.') 
      ? sanitizedValue.slice(0, sanitizedValue.indexOf('.') + 3)
      : sanitizedValue;

    setPrices(prev => ({
      ...prev,
      [serviceLevelId]: finalValue
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!procedureCode) return;

    try {
      setLoading(true);
      setError(null);

      // Update active status if changed
      if (isActive !== procedureCode.is_active) {
        const { error: statusError } = await supabase
          .rpc('update_procedure_code_status', {
            p_code: procedureCode.code,
            p_is_active: isActive
          });

        if (statusError) throw statusError;
      }

      // Process each price
      for (const [serviceLevelId, priceStr] of Object.entries(prices)) {
        if (!priceStr) continue;

        const numericPrice = parseFloat(priceStr);
        if (isNaN(numericPrice)) {
          throw new Error(`Invalid price format for service level ${
            serviceLevels.find(l => l.id === serviceLevelId)?.name
          }`);
        }

        const { error } = await supabase
          .rpc('update_procedure_pricing', {
            p_procedure_code: procedureCode.code,
            p_service_level_id: serviceLevelId,
            p_price: numericPrice
          });

        if (error) throw error;
      }

      onSuccess();
      onClose();
    } catch (error: any) {
      console.error('Error saving prices:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    // Submit form on Enter key, unless Shift is held
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      formRef.current?.dispatchEvent(
        new Event('submit', { cancelable: true, bubbles: true })
      );
    }
  };

  if (!isOpen || !procedureCode) return null;

  return (
    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg shadow-xl max-w-lg w-full">
        <div className="px-6 py-4 border-b border-gray-200 flex items-center justify-between">
          <h3 className="text-lg font-medium text-gray-900">
            Edit Pricing for {procedureCode.code}
          </h3>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-500"
          >
            <X className="h-5 w-5" />
          </button>
        </div>

        <form ref={formRef} onSubmit={handleSubmit} className="p-6">
          {error && (
            <div className="mb-4 p-4 bg-red-50 rounded-md">
              <div className="flex">
                <AlertCircle className="h-5 w-5 text-red-400" />
                <p className="ml-3 text-sm text-red-700">{error}</p>
              </div>
            </div>
          )}

          <div className="mb-4">
            <h4 className="text-sm font-medium text-gray-700">Description</h4>
            <p className="mt-1 text-sm text-gray-500">
              {procedureCode.description || 'No description available'}
            </p>
          </div>

          <div className="mb-6">
            <button
              type="button"
              onClick={() => setIsActive(!isActive)}
              className="inline-flex items-center space-x-2"
            >
              {isActive ? (
                <ToggleRight className="h-6 w-6 text-green-600" />
              ) : (
                <ToggleLeft className="h-6 w-6 text-gray-400" />
              )}
              <span className={`text-sm font-medium ${
                isActive ? 'text-green-600' : 'text-gray-500'
              }`}>
                {isActive ? 'Active' : 'Inactive'}
              </span>
            </button>
          </div>

          <div className="space-y-4">
            {serviceLevels.map((level, index) => (
              <div key={level.id}>
                <label htmlFor={level.id} className="block text-sm font-medium text-gray-700">
                  {level.name}
                </label>
                <div className="mt-1 relative rounded-md shadow-sm">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <span className="text-gray-500 sm:text-sm">$</span>
                  </div>
                  <input
                    ref={index === 0 ? firstInputRef : null}
                    type="text"
                    id={level.id}
                    value={prices[level.id] || ''}
                    onChange={(e) => handlePriceChange(level.id, e.target.value)}
                    onKeyDown={handleKeyDown}
                    className="pl-7 block w-full pr-3 py-2 sm:text-sm border-gray-300 rounded-md focus:ring-green-500 focus:border-green-500"
                    placeholder="0.00"
                  />
                </div>
              </div>
            ))}
          </div>

          <div className="mt-6 flex justify-end space-x-3">
            <button
              type="button"
              onClick={onClose}
              className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 disabled:opacity-50 disabled:cursor-not-allowed"
              disabled={loading}
            >
              {loading ? (
                <>
                  <span className="animate-spin rounded-full h-4 w-4 border-b-2 border-white mr-2" />
                  Saving...
                </>
              ) : (
                'Save Changes'
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}