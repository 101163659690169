import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  ArrowLeft, 
  Leaf, 
  Users, 
  Activity, 
  Calendar, 
  Clock, 
  Building2,
  Pill,
  Syringe
} from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import { supabase } from '../lib/supabase';
import {
  getCurrentPacificDate,
  formatPacificDateRange,
  startOfPacificDay,
  endOfPacificDay,
  subPacificDays
} from '../utils/dateTime';

interface PatientCounts {
  total_patients: number;
  active_patients_3m: number;
  active_patients_2m: number;
  active_patients_1m: number;
}

interface DepartmentCount {
  department: string;
  patient_count: number;
}

interface DateRange {
  start: Date;
  end: Date;
}

interface MedicationUsage {
  semaglutide: number;
  tirzepatide: number;
}

export function DataAnalytics() {
  const navigate = useNavigate();
  const { user, userRoles } = useAuth();
  const isAdmin = userRoles.includes('admin');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [dateRange, setDateRange] = useState<DateRange>({ 
    start: getCurrentPacificDate(), 
    end: getCurrentPacificDate() 
  });
  const [counts, setCounts] = useState<PatientCounts>({
    total_patients: 0,
    active_patients_3m: 0,
    active_patients_2m: 0,
    active_patients_1m: 0
  });
  const [departmentCounts, setDepartmentCounts] = useState<DepartmentCount[]>([]);
  const [medicationUsage, setMedicationUsage] = useState<MedicationUsage>({
    semaglutide: 0,
    tirzepatide: 0
  });

  useEffect(() => {
    if (!isAdmin) {
      navigate('/settings');
      return;
    }
    calculateLastWeekRange();
    fetchPatientCounts();
  }, [isAdmin, navigate]);

  useEffect(() => {
    if (dateRange.start && dateRange.end) {
      fetchDepartmentCounts();
      fetchMedicationUsage();
    }
  }, [dateRange]);

  const calculateLastWeekRange = () => {
    const now = getCurrentPacificDate();
    const today = now.getDay(); // 0 = Sunday, 1 = Monday, etc.
    
    // Calculate last Saturday
    const lastSaturday = endOfPacificDay(subPacificDays(now, today + 1));

    // Calculate last Sunday
    const lastSunday = startOfPacificDay(subPacificDays(lastSaturday, 6));

    setDateRange({ start: lastSunday, end: lastSaturday });
  };

  const formattedDates = formatPacificDateRange(dateRange.start, dateRange.end);

  const fetchDepartmentCounts = async () => {
    try {
      // Get appointments by department for last calendar week
      const { data, error } = await supabase
        .from('appointments')
        .select('service_department, external_patient_id')
        .gte('appointment_date', dateRange.start.toISOString().split('T')[0])
        .lte('appointment_date', dateRange.end.toISOString().split('T')[0])
        .is('cancelled_date', null);

      if (error) throw error;

      // Process the data to count unique patients per department
      const departmentPatients = new Map<string, Set<string>>();
      
      data?.forEach(appointment => {
        const department = appointment.service_department || 'Unspecified';
        if (!departmentPatients.has(department)) {
          departmentPatients.set(department, new Set());
        }
        departmentPatients.get(department)?.add(appointment.external_patient_id);
      });

      // Convert to array of department counts
      const counts = Array.from(departmentPatients.entries())
        .map(([department, patients]) => ({
          department,
          patient_count: patients.size
        }))
        .sort((a, b) => b.patient_count - a.patient_count);

      setDepartmentCounts(counts);
    } catch (error: any) {
      console.error('Error fetching department counts:', error);
      setError(error.message);
    }
  };

  const fetchPatientCounts = async () => {
    try {
      // Get total patient count
      const { count: totalCount, error: totalError } = await supabase
        .from('patients')
        .select('*', { count: 'exact', head: true });

      if (totalError) throw totalError;

      // Calculate dates for 1, 2, and 3 months ago
      const now = getCurrentPacificDate();
      const oneMonthAgo = subPacificDays(now, 30);
      const twoMonthsAgo = subPacificDays(now, 60);
      const threeMonthsAgo = subPacificDays(now, 90);

      // Get all appointments in the last 3 months
      const { data: appointmentData, error: appointmentError } = await supabase
        .from('appointments')
        .select('external_patient_id, appointment_date')
        .gte('appointment_date', threeMonthsAgo.toISOString().split('T')[0])
        .lte('appointment_date', now.toISOString().split('T')[0])
        .is('cancelled_date', null)
        .order('appointment_date', { ascending: true });

      if (appointmentError) throw appointmentError;

      // Process appointments to get unique patient counts
      const uniquePatients1m = new Set();
      const uniquePatients2m = new Set();
      const uniquePatients3m = new Set();

      appointmentData?.forEach(appointment => {
        const appointmentDate = new Date(appointment.appointment_date);
        
        // Add to appropriate sets based on date
        if (appointmentDate >= threeMonthsAgo) {
          uniquePatients3m.add(appointment.external_patient_id);
          
          if (appointmentDate >= twoMonthsAgo) {
            uniquePatients2m.add(appointment.external_patient_id);
            
            if (appointmentDate >= oneMonthAgo) {
              uniquePatients1m.add(appointment.external_patient_id);
            }
          }
        }
      });

      setCounts({
        total_patients: totalCount || 0,
        active_patients_3m: uniquePatients3m.size,
        active_patients_2m: uniquePatients2m.size,
        active_patients_1m: uniquePatients1m.size
      });
    } catch (error: any) {
      console.error('Error fetching patient counts:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchMedicationUsage = async () => {
    try {
      // Get semaglutide usage
      const { data: semaglutideData, error: semaglutideError } = await supabase
        .from('medications_administered')
        .select(`
          units,
          procedure_codes!inner (
            milligrams,
            medication_type
          )
        `)
        .eq('procedure_codes.medication_type', 'semaglutide')
        .gte('service_date', dateRange.start.toISOString().split('T')[0])
        .lte('service_date', dateRange.end.toISOString().split('T')[0]);

      if (semaglutideError) throw semaglutideError;

      // Get tirzepatide usage
      const { data: tirzepatideData, error: tirzepatideError } = await supabase
        .from('medications_administered')
        .select(`
          units,
          procedure_codes!inner (
            milligrams,
            medication_type
          )
        `)
        .eq('procedure_codes.medication_type', 'tirzepatide')
        .gte('service_date', dateRange.start.toISOString().split('T')[0])
        .lte('service_date', dateRange.end.toISOString().split('T')[0]);

      if (tirzepatideError) throw tirzepatideError;

      // Calculate total milligrams
      const semaglutideTotal = semaglutideData?.reduce((total, record) => {
        return total + (record.units * (record.procedure_codes?.milligrams || 0));
      }, 0) || 0;

      const tirzepatideTotal = tirzepatideData?.reduce((total, record) => {
        return total + (record.units * (record.procedure_codes?.milligrams || 0));
      }, 0) || 0;

      setMedicationUsage({
        semaglutide: semaglutideTotal,
        tirzepatide: tirzepatideTotal
      });
    } catch (error: any) {
      console.error('Error fetching medication usage:', error);
      setError(error.message);
    }
  };

  // Redirect non-admin users
  if (!isAdmin) {
    return null;
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-green-50 to-blue-50">
      <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        <div className="px-4 py-4 sm:px-0">
          {/* Header Section */}
          <div className="bg-white shadow-lg rounded-lg mb-6 overflow-hidden">
            <div className="relative">
              <div className="absolute inset-0 bg-gradient-to-r from-green-600 to-blue-600 opacity-90"></div>
              <div className="relative px-8 py-12">
                <div className="flex items-center justify-between">
                  <button
                    onClick={() => navigate('/settings')}
                    className="text-white hover:text-green-100 transition-colors duration-200"
                  >
                    <ArrowLeft className="h-6 w-6" />
                  </button>
                  <Leaf className="h-12 w-12 text-white opacity-20" />
                </div>
                
                <div className="mt-4 text-center">
                  <h1 className="text-4xl font-bold text-white mb-2">THRIVE</h1>
                  <div className="text-green-100 text-lg mb-4">
                    Tracking Health and Reducing Illness Via Efficiency
                  </div>
                  <div className="w-16 h-1 bg-white/30 mx-auto rounded-full"></div>
                  <h2 className="text-2xl font-semibold text-white mt-4">Data Analytics</h2>
                </div>
              </div>
            </div>
          </div>

          {/* Analytics Panels */}
          <div className="grid grid-cols-1 gap-5 sm:grid-cols-4 mb-8">
            {/* Total Patients */}
            <div className="bg-white overflow-hidden shadow-lg rounded-lg">
              <div className="p-5">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <Users className="h-6 w-6 text-green-600" />
                  </div>
                  <div className="ml-5 w-0 flex-1">
                    <dl>
                      <dt className="text-sm font-medium text-gray-500 truncate">
                        Total Patients
                      </dt>
                      <dd className="text-lg font-semibold text-gray-900">
                        {loading ? (
                          <div className="animate-pulse h-6 w-16 bg-gray-200 rounded"></div>
                        ) : (
                          counts.total_patients.toLocaleString()
                        )}
                      </dd>
                    </dl>
                  </div>
                </div>
              </div>
            </div>

            {/* Active Patients (3 Months) */}
            <div className="bg-white overflow-hidden shadow-lg rounded-lg">
              <div className="p-5">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <Calendar className="h-6 w-6 text-green-600" />
                  </div>
                  <div className="ml-5 w-0 flex-1">
                    <dl>
                      <dt className="text-sm font-medium text-gray-500 truncate">
                        Active (3 Months)
                      </dt>
                      <dd className="text-lg font-semibold text-gray-900">
                        {loading ? (
                          <div className="animate-pulse h-6 w-16 bg-gray-200 rounded"></div>
                        ) : (
                          counts.active_patients_3m.toLocaleString()
                        )}
                      </dd>
                    </dl>
                  </div>
                </div>
              </div>
            </div>

            {/* Active Patients (2 Months) */}
            <div className="bg-white overflow-hidden shadow-lg rounded-lg">
              <div className="p-5">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <Activity className="h-6 w-6 text-green-600" />
                  </div>
                  <div className="ml-5 w-0 flex-1">
                    <dl>
                      <dt className="text-sm font-medium text-gray-500 truncate">
                        Active (2 Months)
                      </dt>
                      <dd className="text-lg font-semibold text-gray-900">
                        {loading ? (
                          <div className="animate-pulse h-6 w-16 bg-gray-200 rounded"></div>
                        ) : (
                          counts.active_patients_2m.toLocaleString()
                        )}
                      </dd>
                    </dl>
                  </div>
                </div>
              </div>
            </div>

            {/* Active Patients (1 Month) */}
            <div className="bg-white overflow-hidden shadow-lg rounded-lg">
              <div className="p-5">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <Clock className="h-6 w-6 text-green-600" />
                  </div>
                  <div className="ml-5 w-0 flex-1">
                    <dl>
                      <dt className="text-sm font-medium text-gray-500 truncate">
                        Active (1 Month)
                      </dt>
                      <dd className="text-lg font-semibold text-gray-900">
                        {loading ? (
                          <div className="animate-pulse h-6 w-16 bg-gray-200 rounded"></div>
                        ) : (
                          counts.active_patients_1m.toLocaleString()
                        )}
                      </dd>
                    </dl>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Department Analytics */}
          <div className="bg-white shadow-lg rounded-lg overflow-hidden mb-8">
            <div className="px-6 py-5 border-b border-gray-200">
              <div className="flex flex-col space-y-1">
                <h3 className="text-lg font-medium text-gray-900">
                  Department Activity
                </h3>
                <div className="text-sm text-gray-500">
                  {formattedDates.start} - {formattedDates.end}
                </div>
              </div>
            </div>
            <div className="p-6">
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                {loading ? (
                  Array.from({ length: 6 }).map((_, index) => (
                    <div key={index} className="bg-gray-50 rounded-lg p-5 animate-pulse">
                      <div className="h-4 bg-gray-200 rounded w-3/4 mb-4"></div>
                      <div className="h-6 bg-gray-200 rounded w-1/4"></div>
                    </div>
                  ))
                ) : departmentCounts.length > 0 ? (
                  departmentCounts.map((dept) => (
                    <div key={dept.department} className="bg-gray-50 rounded-lg p-5">
                      <div className="flex items-center">
                        <Building2 className="h-5 w-5 text-green-600 mr-2" />
                        <h4 className="text-sm font-medium text-gray-900 truncate">
                          {dept.department}
                        </h4>
                      </div>
                      <div className="mt-2 text-2xl font-semibold text-gray-900">
                        {dept.patient_count.toLocaleString()}
                      </div>
                      <div className="mt-1 text-sm text-gray-500">unique patients</div>
                    </div>
                  ))
                ) : (
                  <div className="col-span-full text-center text-gray-500 py-8">
                    No department activity for this period
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* Medication Utilization */}
          <div className="bg-white shadow-lg rounded-lg overflow-hidden mb-8">
            <div className="px-6 py-5 border-b border-gray-200">
              <div className="flex flex-col space-y-1">
                <h3 className="text-lg font-medium text-gray-900">
                  Medication Utilization
                </h3>
                <div className="text-sm text-gray-500">
                  {formattedDates.start} - {formattedDates.end}
                </div>
              </div>
            </div>
            <div className="p-6">
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                {/* Semaglutide Usage */}
                <div className="bg-gray-50 rounded-lg p-5">
                  <div className="flex items-center">
                    <Pill className="h-5 w-5 text-green-600 mr-2" />
                    <h4 className="text-sm font-medium text-gray-900">
                      Semaglutide
                    </h4>
                  </div>
                  <div className="mt-2 text-2xl font-semibold text-gray-900">
                    {loading ? (
                      <div className="animate-pulse h-8 w-24 bg-gray-200 rounded"></div>
                    ) : (
                      `${medicationUsage.semaglutide.toLocaleString()} mg`
                    )}
                  </div>
                  <div className="mt-1 text-sm text-gray-500">total administered</div>
                </div>

                {/* Tirzepatide Usage */}
                <div className="bg-gray-50 rounded-lg p-5">
                  <div className="flex items-center">
                    <Syringe className="h-5 w-5 text-green-600 mr-2" />
                    <h4 className="text-sm font-medium text-gray-900">
                      Tirzepatide
                    </h4>
                  </div>
                  <div className="mt-2 text-2xl font-semibold text-gray-900">
                    {loading ? (
                      <div className="animate-pulse h-8 w-24 bg-gray-200 rounded"></div>
                    ) : (
                      `${medicationUsage.tirzepatide.toLocaleString()} mg`
                    )}
                  </div>
                  <div className="mt-1 text-sm text-gray-500">total administered</div>
                </div>
              </div>
            </div>
          </div>

          {/* Error Display */}
          {error && (
            <div className="bg-red-50 p-4 rounded-md mb-6">
              <div className="flex">
                <div className="flex-shrink-0">
                  <AlertCircle className="h-5 w-5 text-red-400" />
                </div>
                <div className="ml-3">
                  <h3 className="text-sm font-medium text-red-800">Error loading analytics data</h3>
                  <div className="mt-2 text-sm text-red-700">
                    {error}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}