import React, { useEffect, useState } from 'react';
import { supabase } from '../lib/supabase';
import { PasswordChangeModal } from './PasswordChangeModal';

export function ForcePasswordChange() {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    checkPasswordChange();
  }, []);

  const checkPasswordChange = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) return;

      const { data, error } = await supabase.rpc('get_user_details', {
        p_user_id: user.id
      });

      if (error) throw error;
      if (data && data.length > 0 && data[0].force_password_change) {
        setShowModal(true);
      }
    } catch (error) {
      console.error('Error checking password change requirement:', error);
    }
  };

  const handlePasswordChanged = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) return;

      // Update force_password_change flag
      const { error } = await supabase.rpc('update_user_details', {
        p_user_id: user.id,
        p_force_password_change: false
      });

      if (error) throw error;
      setShowModal(false);
    } catch (error) {
      console.error('Error updating password change flag:', error);
    }
  };

  if (!showModal) return null;

  return <PasswordChangeModal onClose={handlePasswordChanged} />;
}