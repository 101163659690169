import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  ArrowLeft, 
  Leaf, 
  Users, 
  Calendar,
  Clock,
  BarChart,
  LogOut,
  ChevronLeft,
  ChevronRight,
  CalendarDays,
  Edit2,
  AlertCircle
} from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import { supabase } from '../lib/supabase';
import { formatPacificDate } from '../utils/dateTime';
import { ProviderNameModal } from '../components/ProviderNameModal';

interface ProviderStats {
  provider_name: string;
  display_name: string | null;
  total_visits: number;
  cancelled_visits: number;
  unique_patients: number;
}

interface TimeRange {
  label: string;
  days: number;
}

interface PayrollCycle {
  startDate: Date;
  endDate: Date;
}

export function ProviderVisits() {
  const navigate = useNavigate();
  const { user, userRoles, signOut } = useAuth();
  const isAdmin = userRoles.includes('admin');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [providerStats, setProviderStats] = useState<ProviderStats[]>([]);
  const [selectedRange, setSelectedRange] = useState<number>(30);
  const [usePayrollCycle, setUsePayrollCycle] = useState(true);
  const [currentPayrollCycle, setCurrentPayrollCycle] = useState<PayrollCycle>(() => {
    const mostRecentStart = new Date(2025, 2, 8); // March 8, 2025
    const today = new Date();
    const twoWeeks = 14 * 24 * 60 * 60 * 1000;
    
    while (mostRecentStart.getTime() + twoWeeks <= today.getTime()) {
      mostRecentStart.setDate(mostRecentStart.getDate() + 14);
    }
    
    mostRecentStart.setDate(mostRecentStart.getDate() - 14);
    
    const cycleEnd = new Date(mostRecentStart);
    cycleEnd.setDate(cycleEnd.getDate() + 13);
    
    return {
      startDate: mostRecentStart,
      endDate: cycleEnd
    };
  });
  const [selectedProvider, setSelectedProvider] = useState<{
    renderingProvider: string;
    displayName?: string;
  } | null>(null);
  const [showNameModal, setShowNameModal] = useState(false);

  const timeRanges: TimeRange[] = [
    { label: 'Last 7 Days', days: 7 },
    { label: 'Last 30 Days', days: 30 },
    { label: 'Last 90 Days', days: 90 },
    { label: 'Last 180 Days', days: 180 },
    { label: 'Last 365 Days', days: 365 }
  ];

  useEffect(() => {
    if (!isAdmin) {
      navigate('/settings');
      return;
    }
    fetchProviderStats();
  }, [isAdmin, navigate, selectedRange, currentPayrollCycle, usePayrollCycle]);

  const fetchProviderStats = async () => {
    try {
      setLoading(true);
      setError(null);

      let startDate: Date;
      let endDate: Date;

      if (usePayrollCycle) {
        startDate = currentPayrollCycle.startDate;
        endDate = currentPayrollCycle.endDate;
      } else {
        endDate = new Date();
        startDate = new Date();
        startDate.setDate(startDate.getDate() - selectedRange);
      }

      // First get all provider names
      const { data: providerNames, error: namesError } = await supabase
        .from('provider_names')
        .select('*');

      if (namesError) throw namesError;

      // Create a map of rendering_provider to display_name
      const nameMap = new Map(
        providerNames?.map(p => [p.rendering_provider, p.display_name]) || []
      );

      // Get appointment data
      const { data, error } = await supabase
        .from('appointments')
        .select(`
          rendering_provider,
          cancelled_date,
          external_patient_id
        `)
        .gte('appointment_date', startDate.toISOString().split('T')[0])
        .lte('appointment_date', endDate.toISOString().split('T')[0]);

      if (error) throw error;

      const providerMap = new Map<string, {
        display_name: string | null;
        total: number;
        cancelled: number;
        patients: Set<string>;
      }>();

      data.forEach(appointment => {
        const provider = appointment.rendering_provider;
        if (!providerMap.has(provider)) {
          providerMap.set(provider, {
            display_name: nameMap.get(provider) || null,
            total: 0,
            cancelled: 0,
            patients: new Set()
          });
        }

        const stats = providerMap.get(provider)!;
        stats.total++;
        if (appointment.cancelled_date) {
          stats.cancelled++;
        }
        stats.patients.add(appointment.external_patient_id);
      });

      const stats = Array.from(providerMap.entries()).map(([provider, stats]) => ({
        provider_name: provider,
        display_name: stats.display_name,
        total_visits: stats.total,
        cancelled_visits: stats.cancelled,
        unique_patients: stats.patients.size
      }));

      stats.sort((a, b) => b.total_visits - a.total_visits);

      setProviderStats(stats);
    } catch (error: any) {
      console.error('Error fetching provider stats:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const navigatePayrollCycle = (direction: 'prev' | 'next') => {
    setCurrentPayrollCycle(prev => {
      const newStart = new Date(prev.startDate);
      const newEnd = new Date(prev.endDate);
      
      if (direction === 'prev') {
        newStart.setDate(newStart.getDate() - 14);
        newEnd.setDate(newEnd.getDate() - 14);
      } else {
        newStart.setDate(newStart.getDate() + 14);
        newEnd.setDate(newEnd.getDate() + 14);
      }

      return {
        startDate: newStart,
        endDate: newEnd
      };
    });
  };

  const handleEditName = (provider: string, displayName?: string) => {
    setSelectedProvider({
      renderingProvider: provider,
      displayName
    });
    setShowNameModal(true);
  };

  // Redirect non-admin users
  if (!isAdmin) {
    return null;
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-green-50 to-blue-50">
      {/* Header */}
      <nav className="bg-white shadow-sm">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16">
            <div className="flex items-center">
              <Leaf className="h-6 w-6 text-green-600" />
              <div className="ml-2">
                <span className="text-xl font-semibold text-gray-900">THRIVE</span>
                <span className="ml-2 text-sm text-gray-500">Provider Visits</span>
              </div>
            </div>
            <div className="flex items-center space-x-4">
              <span className="text-sm text-gray-700">{user?.email}</span>
              <div className="flex flex-wrap gap-2">
                {userRoles.map((role) => (
                  <span
                    key={role}
                    className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800"
                  >
                    {role}
                  </span>
                ))}
              </div>
              <button
                onClick={() => signOut()}
                className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-green-600 hover:bg-green-700"
              >
                <LogOut className="h-4 w-4 mr-2" />
                Sign Out
              </button>
            </div>
          </div>
        </div>
      </nav>

      <main className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        <div className="px-4 py-4 sm:px-0">
          {/* Header Section */}
          <div className="bg-white shadow-lg rounded-lg mb-6 overflow-hidden">
            <div className="relative">
              <div className="absolute inset-0 bg-gradient-to-r from-green-600 to-blue-600 opacity-90"></div>
              <div className="relative px-8 py-12">
                <div className="flex items-center justify-between">
                  <button
                    onClick={() => navigate('/settings')}
                    className="text-white hover:text-green-100 transition-colors duration-200"
                  >
                    <ArrowLeft className="h-6 w-6" />
                  </button>
                  <Leaf className="h-12 w-12 text-white opacity-20" />
                </div>
                
                <div className="mt-4 text-center">
                  <h1 className="text-4xl font-bold text-white mb-2">THRIVE</h1>
                  <p className="text-green-100 text-lg mb-4">
                    Tracking Health and Reducing Illness Via Efficiency
                  </p>
                  <div className="w-16 h-1 bg-white/30 mx-auto rounded-full"></div>
                  <h2 className="text-2xl font-semibold text-white mt-4">Provider Visit Statistics</h2>
                </div>
              </div>
            </div>
          </div>

          {/* View Type Selector */}
          <div className="bg-white shadow-lg rounded-lg mb-6 overflow-hidden">
            <div className="p-6">
              <div className="flex items-center justify-between">
                <h3 className="text-lg font-medium text-gray-900">View Type</h3>
                <div className="flex gap-2">
                  <button
                    onClick={() => setUsePayrollCycle(true)}
                    className={`px-4 py-2 rounded-lg text-sm font-medium transition-colors duration-200 ${
                      usePayrollCycle
                        ? 'bg-blue-100 text-blue-800 border-2 border-blue-200'
                        : 'bg-gray-100 text-gray-700 border-2 border-gray-200 hover:bg-gray-200'
                    }`}
                  >
                    <CalendarDays className="h-4 w-4 inline mr-2" />
                    Payroll Cycles
                  </button>
                  <button
                    onClick={() => setUsePayrollCycle(false)}
                    className={`px-4 py-2 rounded-lg text-sm font-medium transition-colors duration-200 ${
                      !usePayrollCycle
                        ? 'bg-blue-100 text-blue-800 border-2 border-blue-200'
                        : 'bg-gray-100 text-gray-700 border-2 border-gray-200 hover:bg-gray-200'
                    }`}
                  >
                    <Calendar className="h-4 w-4 inline mr-2" />
                    Custom Ranges
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* Time Range Selector */}
          <div className="bg-white shadow-lg rounded-lg mb-6 overflow-hidden">
            <div className="p-6">
              <div className="flex items-center justify-between">
                <h3 className="text-lg font-medium text-gray-900">Time Range</h3>
                {usePayrollCycle ? (
                  <div className="flex items-center gap-4">
                    <button
                      onClick={() => navigatePayrollCycle('prev')}
                      className="p-2 text-gray-400 hover:text-gray-600"
                    >
                      <ChevronLeft className="h-5 w-5" />
                    </button>
                    <div className="text-sm font-medium text-gray-900">
                      {formatPacificDate(currentPayrollCycle.startDate)} - {formatPacificDate(currentPayrollCycle.endDate)}
                    </div>
                    <button
                      onClick={() => navigatePayrollCycle('next')}
                      className="p-2 text-gray-400 hover:text-gray-600"
                    >
                      <ChevronRight className="h-5 w-5" />
                    </button>
                  </div>
                ) : (
                  <div className="flex gap-2">
                    {timeRanges.map(range => (
                      <button
                        key={range.days}
                        onClick={() => setSelectedRange(range.days)}
                        className={`px-4 py-2 rounded-lg text-sm font-medium transition-colors duration-200 ${
                          selectedRange === range.days
                            ? 'bg-green-100 text-green-800 border-2 border-green-200'
                            : 'bg-gray-100 text-gray-700 border-2 border-gray-200 hover:bg-gray-200'
                        }`}
                      >
                        {range.label}
                      </button>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* Provider Stats */}
          <div className="bg-white shadow-lg rounded-lg overflow-hidden">
            <div className="px-6 py-5 border-b border-gray-200">
              <div className="flex items-center">
                <BarChart className="h-5 w-5 text-green-600" />
                <h3 className="ml-2 text-lg font-medium text-gray-900">
                  Provider Statistics
                </h3>
              </div>
            </div>

            {error && (
              <div className="px-6 py-4 bg-red-50">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <AlertCircle className="h-5 w-5 text-red-400" />
                  </div>
                  <div className="ml-3">
                    <h3 className="text-sm font-medium text-red-800">Error</h3>
                    <div className="mt-2 text-sm text-red-700">
                      {error}
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Provider
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Total Visits
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider font-bold">
                      Completed Visits
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Cancelled Visits
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Unique Patients
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Completion Rate
                    </th>
                    <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {loading ? (
                    <tr>
                      <td colSpan={7} className="px-6 py-4 text-center">
                        <div className="flex items-center justify-center">
                          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-green-600"></div>
                        </div>
                      </td>
                    </tr>
                  ) : providerStats.length === 0 ? (
                    <tr>
                      <td colSpan={7} className="px-6 py-4 text-center text-gray-500">
                        No provider statistics available for the selected time range
                      </td>
                    </tr>
                  ) : (
                    providerStats.map((stat, index) => (
                      <tr key={index} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          {stat.display_name || stat.provider_name}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {stat.total_visits}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-bold text-gray-900">
                          {stat.total_visits - stat.cancelled_visits}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {stat.cancelled_visits}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {stat.unique_patients}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {((stat.total_visits - stat.cancelled_visits) / stat.total_visits * 100).toFixed(1)}%
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                          <button
                            onClick={() => handleEditName(stat.provider_name, stat.display_name || undefined)}
                            className="text-blue-600 hover:text-blue-900"
                          >
                            <Edit2 className="h-4 w-4" />
                          </button>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </main>

      {/* Provider Name Modal */}
      {selectedProvider && (
        <ProviderNameModal
          isOpen={showNameModal}
          onClose={() => {
            setShowNameModal(false);
            setSelectedProvider(null);
          }}
          renderingProvider={selectedProvider.renderingProvider}
          displayName={selectedProvider.displayName}
          onSuccess={fetchProviderStats}
        />
      )}
    </div>
  );
}