import React, { useState, useEffect } from 'react';
import { X, Eye, EyeOff, Check, AlertCircle, Lightbulb, ThumbsUp, ThumbsDown, FileText, Clock } from 'lucide-react';
import { supabase } from '../../lib/supabase';
import { useAuth } from '../../contexts/AuthContext';
import { formatPacificDateTime } from '../../utils/dateTime';

interface EnhancementSuggestionModalProps {
  isOpen: boolean;
  onClose: () => void;
}

interface Suggestion {
  id: string;
  title: string;
  description: string;
  created_at: string;
  created_by_email: string;
  upvotes: number;
  downvotes: number;
  status: 'pending' | 'approved' | 'rejected' | 'implemented';
  notes?: {
    content: string;
  };
}

export function EnhancementSuggestionModal({ isOpen, onClose }: EnhancementSuggestionModalProps) {
  const { user } = useAuth();
  const [showForm, setShowForm] = useState(false);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [suggestions, setSuggestions] = useState<Suggestion[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [userVotes, setUserVotes] = useState<Record<string, 'up' | 'down' | null>>({});
  const [showAllSuggestions, setShowAllSuggestions] = useState(false);

  useEffect(() => {
    if (isOpen) {
      fetchSuggestions();
      fetchUserVotes();
    }
  }, [isOpen]);

  const fetchSuggestions = async () => {
    try {
      setLoading(true);
      setError(null);

      const { data: suggestionsData, error: suggestionsError } = await supabase
        .from('enhancement_suggestions')
        .select(`
          *,
          notes:enhancement_notes(content)
        `)
        .order('created_at', { ascending: false });

      if (suggestionsError) throw suggestionsError;
      setSuggestions(suggestionsData || []);
    } catch (error: any) {
      console.error('Error fetching suggestions:', error);
      setError('Failed to load suggestions');
    } finally {
      setLoading(false);
    }
  };

  const fetchUserVotes = async () => {
    if (!user) return;

    try {
      const { data: votesData, error: votesError } = await supabase
        .from('enhancement_votes')
        .select('*')
        .eq('user_id', user.id);

      if (votesError) throw votesError;

      const votes: Record<string, 'up' | 'down' | null> = {};
      votesData?.forEach(vote => {
        votes[vote.suggestion_id] = vote.vote_type === 'upvote' ? 'up' : 'down';
      });

      setUserVotes(votes);
    } catch (error) {
      console.error('Error fetching user votes:', error);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!user?.email) {
      setError('You must be logged in to submit suggestions');
      return;
    }

    try {
      setError(null);
      setSuccess(null);
      
      if (!title.trim()) {
        setError('Title is required');
        return;
      }

      if (!description.trim()) {
        setError('Description is required');
        return;
      }

      const { data: suggestionData, error: suggestionError } = await supabase
        .from('enhancement_suggestions')
        .insert({
          title: title.trim(),
          description: description.trim(),
          created_by_email: user.email,
          status: 'pending',
          upvotes: 0,
          downvotes: 0
        })
        .select()
        .single();

      if (suggestionError) {
        console.error('Error inserting suggestion:', suggestionError);
        throw suggestionError;
      }

      setSuggestions(prev => [suggestionData, ...prev]);
      setSuccess('Suggestion submitted successfully!');
      setTitle('');
      setDescription('');
      setShowForm(false);

      setTimeout(() => setSuccess(null), 3000);
    } catch (error: any) {
      console.error('Error in handleSubmit:', error);
      setError(error.message || 'Failed to submit suggestion');
    }
  };

  const handleVote = async (suggestionId: string, voteType: 'up' | 'down') => {
    if (!user) {
      setError('You must be logged in to vote');
      return;
    }

    try {
      const currentVote = userVotes[suggestionId];
      let newVote: 'up' | 'down' | null = voteType;

      // If clicking the same vote type, remove the vote
      if (currentVote === voteType) {
        newVote = null;
      }

      // Update the vote in the database
      if (newVote === null) {
        // Remove the vote
        await supabase
          .from('enhancement_votes')
          .delete()
          .eq('suggestion_id', suggestionId)
          .eq('user_id', user.id);
      } else {
        // Upsert the vote
        await supabase
          .from('enhancement_votes')
          .upsert({
            suggestion_id: suggestionId,
            user_id: user.id,
            vote_type: newVote === 'up' ? 'upvote' : 'downvote'
          });
      }

      // Update local state
      setUserVotes(prev => ({
        ...prev,
        [suggestionId]: newVote
      }));

      // Refresh suggestions to get updated vote counts
      await fetchSuggestions();
    } catch (error) {
      console.error('Error voting:', error);
      setError('Failed to register vote');
    }
  };

  const getStatusPriority = (status: string): number => {
    switch (status) {
      case 'pending': return 0;
      case 'approved': return 1;
      case 'rejected': return 2;
      case 'implemented': return 3;
      default: return 4;
    }
  };

  const getFilteredSuggestions = () => {
    if (showAllSuggestions) {
      return suggestions;
    }

    const sixtyDaysAgo = new Date();
    sixtyDaysAgo.setDate(sixtyDaysAgo.getDate() - 60);

    return suggestions.filter(suggestion => {
      const lastUpdated = new Date(suggestion.updated_at);
      return lastUpdated >= sixtyDaysAgo;
    });
  };

  const sortedSuggestions = getFilteredSuggestions().sort((a, b) => {
    // First sort by status priority
    const statusDiff = getStatusPriority(a.status) - getStatusPriority(b.status);
    if (statusDiff !== 0) return statusDiff;

    // Then sort by upvotes (descending)
    const voteDiff = b.upvotes - a.upvotes;
    if (voteDiff !== 0) return voteDiff;

    // Finally sort by date (newest first)
    return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
  });

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg shadow-xl max-w-4xl w-full max-h-[90vh] flex flex-col">
        <div className="px-6 py-4 border-b border-gray-200 flex items-center justify-between">
          <div className="flex items-center">
            <Lightbulb className="h-5 w-5 text-purple-600 mr-2" />
            <h3 className="text-lg font-medium text-gray-900">
              Enhancement Suggestions
            </h3>
          </div>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-500"
          >
            <X className="h-5 w-5" />
          </button>
        </div>

        <div className="p-6 flex-1 overflow-y-auto">
          {error && (
            <div className="mb-4 p-4 bg-red-50 rounded-md">
              <div className="flex">
                <AlertCircle className="h-5 w-5 text-red-400" />
                <p className="ml-3 text-sm text-red-700">{error}</p>
              </div>
            </div>
          )}

          {success && (
            <div className="mb-4 p-4 bg-green-50 rounded-md">
              <div className="flex">
                <Check className="h-5 w-5 text-green-400" />
                <p className="ml-3 text-sm text-green-700">{success}</p>
              </div>
            </div>
          )}

          {showForm ? (
            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <label htmlFor="title" className="block text-sm font-medium text-gray-700">
                  Title
                </label>
                <input
                  type="text"
                  id="title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500 sm:text-sm"
                  placeholder="Enter a title for your suggestion"
                />
              </div>

              <div>
                <label htmlFor="description" className="block text-sm font-medium text-gray-700">
                  Description
                </label>
                <textarea
                  id="description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  rows={4}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500 sm:text-sm"
                  placeholder="Describe your enhancement suggestion in detail"
                />
              </div>

              <div className="flex justify-end space-x-3">
                <button
                  type="button"
                  onClick={() => setShowForm(false)}
                  className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 text-sm font-medium text-white bg-purple-600 border border-transparent rounded-md hover:bg-purple-700"
                >
                  Submit Suggestion
                </button>
              </div>
            </form>
          ) : (
            <>
              <div className="flex items-center justify-between mb-6">
                <button
                  onClick={() => setShowForm(true)}
                  className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-purple-600 hover:bg-purple-700"
                >
                  <Lightbulb className="h-4 w-4 mr-2" />
                  Submit New Suggestion
                </button>

                <div className="flex items-center">
                  <Clock className="h-4 w-4 mr-2 text-gray-500" />
                  <button
                    onClick={() => setShowAllSuggestions(!showAllSuggestions)}
                    className="text-sm text-gray-600 hover:text-gray-900"
                  >
                    {showAllSuggestions ? 'Show Recent (60 Days)' : 'Show All Time'}
                  </button>
                </div>
              </div>

              <div className="space-y-4">
                {loading ? (
                  <div className="text-center py-8">
                    <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-purple-600 mx-auto"></div>
                    <p className="mt-2 text-gray-500">Loading suggestions...</p>
                  </div>
                ) : sortedSuggestions.length === 0 ? (
                  <div className="text-center py-8">
                    <p className="text-gray-500">No suggestions found for the selected time period</p>
                  </div>
                ) : (
                  sortedSuggestions.map((suggestion) => (
                    <div
                      key={suggestion.id}
                      className="bg-white border border-gray-200 rounded-lg p-4 shadow-sm"
                    >
                      <div className="flex justify-between items-start">
                        <div className="flex-1">
                          <h4 className="text-lg font-medium text-gray-900">
                            {suggestion.title}
                          </h4>
                          <p className="mt-1 text-sm text-gray-500">
                            {suggestion.description}
                          </p>
                          {suggestion.notes && suggestion.notes.content && (
                            <div className="mt-3 p-3 bg-gray-50 rounded-md">
                              <div className="flex items-center text-sm font-medium text-gray-700 mb-2">
                                <FileText className="h-4 w-4 mr-2 text-gray-500" />
                                Implementation Notes
                              </div>
                              <div className="text-sm text-gray-600 prose prose-sm max-w-none"
                                   dangerouslySetInnerHTML={{ __html: suggestion.notes.content }} />
                            </div>
                          )}
                          <div className="mt-2 flex items-center space-x-4 text-xs text-gray-500">
                            <span>
                              Submitted by {suggestion.created_by_email}
                            </span>
                            <span>•</span>
                            <span>
                              {formatPacificDateTime(suggestion.created_at)}
                            </span>
                            <span>•</span>
                            <span className={`capitalize ${
                              suggestion.status === 'implemented' ? 'text-green-600' :
                              suggestion.status === 'approved' ? 'text-blue-600' :
                              suggestion.status === 'rejected' ? 'text-red-600' :
                              'text-yellow-600'
                            }`}>
                              {suggestion.status}
                            </span>
                          </div>
                        </div>
                        <div className="flex items-center space-x-2">
                          <button
                            onClick={() => handleVote(suggestion.id, 'up')}
                            className={`p-1 rounded ${
                              userVotes[suggestion.id] === 'up'
                                ? 'text-green-600 bg-green-50'
                                : 'text-gray-400 hover:text-gray-500'
                            }`}
                          >
                            <ThumbsUp className="h-5 w-5" />
                          </button>
                          <span className="text-sm font-medium text-gray-700">
                            {suggestion.upvotes}
                          </span>
                          <button
                            onClick={() => handleVote(suggestion.id, 'down')}
                            className={`p-1 rounded ${
                              userVotes[suggestion.id] === 'down'
                                ? 'text-red-600 bg-red-50'
                                : 'text-gray-400 hover:text-gray-500'
                            }`}
                          >
                            <ThumbsDown className="h-5 w-5" />
                          </button>
                          <span className="text-sm font-medium text-gray-700">
                            {suggestion.downvotes}
                          </span>
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}