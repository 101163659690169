import React, { useState, useEffect, useRef } from 'react';
import { X, AlertCircle } from 'lucide-react';
import { supabase } from '../lib/supabase';

interface ProcedureCode {
  code: string;
  description: string | null;
  milligrams: number | null;
  form: string;
  medication_type: string;
  mapped: boolean;
}

interface ProcedureCodeModalProps {
  procedureCode: ProcedureCode | null;
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

export function ProcedureCodeModal({ procedureCode, isOpen, onClose, onSuccess }: ProcedureCodeModalProps) {
  const [description, setDescription] = useState('');
  const [milligrams, setMilligrams] = useState('');
  const [form, setForm] = useState('none');
  const [medicationType, setMedicationType] = useState('none');
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const descriptionInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isOpen && descriptionInputRef.current) {
      descriptionInputRef.current.focus();
    }
  }, [isOpen]);

  useEffect(() => {
    if (procedureCode) {
      setDescription(procedureCode.description || '');
      setMilligrams(procedureCode.milligrams?.toString() || '');
      setForm(procedureCode.form);
      setMedicationType(procedureCode.medication_type);
    } else {
      setDescription('');
      setMilligrams('');
      setForm('none');
      setMedicationType('none');
    }
  }, [procedureCode]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!procedureCode) return;

    setError(null);
    setLoading(true);

    try {
      const { error: upsertError } = await supabase
        .from('procedure_codes')
        .upsert({
          code: procedureCode.code,
          description: description.trim() || null,
          milligrams: milligrams ? parseFloat(milligrams) : null,
          form,
          medication_type: medicationType
        });

      if (upsertError) throw upsertError;

      onSuccess();
      onClose();
    } catch (error: any) {
      console.error('Error saving procedure code:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  if (!isOpen || !procedureCode) return null;

  return (
    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg shadow-xl max-w-md w-full">
        <div className="px-6 py-4 border-b border-gray-200 flex items-center justify-between">
          <h3 className="text-lg font-medium text-gray-900">
            Edit Procedure Code {procedureCode.code}
          </h3>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-500"
          >
            <X className="h-5 w-5" />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="p-6">
          {error && (
            <div className="mb-4 p-4 bg-red-50 rounded-md">
              <div className="flex">
                <AlertCircle className="h-5 w-5 text-red-400" />
                <p className="ml-3 text-sm text-red-700">{error}</p>
              </div>
            </div>
          )}

          <div className="space-y-4">
            <div>
              <label htmlFor="description" className="block text-sm font-medium text-gray-700">
                Description
              </label>
              <input
                ref={descriptionInputRef}
                type="text"
                id="description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                placeholder="Enter procedure description"
              />
            </div>

            <div>
              <label htmlFor="milligrams" className="block text-sm font-medium text-gray-700">
                Milligrams
              </label>
              <input
                type="number"
                id="milligrams"
                value={milligrams}
                onChange={(e) => setMilligrams(e.target.value)}
                step="0.01"
                min="0"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                placeholder="Enter milligrams"
              />
            </div>

            <div>
              <label htmlFor="form" className="block text-sm font-medium text-gray-700">
                Form
              </label>
              <select
                id="form"
                value={form}
                onChange={(e) => setForm(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
              >
                <option value="none">None</option>
                <option value="vial">Vial</option>
                <option value="syringe">Syringe</option>
                <option value="injection">Injection</option>
              </select>
            </div>

            <div>
              <label htmlFor="medicationType" className="block text-sm font-medium text-gray-700">
                Medication Type
              </label>
              <select
                id="medicationType"
                value={medicationType}
                onChange={(e) => setMedicationType(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
              >
                <option value="none">None</option>
                <option value="semaglutide">Semaglutide</option>
                <option value="tirzepatide">Tirzepatide</option>
                <option value="vitamin_b12">Vitamin B12</option>
              </select>
            </div>
          </div>

          <div className="mt-6 flex justify-end space-x-3">
            <button
              type="button"
              onClick={onClose}
              className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 disabled:opacity-50 disabled:cursor-not-allowed"
              disabled={loading}
            >
              {loading ? (
                <>
                  <span className="animate-spin rounded-full h-4 w-4 border-b-2 border-white mr-2" />
                  Saving...
                </>
              ) : (
                'Save Changes'
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}