import React, { useState, useEffect } from 'react';
import { Leaf, LogOut, LayoutTemplate, MessageSquare, Bell } from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate, useLocation } from 'react-router-dom';
import { supabase } from '../../lib/supabase';

export function Header() {
  const { user, userRoles, signOut } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const isVerticalLayout = location.pathname === '/dashboard-vertical';
  const [hasUnreadMessages, setHasUnreadMessages] = useState(false);
  const [checkingMessages, setCheckingMessages] = useState(true);

  useEffect(() => {
    checkForUnreadMessages();
    
    // Set up a polling interval to check for new messages every minute
    const interval = setInterval(checkForUnreadMessages, 60000);
    
    return () => clearInterval(interval);
  }, []);

  const checkForUnreadMessages = async () => {
    try {
      setCheckingMessages(true);
      const { data, error } = await supabase.rpc('has_unread_messages');
      
      if (error) {
        console.error('Error checking for unread messages:', error);
        return;
      }
      
      setHasUnreadMessages(data);
    } catch (error) {
      console.error('Error checking for unread messages:', error);
    } finally {
      setCheckingMessages(false);
    }
  };

  return (
    <nav className="bg-white shadow-sm">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          <div className="flex items-center">
            <Leaf className="h-6 w-6 text-green-600" />
            <div className="ml-2">
              <span className="text-xl font-semibold text-gray-900">THRIVE</span>
              <span className="ml-2 text-sm text-gray-500">Dashboard</span>
            </div>
          </div>
          <div className="flex items-center space-x-4">
            {/* Message notification button */}
            <button
              onClick={() => navigate('/message-board')}
              className="relative inline-flex items-center px-3 py-2 border border-gray-300 text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
            >
              {hasUnreadMessages ? (
                <Bell className="h-4 w-4 mr-2 text-red-500" />
              ) : (
                <MessageSquare className="h-4 w-4 mr-2 text-gray-500" />
              )}
              Messages
              {hasUnreadMessages && (
                <span className="absolute -top-1 -right-1 flex h-4 w-4">
                  <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"></span>
                  <span className="relative inline-flex rounded-full h-4 w-4 bg-red-500 items-center justify-center">
                    <span className="text-white text-xs font-bold">!</span>
                  </span>
                </span>
              )}
            </button>
            
            <button
              onClick={() => navigate(isVerticalLayout ? '/dashboard' : '/dashboard-vertical')}
              className="inline-flex items-center px-3 py-2 border border-gray-300 text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
            >
              <LayoutTemplate className="h-4 w-4 mr-2" />
              Switch to {isVerticalLayout ? 'Standard' : 'Vertical'} Layout
            </button>
            <span className="text-sm text-gray-700">{user?.email}</span>
            <div className="flex flex-wrap gap-2">
              {userRoles.map((role) => (
                <span
                  key={role}
                  className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800"
                >
                  {role}
                </span>
              ))}
            </div>
            <button
              onClick={() => signOut()}
              className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-green-600 hover:bg-green-700"
            >
              <LogOut className="h-4 w-4 mr-2" />
              Sign Out
            </button>
          </div>
        </div>
      </div>
    </nav>
  );
}