import React from 'react';
import { ArrowLeft, Leaf, RefreshCw, Trash2 } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

interface HeroHeaderProps {
  loading: boolean;
  refreshing: boolean;
  bulkDeleteMode: boolean;
  onRefresh: () => void;
  onToggleBulkDelete: () => void;
}

export function HeroHeader({ 
  loading, 
  refreshing, 
  bulkDeleteMode,
  onRefresh,
  onToggleBulkDelete
}: HeroHeaderProps) {
  const navigate = useNavigate();

  return (
    <div className="bg-white shadow-lg rounded-lg mb-8 overflow-hidden">
      <div className="relative">
        <div className="absolute inset-0 bg-gradient-to-r from-green-600 to-blue-600 opacity-90"></div>
        <div className="relative px-8 py-12">
          <div className="flex items-center justify-between">
            <button
              onClick={() => navigate('/settings')}
              className="text-white hover:text-green-100 transition-colors duration-200"
            >
              <ArrowLeft className="h-6 w-6" />
            </button>
            <div className="flex items-center space-x-4">
              <button
                onClick={onRefresh}
                className="bg-white/10 backdrop-blur-sm rounded-lg px-4 py-2 text-white hover:bg-white/20"
                disabled={loading || refreshing}
              >
                <RefreshCw className={`h-4 w-4 mr-2 ${refreshing ? 'animate-spin' : ''}`} />
                Refresh Data
              </button>
              <button
                onClick={onToggleBulkDelete}
                className={`bg-white/10 backdrop-blur-sm rounded-lg px-4 py-2 text-white hover:bg-white/20 ${
                  bulkDeleteMode ? 'bg-red-500/20' : ''
                }`}
                disabled={loading}
              >
                <Trash2 className="h-4 w-4 mr-2" />
                {bulkDeleteMode ? 'Cancel Bulk Delete' : 'Bulk Delete'}
              </button>
              <Leaf className="h-12 w-12 text-white opacity-20" />
            </div>
          </div>
          
          <div className="mt-4 text-center">
            <h1 className="text-4xl font-bold text-white mb-2">THRIVE</h1>
            <div className="text-green-100 text-lg mb-4">
              Tracking Health and Reducing Illness Via Efficiency
            </div>
            <div className="w-16 h-1 bg-white/30 mx-auto rounded-full"></div>
            <h2 className="text-2xl font-semibold text-white mt-4">Population Management</h2>
          </div>
        </div>
      </div>
    </div>
  );
}