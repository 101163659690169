import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  ArrowLeft, 
  Leaf, 
  Plus, 
  Edit2, 
  Trash2, 
  AlertCircle,
  Check,
  Tags,
  Info,
  GripVertical
} from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import { supabase } from '../lib/supabase';
import { CategoryModal } from '../components/CategoryModal';

interface Category {
  id: string;
  name: string;
  description: string;
  display_order: number;
  created_at: string;
}

export function ProductCategories() {
  const navigate = useNavigate();
  const { userRoles } = useAuth();
  const isAdmin = userRoles.includes('admin');
  const [categories, setCategories] = useState<Category[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [editingCategory, setEditingCategory] = useState<Category | null>(null);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState<string | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [draggingId, setDraggingId] = useState<string | null>(null);

  useEffect(() => {
    if (!isAdmin) {
      navigate('/settings');
      return;
    }
    fetchCategories();
  }, [isAdmin, navigate]);

  const fetchCategories = async () => {
    try {
      const { data, error } = await supabase
        .from('product_categories')
        .select('*')
        .order('display_order');

      if (error) throw error;
      setCategories(data || []);
    } catch (error: any) {
      console.error('Error fetching categories:', error);
      setError('Failed to load categories');
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (categoryId: string) => {
    try {
      const { error } = await supabase
        .from('product_categories')
        .delete()
        .eq('id', categoryId);

      if (error) throw error;

      setSuccess('Category successfully deleted');
      setShowDeleteConfirm(null);
      await fetchCategories();

      setTimeout(() => setSuccess(null), 3000);
    } catch (error: any) {
      console.error('Error deleting category:', error);
      setError('Failed to delete category');
      setTimeout(() => setError(null), 3000);
    }
  };

  const handleModalSuccess = async () => {
    await fetchCategories();
    setSuccess(editingCategory ? 'Category updated successfully' : 'Category created successfully');
    setTimeout(() => setSuccess(null), 3000);
  };

  const handleDragStart = (e: React.DragEvent<HTMLTableRowElement>, category: Category) => {
    setDraggingId(category.id);
    e.dataTransfer.effectAllowed = 'move';
    
    // Add dragging class to row
    e.currentTarget.classList.add('bg-gray-100');
  };

  const handleDragEnd = (e: React.DragEvent<HTMLTableRowElement>) => {
    setDraggingId(null);
    e.currentTarget.classList.remove('bg-gray-100');
  };

  const handleDragOver = (e: React.DragEvent<HTMLTableRowElement>) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = 'move';
  };

  const handleDrop = async (e: React.DragEvent<HTMLTableRowElement>, targetCategory: Category) => {
    e.preventDefault();
    
    if (!draggingId || draggingId === targetCategory.id) return;

    const draggedCategory = categories.find(c => c.id === draggingId);
    if (!draggedCategory) return;

    // Create new array with updated order
    const oldIndex = categories.findIndex(c => c.id === draggingId);
    const newIndex = categories.findIndex(c => c.id === targetCategory.id);
    
    const newCategories = [...categories];
    newCategories.splice(oldIndex, 1);
    newCategories.splice(newIndex, 0, draggedCategory);

    // Update the display order
    const reorderedIds = newCategories.map(c => c.id);

    try {
      const { error } = await supabase
        .rpc('reorder_categories', {
          p_category_ids: reorderedIds
        });

      if (error) throw error;

      // Update local state
      setCategories(newCategories.map((cat, index) => ({
        ...cat,
        display_order: index
      })));

      setSuccess('Categories reordered successfully');
      setTimeout(() => setSuccess(null), 3000);
    } catch (error: any) {
      console.error('Error reordering categories:', error);
      setError('Failed to reorder categories');
      setTimeout(() => setError(null), 3000);
      
      // Revert to original order
      await fetchCategories();
    }
  };

  if (!isAdmin) return null;

  return (
    <div className="min-h-screen bg-gradient-to-br from-green-50 to-blue-50">
      <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        <div className="px-4 py-4 sm:px-0">
          {/* Header Section */}
          <div className="bg-white shadow-lg rounded-lg mb-6 overflow-hidden">
            <div className="relative">
              <div className="absolute inset-0 bg-gradient-to-r from-green-600 to-blue-600 opacity-90"></div>
              <div className="relative px-8 py-12">
                <div className="flex items-center justify-between">
                  <button
                    onClick={() => navigate('/settings')}
                    className="text-white hover:text-green-100 transition-colors duration-200"
                  >
                    <ArrowLeft className="h-6 w-6" />
                  </button>
                  <Leaf className="h-12 w-12 text-white opacity-20" />
                </div>
                
                <div className="mt-4 text-center">
                  <h1 className="text-4xl font-bold text-white mb-2">THRIVE</h1>
                  <p className="text-green-100 text-lg mb-4">
                    Tracking Health and Reducing Illness Via Efficiency
                  </p>
                  <div className="w-16 h-1 bg-white/30 mx-auto rounded-full"></div>
                  <h2 className="text-2xl font-semibold text-white mt-4">Product Categories</h2>
                </div>
              </div>
            </div>
          </div>

          {/* Main Content */}
          <div className="bg-white shadow-lg rounded-lg overflow-hidden">
            <div className="px-6 py-5 border-b border-gray-200">
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <Tags className="h-5 w-5 text-green-600" />
                  <h3 className="ml-2 text-lg font-medium text-gray-900">
                    Product Categories
                  </h3>
                </div>
                <button
                  onClick={() => {
                    setEditingCategory(null);
                    setShowModal(true);
                  }}
                  className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700"
                >
                  <Plus className="h-4 w-4 mr-2" />
                  Add Category
                </button>
              </div>
            </div>

            {/* Messages */}
            {error && (
              <div className="px-6 py-4 bg-red-50">
                <div className="flex">
                  <AlertCircle className="h-5 w-5 text-red-400" />
                  <p className="ml-3 text-sm text-red-700">{error}</p>
                </div>
              </div>
            )}

            {success && (
              <div className="px-6 py-4 bg-green-50">
                <div className="flex">
                  <Check className="h-5 w-5 text-green-400" />
                  <p className="ml-3 text-sm text-green-700">{success}</p>
                </div>
              </div>
            )}

            {/* Categories List */}
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="w-8 px-6 py-3"></th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Category Name
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Description
                    </th>
                    <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {loading ? (
                    <tr>
                      <td colSpan={4} className="px-6 py-4 text-center text-gray-500">
                        Loading categories...
                      </td>
                    </tr>
                  ) : categories.length === 0 ? (
                    <tr>
                      <td colSpan={4} className="px-6 py-4 text-center text-gray-500">
                        <div className="flex flex-col items-center">
                          <Info className="h-12 w-12 text-gray-400 mb-2" />
                          <p>No categories found</p>
                          <p className="text-sm text-gray-400 mt-1">
                            Click the "Add Category" button to create your first category
                          </p>
                        </div>
                      </td>
                    </tr>
                  ) : (
                    categories.map((category) => (
                      <tr 
                        key={category.id} 
                        className="hover:bg-gray-50"
                        draggable
                        onDragStart={(e) => handleDragStart(e, category)}
                        onDragEnd={handleDragEnd}
                        onDragOver={handleDragOver}
                        onDrop={(e) => handleDrop(e, category)}
                      >
                        <td className="px-6 py-4">
                          <GripVertical className="h-5 w-5 text-gray-400 cursor-move" />
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          {category.name}
                        </td>
                        <td className="px-6 py-4 text-sm text-gray-500">
                          {category.description || '-'}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                          <div className="flex items-center justify-end space-x-3">
                            <button
                              onClick={() => {
                                setEditingCategory(category);
                                setShowModal(true);
                              }}
                              className="text-blue-600 hover:text-blue-900"
                            >
                              <Edit2 className="h-5 w-5" />
                            </button>
                            {showDeleteConfirm === category.id ? (
                              <div className="flex items-center space-x-2">
                                <button
                                  onClick={() => handleDelete(category.id)}
                                  className="text-red-600 hover:text-red-900"
                                >
                                  Confirm
                                </button>
                                <button
                                  onClick={() => setShowDeleteConfirm(null)}
                                  className="text-gray-600 hover:text-gray-900"
                                >
                                  Cancel
                                </button>
                              </div>
                            ) : (
                              <button
                                onClick={() => setShowDeleteConfirm(category.id)}
                                className="text-red-600 hover:text-red-900"
                              >
                                <Trash2 className="h-5 w-5" />
                              </button>
                            )}
                          </div>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <CategoryModal
        category={editingCategory}
        isOpen={showModal}
        onClose={() => {
          setShowModal(false);
          setEditingCategory(null);
        }}
        onSuccess={handleModalSuccess}
      />
    </div>
  );
}