import React from 'react';
import { Search, Clock, Scale } from 'lucide-react';

interface SearchAndFiltersProps {
  searchTerm: string;
  onSearchChange: (value: string) => void;
  activeFilter: 'no_first_visit' | 'no_first_weight' | null;
  onFilterClick: (filter: 'no_first_visit' | 'no_first_weight') => void;
}

export function SearchAndFilters({
  searchTerm,
  onSearchChange,
  activeFilter,
  onFilterClick
}: SearchAndFiltersProps) {
  return (
    <div className="bg-white shadow-lg rounded-lg mb-6 p-6">
      <div className="flex flex-col sm:flex-row gap-4">
        <div className="flex-1">
          <div className="relative">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
            <input
              type="text"
              placeholder="Search by name or ID..."
              value={searchTerm}
              onChange={(e) => onSearchChange(e.target.value)}
              className="pl-10 pr-4 py-2 w-full rounded-lg border border-gray-300 focus:ring-2 focus:ring-green-500 focus:border-transparent"
            />
          </div>
        </div>
        <div className="flex gap-4">
          <button
            onClick={() => onFilterClick('no_first_visit')}
            className={`flex items-center px-4 py-2 rounded-lg border-2 transition-colors duration-200 ${
              activeFilter === 'no_first_visit'
                ? 'bg-red-50 border-red-500 text-red-700'
                : 'border-gray-300 text-gray-700 hover:bg-gray-50'
            }`}
          >
            <Clock className={`h-4 w-4 mr-2 ${
              activeFilter === 'no_first_visit' ? 'text-red-500' : 'text-gray-400'
            }`} />
            No First Visit
          </button>
          <button
            onClick={() => onFilterClick('no_first_weight')}
            className={`flex items-center px-4 py-2 rounded-lg border-2 transition-colors duration-200 ${
              activeFilter === 'no_first_weight'
                ? 'bg-yellow-50 border-yellow-500 text-yellow-700'
                : 'border-gray-300 text-gray-700 hover:bg-gray-50'
            }`}
          >
            <Scale className={`h-4 w-4 mr-2 ${
              activeFilter === 'no_first_weight' ? 'text-yellow-500' : 'text-gray-400'
            }`} />
            No First Weight
          </button>
        </div>
      </div>
    </div>
  );
}